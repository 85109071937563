import React from 'react'
import {Link, withRouter} from 'react-router-dom'
import Moment from 'react-moment';
import {userContext, clubContext} from '../_helpers/userContext';
import API from '../api';
import $ from 'jquery'
import {Context} from "./MyContext";
import ReactTooltip from 'react-tooltip';
import {hideLoader, showLoader, toast} from "../_helpers/Loader";

const bootstrap = require('bootstrap');

const user = userContext;

class Layout extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            user: {},
            clubs: [],
            invitation: false,
            invitation_count: 0,
            club_members: [],
        };

        this.getInvitation = this.getInvitation.bind(this);
        this.logOut = this.logOut.bind(this)
    }

    async componentDidMount() {
        await (this.props.user_state === 'personal') && this.props.context.updateUserState('personal');
        await (this.props.user_state === 'club') && this.props.context.updateUserState(this.props.user_state, this.props.context.club_id);
        await (this.props.user_state === 'subinvestor') && this.props.context.updateUserState(this.props.user_state, this.props.context.sub_investor_id);
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        await this.setState({user: this.props.context.user, clubs: this.props.context.clubs});
        await this.getInvitation();
        $("#note-drop").on('click', function () {
            if ($(this).attr('aria-expanded') === 'false') {
                $(this).dropdown('toggle');
            } else {
                $(this).dropdown('toggle');
            }
        });
        $("#dropdownMenuButton1").on('click', function () {
            $(this).dropdown('toggle')
        });
        await this.context.updateNotification();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        $('aside .toggle_menu').on('click', () => {
            ($(window).width() < 768) && $('body').removeClass("sidenav-toggled");
        })
    }

    async getInvitation() {
        let check_invitation = false;
        let count = 0;
        API.get('club_invites/').then(res => {
            res.data.map(item => {
                if (item.status.toLowerCase() === 'pending') {
                    check_invitation = true;
                    count = count + 1;
                }
            });
            this.setState({invitation_count: count});
            this.setState({invitation: check_invitation});
        }).catch(err => {
            // console.log(err.response)
        });
    }

    logOut(event) {
        event.preventDefault();
        localStorage.clear();
        this.props.history.push('/login')
    }


    leaveGroup = async (event) => {
        showLoader();
        try {
            let res = await API.post("clubs/leave_club/", {club_id: this.context.club_id});
            await this.context.updateClubs();
            await this.context.updateUser();
            $('.modal').modal('hide');
            $(document.body).removeClass('modal-open');
            $('.modal-backdrop').remove();
            hideLoader();
            this.props.history.push("/");
        } catch (e) {
            hideLoader();
            if (e.response.status === 403) {
                toast.info("Action not allowed");
            }
        }
    };

    cutoutName(value) {
        if (value.length > 18) {
            return `${value.slice(0, 18)}...`
        } else {
            return value
        }
    }

    checkAdmin = () => {
        let filter_club = this.context.clubs.filter(item => item.id === parseInt(this.context.club_id));
        let main_club = (filter_club.length > 0) ? filter_club[0] : false;
        if (main_club !== false) {
            let check = main_club.club_admins.filter(item => item.user === this.context.user.id);
            main_club = (check.length > 0)
        }
        return main_club;
    };

    footerDisclaimer = () => {
        return (
        <div className="row">
          <div className="col-md-12 text-left my-auto pb-2">
            <h5 className="my-auto twelve-green">DISCLAIMER</h5><br/>
            <>
                <p>
                Twelve is not a registered investment, legal or tax advisor or a broker or dealer. All investment and financial options expressed by Twelve are intended to be used and must be used for information purposes only. Although best efforts are made to ensure all information is accurate and up to date, occasionally unintended errors and misprints may occur. It is especially important that you do your own analysis before making any investment based on your own personal circumstances. You must obtain independent financial expert advise for any investment option on this Site and you must independently research and verify any information relied upon that you find on the Site, whether or not you are making an investment decision. You are responsible for all risks and financial resources you use and any chosen investment option. You should not participate in any investment option unless you fully understand the nature of the transactions you are entering into and the extent of your exposure to loss. If you do not fully understand these risks, you must seek independent advice from a financial advisor. On Twelve.com past performance is not a guarantee of future returns, nor is it indicative of future performance. You must be aware that all investments involve risks, and the value of your investment will fluctuate over time and you may gain or lose money. All uses of the contents of the Site, other than for personal uses, are prohibited.<br/><br/>
                For more enquiries: <a href="mailto:support@twelvevest.com"><span className="twelve-green">support@twelvevest.com</span></a>
                <br/><br/>
                <p className="text-lg-center text-sm-left">
                    <a href="https://twelvevest.com/privacypolicy/"><u className="twelve-green">Privacy Policy</u></a> | <a href="https://twelvevest.com/EULA/"><u className="twelve-green">EULA</u></a> | <a href="https://twelvevest.com/termsandconditions/"><u className="twelve-green">Terms &amp; Conditions</u></a>
                </p>
                </p>
            </>
          </div>
        </div>)
    }

    personalLayout() {
        const date_format = {month: 'short', 'year': 'numeric', day: 'numeric'};
        const date = new Date();
        return (
            <div>
                <ReactTooltip/>
                <div id="app">
                    <div className="main-wrapper">
                        <nav className="navbar navbar-expand-lg main-navbar">
                            <a className="header-brand">
                                <img src="/assets/img/logo.png" alt="logo" className="header-brand-img"/>
                            </a>

                            <div className="form-inline mr-auto"/>

                            <a id="collapse-menu" href="#" data-toggle="sidebar" className="nav-link nav-link-lg"><i
                                className="ion ion-navicon-round text-logo"/></a>

                            <ul className="navbar-nav navbar-right">
                                {(this.props.context.notification) ? (
                                    <li className="dropdown dropdown-list-toggle">
                                        <a id="note-drop" href="#" data-toggle="dropdown" aria-expanded="false"
                                           className="nav-link  nav-link-lg beep"><i
                                            className="ion-ios-bell-outline text-main"/></a>
                                        <div className="dropdown-menu dropdown-list dropdown-menu-right">
                                            <div className="dropdown-header">Notifications
                                            </div>
                                            <div className="dropdown-list-content">
                                                {this.props.context.notification_list.map(item => (
                                                    <Link to={item.action} href="#" className="dropdown-item">
                                                        <i className={`${item.icon} text-primary`}/>
                                                        <div className="dropdown-item-desc">
                                                            <b>{item.message}</b>
                                                        </div>
                                                    </Link>
                                                ))}
                                            </div>
                                        </div>
                                    </li>
                                ) : ''}

                                <li className="mr-5 mytime">
                                    <div
                                        className="nav-link notification-toggle nav-link-lg"><i
                                        className="fa fa-clock text-white"/>
                                        <strong><Moment className="text-default"
                                                        format="MMM D, Y">{date}</Moment></strong>
                                    </div>
                                </li>
                                <li className="ml-5 mylogout">
                                    <Link to="/login" className="nav-link nav-link-lg full-screen-link">
                                        <i className="fa fa-sign-out-alt text-default"
                                           id="fullscreen-button"/><strong
                                        className="text-primary text-default"> Logout</strong>
                                    </Link>
                                </li>
                            </ul>
                        </nav>

                        <aside className="app-sidebar side">
                            <div className="app-sidebar__user">
                                <div className="dropdown">
                                    <div className="nav-link pl-2 pr-2 leading-none d-flex">
                                        <Link to="/profile">
                                            <img alt="profile image"
                                                 src={(this.props.context.user.profile_photo) ? (this.props.context.user.profile_photo) : ('https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png')}
                                                 className=" avatar-md rounded-circle"/>
                                        </Link>
                                        <span className="ml-2 d-lg-block">
									<span
                                        data-tip={`${this.props.context.user.first_name} ${this.props.context.user.last_name}`}
                                        className=" app-sidebar__user-name mt-5">{this.cutoutName(`${this.props.context.user.first_name} ${this.props.context.user.last_name}`)}</span><br/>
									<div className="dropdown d-inline">
                                            <span className="mybtn dropdown-toggle m-b-5 m-t-5"
                                                  type="button" id="dropdownMenuButton1" data-toggle="dropdown"
                                                  aria-haspopup="false" aria-expanded="false">
                                                    {this.props.context.userState}
												</span>
                                        <div className="dropdown-menu sidebar-dropdown"
                                             x-placement="bottom-start"
                                             style={{
                                                 position: 'absolute',
                                                 transform: 'translate3d(0px, 30px, 0px)',
                                                 top: '0px',
                                                 left: '0px',
                                                 willChange: 'transform'
                                             }}>
                                                {this.props.context.clubs.map(item => (
                                                    <Link key={item.id}
                                                          onClick={event => this.props.context.updateUserState('club', item.id)}
                                                          to={`/club/${item.id}/details`}
                                                          className="dropdown-item has-icon"><i
                                                        className="fa fa-users"/> {item.name}</Link>
                                                ))}
                                            {(this.props.context.sub_investors.length > 0) ? (
                                                <hr style={{marginTop: '0.2rem', marginBottom: '0.2rem'}}/>
                                            ) : ''}
                                            {this.props.context.sub_investors.map(item => (
                                                <Link key={item.id}
                                                      onClick={event => this.props.context.updateUserState('subinvestor', item.id)}
                                                      to={`/sub-investor/${item.id}/dashboard`}
                                                      className="dropdown-item has-icon"><i
                                                    className="fa fa-child"/> {item.first_name} {item.last_name}
                                                </Link>
                                            ))}
                                            </div>
											</div>
								</span>
                                    </div>
                                </div>
                            </div>
                            <ul className="side-menu">
                                {this.props.context.user.accredited === "YES" && this.props.context.user.accreditation_payment === true ? (<li className='mx-4 pb-3' style={{marginTop: "-20px"}}>
                                    <span
                                        className="mx-4 badge badge-pill badge-primary">Qualified  <img src={"/assets/img/twelve-Badge-copy-09.svg"} alt="qualified" width="20%" />
                                    </span>
                                </li>): ""}
                                <li>
                                    <Link to="/"
                                          className={`side-menu__item toggle_menu ${(this.props.active_menu === 'dash') ? 'active' : ''}`}><i
                                        className="side-menu__icon fa fa-pie-chart"/><span
                                        className="side-menu__label">Dashboard</span></Link>
                                </li>
                                <li>
                                    <Link to="/marketplace"
                                          className={`side-menu__item toggle_menu ${(this.props.active_menu === 'market') ? 'active' : ''}`}><i
                                        className="side-menu__icon fa fa-signal"/><span
                                        className="side-menu__label">Offers</span></Link>
                                </li>
                                <li>
                                    <Link to="/account/wallet"
                                          className={`side-menu__item toggle_menu ${(this.props.active_menu === 'wallet') ? 'active' : ''}`}><i
                                        className="side-menu__icon fa fa-wallet"/><span
                                        className="side-menu__label">Wallet</span></Link>
                                </li>
                                <li className={`slide ${(this.props.active_menu === 'club' || this.props.active_menu === 'inbox' || this.props.active_menu === 'invite') ? 'is-expanded' : ''}`}>
                                    <a className={`side-menu__item ${(this.props.active_menu === 'club' || this.props.active_menu === 'inbox' || this.props.active_menu === 'invite') ? 'active' : ''}`}
                                       data-toggle="slide" href="#"><i
                                        className="side-menu__icon fa fa-table"/><span
                                        className="side-menu__label">Clubs <sup>{(this.state.invitation_count > 0) ? (
                                        <i className="fa fa-bell text-orange"/>) : ''}</sup></span><i
                                        className="angle fa fa-angle-right"/></a>
                                    <ul className="slide-menu">
                                        <li>
                                            <Link to="/clubs"
                                                  className={`slide-item toggle_menu ${(this.props.active_menu === 'club') ? 'active' : ''}`}><i
                                                className="side-menu__icon fa fa-users"/><span
                                                className="side-menu__label">My Clubs</span></Link>
                                        </li>
                                        <li>
                                            <Link to="/chats"
                                                  className={`slide-item toggle_menu ${(this.props.active_menu === 'inbox') ? 'active' : ''}`}><i
                                                className="side-menu__icon fa fa-comments"/><span
                                                className="side-menu__label">Inbox</span></Link>
                                        </li>
                                        <li>
                                            <Link to="/club-invites"
                                                  className={`slide-item toggle_menu ${(this.props.active_menu === 'invite') ? 'active' : ''}`}><i
                                                className="side-menu__icon fa fa-vr-cardboard"/><span
                                                className="side-menu__label">Invitations {(this.state.invitation) ? (
                                                <sup><span
                                                    className="badge badge-orange"> {this.state.invitation_count}</span></sup>
                                            ) : ''}</span></Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/manage-files"
                                          className={`side-menu__item toggle_menu ${(this.props.active_menu === 'files') ? 'active' : ''}`}><i
                                        className="side-menu__icon fa fa-file-archive-o"/><span
                                        className="side-menu__label">My Files</span></Link>
                                </li>
                                <li>
                                    <Link to="/reports"
                                          className={`side-menu__item toggle_menu ${(this.props.active_menu === 'reports') ? 'active' : ''}`}><i
                                        className="side-menu__icon fa fa-file-pdf-o"/><span
                                        className="side-menu__label">Reports</span></Link>
                                </li>
                                {this.props.context.user.user_type === "fwp" ? 
                                <li>
                                    <Link to="/resources"
                                          className={`side-menu__item toggle_menu ${(this.props.active_menu === 'resources') ? 'active' : ''}`}><i
                                        className="side-menu__icon fa fa-question-circle"/><span
                                        className="side-menu__label">Resources</span></Link>
                                </li> : ""}
                                <li>
                                    <Link to="/account"
                                          className={`side-menu__item toggle_menu ${(this.props.active_menu === 'account') ? 'active' : ''}`}><i
                                        className="side-menu__icon fa fa-sliders"/><span
                                        className="side-menu__label">Settings</span></Link>
                                </li>
                                <li>
                                    <Link to="/login" className="side-menu__item"><i
                                        className="side-menu__icon fa fa-sign-out-alt"/><span
                                        className="side-menu__label">Logout</span></Link>
                                </li>

                            </ul>
                            {!this.props.context.user.accredited && !this.context.user.accreditation_form_submitted ? (<div className='text-center' title='Build a diversified portfolio with potentially higher rewards and higher risks.' style={{marginTop: "15%"}}>
                                {/* <Link to={'#'} className='btn text-blue text-bold' style={{backgroundColor: "white"}}><strong>Get Qualified <span className="badge badge-warning">Beta</span></strong> </Link> */}

                                <Link to={'/account/accreditation'} className='btn text-blue text-bold' style={{backgroundColor: "white"}}><strong>Get Qualified <span className="badge badge-warning">Beta</span></strong> </Link>
                            </div>) : ""}
                                               
                        </aside>

                        <div className="app-content">
                            {this.props.children}
                        </div>

                        <footer className="main-footer">
                            <div className="text-center">
                                Copyright &copy;Twelvevest 2021.
                                {this.footerDisclaimer()}
                            </div>
                        </footer>

                    </div>
                </div>
            </div>
        )
    }

    subInvestorLayout() {
        const date_format = {month: 'short', 'year': 'numeric', day: 'numeric'};
        const date = new Date();
        return (
            <div>
                <ReactTooltip/>
                <div id="app">
                    <div className="main-wrapper">
                        <nav className="navbar navbar-expand-lg main-navbar">
                            <a className="header-brand">
                                <img src="/assets/img/logo.png" alt="logo" className="header-brand-img"/>
                            </a>

                            <div className="form-inline mr-auto"/>

                            <a id="collapse-menu" href="#" data-toggle="sidebar" className="nav-link nav-link-lg"><i
                                className="ion ion-navicon-round text-logo"/></a>

                            <ul className="navbar-nav navbar-right">
                                {(this.props.context.notification) ? (
                                    <li className="dropdown dropdown-list-toggle">
                                        <a id="note-drop" href="#" data-toggle="dropdown" aria-expanded="false"
                                           className="nav-link  nav-link-lg beep"><i
                                            className="ion-ios-bell-outline text-main"/></a>
                                        <div className="dropdown-menu dropdown-list dropdown-menu-right">
                                            <div className="dropdown-header">Notifications
                                            </div>
                                            <div className="dropdown-list-content">
                                                {this.props.context.notification_list.map(item => (
                                                    <Link to={item.action} href="#" className="dropdown-item">
                                                        <i className={`${item.icon} text-primary`}/>
                                                        <div className="dropdown-item-desc">
                                                            <b>{item.message}</b>
                                                        </div>
                                                    </Link>
                                                ))}
                                            </div>
                                        </div>
                                    </li>
                                ) : ''}

                                <li className="mr-5 mytime">
                                    <div
                                        className="nav-link notification-toggle nav-link-lg"><i
                                        className="fa fa-clock text-white"/>
                                        <strong><Moment className="text-default"
                                                        format="MMM D, Y">{date}</Moment></strong>
                                    </div>
                                </li>
                                <li className="ml-5 mylogout">
                                    <Link to="/login" className="nav-link nav-link-lg full-screen-link">
                                        <i className="fa fa-sign-out-alt text-default"
                                           id="fullscreen-button"/><strong
                                        className="text-primary text-default">Logout</strong>
                                    </Link>
                                </li>
                            </ul>
                        </nav>

                        <aside className="app-sidebar side">
                            <div className="app-sidebar__user">
                                <div className="dropdown">
                                    <div className="nav-link pl-2 pr-2 leading-none d-flex">
                                        <Link to="/profile">
                                            <img alt="profile image"
                                                 src={(this.props.context.user.profile_photo) ? (this.props.context.user.profile_photo) : ('https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png')}
                                                 className=" avatar-md rounded-circle"/>
                                        </Link>
                                        <span className="ml-2 d-lg-block">
									<span
                                        data-tip={`${this.props.context.select_sub_investor.first_name} ${this.props.context.select_sub_investor.last_name}`}
                                        className=" app-sidebar__user-name mt-5">{this.cutoutName(`${this.props.context.select_sub_investor.first_name} ${this.props.context.select_sub_investor.last_name}`)}</span><br/>
									<div className="dropdown d-inline">
                                            <span className="mybtn dropdown-toggle m-b-5 m-t-5"
                                                  type="button" id="dropdownMenuButton1" data-toggle="dropdown"
                                                  aria-haspopup="false" aria-expanded="false">
                                                    Child
												</span>
                                            <div className="dropdown-menu sidebar-dropdown"
                                                 x-placement="bottom-start"
                                                 style={{
                                                     position: 'absolute',
                                                     transform: 'translate3d(0px, 30px, 0px)',
                                                     top: '0px',
                                                     left: '0px',
                                                     willChange: 'transform'
                                                 }}>
                                                <Link
                                                    onClick={event => this.props.context.updateUserState('personal', this.props.context.user.id)}
                                                    to="/"
                                                    className="dropdown-item has-icon" href="#"><i
                                                    className="fa fa-user"/> Personal Dashboard</Link>
                                                {(this.props.context.clubs.length > 0) ? (
                                                    <hr style={{marginTop: '0.2rem', marginBottom: '0.2rem'}}/>
                                                ) : ''}
                                                {this.props.context.clubs.map(item => (
                                                    <Link key={item.id}
                                                          onClick={event => this.props.context.updateUserState('club', item.id)}
                                                          to={`/club/${item.id}/details`}
                                                          className="dropdown-item has-icon"><i
                                                        className="fa fa-users"/> {item.name}</Link>
                                                ))}
                                                {(this.props.context.sub_investors.length > 1) ? (
                                                    <hr style={{marginTop: '0.2rem', marginBottom: '0.2rem'}}/>
                                                ) : ''}
                                                {this.props.context.sub_investors.map(item => (
                                                    (item.id !== this.props.context.sub_investor_id) ? (
                                                        <Link key={item.id}
                                                              onClick={event => this.props.context.updateUserState('subinvestor', item.id)}
                                                              to={`/sub-investor/${item.id}/dashboard`}
                                                              className="dropdown-item has-icon"><i
                                                            className="fa fa-child"/> {item.first_name} {item.last_name}
                                                        </Link>
                                                    ) : ''))}
                                            </div>
									</div>
								</span>
                                    </div>
                                </div>
                            </div>
                            <ul className="side-menu">
                                <li>
                                    <Link to={`/sub-investor/${this.props.context.sub_investor_id}/dashboard`}
                                          className={`side-menu__item toggle_menu ${(this.props.active_menu === 'dash') ? 'active' : ''}`}><i
                                        className="side-menu__icon fa fa-pie-chart"/><span
                                        className="side-menu__label">Dashboard</span></Link>
                                </li>
                                <li>
                                    <Link to={`/sub-investor/${this.props.context.sub_investor_id}/marketplace`}
                                          className={`side-menu__item toggle_menu ${(this.props.active_menu === 'market') ? 'active' : ''}`}><i
                                        className="side-menu__icon fa fa-signal"/><span
                                        className="side-menu__label">Offers</span></Link>
                                </li>
                                <li>
                                    <Link to="/account"
                                          className={`side-menu__item toggle_menu ${(this.props.active_menu === 'account') ? 'active' : ''}`}><i
                                        className="side-menu__icon fa fa-sliders"/><span
                                        className="side-menu__label">Settings</span></Link>
                                </li>
                                <li>
                                    <Link to="/login" className="side-menu__item"><i
                                        className="side-menu__icon fa fa-sign-out-alt"/><span
                                        className="side-menu__label">Logout</span></Link>
                                </li>

                            </ul>
                        </aside>

                        <div className="app-content">
                            {this.props.children}
                        </div>

                        <footer className="main-footer">
                            <div className="text-center">
                                Copyright &copy;Twelvevest 2021.
                                {this.footerDisclaimer()}
                            </div>
                        </footer>

                    </div>
                </div>
            </div>
        )
    }

    clubLayout() {
        const date_format = {month: 'short', 'year': 'numeric', day: 'numeric'};
        const date = new Date();
        return (
            <div>
                <div id="app">
                    <ReactTooltip/>
                    <div className="main-wrapper">
                        <nav className="navbar navbar-expand-lg main-navbar">
                            <a className="header-brand">
                                <img src="/assets/img/logo.png" alt="logo" className="header-brand-img"/>
                            </a>

                            <div className="form-inline mr-auto"/>

                            <a id="collapse-menu" href="#" data-toggle="sidebar" className="nav-link nav-link-lg"><i
                                className="ion ion-navicon-round text-logo"/></a>

                            <ul className="navbar-nav navbar-right">
                                {(this.props.context.notification) ? (
                                    <li className="dropdown dropdown-list-toggle">
                                        <a id="note-drop" href="#" data-toggle="dropdown" aria-expanded="false"
                                           className="nav-link  nav-link-lg beep"><i
                                            className="ion-ios-bell-outline text-main"/></a>
                                        <div className="dropdown-menu dropdown-list dropdown-menu-right">
                                            <div className="dropdown-header">Notifications
                                            </div>
                                            <div className="dropdown-list-content">
                                                {this.props.context.notification_list.map(item => (
                                                    <Link to={item.action} href="#" className="dropdown-item">
                                                        <i className={`${item.icon} text-primary`}/>
                                                        <div className="dropdown-item-desc">
                                                            <b>{item.message}</b>
                                                        </div>
                                                    </Link>
                                                ))}
                                            </div>
                                        </div>
                                    </li>
                                ) : ''}
                                <li className="mr-5 mytime">
                                    <div
                                        className="nav-link notification-toggle nav-link-lg"><i
                                        className="fa fa-clock text-white"/>
                                        <strong><Moment className="text-default"
                                                        format="MMM D, Y">{date}</Moment></strong>
                                    </div>
                                </li>
                                <li className="ml-5 mylogout">
                                    <Link to="/login" className="nav-link nav-link-lg full-screen-link">
                                        <i className="fa fa-sign-out-alt text-default"
                                           id="fullscreen-button"/><strong
                                        className="text-primary text-default"> Logout</strong>
                                    </Link>
                                </li>
                            </ul>
                        </nav>

                        <aside className="app-sidebar side">
                            <div className="app-sidebar__user">
                                <div className="dropdown">
                                    <div className="nav-link pl-2 pr-2 leading-none d-flex">
                                        <Link to="/profile">
                                            <img alt="profile image"
                                                 src={(this.props.context.user.profile_photo) ? (this.props.context.user.profile_photo) : ('https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png')}
                                                 className=" avatar-md rounded-circle"/>
                                        </Link>
                                        <span className="ml-2 d-lg-block">
									<span
                                        data-tip={`${this.props.context.user.first_name} ${this.props.context.user.last_name}`}
                                        className=" app-sidebar__user-name mt-5">{this.cutoutName(`${this.props.context.user.first_name} ${this.props.context.user.last_name}`)}</span><br/>
									<div className="dropdown d-inline show">
												<span data-tip={this.props.context.userState}
                                                      className="mybtn dropdown-toggle m-b-5 m-t-5"
                                                      type="button" id="dropdownMenuButton1" data-toggle="dropdown"
                                                      aria-haspopup="false" aria-expanded="false">
                                                    {(this.props.context.userState.length > 18) ? `${this.props.context.userState.slice(0, 18)}...` : (this.props.context.userState)}
												</span>
												<div className="dropdown-menu sidebar-dropdown"
                                                     x-placement="bottom-start"
                                                     style={{
                                                         position: 'absolute',
                                                         transform: 'translate3d(0px, 30px, 0px)',
                                                         top: '0px',
                                                         left: '0px',
                                                         willChange: 'transform'
                                                     }}>
                                                    <Link
                                                        onClick={event => this.props.context.updateUserState('personal', this.props.context.user.id)}
                                                        to="/"
                                                        className="dropdown-item has-icon" href="#"><i
                                                        className="fa fa-user"/> Personal Dashboard</Link>
                                                    {(this.props.context.clubs.length > 1) ? (
                                                        <hr style={{marginTop: '0.2rem', marginBottom: '0.2rem'}}/>
                                                    ) : ''}
                                                    {this.props.context.clubs.map(item => (
                                                        (item.id != this.props.context.club_id) ? (
                                                            <Link key={item.id}
                                                                  onClick={event => this.props.context.updateUserState('club', item.id)}
                                                                  to={`/club/${item.id}/details`}
                                                                  className="dropdown-item has-icon" href="#"><i
                                                                className="fa fa-users"/> {item.name}</Link>
                                                        ) : ''
                                                    ))}
                                                    {(this.props.context.sub_investors.length > 0) ? (
                                                        <hr style={{marginTop: '0.2rem', marginBottom: '0.2rem'}}/>
                                                    ) : ''}
                                                    {this.props.context.sub_investors.map(item => (
                                                        <Link key={item.id}
                                                              onClick={event => this.props.context.updateUserState('subinvestor', item.id)}
                                                              to={`/sub-investor/${item.id}/dashboard`}
                                                              className="dropdown-item has-icon"><i
                                                            className="fa fa-child"/> {item.first_name} {item.last_name}
                                                        </Link>
                                                    ))}
												</div>
											</div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <ul className="side-menu">
                                <li>
                                    <Link to={`/club/${this.props.context.club_id}/details`}
                                          className={`side-menu__item toggle_menu ${(this.props.active_menu === 'club-detail') ? 'active' : ''}`}><i
                                        className="side-menu__icon fa fa-th"/><span
                                        className="side-menu__label">Club Dashboard</span></Link>
                                </li>
                                <li>
                                    <Link to={`/club/${this.props.context.club_id}/marketplace`}
                                          className={`side-menu__item toggle_menu ${(this.props.active_menu === 'market') ? 'active' : ''}`}><i
                                        className="side-menu__icon fa fa-signal"/><span
                                        className="side-menu__label">Offers</span></Link>
                                </li>
                                <li>
                                    <Link to="/clubs"
                                          className={`side-menu__item toggle_menu ${(this.props.active_menu === 'club') ? 'active' : ''}`}><i
                                        className="side-menu__icon fa fa-users"/><span
                                        className="side-menu__label">My Clubs</span></Link>
                                </li>
                                <li>
                                    <Link to="/chats"
                                          className={`side-menu__item toggle_menu ${(this.props.active_menu === 'inbox') ? 'active' : ''}`}><i
                                        className="side-menu__icon fa fa-comments"/><span
                                        className="side-menu__label">Inbox </span></Link>
                                </li>
                                <li>
                                    <Link to="/club-invites"
                                          className={`side-menu__item toggle_menu ${(this.props.active_menu === 'invite') ? 'active' : ''}`}><i
                                        className="side-menu__icon fa fa-vr-cardboard"/><span
                                        className="side-menu__label">Invitations {(this.state.invitation) ? (
                                        <sup><span
                                            className="badge badge-orange"> {this.state.invitation_count}</span></sup>
                                    ) : ''}</span></Link>
                                </li>
                                {(this.checkAdmin()) ? (
                                    <li>
                                        <Link to={`/manage-club/${this.props.context.club_id}`}
                                              className={`side-menu__item toggle_menu ${(this.props.active_menu === 'settings') ? 'active' : ''}`}><i
                                            className="side-menu__icon fa fa-sliders"/><span
                                            className="side-menu__label">Club Settings </span></Link>
                                    </li>
                                ) : ''}

                                <li>
                                    <a data-toggle="modal" data-target="#deleteModal"
                                       className="side-menu__item pointer-cursor"><i
                                        className="side-menu__icon fa fa-close"/><span
                                        className="side-menu__label">Leave Club</span></a>
                                </li>
                                <li>
                                    <Link to="/login" className="side-menu__item"><i
                                        className="side-menu__icon fa fa-sign-out-alt"/><span
                                        className="side-menu__label">Logout</span></Link>
                                </li>

                            </ul>
                        </aside>

                        <div className="app-content">
                            {this.props.children}
                        </div>

                        <footer className="main-footer">
                            <div className="text-center">
                                Copyright &copy;Twelvevest 2021.
                                {this.footerDisclaimer()}
                            </div>
                        </footer>

                    </div>
                </div>
                <div id="deleteModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h4 className="modal-title" id="example-Modal3">Are you sure?</h4>
                                <button id="close-modal" type="button" className="close" data-dismiss="modal"
                                        aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <p>You are about to exit group: <strong>{this.context.userState}</strong></p>
                                        <p>Note: This action can not be reversed.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="col-lg-12 mx-auto text-center">
                                    <button
                                        type="button"
                                        className="btn btn-outline-green mr-3"
                                        data-dismiss="modal">Close
                                    </button>
                                    <button onClick={e => this.leaveGroup(e)} className="btn btn-danger">Exit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        if (this.props.context.userState.toLowerCase() === 'personal') {
            return this.personalLayout();
        } else if (this.props.context.userState.toLocaleLowerCase() === 'subinvestor') {
            return this.subInvestorLayout();
        } else {
            return this.clubLayout();
        }
    }
}

export default withRouter(Layout)
