import React, {Component} from 'react';
import ReactTooltip from "react-tooltip";
import Layout from "../Layout";
import {Context} from "../MyContext";
import NumberFormat from "react-number-format";
import {showLoader, hideLoader, toast, mixpanel, trackMixpanel} from '../../_helpers/Loader';
import API from '../../api';
import $ from "jquery";
import Moment from "react-moment";
import moment from 'moment';
import {Link} from "react-router-dom";

class SubInvestor extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            sub_investor: [],
            form: {
                alternate: "active"
            },
            certificate_upload: {}
        }
    }

    async componentDidMount() {
        trackMixpanel('SubInvestorListView', false, false, this.context.user.email)
        showLoader();
        // let today = new Date()
        // let start_date = today.setDate(today.getMonth() - 216)
        await this.getInvestor();
        $('.date').datepicker({
            startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
            format: 'yyyy-mm-dd',
            todayHighlight: true,
            autoclose: true,
        }).datepicker("setDate", 'now');
        hideLoader();
    }

    getInvestor = async () => {
        try {
            const res = await API.get('sub_investor/');
            this.setState({sub_investor: res.data})
        } catch (e) {

        }
    };

    addInvestor = async (event) => {
        event.preventDefault();
        const postData = {...this.state.form};
        postData.dob = $('#date').val();
        postData.last_name = this.context.user.last_name;
        showLoader()
        try {
            let res = await API.post('sub_investor/', postData);
            await this.getInvestor();
            await this.context.updateUser();
            $('.modal').modal('hide');
            $(document.body).removeClass('modal-open');
            $('.modal-backdrop').remove();
            toast.success("Child account added successfully");
            (this.state.form.status !== 'active') && $("#infoModal").modal('show');
            hideLoader()
        } catch (e) {
            hideLoader()
            // console.log(e.response);
        }
    };

    imageUpload = (event) => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({certificate_upload: {...this.state.certificate_upload, file: e.target.result}});
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    certificateUpload = async (event) => {
        event.preventDefault();
        showLoader();
        try {
            const res = await API.post(`sub_investor/${this.state.certificate_upload.id}/upload_certification/`, this.state.certificate_upload);
            await this.context.updateSubinvestor();
            toast.success("Successfully Uploaded");
            $('.modal').modal('hide');
            $(document.body).removeClass('modal-open');
            $('.modal-backdrop').remove();
            setInterval(() => {
                toast.success("Awaiting admin approval")
            }, 4000);
            await this.getInvestor();
            hideLoader();
        } catch (e) {
            hideLoader();
            toast.error("Something went wrong");
            // console.log(e.response);
        }
    }

    render() {
        return (
            <Layout active_menu={'settings'} user_state={'personal'} context={this.context}>
                <ReactTooltip/>
                <div className="section">
                    <div className="breadcrumb" style={{paddingRight: '0'}}>
                        <div className="col-sm-6" style={{paddingLeft: '0'}}>
                            <Link onClick={event => window.history.back()}> <span className="fa fa-chevron-left"/> Back</Link>
                        </div>
                        <div className="col-sm-6 float-right text-right" style={{paddingRight: '0'}}>
                            <button onClick={event => this.setState({
                                form: {
                                    status: "active"
                                }
                            })} data-toggle='modal' data-target='#addModal'
                                    className="btn  btn-social m-b-5 btn-blue text-white"><i
                                className="fa fa-plus-square"/> Add Child Account
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="float-left">Child Accounts' List</h4>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-striped mb-0 text-nowrap">
                                        <tbody>
                                        <tr>
                                            <th>Name</th>
                                            <th>Target Net worth</th>
                                            <th>Date of birth</th>
                                            <th>Date &#8212; Time Created</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                        {this.state.sub_investor.map(item => (
                                            <tr key={item.id}>
                                                <td>{item.first_name} {item.last_name}</td>
                                                <td>₦{parseFloat(item.target_networth).toLocaleString()}</td>
                                                <td><Moment format="MMM D, Y">{item.dob}</Moment></td>
                                                <td><Moment format="MMM D, Y HH:mm">{item.created_at}</Moment></td>
                                                <td className="text-capitalize">{item.status}</td>
                                                <td>
                                                    {(item.status === 'action required') ? (
                                                        <span
                                                            onClick={event => this.setState({
                                                                certificate_upload: {
                                                                    ...this.state.certificate_upload,
                                                                    id: item.id
                                                                }
                                                            })}
                                                            data-toggle='modal' data-target='#certificationModal'
                                                            className="badge btn-blue text-white pointer-cursor">Upload Birth Certificate</span>
                                                    ) : (item.status === 'pending approval') ? (
                                                        <span
                                                            onClick={event => this.setState({
                                                                certificate_upload: {
                                                                    ...this.state.certificate_upload,
                                                                    id: item.id
                                                                }
                                                            })}
                                                            data-toggle='modal' data-target='#certificationModal'
                                                            className="badge btn-blue text-white pointer-cursor btn-info disabled">Upload Birth Certificate</span>
                                                    ) : ''}
                                                </td>
                                            </tr>))}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="addModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h5 className="modal-title" id="example-Modal3">Add child account</h5>
                                <button type="button"
                                        className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <form onSubmit={event => this.addInvestor(event)}>
                                <div className="modal-body">
                                    <div className="form-group mb-3">
                                        <label htmlFor="message-text"
                                               className="form-control-label">First Name</label>
                                        <input required value={this.state.form.first_name}
                                               onChange={event => this.setState({
                                                   form: {
                                                       ...this.state.form,
                                                       first_name: event.target.value
                                                   }
                                               })} className="form-control"/>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="message-text"
                                               className="form-control-label">Last Name</label>
                                        <input readOnly required value={this.context.user.last_name}
                                               className="form-control"/>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label>Does the child have a different last name?</label><br/>
                                        <label htmlFor="no" className="mr-4">
                                            <input onChange={event => {
                                                this.setState({
                                                    form: {...this.state.form, status: event.target.value}
                                                })
                                            }} value="active"
                                                   checked={this.state.form.status === "active"} type="radio"
                                                   name="alternate" id="no"/> No
                                        </label>
                                        <label htmlFor="yes">
                                            <input onChange={event => {
                                                this.setState({
                                                    form: {...this.state.form, status: event.target.value}
                                                })
                                            }} value="action required"
                                                   checked={this.state.form.status === "action required"} type="radio"
                                                   name="alternate" id="yes"/> Yes
                                        </label>
                                    </div>
                                    {(this.state.form.status === "action required") ? (
                                        <div className="form-group mb-3">
                                            <label htmlFor="message-text"
                                                   className="form-control-label">Alternative Last Name</label>
                                            <input required value={this.context.user.temp_last_name}
                                                   className="form-control"
                                                   placeholder="Kindly provide an alternate last name"/>
                                        </div>
                                    ) : ''}
                                    <div className="form-group mb-3">
                                        <label htmlFor="message-text"
                                               className="form-control-label">Child Date of Birth:</label>
                                        <div className="input-group date" data-provide="datepicker">
                                            <input required id="date" readOnly type="text" className="form-control"/>
                                            <div className="input-group-addon">
                                                <span className="fa fa-calendar"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="message-text"
                                               className="form-control-label">Target Net worth:</label>
                                        <NumberFormat required
                                                      value={this.state.form.target_networth}
                                                      thousandSeparator={true} prefix={'₦'}
                                                      onValueChange={(values) => {
                                                          const {formattedValue, value} = values;
                                                          this.setState({
                                                              form: {
                                                                  ...this.state.form,
                                                                  target_networth: value
                                                              }
                                                          })
                                                      }} className="form-control"
                                                      placeholder="Enter Amount"/>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-green" data-dismiss="modal">Close
                                    </button>
                                    <button type="submit" className="btn btn-blue text-white">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div id="infoModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h5 className="modal-title" id="example-Modal3">Child account info</h5>
                                <button type="button"
                                        className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>Child account pending approval. <br/> Kindly upload child’s birth
                                    certificate in settings
                                    to use a different name.</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-green" data-dismiss="modal">OK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="certificationModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h5 className="modal-title" id="example-Modal3">Upload Certificate</h5>
                                <button type="button"
                                        className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <form onSubmit={event => this.certificateUpload(event)}>
                                <div className="modal-body">
                                    <div className="form-group mb-3">
                                        <label htmlFor="message-text"
                                               className="form-control-label">Last</label>
                                        <input required value={this.state.certificate_upload.last_name}
                                               onChange={event => this.setState({
                                                   certificate_upload: {
                                                       ...this.state.certificate_upload,
                                                       last_name: event.target.value
                                                   }
                                               })} className="form-control"/>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="message-text"
                                               className="form-control-label">Certificate</label>
                                        <input accept="image/*" id="get-image"
                                               onChange={event => this.imageUpload(event)} required type="file"
                                               className="form-control"/>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-green" data-dismiss="modal">Close
                                    </button>
                                    <button type="submit" className="btn btn-blue text-white">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default SubInvestor;
