import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import uuidv1 from 'uuid';

import Moment from 'react-moment';
import NumberFormat from 'react-number-format';

import Layout from '.././Layout';
import API from '../../api';
import {CircularProgressbar} from "react-circular-progressbar";
import {userContext} from "../../_helpers/userContext";
import {hideLoader, mixpanel, showLoader, toast, trackMixpanel, Utils} from "../../_helpers/Loader";
import {Context} from "../MyContext";
import $ from "jquery";
import ReactTooltip from "react-tooltip";
import daterange from 'daterangepicker';
import "daterangepicker/daterangepicker.css";
import moment from 'moment';
import Chart from 'chart.js';
import excludeList from '../excludeList';

class ClubPortfolio extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            portfolio: '',
            club: {},
            total_networth: 0,
            total_networth_ngn: 0,
            total_networth_gbp: 0,
            total_amount: 0,
            total_return: 0,
            investment_currency: "ALL",
            investments: [],
            interested_investments: [],
            percentage_interest: 0,
            activities: [],
            members: [],
            contributions: [],
            target: 0,
            chart_data: {},
            total_deposits: 0,
            total_investment: 0,
            total_expected_return: 0,
            portfolio_currency: "NGN",
            total_interest: 0,
            total_asset_value_usd_value: 0,
            total_asset_value_ngn_value: 0,
            total_asset_value_gbp_value: 0,
            total_liability_value_ngn: 0,
            total_liability_value_usd: 0,
            total_liability_value_gbp: 0,
            total_roi: 0,
            total_percentage: 0,
            modal: 'none',
            message: '',
            max_members: 0,
            multiple_data: [],
            total_amount_usd: 0,
            total_amount_gbp: 0,
            total_return_usd: 0,
            total_return_gbp: 0,
            percentage_interest_usd: 0,
            percentage_interest_gbp: 0,
            interested_investments_count: 0,
            modify_no_of_units: null,
            interest_investment_id: null,
            interest_investment_investment_id: null 
        };

        this.onChangeMember = this.onChangeMember.bind(this);
        this.sendGroupMsg = this.sendGroupMsg.bind(this);
        this.updateClubUnits = this.updateClubUnits.bind(this);
    }

    async componentDidMount() {
        trackMixpanel('ClubPortfolioView', false, false, this.context.user.email)
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
        showLoader();
        await this.getPortfolio();
        await this.getClubPortfolio();
        await this.getInvestment();
        await this.getInterestedInvestment();
        $(window).resize(() => {
            window.location.reload();
        });
        var maxHeight = Math.max.apply(null, $(".boxes .card").map(function () {
            return $(this).height();
        }).get());
        $(".boxes .card").height(maxHeight);
        await this.getPieInfo();
        ReactTooltip.rebuild();
        hideLoader();
    }

    componentWillMount() {
        if (this.context.user.completed_onboarding === null || this.context.user.completed_onboarding === undefined) {
            window.location.reload();
        }
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
    }

    async componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.location.pathname !== this.props.location.pathname) {
            showLoader();
            await this.getPortfolio(nextProps.match.params.id);
            hideLoader();
        }
    }

    removeHeight() {
        var maxHeight = Math.max.apply(null, $(".boxes .card").map(function () {
            return $(this).height();
        }).get());
        $(".boxes .card").css({"height": 'unset'});
    }

    async getClubPortfolio() {
        try {
            let res = await API.get(`clubs/${this.props.match.params.id}/get_stats/`);
            let data = res.data;
            let networth_ngn = data.total_asset_value_ngn - data.total_liability_value_ngn;
            let networth_usd = data.total_asset_value_usd - data.total_liability_value_usd;
            let networth_gbp = data.total_asset_value_gbp - data.total_liability_value_gbp;
            let target = ((networth_ngn / parseFloat(this.state.club.networth_goal)) * 100).toFixed(2);
            if (this.state.target <= 0 || target < 0) {
                target = 0
            }
            this.setState({
                total_asset_value_ngn: data.total_asset_value_ngn,
                total_asset_value_usd: data.total_asset_value_usd,
                total_asset_value_gbp: data.total_asset_value_gbp,
                total_networth_ngn: networth_ngn,
                total_networth_usd: networth_usd,
                total_networth_gbp: networth_gbp,
                total_liability_value_ngn: data.total_liability_value_ngn,
                total_liability_value_usd: data.total_liability_value_usd,
                total_liability_value_gbp: data.total_liability_value_gbp,
                target: (target != Infinity) ? (target) : 0
            });
            (target < 0) && (this.setState({radial_colour: 'circle-danger'}));
            this.setState({
                chart_data: {
                    asset: data.assets_total_quarterly,
                    liability: data.liabilities_total_quarterly,
                    networth: data.quarterly_networth
                }
            });
        } catch (e) {
            toast.error("Something Went Wrong");
            // console.log(e.response)
        }
    }

    rateConverted(currency, from_amount, to_amount) {
        if (currency !== "NGN") {
            return parseFloat(from_amount) / parseFloat(to_amount)
        } else {
            return parseFloat(1)
        }
    }

    async getInvestment() {
        try {
            let res = await API.get(`clubs/${this.props.match.params.id}/get_investments_stats/`);
            if (res) {
                this.setState({
                    investments: res.data.investments,
                    total_amount: res.data.total_amount,
                    total_amount_usd: res.data.total_amount_usd,
                    total_amount_gbp: res.data.total_amount_gbp,
                    total_return: res.data.expected_return,
                    total_return_usd: res.data.expected_return_usd,
                    total_return_gbp: res.data.expected_return_gbp,
                    percentage_interest: (res.data.percentage) ? (res.data.percentage) : 0,
                    percentage_interest_usd: (res.data.percentage_usd) ? (res.data.percentage_usd) : 0,
                    percentage_interest_gbp: (res.data.percentage_gbp) ? (res.data.percentage_gbp) : 0,
                });
            }
        } catch (e) {
            toast.error("Something Went Wrong");
            // console.log(e.response);
        }
    }

    async getInterestedInvestment() {
        try {
            let res = await API.get(`show_club_interest/${this.props.match.params.id}/get_interested_investment_by_members/`);
            if (res) {
                this.setState({
                    interested_investments: res.data.result
                });
            }
        } catch (e) {
            // toast.error("Something Went Wrong");
            console.log(e.response);
        }
    }

    async getPortfolio() {
        console.log(this.props.match.params.id, "verification")
        try {
            let res = await API.get(`clubs/${this.props.match.params.id}/`);
            let data = res.data;
            this.setState({portfolio: data});
            let networth = data.total_asset_value - data.total_liability_value;
            let target = ((networth / data.networth_goal) * 100).toFixed(2);
            this.setState({total_networth: networth});
            this.setState({target: target});
            this.setState({max_members: data.club_subscription[0].package.max_members});
            this.setState({
                club: data,
                activities: data.club_activities.slice(0, 4),
                members: data.club_members,
                contributions: data.club_deposits.slice(0, 4)
            });
            this.setState({
                chart_data: {
                    asset: data.assets_total_quarterly,
                    liability: data.liabilities_total_quarterly,
                    networth: data.quarterly_networth
                }
            });
            this.setState({investments: data.club_clubinvestments});
            let total_investment = 0;
            let total_expected_return = 0;
            let total_roi = 0;
            let total_interest = 0;
            let total_percentage = 0;
            for (var i = 0; i < data.club_clubinvestments.length; i++) {
                total_investment = total_investment + parseFloat(data.club_clubinvestments[i].value);
                total_expected_return = total_expected_return + parseFloat(data.club_clubinvestments[i].expected_return);
                total_roi = total_roi + parseFloat(data.club_clubinvestments[i].roi);
            }
            total_interest = total_expected_return - total_investment;
            total_percentage = ((total_interest) / total_investment) * 100;
            if (data.club_deposits.length > 0) {
                this.setState({total_deposits: data.club_deposits[0].total_deposits})
            }
        } catch (e) {
            toast.error("Something Went Wrong");
            // console.log(e.response)
        }
    }

    async quarterlyChart(labels, dataset, is_empty) {
        var canvas = document.getElementById("myChart");
        var ctx = canvas.getContext('2d');
        let title = 'Add or buy asset to see your asset distribution chart here.';
        var options = {
            title: {
                display: true,
                text: title,
                position: 'top'
            },
            responsive: true,
            maintainAspectRatio: true,
            rotation: -0.7 * Math.PI,
            animation: {
                duration: 500,
                easing: "easeOutQuart",
                onComplete: function () {
                    var ctx = this.chart.ctx;
                    ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontFamily, 'normal', Chart.defaults.global.defaultFontFamily);
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'bottom';

                    this.data.datasets.forEach(function (dataset) {
                        for (var i = 0; i < dataset.data.length; i++) {
                            var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                                total = dataset._meta[Object.keys(dataset._meta)[0]].total,
                                mid_radius = model.innerRadius + (model.outerRadius - model.innerRadius) / 2,
                                start_angle = model.startAngle,
                                end_angle = model.endAngle,
                                mid_angle = start_angle + (end_angle - start_angle) / 2;

                            var x = mid_radius * Math.cos(mid_angle);
                            var y = mid_radius * Math.sin(mid_angle);

                            ctx.fillStyle = '#fff';
                            var percent = String(Math.round(dataset.data[i] / total * 100)) + "%";
                            //Don't Display If Legend is hide or value is 0
                            if (dataset.data[i] != 0) {
                                // Display percent in another line, line break doesn't work for fillText
                                ctx.fillText(percent, model.x + x, model.y + y + 15);
                            }
                        }
                    });
                }
            },
            legend: {
                position: 'bottom'
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {

                        let label = data.labels[tooltipItem.index];
                        let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        return ` ${label}: ₦${value.toLocaleString()}`

                    }
                }
            }
        };
        if (!is_empty) {
            delete options['title']
        }
        var oilData = {
            labels: labels,
            datasets: [
                {
                    data: dataset,
                    backgroundColor: [
                        "#47C4AA",
                        "#23414D",
                        "#FB703E",
                        "#F2BF5E",
                        "#F28B50",
                        "#fcb8a9",
                        "#177f68",
                        "#41738b",
                        "#99d249",
                        "#d2fb3e",
                        "#3e57fb"
                    ]
                }]
        };

        var pieChart = new Chart(ctx, {
            type: 'pie',
            data: oilData,
            options: options
        });

    }

    getPieInfo = async () => {
        try {
            const res = await API.post(`clubs/${this.props.match.params.id}/get_asset_info/`)
            let data = res.data;
            let labels = Object.keys(data), dataset = [];
            labels = labels.filter(item => item !== 'is_empty');
            labels.map(item => {
                dataset.push(data[item])
            })
            this.quarterlyChart(labels, dataset, data.is_empty)
        } catch (e) {
            console.log(e.response)
        }
    }

    checkAdmin(item) {
        if (item !== undefined) {
            for (let i = 0; i < item.length; i++) {
                if (item[i].user.id === this.context.user.id && item[i].user_role === 'admin') {
                    return true;
                }
            }
            return false
        } else {
            return false
        }
    }

    getAdminEmail(item) {
        if (item !== undefined) {
            for (let i = 0; i < item.length; i++) {
                if (item[i].user.id === this.context.user.id && item[i].user_role === 'admin') {
                    return item[i].user.email.toLowerCase();
                }
            }
            return false
        } else {
            return false
        }
    }

    async onChangeMember(event, user_id) {
        let postData = {
            club_id: this.state.club.id,
            user_id: user_id
        };
        if (event.target.checked) {
            showLoader();
            try {
                await API.post('clubs/add_admin/', postData);
                toast.success("Admin Role Created");
                await this.getPortfolio();
                hideLoader();
            } catch (e) {
                toast.error("Something Went Wrong");
                hideLoader();
                // console.log(e.response)
            }
        } else {
            showLoader();
            try {
                await API.post('clubs/remove_admin/', postData);
                toast.success("Admin Role Changed");
                await this.getPortfolio();
                hideLoader();
            } catch (e) {
                toast.error("Something Went Wrong");
                hideLoader();
                // console.log(e.response)
            }
        }
    }

    // async sendMessage(user_id){
    //     let postData = {receiver_id: user_id};
    //     try{
    //         console.log("new", postData);
    //         let res = await API.post('messages/get_all_messages_with_user/', postData);
    //         if(res.data.length === 0){
    //             try{
    //                 let res2 = await API.post("messages/")
    //             }
    //         }
    //     }catch (e) {
    //         console.log(e.response)
    //     }
    // }

    async sendGroupMsg(event) {
        event.preventDefault();
        let postData = {
            club_id: this.state.club.id,
            message: this.state.message,
        };
        try {
            let res = await API.post('messages/send_group_message/', postData);
        } catch (e) {
            // console.log(e.response)
        }
    }

    async updateClubUnits(event) {
        event.preventDefault();
        showLoader()
        try {
            let res = await API.put(`show_club_interest/${this.state.interest_investment_id}/update_proposed_units/`, {
                proposed_unit: this.state.modify_no_of_units
            })
            if (res){
                await this.getInterestedInvestment();
                toast.success("Proposed units has been updated")
            }
        } catch(e){
            toast.error("Error updating units")
            console.log(e, "hello there")
        }
        hideLoader()
    }

    async deleteClubInterest(event) {
        event.preventDefault();
        showLoader()
        try {
            let res = await API.delete(`show_club_interest/${this.state.interest_investment_id}/delete_club_interest/`)
            if (res){
                await this.getInterestedInvestment();
                toast.success("Interest deleted and fund refunded to the members")
            }
        } catch(e){
            toast.error("Error deleting interest")
        }
        hideLoader()
    }

    dateToNum(d) {
        d = d.split("-");
        return Number(d[2] + d[1] + d[0]);
    }

    processDates(payout_dates, the_amount, roi, paid_times) {
        let multiple_data = []
        let count = paid_times
        let dateToNum = this.dateToNum
        // payout_dates.sort(function (a, b) {
        //     return dateToNum(a) - dateToNum(b);
        // });
        // console.log(payout_dates)
        payout_dates.map((item, key) => {
            let amount = 0;
            if (key + 1 < payout_dates.length) {
                amount = parseFloat(the_amount) * ((parseFloat(roi) / payout_dates.length) / 100)
            } else {
                amount = parseFloat(the_amount) * ((parseFloat(roi) / payout_dates.length) / 100) + parseFloat(the_amount)
            }
            multiple_data.push({
                date: item, paid: (count > 0), amount: amount
            })
            count = count - 1
        })
        this.setState({multiple_data: multiple_data})
    }

    convertCurrency = (value, currency) => {
        if (currency === 'USD') {
            return parseFloat(value) / parseFloat(this.context.user.display_usd);
        } else if (currency === "GBP") {
            return parseFloat(value) / parseFloat(this.context.user.display_gbp);
        } else {
            return parseFloat(this.context.user.display_usd) * parseFloat(value);
        }
    }

    totalSummary(category) {
        if (category === 'current_networth') {
            if (this.state.portfolio_currency === "NGN") {
                return (
                    <strong>₦{parseFloat(parseFloat(this.state.total_networth_ngn) + this.convertCurrency(this.state.total_networth_usd, 'NGN') + this.convertCurrency(this.state.total_networth_gbp, 'NGN')).toLocaleString()}</strong>
                )
            } else if (this.state.portfolio_currency === "USD") {
                return (
                    <strong>${parseFloat(parseFloat(this.state.total_networth_usd) + this.convertCurrency(this.state.total_networth_ngn, 'USD') + this.convertCurrency(this.convertCurrency(this.state.total_networth_gbp, 'NGN'), "USD")).toLocaleString()}</strong>
                )
            } else if (this.state.portfolio_currency === "GBP") {
                return (
                    <strong>£{parseFloat(parseFloat(this.state.total_networth_gbp) + this.convertCurrency(this.state.total_networth_ngn, 'GBP') + this.convertCurrency(this.convertCurrency(this.state.total_networth_usd, 'NGN'), "GBP")).toLocaleString()}</strong>
                )
            } else {
                return (
                    <strong>₦{parseFloat(this.state.total_networth_ngn).toLocaleString()}</strong>
                )
            }
        } else if (category === 'target_networth') {
            if (this.state.portfolio_currency === "NGN") {
                return (
                    <strong>₦{parseFloat(this.state.club.networth_goal).toLocaleString()}</strong>
                )
            } else if (this.state.portfolio_currency === "USD") {
                return (
                    <strong>${parseFloat(this.convertCurrency(this.state.club.networth_goal, "USD")).toLocaleString()}</strong>
                )
            } else if (this.state.portfolio_currency === "GBP") {
                return (
                    <strong>£{parseFloat(this.convertCurrency(this.state.club.networth_goal, "GBP")).toLocaleString()}</strong>
                )
            } else {
                return (
                    <strong>₦{parseFloat(this.state.club.networth_goal).toLocaleString()}</strong>
                )
            }
        } else if (category === 'total_asset') {
            if (this.state.portfolio_currency === "NGN") {
                return (
                    <strong>₦{parseFloat((parseFloat(this.state.total_asset_value_ngn) + this.convertCurrency(this.state.total_asset_value_usd, "NGN") + this.convertCurrency(this.state.total_asset_value_gbp, "NGN")).toFixed(2)).toLocaleString()}</strong>
                )
            } else if (this.state.portfolio_currency === "USD") {
                return (
                    <strong>${parseFloat((parseFloat(this.state.total_asset_value_usd) + this.convertCurrency(this.state.total_asset_value_ngn, "USD") + this.convertCurrency(this.convertCurrency(this.state.total_asset_value_gbp, "NGN"), "USD")).toFixed(2)).toLocaleString()}</strong>
                )
            } else if (this.state.portfolio_currency === "GBP") {
                return (
                    <strong>£{parseFloat((parseFloat(this.state.total_asset_value_usd) + this.convertCurrency(this.state.total_asset_value_ngn, "GBP") + this.convertCurrency(this.convertCurrency(this.state.total_asset_value_usd, "NGN"), "GBP")).toFixed(2)).toLocaleString()}</strong>
                )
            } else {
                return (
                    <strong>₦{parseFloat(parseFloat(this.state.total_asset_value_ngn).toFixed(2)).toLocaleString()}</strong>
                )
            }
        } else if (category === 'total_liability') {
            if (this.state.portfolio_currency === "NGN") {
                return (
                    <strong>₦{parseFloat((parseFloat(this.state.total_liability_value_ngn) + this.convertCurrency(this.state.total_liability_value_usd, "NGN") + this.convertCurrency(this.state.total_liability_value_gbp, "NGN")).toFixed(2)).toLocaleString()}</strong>
                )
            } else if (this.state.portfolio_currency === "USD") {
                return (
                    <strong>${parseFloat((parseFloat(this.state.total_liability_value_usd) + this.convertCurrency(this.state.total_liability_value_ngn, "USD") + this.convertCurrency(this.convertCurrency(this.state.total_liability_value_gbp, "NGN"), "USD")).toFixed(2)).toLocaleString()}</strong>
                )
            } else if (this.state.portfolio_currency === "GBP") {
                return (
                    <strong>£{parseFloat((parseFloat(this.state.total_liability_value_gbp) + this.convertCurrency(this.state.total_liability_value_ngn, "GBP") + this.convertCurrency(this.convertCurrency(this.state.total_liability_value_usd, "NGN"), "GBP")).toFixed(2)).toLocaleString()}</strong>
                )
            } else {
                return (
                    <strong>₦{parseFloat(parseFloat(this.state.total_liability_value_ngn).toFixed(2)).toLocaleString()}</strong>
                )
            }
        }
    }

    render() {
        return (
            <Layout active_menu={'club-detail'} user_state={this.props.match.params.id}
                    club_admin={this.checkAdmin(this.state.club.club_members)} context={this.context}>
                <ReactTooltip/>
                <div className="section">
                    <div className="breadcrumb">
                        {this.state.club.name}
                    </div>
                    <div className="row">
                        
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                                            <button onClick={event => {
                                                document.getElementById('portfolio').scrollIntoView({'behavior': 'smooth'})
                                            }}
                                                    className="btn  btn-social m-b-5 btn-blue text-white mr-2"><i
                                                className="fa fa-folder-open"/> Portfolio
                                            </button>
                                            <button onClick={event => {
                                                document.getElementById('investments').scrollIntoView({'behavior': 'smooth'})
                                            }} className="btn  btn-social m-b-5 btn-blue text-white mr-2"><i
                                                className="fa fa-bar-chart"/> Investments
                                            </button>
                                            <button onClick={event => {
                                                document.getElementById('contributions').scrollIntoView({'behavior': 'smooth'})
                                            }} className="btn  btn-social m-b-5 btn-blue text-white mr-2"><i
                                                className="fa fa-coins"/> Contributions
                                            </button>
                                            <button onClick={event => {
                                                document.getElementById('activities').scrollIntoView({'behavior': 'smooth'})
                                            }} className="btn  btn-social m-b-5 btn-blue text-white mr-2"><i
                                                className="fa fa-newspaper-o"/> Activities
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" id="portfolio">
                        <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <h4>Club Portfolio</h4>
                                        </div>
                                        {(this.context.is_admin) ? (
                                            <div className="col-sm-6">
                                                <select
                                                    onChange={event => this.setState({portfolio_currency: event.target.value})}
                                                    value={this.state.portfolio_currency}
                                                    className="form-control col-lg-7 col-xl-5 text-right float-right ml-2 pulse-out">
                                                    <option value="">Total NGN and Total USD</option>
                                                    <option value="NGN">Total NGN value</option>
                                                    <option value="USD">Total USD value</option>
                                                    <option value="GBP">Total GBP value</option>
                                                </select>
                                                <Link to={`/club/${this.props.match.params.id}/edit`}
                                                      className="btn  btn-social m-b-5 btn-blue text-white text-white float-right"><i
                                                    className="fa fa-edit"/> Edit Goals
                                                </Link>
                                                <Link to={`/manage-club/${this.props.match.params.id}`}
                                                      className="btn  btn-social m-b-5 btn-blue text-white text-white float-right mr-2"><i
                                                    className="fa fa-cog"/> Settings
                                                </Link>
                                            </div>
                                        ) : ''}
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row mb-4">
                                        <div className="col-lg-6 mx-auto justify-content-center text-center">
                                            <div className="mb-4 mt-4 circular-chart"><h5 className="text-blue"
                                                                                          style={{
                                                                                              fontWeight: 'bolder',
                                                                                              fontSize: '16px'
                                                                                          }}>
                                                {(parseInt(this.state.target) <= 0) && 'How are you doing with your goal? Taking action?'}
                                                {(parseInt(this.state.target) <= 39 && parseInt(this.state.target) >= 1) && 'Keep going. You are in it to win it!'}
                                                {(parseInt(this.state.target) <= 70 && parseInt(this.state.target) >= 40) && 'You are phenomenal, you are almost there. Keep going.'}
                                                {(parseInt(this.state.target) <= 99 && parseInt(this.state.target) >= 71) && 'You have taken this so seriously. Keep pushing.'}
                                                {(parseInt(this.state.target) === 100) && 'Congratulations. You have achieved your goal!'}
                                                {(parseInt(this.state.target) > 100) && 'You are on a roll. You are an overachiever!'}</h5>
                                            </div>
                                            <div
                                                data-tip={`You have achieved ${this.state.target}% of your net worth goal`}>
                                                <CircularProgressbar
                                                    className={`mx-auto ${this.state.radial_colour} text-center text-blue`}
                                                    styles={{width: '70%'}}
                                                    value={parseInt(this.state.target).toFixed(2)}
                                                    text={`${parseInt(this.state.target)}%`}>
                                                </CircularProgressbar>
                                            </div>
                                            <br/><br/>
                                            <strong className="text-blue">{parseInt(this.state.target)}% of target
                                                net worth achieved.</strong>
                                        </div>
                                        <div className="col-lg-6 col-xl-6 justify-content-center text-center">
                                            <div className="mb-4 mt-4 circular-chart"><h5
                                                className="text-blue"
                                                style={{
                                                    fontWeight: 'bolder',
                                                    fontSize: '16px'
                                                }}>Breakdown of total assets</h5>
                                            </div>
                                            <div className="" style={{marginBottom: '2.5rem'}}></div>
                                            <div className="chart-container">
                                                <canvas id="myChart"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-lg-3">
                                            <div className="list-group">
                                                <a href="#"
                                                   className="list-group-item list-group-item-action flex-column align-items-start">
                                                    <div className="d-sm-flex w-100 justify-content-between">
                                                        <h5 className="mb-1">
                                                            {this.totalSummary('current_networth')}
                                                        </h5>
                                                    </div>
                                                    <p className="mb-1">Current Net Worth</p>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="list-group">
                                                <a href="#"
                                                   className="list-group-item list-group-item-action flex-column align-items-start">
                                                    <div className="d-sm-flex w-100 justify-content-between">
                                                        <h5 className="mb-1">
                                                            {this.totalSummary('target_networth')}
                                                        </h5>
                                                    </div>
                                                    <p className="mb-1">Target Net Worth</p>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="list-group">
                                                <a href="#"
                                                   className="list-group-item list-group-item-action flex-column align-items-start">
                                                    <div className="d-sm-flex w-100 justify-content-between">
                                                        <h5 className="mb-1">
                                                            {this.totalSummary('total_asset')}
                                                        </h5>
                                                    </div>
                                                    <p className="mb-1">Total Assets</p>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="list-group">
                                                <a href="#"
                                                   className="list-group-item list-group-item-action flex-column align-items-start">
                                                    <div className="d-sm-flex w-100 justify-content-between">
                                                        <h5 className="mb-1">
                                                            {this.totalSummary('total_liability')}
                                                        </h5>
                                                    </div>
                                                    <p className="mb-1">Total Liability</p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {(this.state.portfolio_currency === "") ? (
                                        <div>
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <div className="list-group">
                                                        <a href="#"
                                                           className="list-group-item list-group-item-action flex-column align-items-start">
                                                            <div className="d-sm-flex w-100 justify-content-between">
                                                                <h5 className="mb-1">
                                                                    <strong>${parseFloat(parseFloat(this.state.total_networth_usd).toFixed(2)).toLocaleString()}</strong>
                                                                </h5>
                                                            </div>
                                                            <p className="mb-1">Current Net Worth</p>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="list-group">
                                                        <a href="#"
                                                           className="list-group-item list-group-item-action flex-column align-items-start">
                                                            <div className="d-sm-flex w-100 justify-content-between">
                                                                <h5 className="mb-1">
                                                                    <strong>${parseFloat((this.convertCurrency(this.state.club.networth_goal, "USD")).toFixed(2)).toLocaleString()}</strong>
                                                                </h5>
                                                            </div>
                                                            <p className="mb-1">Target Net Worth</p>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="list-group">
                                                        <a href="#"
                                                           className="list-group-item list-group-item-action flex-column align-items-start">
                                                            <div className="d-sm-flex w-100 justify-content-between">
                                                                <h5 className="mb-1">
                                                                    <strong>${parseFloat(parseFloat(this.state.total_asset_value_usd).toFixed(2)).toLocaleString()}</strong>
                                                                </h5>
                                                            </div>
                                                            <p className="mb-1">Total Assets</p>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="list-group">
                                                        <a href="#"
                                                           className="list-group-item list-group-item-action flex-column align-items-start">
                                                            <div className="d-sm-flex w-100 justify-content-between">
                                                                <h5 className="mb-1">
                                                                    <strong>${parseFloat(parseFloat(this.state.total_liability_value_usd).toFixed(2)).toLocaleString()}</strong>
                                                                </h5>
                                                            </div>
                                                            <p className="mb-1">Total Liability</p>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <div className="list-group">
                                                        <a href="#"
                                                           className="list-group-item list-group-item-action flex-column align-items-start">
                                                            <div
                                                                className="d-sm-flex w-100 justify-content-between">
                                                                <h5 className="mb-1">
                                                                    <strong>£{parseFloat(parseFloat(this.state.total_networth_gbp).toFixed(2)).toLocaleString()}</strong>
                                                                </h5>
                                                            </div>
                                                            <p className="mb-1">Current Net Worth</p>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="list-group">
                                                        <a href="#"
                                                           className="list-group-item list-group-item-action flex-column align-items-start">
                                                            <div
                                                                className="d-sm-flex w-100 justify-content-between">
                                                                <h5 className="mb-1">
                                                                    <strong>£{parseFloat((this.convertCurrency(this.state.club.networth_goal, "GBP")).toFixed(2)).toLocaleString()}</strong>
                                                                </h5>
                                                            </div>
                                                            <p className="mb-1">Target Net Worth</p>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="list-group">
                                                        <a href="#"
                                                           className="list-group-item list-group-item-action flex-column align-items-start">
                                                            <div
                                                                className="d-sm-flex w-100 justify-content-between">
                                                                <h5 className="mb-1">
                                                                    <strong>£{parseFloat(parseFloat(this.state.total_asset_value_gbp).toFixed(2)).toLocaleString()}</strong>
                                                                </h5>
                                                            </div>
                                                            <p className="mb-1">Total Assets</p>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="list-group">
                                                        <a href="#"
                                                           className="list-group-item list-group-item-action flex-column align-items-start">
                                                            <div
                                                                className="d-sm-flex w-100 justify-content-between">
                                                                <h5 className="mb-1">
                                                                    <strong>£{parseFloat(parseFloat(this.state.total_liability_value_gbp).toFixed(2)).toLocaleString()}</strong>
                                                                </h5>
                                                            </div>
                                                            <p className="mb-1">Total Liability</p>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : ''}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" id="investments">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="float-left">Club Investments</h4>
                                    <select
                                        onChange={event => {
                                            this.setState({investment_currency: event.target.value});
                                            this.removeHeight()
                                        }}
                                        value={this.state.investment_currency}
                                        className="form-control col-lg-6 col-xl-3 text-right float-right ml-3">
                                        <option value="ALL">Total NGN, USD and GBP</option>
                                        <option value="NGN">Total NGN value</option>
                                        <option value="USD">Total USD value</option>
                                        <option value="GBP">Total GBP value</option>
                                    </select>
                                    <Link to={`/club/${this.props.match.params.id}/investments`}
                                          className="btn m-b-5 btn-blue text-white text-white float-right"> View All
                                    </Link>
                                    <Link to={`/club/${this.props.match.params.id}/marketplace`}
                                          className="btn btn-blue text-white text-white m-b-5 mr-2 float-right"> Find
                                        Investments
                                    </Link>
                                </div>
                                <div className="card-body">
                                    <div className="row boxes">
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                    <span className="text-blue"><i
                                                                        className="fa fa-bar-chart text-blue"/> <strong>Total Investment</strong> <i
                                                                        data-tip="Total Amount Invested "
                                                                        className="fa fa-info-circle text-blue"/></span>
                                                                {["NGN"].includes(this.state.investment_currency) ? (
                                                                    <h3 className="text-blue mb-0 mt-2">₦{parseFloat(parseFloat(this.state.total_amount).toFixed(2)).toLocaleString()}</h3>
                                                                ) : ["USD"].includes(this.state.investment_currency) ? (
                                                                    <React.Fragment>
                                                                        <h3 className="text-blue mb-0">${parseFloat(parseFloat(this.state.total_amount_usd).toFixed(2)).toLocaleString()}</h3>
                                                                    </React.Fragment>
                                                                ) : ["GBP"].includes(this.state.investment_currency) ? (
                                                                    <React.Fragment>
                                                                        <h3 className="text-blue mb-0">£{parseFloat(parseFloat(this.state.total_amount_gbp).toFixed(2)).toLocaleString()}</h3>
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <React.Fragment>
                                                                        <h3 className="text-blue mb-0 mt-2">₦{parseFloat(parseFloat(this.state.total_amount).toFixed(2)).toLocaleString()}</h3>
                                                                        <hr className="line-2"/>
                                                                        <h3 className="text-blue mb-0">${parseFloat(parseFloat(this.state.total_amount_usd).toFixed(2)).toLocaleString()}</h3>
                                                                        <hr className="line-2"/>
                                                                        <h3 className="text-blue mb-0">£{parseFloat(parseFloat(this.state.total_amount_gbp).toFixed(2)).toLocaleString()}</h3>
                                                                    </React.Fragment>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-blue"><i
                                                                        className="fa fa-reply-all text-blue"/> <strong>Expected ROI</strong>  <i
                                                                        data-tip="Expected return on investment"
                                                                        className="fa fa-info-circle text-right text-blue"/></span>
                                                                {["NGN"].includes(this.state.investment_currency) ? (
                                                                    <h3 className="text-blue mb-0 mt-2">₦{parseFloat(parseFloat(this.state.total_return).toFixed(2)).toLocaleString()}</h3>
                                                                ) : ["USD"].includes(this.state.investment_currency) ? (
                                                                    <React.Fragment>
                                                                        <h3 className="text-blue mb-0">${parseFloat(parseFloat(this.state.total_return_usd).toFixed(2)).toLocaleString()}</h3>
                                                                    </React.Fragment>
                                                                ) : ["GBP"].includes(this.state.investment_currency) ? (
                                                                    <React.Fragment>
                                                                        <h3 className="text-blue mb-0">£{parseFloat(parseFloat(this.state.total_return_gbp).toFixed(2)).toLocaleString()}</h3>
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <React.Fragment>
                                                                        <h3 className="text-blue mb-0 mt-2">₦{parseFloat(parseFloat(this.state.total_return).toFixed(2)).toLocaleString()}</h3>
                                                                        <hr className="line-2"/>
                                                                        <h3 className="text-blue mb-0">${parseFloat(parseFloat(this.state.total_return_usd).toFixed(2)).toLocaleString()}</h3>
                                                                        <hr className="line-2"/>
                                                                        <h3 className="text-blue mb-0">£{parseFloat(parseFloat(this.state.total_return_gbp).toFixed(2)).toLocaleString()}</h3>
                                                                    </React.Fragment>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-blue"><i
                                                                        className="fa fa-signal text-blue"/> <strong>Interest</strong> <i
                                                                        data-tip="Total amount you will receive from your investments"
                                                                        className="fa fa-info-circle text-right text-blue"/></span>
                                                                {["NGN"].includes(this.state.investment_currency) ? (
                                                                    <h3 className="text-blue mb-0 mt-2">₦{parseFloat(parseFloat(this.state.total_return - this.state.total_amount).toFixed(2)).toLocaleString()}</h3>
                                                                ) : ["USD"].includes(this.state.investment_currency) ? (
                                                                    <React.Fragment>
                                                                        <h3 className="text-blue mb-0">${parseFloat(parseFloat(this.state.total_return_usd - this.state.total_amount_usd).toFixed(2)).toLocaleString()}</h3>
                                                                    </React.Fragment>
                                                                ) : ["GBP"].includes(this.state.investment_currency) ? (
                                                                    <React.Fragment>
                                                                        <h3 className="text-blue mb-0">£{parseFloat(parseFloat(this.state.total_return_gbp - this.state.total_amount_gbp).toFixed(2)).toLocaleString()}</h3>
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <React.Fragment>
                                                                        <h3 className="text-blue mb-0 mt-2">₦{parseFloat(parseFloat(this.state.total_return - this.state.total_amount).toFixed(2)).toLocaleString()}</h3>
                                                                        <hr className="line-2"/>
                                                                        <h3 className="text-blue mb-0">${parseFloat(parseFloat(this.state.total_return_usd - this.state.total_amount_usd).toFixed(2)).toLocaleString()}</h3>
                                                                        <hr className="line-2"/>
                                                                        <h3 className="text-blue mb-0">£{parseFloat(parseFloat(this.state.total_return_gbp - this.state.total_amount_gbp).toFixed(2)).toLocaleString()}</h3>
                                                                    </React.Fragment>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-blue"><i
                                                                        className="fa fa-line-chart text-blue"/> <strong>Percentage Interest</strong> <i
                                                                        data-tip="Percentage Interest gained from  your investments"
                                                                        className="fa fa-info-circle text-right text-blue"/></span>
                                                                {["NGN"].includes(this.state.investment_currency) ? (
                                                                    <h3 className="text-blue mb-0 mt-2">₦{parseFloat(this.state.percentage_interest).toFixed(2).toLocaleString()}</h3>
                                                                ) : ["USD"].includes(this.state.investment_currency) ? (
                                                                    <React.Fragment>
                                                                        <h3 className="text-blue mb-0">${parseFloat(parseFloat(this.state.percentage_interest_usd).toFixed(2)).toLocaleString()}</h3>
                                                                    </React.Fragment>
                                                                ) : ["GBP"].includes(this.state.investment_currency) ? (
                                                                    <React.Fragment>
                                                                        <h3 className="text-blue mb-0">£{parseFloat(parseFloat(this.state.percentage_interest_gbp).toFixed(2)).toLocaleString()}</h3>
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <React.Fragment>
                                                                        <h3 className="text-blue mb-0 mt-2">{parseFloat(this.state.percentage_interest).toFixed(2)}%</h3>
                                                                        <hr className="line-2"/>
                                                                        <h3 className="text-blue mb-0">{parseFloat(parseFloat(this.state.percentage_interest_usd).toFixed(2)).toLocaleString()}</h3>
                                                                        <hr className="line-2"/>
                                                                        <h3 className="text-blue mb-0">{parseFloat(parseFloat(this.state.percentage_interest_gbp).toFixed(2)).toLocaleString()}</h3>
                                                                    </React.Fragment>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped mb-0 text-nowrap">
                                            <tbody>
                                            <tr>
                                                <th>Name</th>
                                                <th>Amount</th>
                                                <th>Unit/Token</th>
                                                <th>ROI(%)</th>
                                                <th>Expected Return</th>
                                                <th>Purchased Date</th>
                                                <th>Maturity Date</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                            {this.state.investments.map(item => (
                                                <tr key={item.id}>
                                                    <td>{item.investment.in_trust ? (
                                                                    <i className="fas fa-shield-alt text-dark"/>) : ''} {item.investment.title} by {item.investment.company}</td>
                                                    <td data-tip={`Rate at ₦${this.rateConverted(item.currency, item.amount_ngn, item.amount).toFixed(2).toLocaleString()}`}>
                                                        <NumberFormat renderText={value => <div>{value}</div>}
                                                                      prefix={Utils.currencyToSym(item.currency)}
                                                                      displayType={'text'}
                                                                      thousandSeparator={true}
                                                                      value={parseFloat(item.value).toFixed(2)}/>
                                                    </td>
                                                    <td>{parseInt(item.units)}</td>
                                                    <td>{item.roi}</td>
                                                    <td><NumberFormat renderText={value => <div>{value}</div>}
                                                                      prefix={Utils.currencyToSym(item.currency)}
                                                                      displayType={'text'}
                                                                      thousandSeparator={true}
                                                                      value={item.expected_return}/></td>
                                                    <td><Moment format="MMM D, Y">{item.created_at}</Moment></td>
                                                    <td><Moment format="MMM D, Y">{item.maturity_date}</Moment></td>
                                                    <td>                                                   
                                                        {item.investment.id == 27 && excludeList.includes(this.getAdminEmail(this.state.club.club_members)) ? "Active" : (item.investment.payout_status == "pending" || item.investment.payout_status == "none" ? "Active" : "Completed")}
                                                    </td>
                                                    {(item.investment.club_tag) ?
                                                        <td><Link
                                                            to={`/club/${this.state.club.id}/investment/${item.id}/history`}> <span
                                                            className="fa fa-list"/></Link>
                                                        </td> : (item.investment.multiple_payout) ? (
                                                                <td><span
                                                                    onClick={event => this.processDates(item.investment.payout_dates, item.value, item.investment.inv_roi, item.payout_times)}
                                                                    data-target="#dateModal"
                                                                    data-toggle="modal"
                                                                    style={{'cursor': 'pointer'}}
                                                                    className="fa fa-eye"/>
                                                                </td>
                                                            ) :
                                                            <td><span className="fa fa-ban"/></td>
                                                    }
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" id="investments">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="float-left">Members' Proposed Investments</h4>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-striped mb-0 text-nowrap">
                                            <tbody>
                                            <tr>
                                                <th>Name</th>
                                                <th>Total Units Available</th>
                                                <th>Booked Units</th>
                                                <th>Amount/Token</th>
                                                <th>No. of Members</th>
                                                <th>Start Date </th>
                                                <th>End Date </th>
                                                <th>Status </th>
                                                <th>Actions</th>
                                            </tr>
                                            {this.state.interested_investments.map(item => (
                                                <tr key={item.id}>
                                                    <td>{item.investment.in_trust ? (
                                                                    <i className="fas fa-shield-alt text-dark"/>) : ''} {item.investment.title} by {item.investment.company}</td>
                                                    <td>{parseInt(item.proposed_unit)}</td>
                                                    <td>{item.members_units}</td>
                                                    <td data-tip={`Rate at ₦${this.rateConverted(item.investment.currency, item.investment.amount_ngn, item.investment.amount).toFixed(2).toLocaleString()}`}>
                                                        <NumberFormat renderText={value => <div>{value}</div>}
                                                                      prefix={Utils.currencyToSym(item.investment.currency)}
                                                                      displayType={'text'}
                                                                      thousandSeparator={true}
                                                                      value={parseFloat(item.investment.unit_price).toFixed(2)}/>
                                                    </td>
                                                    <td>{item.members}</td>
                                                    <td><Moment format="MMM D, Y">{item.investment.starting_date}</Moment></td>
                                                    <td><Moment format="MMM D, Y">{item.investment.closing_date}</Moment></td>
                                                    <td>
                                                        <div className="progress bg-warning">
                                                            <div className="progress-bar btn-blue text-white" role="progressbar" aria-valuenow={(item.members_units / item.proposed_unit) * 100}
                                                            aria-valuemin="0" aria-valuemax="100" style={{width:((item.members_units / item.proposed_unit) * 100).toFixed(2) + "%"}}>
                                                                {((item.members_units / item.proposed_unit) * 100).toFixed(2)}%
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td><Link to={`/club/${this.props.match.params.id}/${item.investment.id}/interested_members`}
                                                        style={{'cursor': 'pointer', 'color': 'white'}}
                                                        className="btn btn-primary mr-1 fa fa-eye text-white" />
                                                    <Link to={`/club/${this.props.match.params.id}/marketplace/${item.investment.id}`}
                                                        style={{'cursor': 'pointer', 'color': 'white'}}
                                                        className="btn btn-primary mr-1 text-white">View</Link>
                                                    {this.checkAdmin(this.state.club.club_members) ? (
                                                        <>
                                                            <span 
                                                                style={{'cursor': 'pointer', 'color': 'white'}}
                                                                className="btn btn-info mr-1 fa fa-edit text-white"                                                                      data-target="#editUnits"
                                                                data-toggle="modal"  onClick={(e) => {
                                                                    this.setState({interest_investment_id : item.id, interest_investment_investment_id: item.investment.id})
                                                                }}/>
                                                            <span 
                                                                style={{'cursor': 'pointer', 'color': 'white'}}
                                                                className="btn btn-danger fa fa-trash text-white"                                                                      
                                                                data-target="#deleteInterest"
                                                                data-toggle="modal"  onClick={(e) => {
                                                                    this.setState({interest_investment_id : item.id})
                                                                }}/>
                                                        </>
                                                        ) : ""}
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" id="contributions">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="float-left">Members' Financial Contributions</h4>
                                    <Link to={`/club/${this.props.match.params.id}/contributions`}
                                          className="btn m-b-5 btn-blue text-white text-white float-right"> View All
                                    </Link>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <i className="fa fa-bar-chart text-blue"/>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-blue">Total Contributions</span>
                                                                <h2 className="text-blue mb-0">₦{parseFloat(this.state.total_deposits).toLocaleString()}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped mb-0 text-nowrap">
                                            <tbody>
                                            <tr>
                                                <th>Depositor</th>
                                                <th>Amount</th>
                                                <th>Reference</th>
                                                <th>Status</th>
                                                <th>Date</th>
                                            </tr>
                                            {this.state.contributions.map(item => (
                                                <tr key={item.id}>
                                                    <td>{item.user.first_name} {item.user.last_name}</td>
                                                    <td><NumberFormat renderText={value => <div>{value}</div>}
                                                                      prefix={'₦'} displayType={'text'}
                                                                      thousandSeparator={true}
                                                                      value={item.amount}/>
                                                    </td>
                                                    <td>{item.ref_code}</td>
                                                    <td>
                                                        {(item.status.toLowerCase() === 'paid') ? (
                                                            <span
                                                                className="badge bg-blue m-b-5">{item.status}</span>
                                                        ) : (item.status.toLowerCase() === 'cancelled') ? (
                                                            <span
                                                                className="badge bg-yellow m-b-5">{item.status}</span>
                                                        ) : (
                                                            <span
                                                                className="badge badge-danger m-b-5">{item.status}</span>
                                                        )}
                                                    </td>
                                                    <td><Moment format="MMM D, Y">{item.created_at}</Moment>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" id="activities">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="float-left">Club Member's Activities</h4>
                                    <Link to={`/club/${this.props.match.params.id}/activities`}
                                          className="btn m-b-5 btn-blue text-white text-white float-right"> View All
                                    </Link>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="table-responsive">
                                                <table className="table table-striped mb-0 text-nowrap">
                                                    <tbody>
                                                    <tr>
                                                        <th>Action</th>
                                                        <th>Date</th>
                                                        <th>Time</th>
                                                    </tr>
                                                    {this.state.activities.map(item => (
                                                        <tr key={uuidv1()}>
                                                            <td>{item.action}</td>
                                                            <td><Moment format="MMM D Y">{item.created_at}</Moment>
                                                            </td>
                                                            <td><Moment format="HH:mm">{item.created_at}</Moment>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" id="members">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="float-left">Member's Area</h4>
                                    {(this.context.is_admin) ? (
                                        <Link to={`/club/${this.props.match.params.id}/invite`}
                                              className="btn m-b-5 btn-blue text-white text-white float-right">
                                            <span className="fa fa-user-cog"/> Manage Members
                                        </Link>
                                    ) : ''}
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-striped mb-0 text-nowrap">
                                            <tbody>
                                            <tr>
                                                <th>Full Name</th>
                                                <th>Role</th>
                                                {(this.context.is_admin) ? (
                                                    <th>Change Role</th>
                                                ) : ''}
                                                <th>
                                                    <button data-target="#messageModal" data-toggle="modal"
                                                            className="badge badge-primary bg-blue btn btn-sm">Send
                                                        Group Message <sup>beta+</sup> <i
                                                            className='fa fa-paper-plane'/></button>
                                                </th>
                                            </tr>
                                            {this.state.members.map(item => (
                                                <tr role="row" key={uuidv1()}>
                                                    <td className="sorting_1">{item.user.first_name} {item.user.last_name}</td>
                                                    <td style={{textTransform: 'capitalize'}}>{item.user_role}</td>
                                                    {(this.context.is_admin) ? (
                                                        <td><label className="custom-switch">
                                                            <input
                                                                defaultChecked={(item.user_role.toLowerCase() === 'admin')}
                                                                onChange={event => this.onChangeMember(event, item.user.id)}
                                                                type="checkbox" name="custom-switch-checkbox"
                                                                className="custom-switch-input"/>
                                                            <span className="custom-switch-indicator"/>
                                                        </label></td>
                                                    ) : ''}
                                                    <td>
                                                        {(item.user.id !== this.context.user.id) ? (
                                                            <Link to={{
                                                                pathname: '/chats',
                                                                query: item
                                                            }}>
                                                                <span
                                                                    className="badge badge-primary bg-blue btn btn-sm">Send Message</span>
                                                            </Link>
                                                        ) : ''}
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="messageModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-blue">
                                <h5 className="modal-title" id="example-Modal3">Send Message to Group</h5>
                                <button onClick={event => this.setState({modal: 'none', message: ''})} type="button"
                                        className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <form onSubmit={this.sendGroupMsg}>
                                <div className="modal-body">
                                    <div className="form-group mb-0">
                                        <label htmlFor="message-text"
                                               className="form-control-label">Message:</label>
                                        <textarea onChange={event => this.setState({message: event.target.value})}
                                                  required rows="10" className="form-control"
                                                  id="message-text"></textarea>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button onClick={event => this.setState({modal: 'none', message: ''})} type="button"
                                            className="btn btn-outline-green btn-sm" data-dismiss="modal">Close
                                    </button>
                                    <button type="submit" className="btn btn-sm btn-blue text-white text-white">Send message <i
                                        className="fas fa-paper-plane"/></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="dateModal" tabIndex="-1" role="dialog"
                     aria-labelledby="dateModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-blue">
                                <h4 className="modal-title" id="dateModalLabel">Payout Dates</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="list-group">
                                    {(this.state.multiple_data).map(item => (
                                        <a href="#"
                                           className="list-group-item list-group-item-action flex-column align-items-start">
                                            <div className="d-flex w-100 justify-content-between">
                                                <h5 className="mb-1">{item.date}</h5>
                                                <small>{(item.paid) ? 'Completed' : 'Upcoming Payout'}</small>
                                            </div>
                                            <p className="mb-1">₦{item.amount.toLocaleString()}</p>
                                        </a>
                                    ))}
                                </div>
                            </div>
                            <div className="modal-footer text-center">
                                <button type="button" className="btn no-outline-green"
                                        data-dismiss="modal">Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="editUnits" tabIndex="-1" role="dialog"
                     aria-labelledby="editUnitsLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-blue">
                                <h4 className="modal-title" id="editUnitsLabel">Modify Number of Units</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="list-group">
                                    <div className="form-group">
                                        <input type="number" className="form-control" onChange={(e) => {
                                            this.setState({modify_no_of_units: e.target.value})
                                        }} />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer text-center">
                                <button type="button" className="btn btn-blue text-white"
                                    onClick={(e) => {
                                        console.log("sdfdfsfds")
                                        this.updateClubUnits(e)
                                    }} data-dismiss="modal">Submit
                                </button>                                
                                <button type="button" className="btn no-outline-green"
                                        data-dismiss="modal">Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="deleteInterest" tabIndex="-1" role="dialog"
                     aria-labelledby="deleteInterestLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-danger">
                                <h4 className="modal-title" id="deleteInterestLabel">Do you want to continue ?</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="list-group">
                                    <div className="form-group">
                                        Contributions will be returned to the individual member's wallet
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer text-center">
                                <button type="button" className="btn btn-danger"
                                    onClick={(e) => {
                                        this.deleteClubInterest(e)
                                    }}>Delete
                                </button>                                
                                <button type="button" className="btn no-outline-green"
                                        data-dismiss="modal">Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default ClubPortfolio;
