import React from 'react';
import {Link} from 'react-router-dom'
import API from '../../api'
import Layout from '../Layout'
import {hideLoader, mixpanel, showLoader, toast, trackMixpanel} from "../../_helpers/Loader";
import NumberFormat from "react-number-format";
import {Context} from "../MyContext";
import {PAYSTACK_publicKey} from "../../env";
import {Editor} from 'react-draft-wysiwyg';
import {EditorState, convertToRaw, convertFromRaw, ContentState, convertFromHTML} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const PaystackPop = window.PaystackPop;

class StartClub extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            plans: [],
            pages: 1,
            plan: {},
            name: '',
            contribution_interval: null,
            networth: '',
            contribution_amount: null,
            contributions: false,
            editorState: EditorState.createEmpty(),
        };

        this.step1Submit = this.step1Submit.bind(this);
        this.step2Submit = this.step2Submit.bind(this);
    }

    componentWillMount() {
        if (this.context.user.completed_onboarding === null || this.context.user.completed_onboarding === undefined) {
            window.location.reload();
        }
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
    }

    async componentDidMount() {
        trackMixpanel('StartClubView', false, false, this.context.user.email)
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
        showLoader();
        await this.getPlans();
        hideLoader();
    }

    async getPlans() {
        try {
            let res = await API.get('plans/');
            this.setState({plans: res.data})
        } catch (e) {
            hideLoader();
        }
    }

    step1Submit(event, item_id, interval) {
        trackMixpanel('PaymentPlanSelected', false, {
            planType: this.state.plans.find(item => item.id === item_id).name
        }, this.context.user.email)
        let filterPlan;
        this.state.plans.map(item => {
            if (item.id === item_id) {
                filterPlan = item
            }
        });
        this.setState({plan: filterPlan, interval: interval, pages: this.state.pages + 1})

    }

    stripHtml(html) {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }

    async step2Submit(event) {
        event.preventDefault();
        let postData = {
            name: this.state.name,
            networth_goal: this.state.networth,
            contribution: (this.state.contributions) ? this.state.contribution_amount : 0,
            is_contributions: this.state.contributions,
            interval: (this.state.contributions) ? this.state.contribution_interval : null,
            plan: this.state.plan.id,
            payment_plan: (this.state.interval === 'free') ? 'monthly' : this.state.interval,
            description: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
        };
        trackMixpanel('AddClub', false, {
            clubName: postData.name,
            clubNetWorthGoal: postData.networth_goal,
            description: this.stripHtml(postData.description),
            packageChosen: (this.state.plans.find(item => item.id === this.state.plan.id)).name.toLowerCase() === "free" ? "Free" : "Premium",
        }, this.context.user.email)

        showLoader();
        try {
            let res = await API.post('clubs/', postData);
            let club_id = res.data.club_id
            await this.context.updateClubs();
            await this.context.updateUserState('club', club_id)
            toast.success("Club Created Successfully");
            hideLoader();
            (this.state.plan.name.toLowerCase() === 'free') && this.props.history.push(`/club/${club_id}/invite`);
            (this.state.plan.name.toLowerCase() !== 'free') && this.props.history.push(`/club/${club_id}/subscribe`);
        } catch (e) {
            // console.log(e.response);
            toast.error("Something Went Wrong");
            hideLoader()
        }
    }

    payWithCard = (plan_id, postData) => {
        var handler = PaystackPop.setup({
            key: PAYSTACK_publicKey,
            email: this.context.user.email,
            // plan: plan_id,
            amount: "50000",
            currency: "NGN",
            ref: "paystack-sub" + Math.floor((Math.random() * 1000000000) + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
            metadata: {
                custom_fields: [
                    {
                        display_name: "First Name",
                        variable_name: "firstname",
                        value: this.context.user.first_name,
                    }, {
                        display_name: "Last Name",
                        variable_name: "lastname",
                        value: this.context.user.last_name,
                    }, {
                        display_name: "Customer Number",
                        variable_name: "customer_phone",
                        value: this.context.user.phone,
                    }, {
                        display_name: "Club Name",
                        variable_name: "club_name",
                        value: this.state.name
                    },
                ]
            },
            callback: (response) => {
                postData.reference = response.reference;
                postData.pay_type = 'paystack';
                if (
                    response.status === "success"
                ) {
                    // redirect to a success page
                    API.post('clubs/', postData).then((res) => {
                        trackMixpanel('PaymentPlanSelected', false, {
                            utmContent: this.state.personal ? "personal" : "club",
                            planType: this.state.plan.name.toLowerCase(),
                        }, this.context.user.email)
                        hideLoader();
                        toast.success("Successfully Completed Transaction");
                        this.props.history.push(`/club/${res.data.club_id}/invite`);
                    }, function (err) {
                        toast.error("Something Went Wrong");
                        hideLoader()
                    })

                } else {
                    // redirect to a failure page.
                    hideLoader();
                    toast.error("Payment Error.")
                }
            },
            onClose: function () {
                toast.info("Payment Cancelled");
                hideLoader();
            }
        });
        handler.openIframe();
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    step1() {
        return (
            <Layout active_menu={'club'} context={this.context}>
                <div className="section">
                    <div className="breadcrumb">
                        <div className="col-sm-6">
                            <Link to="/clubs"> <span className="fa fa-chevron-left"/> Clubs</Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <h3 className="text-center mb-4">Select a payment plan</h3>
                                    <div className="row">
                                        {this.state.plans.map(item => (
                                            <div className="col-sm-3">
                                                <div className="list-group">
                                                    <div
                                                        className="list-group-item list-group-item-action flex-column align-items-start bg-blue-gray text-blue">
                                                        <div className="d-sm-flex w-100 text-center bg">
                                                            <h4 className="mb-1">{item.name}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ul className="list-group mb-3">
                                                    <li className="list-group-item">{item.min_members} - {item.max_members} Members</li>
                                                    <li className="list-group-item">Access to Investment Marketplace
                                                    </li>
                                                    {(item.club_portfolio_management) ? (
                                                        <li className="list-group-item">Club Portfolio
                                                            Management</li>) : ''}
                                                    {(item).recurring_debit_members ? (
                                                        <li className="list-group-item">Recurring Debit for members</li>
                                                    ) : ''}
                                                    {(item.name.toLowerCase() !== 'free') ? (
                                                        <li className="list-group-item">Get 2 months access free on
                                                            annual plan</li>
                                                    ) : ''}
                                                </ul>
                                                {(item.name.toLowerCase() === 'free') ? (
                                                    <button onClick={event => this.step1Submit(event, item.id, 'free')}
                                                            className="btn btn-blue text-white btn-block"><strong>Free</strong>
                                                    </button>
                                                ) : (
                                                    <div>
                                                        <button
                                                            onClick={event => this.step1Submit(event, item.id, 'monthly')}
                                                            className="btn btn-blue text-white btn-block">
                                                            <strong>${item.monthly_amount} / Monthly</strong></button>
                                                        <button
                                                            onClick={event => this.step1Submit(event, item.id, 'annual')}
                                                            className="btn btn-blue text-white btn-block">
                                                            <strong>${item.annual_amount} / Yearly</strong></button>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }

    step2() {
        const {editorState} = this.state;
        return (
            <Layout active_menu={'club'} context={this.context}>
                <div className="section">
                    <div className="breadcrumb">
                        <a onClick={event => this.setState({pages: 1})}><span className="fa fa-chevron-left"/> Back</a>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-lg-6 col-xl-6 mx-auto">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row text-center">
                                        <div className="col-sm-12 mb-3">
                                            <h3>Enter Club Details</h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <form className="form-horizontal" onSubmit={this.step2Submit}>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group overflow-hidden">
                                                            <label>Club Name <sup
                                                                className="text-danger">*</sup></label>
                                                            <input required value={this.state.name}
                                                                   onChange={event => this.setState({name: event.target.value})}
                                                                   type="text" className="form-control" id="name"
                                                                   placeholder="Enter Club Name"/>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group overflow-hidden">
                                                            <label>Club
                                                                Net Worth Goal <sup
                                                                    className="text-danger">*</sup></label>
                                                            <NumberFormat required={true} thousandSeparator={true}
                                                                          prefix={'₦'}
                                                                          onValueChange={(values) => {
                                                                              const {value} = values;
                                                                              this.setState({networth: value})

                                                                          }} value={this.state.networth}
                                                                          className="form-control"
                                                                          placeholder="Enter Amount in NGN"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="form-group row overflow-hidden">
                                                        <label
                                                            className="col-sm-12 col-form-label">Description</label>
                                                        <div className="col-sm-12">
                                                            <Editor
                                                                editorState={editorState}
                                                                toolbar={options}
                                                                initialEditorState={editorState}
                                                                wrapperClassName="demo-wrapper"
                                                                editorClassName="demo-editor"
                                                                onEditorStateChange={this.onEditorStateChange}
                                                            />
                                                        </div>
                                                        <div className="col-sm-6 text-center mx-auto mt-3">
                                                            <button type="submit"
                                                                    className="btn btn-blue text-white">
                                                                <strong>{this.state.plan.name.toLowerCase() === 'free' ? 'Create' : 'Pay & Create'}</strong>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }

    render() {
        if (this.state.pages === 1) {
            return this.step1();
        } else if (this.state.pages === 2) {
            return this.step2();
        } else {
            return null
        }
    }
}

export default StartClub

let options = {
    options: ['inline', 'textAlign', 'fontSize', 'list', 'link', 'emoji', 'colorPicker', 'history'],
    inline: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'],
    },
    list: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['unordered', 'ordered'],
    },
    colorPicker: {
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
            'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
            'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
            'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
            'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
            'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
    },
    textAlign: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['left', 'center', 'right', 'justify'],
    },
    link: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        dropdownClassName: undefined,
        showOpenOptionOnHover: true,
        defaultTargetOption: '_self',
        options: ['link', 'unlink'],
        linkCallback: undefined
    },
    fontSize: {
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
    },
    emoji: {
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        emojis: [
            '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
            '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
            '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
            '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
            '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
            '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
            '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
            '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
            '✅', '❎', '💯',
        ],
    },
    history: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['undo', 'redo'],
        undo: {className: undefined},
        redo: {className: undefined},
    },
};
