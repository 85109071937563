import React, {Component} from 'react';
import Layout from '.././Layout'
import {Link} from "react-router-dom";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import moment from 'moment';
import API from "../../api";
import {Context} from "../MyContext";
import $ from 'jquery';
import {showLoader, hideLoader, toast, mixpanel, trackMixpanel} from "../../_helpers/Loader";
import {MONNIFY_contractCode, MONNIFY_APIKey} from "../../env";
import uuidv1 from 'uuid';

require('bootstrap');
require('bootstrap-datepicker');
require('bootstrap-datepicker/dist/css/bootstrap-datepicker.css');

const MonnifySDK = window.MonnifySDK;

class ClubContributions extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            portfolio: '',
            club: {},
            contributions: [],
            members: [],
            total_deposits: 0,
            form: {},
            form_edit: {},
            delete_form: {},
            paynow_form: {},
            invoice_form: {}
        };
    }

    async componentDidMount() {
        trackMixpanel('ClubContributionsView', false, false, this.context.user.email)
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
        showLoader();
        await this.getContributions();
        await this.getMembers();
        $('.date').datepicker({
            format: 'yyyy-mm-dd',
            todayHighlight: true,
            autoclose: true,
        }).datepicker("setDate", 'now');
        $('.date2').datepicker({
            format: 'yyyy/mm/dd',
            todayHighlight: true,
            autoclose: true,
        })
        hideLoader();
    }

    componentWillMount() {
        if (this.context.user.completed_onboarding === null || this.context.user.completed_onboarding === undefined) {
            window.location.reload();
        }
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
    }

    async getContributions() {
        try {
            let res = await API.get(`clubs/${this.props.match.params.id}/contributions/`);
            let data = res.data;
            this.setState({
                contributions: data.list,
                total_deposits: data.stats.total_amount
            });
        } catch (e) {
            console.log(e.response)
        }
    };

    handleAddDeposit = async (event) => {
        event.preventDefault();
        const postData = {...this.state.form};
        postData.date = moment.utc(moment($('#date').val(), "YYYY-MM-DD").format('YYYY-MM-DDTHH:mm:ss.SSS')).format();
        showLoader();
        try {
            let res = await API.post(`clubs/${this.props.match.params.id}/make_contributions/`, {...this.state.form});
            this.setState({contributions: [res.data, ...this.state.contributions]});
            $('.modal').modal('hide');
            $(document.body).removeClass('modal-open');
            $('.modal-backdrop').remove();
            toast.success("Contributions added successfully");
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    };

    handleEditDeposit = async (event) => {
        event.preventDefault();
        showLoader();
        const postData = {
            contribution_id: this.state.form_edit.id,
            contribution_amount: this.state.form_edit.value,
            date: moment.utc(moment($('#date2').val(), "YYYY/MM/DD").format('YYYY-MM-DDTHH:mm:ss.SSS')).format()
        };
        try {
            let res = await API.put(`clubs/${this.props.match.params.id}/make_contributions/`, postData);
            // this.setState({contributions: [res.data, ...this.state.contributions]});
            await this.getContributions();
            $('.modal').modal('hide');
            $(document.body).removeClass('modal-open');
            $('.modal-backdrop').remove();
            toast.success("Contributions added successfully");
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    };

    handleDelete = async () => {
        showLoader();
        try {
            let res = await API.post(`clubs/${this.props.match.params.id}/remove_contributions/`, {...this.state.delete_form});
            this.setState({contributions: this.state.contributions.filter(item => item.id !== this.state.delete_form.contribution_id)});
            $('.modal').modal('hide');
            $(document.body).removeClass('modal-open');
            $('.modal-backdrop').remove();
            toast.success("Contributions removed successfully");
            hideLoader();
        } catch (e) {
            console.log(e)
            hideLoader();
        }
    };

    getMembers = async () => {
        try {
            let res = await API.get(`clubs/${this.props.match.params.id}/list_club_members/`);
            this.setState({members: res.data})
        } catch (e) {
        }
    };

    checkAdmin(item) {
        if (item !== undefined) {
            for (let i = 0; i < item.length; i++) {
                if (item[i].user.id === this.context.user.id && item[i].user_role === 'admin') {
                    return true;
                }
            }
            return false
        } else {
            return false
        }
    }

    clearForm = async () => {
        $('.date').datepicker({
            format: 'yyyy-mm-dd',
            todayHighlight: true,
            autoclose: true,
        }).datepicker("setDate", 'now');
        this.setState({
            form: {
                member: '',
                contribution_amount: '',
                date: $('#date').val()
            }
        })
    };

    setEditForm = async (event, item) => {
        await this.setState({
            form_edit: {...item}
        });
        await $('.date').datepicker({
            format: 'yyyy/mm/dd',
            todayHighlight: true,
            autoclose: true,
        }).datepicker("setDate", moment(item.created_at, 'YYYY-MM-DDTHH:mm:ss.SSS').format('YYYY/MM/DD'));
    };

    async payWithMonnify(event) {
        event.preventDefault();
        let postData = {
            contribution_amount: parseFloat(this.state.paynow_form.contribution_amount),
            payment_type: 'monnify_invoice'
        };
        showLoader();
        try {
            let res = await API.post(`clubs/${this.props.match.params.id}/create_deposit/`, postData);
            console.log(res, "create deposit")
            if(res.checkoutUrl){
                // window.open("https://sdk.monnify.com/checkout/MNFY%7C38%7C20220516135008%7C083214", "_blank")
                window.open(res.checkoutUrl, '_blank')
            }
            // this.setState({bank_transfer_details: res.data});
            // hideLoader();
            // $('#bankModal').modal('show');
        } catch (e) {
            console.log(e.response)
            hideLoader();
        }
    }

    render() {
        return (
            <Layout active_menu={'club'} user_state={this.props.match.params.id}
                    club_admin={this.checkAdmin(this.state.club.club_members)} context={this.context}>
                <div className="section">
                    <div className="breadcrumb">
                        <a onClick={event => window.history.back()}> <span
                            className="fa fa-chevron-left"/> Back</a>
                    </div>
                    <div className="row" id="contributions">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="float-left">Contributions </h3>
                                    <button
                                        onClick={event => this.clearForm(event)}
                                        data-toggle='modal'
                                        data-target='#contributeModal'
                                        className="btn m-b-5 ml-3 btn-blue text-white text-white float-right"> Record a Payment
                                    </button>
                                    <button
                                        data-toggle='modal' data-target="#paynowModal"
                                        className="btn ml-3 m-b-5 btn-blue text-white text-white float-right">Contribute Now
                                    </button>

                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <i className="fa fa-bar-chart text-blue"/>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                <span className="text-blue">Total Contributions</span>
                                                                <h2 className="text-blue mb-0">₦{parseFloat(this.state.total_deposits).toLocaleString()}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped mb-0 text-nowrap">
                                            <tbody>
                                            <tr>
                                                <th>Depositor</th>
                                                <th>Amount</th>
                                                <th>Reference</th>
                                                <th>Status</th>
                                                <th>Date</th>
                                                <th>Actions</th>
                                            </tr>
                                            {this.state.contributions.map(item => (
                                                <tr key={item.id}>
                                                    <td>{item.user.first_name} {item.user.last_name}</td>
                                                    <td><NumberFormat renderText={value => <div>{value}</div>}
                                                                      prefix={'₦'} displayType={'text'}
                                                                      thousandSeparator={true} value={item.amount}/>
                                                    </td>
                                                    <td>{item.ref_code}</td>
                                                    <td>
                                                        {(item.status.toLowerCase() === 'paid') ? (
                                                            <span className="badge bg-green m-b-5">{item.status}</span>
                                                        ) : (item.status.toLowerCase() === 'cancelled') ? (
                                                            <span className="badge bg-yellow m-b-5">{item.status}</span>
                                                        ) : (
                                                            <span
                                                                className="badge badge-danger m-b-5">{item.status}</span>
                                                        )}
                                                    </td>
                                                    <td><Moment format="MMM D, Y">{item.created_at}</Moment></td>
                                                    <td>
                                                        <div className="btn-group align-top">
                                                            {(item.payment_type === 'manual') ? (
                                                                <React.Fragment>
                                                                    <button data-target="#editModal" data-toggle="modal"
                                                                            onClick={event => this.setEditForm(event, item)}
                                                                            className="btn btn-sm btn-blue text-white badge"
                                                                            type="button">Edit
                                                                    </button>
                                                                    <button onClick={event => this.setState({
                                                                        delete_form: {
                                                                            contribution_id: item.id,
                                                                            user: item.user,
                                                                            value: item.value
                                                                        }
                                                                    })}
                                                                            data-target="#deleteModal"
                                                                            data-toggle="modal"
                                                                            className="btn btn-sm btn-danger badge"
                                                                            type="button"><i
                                                                        className="fa fa-trash"></i></button>
                                                                </React.Fragment>
                                                            ) : ''}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="contributeModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h5 className="modal-title" id="example-Modal3">Add contributions</h5>
                                <button type="button"
                                        className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <form onSubmit={event => this.handleAddDeposit(event)}>
                                <div className="modal-body">
                                    <div className="form-group mb-3">
                                        <label htmlFor="message-text"
                                               className="form-control-label">Member:</label>
                                        <select required value={this.state.form.member}
                                                onChange={event => this.setState({
                                                    form: {
                                                        ...this.state.form,
                                                        member: event.target.value
                                                    }
                                                })} className="form-control" name="" id="">
                                            <option value="">--- Select a Member ---</option>
                                            {this.state.members.map(item => (
                                                <option
                                                    value={item.id}>{`${item.first_name} ${item.last_name} (${item.email})`}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="message-text"
                                               className="form-control-label">Amount:</label>
                                        <NumberFormat required
                                                      value={this.state.form.contribution_amount}
                                                      thousandSeparator={true} prefix={'₦'}
                                                      onValueChange={(values) => {
                                                          const {formattedValue, value} = values;
                                                          this.setState({
                                                              form: {
                                                                  ...this.state.form,
                                                                  contribution_amount: value
                                                              }
                                                          })
                                                      }} className="form-control"
                                                      placeholder="Enter Amount"/>
                                    </div>
                                    <div className="form-group mb-0">
                                        <label htmlFor="message-text"
                                               className="form-control-label">Date:</label>
                                        <div className="input-group date" data-provide="datepicker">
                                            <input required id="date" readOnly type="text" className="form-control"/>
                                            <div className="input-group-addon">
                                                <span className="fa fa-calendar"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-green" data-dismiss="modal">Close
                                    </button>
                                    <button type="submit" className="btn btn-blue text-white">Add Contribution <i
                                        className="fas fa-plus"/></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div id="paynowModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h5 className="modal-title" id="example-Modal3">Pay Now</h5>
                                <button type="button"
                                        className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <form onSubmit={event => this.payWithMonnify(event)}>
                                <div className="modal-body">
                                    <div className="form-group mb-3">
                                        <label htmlFor="message-text"
                                               className="form-control-label">Amount:</label>
                                        <NumberFormat required
                                                      value={this.state.form.contribution_amount}
                                                      thousandSeparator={true} prefix={'₦'}
                                                      onValueChange={(values) => {
                                                          const {formattedValue, value} = values;
                                                          this.setState({
                                                              paynow_form: {
                                                                  ...this.state.paynow_form,
                                                                  contribution_amount: value
                                                              }
                                                          })
                                                      }} className="form-control"
                                                      placeholder="Enter Amount"/>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-green" data-dismiss="modal">Close
                                    </button>
                                    <button type="submit" className="btn btn-blue text-white">Generate Invoice <i
                                        className="fas fa-files-o"/></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div id="editModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h5 className="modal-title" id="example-Modal3">Edit contribution</h5>
                                <button type="button"
                                        className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <form onSubmit={event => this.handleEditDeposit(event)}>
                                {(this.state.form_edit.user) ? (
                                    <div className="modal-body">
                                        <div className="form-group mb-3">
                                            <label htmlFor="message-text"
                                                   className="form-control-label">Member:</label>
                                            <select disabled required value={this.state.form_edit.user.id}
                                                    className="form-control" name="" id="">
                                                <option
                                                    value={this.state.form_edit.user.id}>{`${this.state.form_edit.user.first_name} ${this.state.form_edit.user.last_name} (${this.state.form_edit.user.email})`}</option>
                                            </select>
                                        </div>
                                        <div className="form-group mb-3">
                                            <label htmlFor="message-text"
                                                   className="form-control-label">Amount:</label>
                                            <NumberFormat required
                                                          value={this.state.form_edit.value}
                                                          thousandSeparator={true} prefix={'₦'}
                                                          onValueChange={(values) => {
                                                              const {formattedValue, value} = values;
                                                              this.setState({
                                                                  form_edit: {
                                                                      ...this.state.form_edit,
                                                                      value: value
                                                                  }
                                                              })
                                                          }} className="form-control"
                                                          placeholder="Enter Amount"/>
                                        </div>
                                        <div className="form-group mb-0">
                                            <label htmlFor="message-text"
                                                   className="form-control-label">Date:</label>
                                            <div className="input-group date" data-provide="datepicker">
                                                <input required id="date2" readOnly type="text"
                                                       className="form-control"/>
                                                <div className="input-group-addon">
                                                    <span className="fa fa-calendar"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : ''}
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-green" data-dismiss="modal">Close
                                    </button>
                                    <button type="submit" className="btn btn-blue text-white">Add Contribution <i
                                        className="fas fa-plus"/></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div id="deleteModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-orange">
                                <h5 className="modal-title" id="example-Modal3">Delete contribution</h5>
                                <button type="button"
                                        className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-sm-12"><p>Are you sure you want to delete?</p>
                                    </div>
                                    {(this.state.delete_form.user) ? (
                                        <div className="col-sm-12">
                                            <p>Member: <strong>{`${this.state.delete_form.user.first_name} ${this.state.delete_form.user.last_name}`}</strong>
                                            </p>
                                            <p>Amount: <strong>{parseFloat(this.state.delete_form.value).toLocaleString()}</strong>
                                            </p>
                                        </div>
                                    ) : ''}
                                    <div className="col-sm-12">
                                        <p>Note: This can't be undone</p>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-green" data-dismiss="modal">Close
                                </button>
                                <button onClick={event => this.handleDelete(event)} className="btn btn-blue text-white text-white">Delete <i
                                    className="fas fa-trash"/></button>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default ClubContributions;
