import React from 'react'
import API from '../../api'
import $ from 'jquery'

import {Link} from 'react-router-dom';
import ReactTooltip from 'react-tooltip'

import Layout from '../Layout'

import {hideLoader, showLoader, toast, trackMixpanel, Utils} from "../../_helpers/Loader";
import {Context} from "../MyContext";

class MarketPlace extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            investments: [],
            all_investments: [],
            mutual_investments: [],
            option: '',
            option2: '',
            funds: ['real estate fund', 'money market fund', 'investment fund'],
            real_estate: ['real estate', 'rental income'],
            mansard: [],
        }
    }

    async componentDidMount() {
        trackMixpanel('MarketplaceView', false, false, this.context.user.email)
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
        showLoader();
        await this.getInvestments();
        // await this.getMansard();
        hideLoader();
    }


    componentWillMount() {
        if (this.context.user.completed_onboarding === null || this.context.user.completed_onboarding === undefined) {
            window.location.reload();
        }
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
    }

    async getInvestments() {
        try {
            let res = await API.get('investments/list_investments/');
            this.setState({investments: res.data, all_investments: res.data})
        } catch (e) {
            hideLoader();
            toast.error("Something Went Wrong");
            // console.log(e.response)
        }
    }

    getMansard = async () => {
        try {
            const res = await API.get('axa_mansard/get_mansard_investments/')
            console.log(res.data);
            this.setState({
                all_investments: [...this.state.all_investments, ...res.data],
                investments: [...this.state.investments, ...res.data]
            })
        } catch (e) {

        }
    }

    category = (option) => {
        showLoader();
        console.log(option, "knowing that you have waht you need")
        this.setState({option: option});
        if (option === 'all') {
            this.setState({investments: this.state.all_investments})
        } else if (option === 'funds') {
            this.setState({
                option2: 'all',
                investments: [...this.state.all_investments.filter(item => this.state.funds.includes(item.investment_type))]
            })
        } else if(option === 'real estate') {
            this.setState({
                option2: 'all',
                investments: [...this.state.all_investments.filter(item => this.state.real_estate.includes(item.investment_type))]
            })
            console.log(this.state.investments.filter(item => this.state.real_estate.includes(item.investment_type)), "hello there is my name")
        }else if(option === 'vc funds') {
            this.setState({
                option2: 'all',
                investments: [...this.state.all_investments.filter(item => item.offer === "VC Funds")]
            })
        } else {
            this.setState({investments: [...this.state.all_investments.filter(item => item.investment_type === option)]})
        }
        hideLoader();
    };

    category2 = (option) => {
        showLoader();
        console.log(option, "when we are here")
        this.setState({option2: option});
        if (option === 'all') {
            this.setState({investments: [...this.state.all_investments.filter(item => this.state.funds.includes(item.investment_type))]})
        } else {
            this.setState({investments: [...this.state.all_investments.filter(item => item.investment_type === option)]})
        }
        hideLoader();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        let cards = $(".card");
        let height = 0;
        cards.map((ind, item) => {
            ($(item).height() > height) && (height = $(item).height());
        });
        cards.height(height);
    }

    investmentCard(item) {
        const completion_rate = (parseInt(item.completion_rate) > 100) ? 100 : parseInt(item.completion_rate);
        const rate_if = (completion_rate < 100) ? 'bg-yellow' : 'bg-green';
        const status_color = (item.status.toLowerCase() === 'active') ? 'bg-green' : ((item.status.toLowerCase() === 'sold out') ? 'bg-orange' : ((item.status.toLowerCase() === 'coming soon') ? 'bg-yellow' : 'bg-danger'));
        const risk_color = (item.risk_level.toLowerCase() === 'low') ? 'bg-green' : (item.risk_level.toLowerCase() === 'medium') ? 'bg-yellow' : 'bg-danger';
        return (
            <div className="col-md-6 col-sm-12 col-lg-4 col-xl-4" key={item.id}>
                <div className="card">
                    {(completion_rate >= 100) ? (
                        <div className="eo2-sold-out js-sold-out-btn">
                            <a href="#">
                                <img src="/assets/img/sold_out_tag.png"/>
                            </a>
                        </div>
                    ) : ''}
                    <div className="img-container">
                        <img className="img-crop-sm " src={item.image} alt=""/>
                        <div className="overlay text-center">
                        </div>

                        <div className="text-overlay text-right">
                            {(completion_rate >= 100) ? '' : (
                                <span
                                    className={`badge ${(completion_rate >= 100) ? 'bg-orange' : status_color} m-b-5 text-capitalize`}>{(completion_rate >= 100) ? 'Sold Out' : item.status.toLowerCase() == 'coming soon' ? 'Upcoming Offer' : item.status}</span>
                            )}{(completion_rate >= 100) ? '' : (<br/>)}
                            <span className={`badge ${risk_color} m-b-5 text-capitalize`}>{item.risk_level} Risk</span>
                        </div>
                        <div className="text-overlay-left-bottom text-left">
                            {item.in_trust ? (<i className="fas fa-shield-alt text-dark"/>) : ''}
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row mb-2">
                            <div className="col-sm-12">
                                <h5 style={{fontWeight: 700}}>{item.title}</h5>
                                <p><b>By {item.company}</b></p>
                                <p className="text-justify">
                                    {item.description.substring(0, 100).replace(/<\/?[^>]+(>|$)/g, "")} . . .
                                </p>
                            </div>
                            <div className="col-sm-12 mb-4">
                                <div className="progress">
                                    <div className={`progress-bar progress-bar-striped ${rate_if}`} role="progressbar"
                                         style={{width: `${completion_rate}%`}}
                                         aria-valuenow={completion_rate} aria-valuemin="0"
                                         aria-valuemax="100">{completion_rate}%
                                    </div>
                                </div>
                            </div>
                            {this.state.funds.includes(item.investment_type) ? '' : (
                                <div className="col-sm-12">
                                    <p><strong>Trade Cycle: </strong>{item.trade_cycle}</p>
                                </div>
                            )}
                            <div className="col-sm-12">
                                <p><strong>Tenor: </strong>{item.maturity_cycle} Months</p>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-sm-12 col-lg-6 col-xl-6 col-md-12">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <strong>ROI</strong>
                                    </div>
                                    <div className="col-sm-12">
                                        {parseFloat(item.inv_roi)}% {item.tenor_type == "p.a" ? "Per Annum" : "Flat"}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-6 col-xl-6 col-md-12">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <strong>Per {this.state.funds.includes(item.investment_type) ? 'Token' : 'Unit'}</strong>
                                    </div>
                                    <div className="col-sm-12">
                                        {Utils.currencyToSym(item.currency)}{parseFloat((item.unit_price)).toLocaleString()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 text-center mx-auto">
                                <Link to={`/marketplace/${item.id}`}>
                                    <button className="btn btn-blue text-white text-center">{ item.status.toLowerCase() !== "coming soon" ? 'Learn More' : 'Show Interest' } </button>
                                </Link>
                            </div>
                        </div>
                        {(item.investment_type === 'real estate') ? (
                            <div className="row">
                                <div className="col-sm-12 text-center">
                                    <p className="text-orange mt-3"><sup>**</sup>Note: Card and wallet transactions only
                                    </p>
                                </div>
                            </div>
                        ) : ''}
                    </div>
                </div>
            </div>
        )
    }

    investmentCardAxa(item) {
        // const completion_rate = (parseInt(item.completion_rate) > 100) ? 100 : parseInt(item.completion_rate);
        // const rate_if = (completion_rate < 100) ? 'bg-yellow' : 'bg-green';
        // const status_color = (item.status.toLowerCase() === 'active') ? 'bg-green' : ((item.status.toLowerCase() === 'sold out') ? 'bg-orange' : ((item.status.toLowerCase() === 'coming soon') ? 'bg-yellow' : 'bg-danger'));
        // const risk_color = (item.risk_level.toLowerCase() === 'low') ? 'bg-green' : (item.risk_level.toLowerCase() === 'medium') ? 'bg-yellow' : 'bg-danger';
        return (
            <div className="col-md-6 col-sm-12 col-lg-4 col-xl-4" key={item.id}>
                <div className="card">
                    {/*{(completion_rate >= 100) ? (*/}
                    {/*    <div className="eo2-sold-out js-sold-out-btn">*/}
                    {/*        <a href="#">*/}
                    {/*            <img src="/assets/img/sold_out_tag.png"/>*/}
                    {/*        </a>*/}
                    {/*    </div>*/}
                    {/*) : ''}*/}
                    <div className="img-container">
                        <img className="img-crop-sm " src="/assets/img/axa.jpg" alt=""/>
                        <div className="overlay text-center">
                        </div>

                        <div className="text-overlay text-right">
                            {/*{(completion_rate >= 100) ? '' : (*/}
                            {/*    <span*/}
                            {/*        className={`badge ${(completion_rate >= 100) ? 'bg-orange' : status_color} m-b-5 text-capitalize`}>{(completion_rate >= 100) ? 'Sold Out' : item.status}</span>*/}
                            {/*)}{(completion_rate >= 100) ? '' : (<br/>)}*/}
                            {/*<span className={`badge ${risk_color} m-b-5 text-capitalize`}>{item.risk_level} Risk</span>*/}
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row mb-2">
                            <div className="col-sm-12">
                                <h5 style={{fontWeight: 700}}>{item.Name}</h5>
                                <p><b>By Axa Mansard</b></p>
                                <p className="text-justify">
                                    {item && item.ShortDescription && item.ShortDescription.substring(0, 100).replace(/<\/?[^>]+(>|$)/g, "")} . . .
                                </p>
                            </div>
                            <div className="col-sm-12 mb-4">
                                {/*<div className="progress">*/}
                                {/*    <div className={`progress-bar progress-bar-striped ${rate_if}`} role="progressbar"*/}
                                {/*         style={{width: `${completion_rate}%`}}*/}
                                {/*         aria-valuenow={completion_rate} aria-valuemin="0"*/}
                                {/*         aria-valuemax="100">{completion_rate}%*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-sm-12 col-lg-6 col-xl-6 col-md-12">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <strong>Minimum Amount</strong>
                                    </div>
                                    <div className="col-sm-12">
                                        ₦{parseFloat(item.MinimumAmount).toLocaleString()}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-6 col-xl-6 col-md-12">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <strong>Per Unit</strong>
                                    </div>
                                    <div className="col-sm-12">
                                        ₦{parseFloat((item.PricePerUnit)).toLocaleString()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 text-center mx-auto">
                                <Link to={{pathname: `/marketplace/${item.ID}/mansard`, query: {item}}}>
                                    <button className="btn btn-blue text-white text-center">Learn More</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <Layout active_menu={'market'} user_state={'personal'} context={this.context}>
                <ReactTooltip/>
                <div className="section">
                    <div className="breadcrumb">
                        <h5>Offers</h5>
                        <p className="text-justify" style={{fontSize: "12px"}}>TwelveVest is not a registered
                            investment, legal or tax advisor or
                            a broker or dealer. All investment and financial options expressed by TwelveVest are
                            intended to be used and must be used for information purposes only. Although best efforts
                            are made to ensure all information is accurate and up to date, occasionally unintended
                            errors and misprints may occur. It is especially important that you do your own analysis
                            before making any investment based on your own personal circumstances. You must obtain
                            independent financial expert advise for any investment option on this Site and you must
                            independently research and verify any information relied upon that you find on the Site,
                            whether or not you are making an investment decision. You are responsible for all risks and
                            financial resources you use and any chosen investment option. You should not participate in
                            any investment option unless you fully understand the nature of the transactions you are
                            entering into and the extent of your exposure to loss. If you do not fully understand these
                            risks, you must seek independent advice from a financial advisor. On TwelveVest.com, past
                            performance is not a guarantee of future returns, nor is it indicative of future
                            performance. You must be aware that all investments involve risks, and the value of your
                            investment will fluctuate over time and you may gain or lose money. All uses of the contents
                            of the Site, other than for personal uses, are prohibited. <br/>
                            ©2021 Vestract Company. All rights reserved.</p>
                    </div>
                    <div className="row mb-5 tab-buttons">
                        <div className="col-sm-12">
                            <ul className="nav nav-pills pb-3" id="myTab3" role="tablist">
                                <li className="nav-item mb-3">
                                    <a onClick={event => this.category('all')} className="nav-link active show"
                                       id="all-tab3"
                                       data-toggle="tab" href="#all3" role="tab"
                                       aria-controls="home" aria-selected="false">All Types</a>
                                </li>
                                <li className="nav-item mb-3">
                                    <a onClick={event => this.category('agro tech')} className="nav-link"
                                       id="agric-tab3"
                                       data-toggle="tab" href="#agric3" role="tab"
                                       aria-controls="home" aria-selected="false">Notes</a>
                                </li>
                                <li className="nav-item mb-3">
                                    <a onClick={event => this.category('funds')} className="nav-link"
                                       id="profile-tab3"
                                       data-toggle="tab" href="#profile3"
                                       role="tab" aria-controls="profile" aria-selected="false">Funds</a>
                                </li>
                                <li className="nav-item mb-3">
                                    <a onClick={event => this.category('real estate')} className="nav-link"
                                       id="real-tab3"
                                       data-toggle="tab" href="#real3" role="tab"
                                       aria-controls="home" aria-selected="false">Real Estate</a>
                                </li>
                                <li className="nav-item mb-3">
                                    <a onClick={event => this.category('vc funds')} className="nav-link"
                                       id="real-tab3"
                                       data-toggle="tab" href="#vcfunds" role="tab"
                                       aria-controls="home" aria-selected="false">VC Funds</a>
                                </li>

                                {/*<li className="nav-item mb-3">*/}
                                {/*    <a onClick={event => this.category('high')} className="nav-link" id="contact-tab3"*/}
                                {/*       data-toggle="tab"*/}
                                {/*       href="#contact3" role="tab" aria-controls="contact"*/}
                                {/*       aria-selected="true">Saving Plans</a>*/}
                                {/*</li>*/}
                            </ul>
                        </div>
                        {(this.state.option === 'funds') ? (
                            <div className="col-sm-12">
                                <ul className="nav nav-pills pb-3" id="myTab3" role="tablist">
                                    <li className="nav-item mb-3">
                                        <a onClick={event => this.category2('all')} className="nav-link active show"
                                           id="all-tab3"
                                           data-toggle="tab" href="#all3" role="tab"
                                           aria-controls="home" aria-selected="false">All Types</a>
                                    </li>
                                    <li className="nav-item mb-3">
                                        <a onClick={event => this.category2('real estate fund')} className="nav-link"
                                           id="agric-tab3"
                                           data-toggle="tab" href="#agric3" role="tab"
                                           aria-controls="home" aria-selected="false">Real Estate Fund</a>
                                    </li>
                                    <li className="nav-item mb-3">
                                        <a onClick={event => this.category2('money market fund')} className="nav-link"
                                           id="profile-tab3"
                                           data-toggle="tab" href="#profile3"
                                           role="tab" aria-controls="profile" aria-selected="false">Money Market
                                            Fund</a>
                                    </li>
                                </ul>
                            </div>
                        ) : ''}
                        {(this.state.option === 'real estate') ? (
                            <div className="col-sm-12">
                                <ul className="nav nav-pills pb-3" id="myTab3" role="tablist">
                                    <li className="nav-item mb-3">
                                        <a onClick={event => this.category2('all')} className="nav-link active show"
                                           id="all-tab3"
                                           data-toggle="tab" href="#all3" role="tab"
                                           aria-controls="home" aria-selected="false">All Types</a>
                                    </li>
                                    <li className="nav-item mb-3">
                                        <a onClick={event => this.category2('real estate')} className="nav-link"
                                           id="real-estate-tab3"
                                           data-toggle="tab" href="#real-estate3" role="tab"
                                           aria-controls="home" aria-selected="false">OwnerShip</a>
                                    </li>
                                    <li className="nav-item mb-3">
                                        <a onClick={event => this.category2('rental income')} className="nav-link"
                                           id="real-income-tab3"
                                           data-toggle="tab" href="#real-income"
                                           role="tab" aria-controls="real-income" aria-selected="false">OwnerShip with Rental Income</a>
                                    </li>
                                </ul>
                            </div>
                        ) : ''}
                        {(this.state.option === 'agro tech') ? (
                            <div className="col-sm-12">
                                <ul className="nav nav-pills pb-3" id="myTab3" role="tablist">
                                    <li className="nav-item mb-3">
                                        <a onClick={event => {
                                            this.category('agro tech')
                                            this.category2('agro tech')
                                        }} className="nav-link"
                                        id="agro-tech-tab3"
                                        data-toggle="tab"
                                        href="#agro-tech3" role="tab" aria-controls="agro-tech"
                                        aria-selected="true">Agro Tech</a>
                                    </li>
                                    <li className="nav-item mb-3">
                                        <a onClick={event => this.category2('investment fund')} className="nav-link"
                                        id="contact-tab3"
                                        data-toggle="tab"
                                        href="#contact3" role="tab" aria-controls="contact"
                                        aria-selected="false">Investment Fund / Credit Debit Note</a>
                                    </li>
                                    <li className="nav-item mb-3">
                                        <a onClick={event => this.category2('treasury bills')} className="nav-link"
                                        id="treasury-tab3"
                                        data-toggle="tab"
                                        href="#treasury3" role="tab" aria-controls="treasury"
                                        aria-selected="false">Treasury Bills</a>
                                    </li>
                                    <li className="nav-item mb-3">
                                        <a onClick={event => this.category2('mutual bonds')} className="nav-link"
                                        id="mutual-bonds3"
                                        data-toggle="tab"
                                        href="#mutual-bonds3" role="tab"
                                        aria-controls="partner"
                                        aria-selected="false">Mutual Bonds</a>
                                    </li>
                                </ul>
                            </div>
                        ) : ''}
                        {(this.state.option === 'vc funds') ? (
                            <div className="col-sm-12">
                                <ul className="nav nav-pills pb-3" id="myTab3" role="tablist">
                                    {/* <li className="nav-item mb-3">
                                        <a onClick={event => {
                                            this.category('agro tech')
                                            this.category2('agro tech')
                                        }} className="nav-link"
                                        id="agro-tech-tab3"
                                        data-toggle="tab"
                                        href="#agro-tech3" role="tab" aria-controls="agro-tech"
                                        aria-selected="true">Agro Tech</a>
                                    </li> */}
                                </ul>
                            </div>
                        ) : ''}
                        {/*{this.state.level === 'medium' ? (*/}
                        {/*    <div className="col-sm-6 mt-5">*/}
                        {/*        <h4 className="">Features</h4>*/}
                        {/*        <ul className="list-group list-group-flush">*/}
                        {/*            <li className="list-group-item"><span className="fa fa-check"/> <strong>Investor*/}
                        {/*                Profile:</strong> Moderate*/}
                        {/*            </li>*/}
                        {/*            <li className="list-group-item"><span className="fa fa-check"/> <strong>Risk*/}
                        {/*                Level:</strong> Medium - High*/}
                        {/*            </li>*/}
                        {/*            <li className="list-group-item"><span className="fa fa-check"/> <strong>Great for*/}
                        {/*                passive income</strong></li>*/}
                        {/*        </ul>*/}
                        {/*    </div>*/}
                        {/*) : ((this.state.level === 'low') ? (*/}
                        {/*    <div className="col-sm-6 mt-5">*/}
                        {/*        <h4 className="">Features</h4>*/}
                        {/*        <ul className="list-group list-group-flush">*/}
                        {/*            <li className="list-group-item"><span className="fa fa-check"/> <strong>Investor*/}
                        {/*                Profile:</strong> Conservative*/}
                        {/*            </li>*/}
                        {/*            <li className="list-group-item"><span className="fa fa-check"/> <strong>Risk*/}
                        {/*                Level:</strong> Low*/}
                        {/*            </li>*/}
                        {/*            <li className="list-group-item"><span className="fa fa-check"/> <strong>Great for*/}
                        {/*                long term investment</strong></li>*/}
                        {/*        </ul>*/}
                        {/*    </div>*/}
                        {/*) : ((this.state.level === 'high') ? (*/}
                        {/*    <div className="col-sm-6 mt-5">*/}
                        {/*        <h4 className="">Features</h4>*/}
                        {/*        <ul className="list-group list-group-flush">*/}
                        {/*            <li className="list-group-item"><span className="fa fa-check"/> <strong>Investor*/}
                        {/*                Profile:</strong> Aggressive*/}
                        {/*            </li>*/}
                        {/*            <li className="list-group-item"><span className="fa fa-check"/> <strong>Risk*/}
                        {/*                Level:</strong> High*/}
                        {/*            </li>*/}
                        {/*            <li className="list-group-item"><span className="fa fa-check"/> <strong>Great for*/}
                        {/*                passive income</strong></li>*/}
                        {/*        </ul>*/}
                        {/*    </div>*/}
                        {/*) : ''))}*/}
                    </div>
                    <div className="row">
                        <React.Fragment>
                            {this.state.investments.length === 0 ? (
                                <div className="col-sm-3">
                                    <p>No offers found for this category yet.</p>
                                </div>
                            ) : ''}
                            {this.state.investments.map(item => {
                                if (item.investment_type === 'mutual bonds') {
                                    return (this.investmentCardAxa(item), this.investmentCard(item))
                                } else {
                                    return this.investmentCard(item)
                                }
                            })
                            }
                        </React.Fragment>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default MarketPlace
