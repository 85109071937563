import React, { useState, useEffect, useContext } from 'react';
import Layout from '../../Layout';
import {Context} from "../../MyContext";
import {Link, withRouter} from 'react-router-dom';
import {showLoader, hideLoader, toast} from "../../../_helpers/Loader";
import API from '../../../api';
import { Country, State, City }  from 'country-state-city';
import SubAccreditation from './SubAccreditation';
import BankStatement from './bankStatement';
import $ from "jquery";


const Accredation = () => {
  const [agree, setAgree] = useState(false);
  const [preagree, setPreagree] = useState(false);
  const [submitted, setSubmitted] = useState(false)
  const [stage, setStage] = useState({
    next: 1,
    prev: -1,
    current: 0});
  let textField = {}

  const [questions, setQuestions] = useState({});
  const [width, setWidth] = useState(0)

  const [answers, setAnswers] = useState({})

  const [qtags, setQtags] = useState([]);
  const [multiselect, setMultiSelect] = useState([])

  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [score, setScore] = useState(0)
  const [accountType, setAccountType] = useState(null)
  const [localCountry, setLocalCountry] = useState(null)
  const [localState, setLocalState] = useState(null)
  const [localCity, setLocalCity] = useState(null)
  
  const [uploadOption, setUploadOption] = useState(null)
  const [licenseLink, setLicenseLink] = useState(null)

  const [outLyers, setOutLyers] = useState({})
  const [onlyNg, setOnlyNg] = useState(null)

  const [nigeriaCountry, setNigeriaCountry] = useState(Country.getAllCountries().filter(item => item.name == 'Nigeria'))

  const context = useContext(Context);

  const calcScore = (vAnswers) => {
    let fScore = Object.values(vAnswers).map(item => {
      try {
        return (item && item.split("|")[1]) || 0
      } catch(e) {
        return 0
      }
    })

    let sum = fScore.reduce(function(a, b){
        return parseInt(a) + parseInt(b);
    }, 0)
    setScore(sum)
    return sum
  }

  //landing
  const submitForm = async() => {
    setAnswers({
      ...answers,
      fund_source: multiselect
    })

    showLoader()
    let postData = {
      answers: JSON.stringify(answers),
      questions: JSON.stringify(questions),
      score: score > 0 ? score : calcScore(answers)
    }

    console.log(postData, "postData")

    try {
      await API.post('users/submit_accreditation_form/', postData);
      console.log(postData, "postData")
      toast.success("Qualification form has been submitted successfully");
      setSubmitted(true)
      let user = localStorage.getItem('user');
      user = JSON.parse(user)
      user.accreditation_form_submitted = true
      localStorage.setItem('user', JSON.stringify(user));
      await context.updateUser()
      setTimeout(() => {
        window.location.assign("/")
      }, 20000);
      hideLoader();
    } catch (err) {
        toast.error("No Duplicate Submission Allowed");
        // console.log(err.response);
        hideLoader();
    }
  }
  const splitScoreOut = (value) => {
    return value.split("|")
  }

  const addMultiSelected = async (e) => {
    let multiSelectMultipleOptions = [];
    if (multiselect.includes(e.target.value) && !e.target.checked){
      console.log(e.target.value, "removed")

      for (let i = 0; i < multiselect.length; i++) {
        const element = multiselect[i];
        if (element === e.target.value){
          multiselect.splice(i, 1)
          e.target.value = false
          setMultiSelect(multiselect)
        }
      }
    } else {
      console.log(multiselect, e.target.value, "added")
      let n_multiple = multiselect;
      n_multiple.push(e.target.value)
      setMultiSelect(n_multiple)
      // setMultiSelect([
      //   ...multiselect,
      //   e.target.value,
      // ])
      // multiSelectMultipleOptions.push(e.target.value)
    }
    if (multiselect.length > 0){
      checkifNotEmpty(multiselect.toString())
      setAnswers({
        ...answers,
        [qtags[stage.current]]: multiselect.toString(),
      })
    }
    console.log(multiSelectMultipleOptions, multiselect, "multiselect")
  }
  
  const getQuestions = async () => {
    fetch('/assets/questions.json',{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }).then((response) => {
      return response.json()
    }).then((q) => {
      console.log(q, ":::>")
      setQuestions(q)
      setQtags(Object.keys(q))
    })
  }

  const checkifNotEmpty = (value, other_value=null, done=false) => {
    // console.log(value, other_value, done, "0000000-------000000")
    if (value && !other_value){
      if (!value || value === "" || value === null || value === false){
        setButtonDisabled(true)
        // return false
      } else {
        setButtonDisabled(false)
        // return true
      }
    } else if (value && other_value){
      //the checks for agreement and 
      if (other_value === "licenseLink"){
        // console.log(other_value, value, "vlaue other_value")
        if (licenseLink && value){
          setButtonDisabled(false)
        }
      } else if (other_value === "letter" && value){
        if (done){
          setButtonDisabled(false)
        } 
      }
      else if(other_value === "email_sent" && value){
        if (done){
          setButtonDisabled(false)
        }
      } else {
        setButtonDisabled(true)
      }
    } else {
      setButtonDisabled(true)
    }
  }

  const imageUpload = async (event) => {
    if (event.target.files && event.target.files[0]) {
      console.log(event.target.files[0].type, "googleooo")
      // let postData = {}
      // let reader = new FileReader();
      // reader.onload = (e) => {
      //     // ({image: e.target.result});
      //     console.log(e.target.result)
      //     postData.file = e.target.result
      // };
      // reader.readAsDataURL(event.target.files[0]);
      if (event.target.files[0].type.toLowerCase().includes("pdf")){
        showLoader()
        let res = await API.uploadFile("users/upload_resources/", event.target.files[0]);
        console.log(res.data.secure_url);
        hideLoader()
        // postData.image = res.data.secure_url;
        return res.data.secure_url
      } else{
        event.target.value = null
        toast.error("Only pdf files allowed")
      }
    }
  };


  useEffect(() => {
    if(context.user.accredited || context.user.accreditation_form_submitted){
      window.location = '/dashboard';
    } else {
      getQuestions()
    }
    console.log(State.getStatesOfCountry("NG"))
    return () => {
      console.log("")
    };
  }, []);

  function arrayRemove(arr, value) {
     
      return arr.filter(function(item){
          return !value.includes(item);
      });
    
  }

  let subParentOptions = ["Bank statements that show my average income for the previous two years", "A Professional license that demonstrates my investment knowledge", "Upload a qualification status letter from a registered SEC-registered Institution or Lawyer", "Send a qualification request to my Lawyer or Sec-registered Institution"]

  return (
    <>
      <div>
        <Layout active_menu={'dash'} user_state={'personal'} context={context}>
          <div className="section">
          <div className="breadcrumb" style={{marginBottom: 0}}>
              <div className="col-sm-12 pl-0">
                <h5>Qualification</h5>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {agree ? (
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 py-3">
                                <form className="row" onSubmit={(e) => {e.preventDefault()}}>
                                  <div className="col-lg-8 col-xl-8 col-md-8 col-sm-8 offset-lg-2 py-3">
                                    {!submitted ?
                                    <div class="progress" style={{height:"15px"}}>
                                      <div class="progress-bar progress-bar-striped progress-bar-animated" style={{width: `${width}%`, backgroundColor: "#1F5277"}}></div>
                                    </div> : ""}
                                    <br/>
                                    {qtags[stage.current] && questions[qtags[stage.current]] && questions[qtags[stage.current]].tag === "input" ? (
                                      <>
                                        <div className='form-group'>
                                          <label className='form-label'><h5>{questions[qtags[stage.current]].label}</h5></label>
                                          <br/>
                                          <input className='form-control' key={questions[qtags[stage.current]].label} type={questions[qtags[stage.current]].type} placeholder={questions[qtags[stage.current]].placeholder} defaultValue={answers[qtags[stage.current]]} onChange={(e) => {
                                            checkifNotEmpty(e.target.value)
                                            setAnswers({
                                              ...answers,
                                              [qtags[stage.current]]: e.target.value,
                                            })
                                          }} required />
                                          <small>{questions[qtags[stage.current]].help}</small>
                                        </div>
                                      </>
                                    ) : ""}

                                    {qtags[stage.current] && questions[qtags[stage.current]] && questions[qtags[stage.current]].tag === "checkbox" ? (
                                      <>
                                        <div className='form-group'>
                                          <input className='form-check-input' id={questions[qtags[stage.current]].name} key={questions[qtags[stage.current]].label} type={questions[qtags[stage.current]].type} placeholder={questions[qtags[stage.current]].placeholder} defaultValue={answers[qtags[stage.current]]} onChange={(e) => {
                                              checkifNotEmpty(e.target.checked)
                                              setAnswers({
                                                ...answers,
                                                [qtags[stage.current]]: e.target.checked ? e.target.value : "",
                                              })
                                          }} required />
                                          <label className='form-check-label' for={questions[qtags[stage.current]].name}><p>{questions[qtags[stage.current]].label}</p></label>
                                          <small>{questions[qtags[stage.current]].help}</small>
                                        </div>
                                      </>
                                    ) : ""}

                                    {qtags[stage.current] && questions[qtags[stage.current]] && questions[qtags[stage.current]].actual_type === "upload" ? (
                                      <>
                                      {!uploadOption ? 
                                        <div>
                                          <h5>{questions[qtags[stage.current]].label}</h5><br/>
                                          <div className='form-group' key={qtags[stage.current]}>
                                            <div className="mx-4">
                                              {questions[qtags[stage.current]].option.map((item, index) => (
                                                <>
                                                  <label class="form-check-label" for={index} >
                                                    <input className="form-check-input" type={questions[qtags[stage.current]].type} name="" id={index} value={item} checked={answers[qtags[stage.current]] === item ? true : false} onChange={(e) => {
                                                        // checkifNotEmpty(e.target.value)
                                                        // setAnswers({
                                                        //   ...answers,
                                                        //   [qtags[stage.current]]: e.target.value,
                                                        // })
                                                        console.log(splitScoreOut(e.target.value)[0])
                                                        setUploadOption(splitScoreOut(e.target.value)[0])
                                                    }} />
                                                      {splitScoreOut(item)[0]}
                                                  </label>
                                                  <br/>
                                                  <br/>
                                                </>
                                              ))}
                                            </div>
                                            <small>{questions[qtags[stage.current]].help}</small>
                                          </div>
                                        </div>
                                         : ""
                                      }

                                      {uploadOption === "Bank statements that show my average income for the previous two years" ? (
                                        <>
                                          <BankStatement setUpl={setUploadOption} q={questions[qtags[stage.current]]} outLyers={outLyers} setOutLyers={setOutLyers} setAnswers={setAnswers} answers={answers} qtagsCurrent={[qtags[stage.current]]} checkifNotEmpty={checkifNotEmpty} /><br/>
                                          {/* <button className="btn btn-secondary" style={{"position": "absolute", "bottom": "20px"}} onClick={(e) => {setUploadOption(null)}}>Back</button> */}
                                        </>
                                      ) : ""}

                                      { uploadOption === "A Professional license that demonstrates my investment knowledge" ? (<div className="mx-4">
                                          <label>
                                            <input className='form-file-input' id="licenseLink" type="file" onChange={async(e) => {
                                                let link = await imageUpload(e)
                                                console.log(link, "====>>>")
                                                setLicenseLink(link)
                                              
                                                setOutLyers({
                                                  ...outLyers,
                                                  [questions[qtags[stage.current]].label]: {
                                                    "A_Professional_license_that_demonstrates_my_investment_knowledge": link
                                                  }
                                                })
                                                setAnswers({
                                                  ...answers,
                                                  [qtags[stage.current]]: JSON.stringify({
                                                    "A_Professional_license_that_demonstrates_my_investment_knowledge": link
                                                  }),
                                                })
                                            }} /> 
                                            {/* <span className='btn btn-primary' onClick={(e) => {
                                                // let link = imageUpload(document.getElementById("licenseLink").files[0])
                                                // console.log(document.getElementById("licenseLink").files[0], "====>>>")
                                                // setLicenseLink(link)
                                                // setOutLyers({
                                                //   ...outLyers,
                                                //   [questions[qtags[stage.current]].label]: {
                                                //     ["_".join("Professional license that demonstrates my investment knowledge")] : link
                                                //   }
                                                // })
                                              }}>Upload File</span> */}
                                          </label><br/><br/>
                                          <span className="my-2" id="link_to_uploaded_license">{ licenseLink ? (<span className="text-success" style={{color: "green"}}>File Uploaded Successfully <i className="fa fa-check-circle text-success" style={{color: "green"}}></i></span>) : "" }</span>
                                          <br/><br/>
                                          <label className='form-check-label px-lg-4' for={questions[qtags[stage.current]].name}>
                                            <input className='form-check-input' id={questions[qtags[stage.current]].name} key={questions[qtags[stage.current]].label} type="checkbox" placeholder={questions[qtags[stage.current]].placeholder} defaultValue={answers[qtags[stage.current]]} onChange={(e) => {
                                                // checkifNotEmpty(e.target.checked)
                                                if(licenseLink){
                                                  checkifNotEmpty(e.target.checked, "licenseLink", e.target.checked)
                                                }
                                                setAnswers({
                                                  ...answers,
                                                  [qtags[stage.current]]: e.target.checked ? e.target.value : "",
                                                })
                                            }} required />
                                            <p>{"I represent that the documents uploaded are true and complete"}</p>
                                          </label>
                                          <br/>
                                          {/* <button className="btn btn-secondary" style={{"position": "absolute", "bottom": "20px"}} onClick={(e) => {setUploadOption(null)}}>Back</button> */}
                                          </div>) : "" }
                                      { uploadOption === "Upload a qualification status letter from a registered SEC-registered Institution or Lawyer" || uploadOption === "Send a qualification request to my Lawyer or Sec-registered Institution" ? (<><SubAccreditation uploadOption={uploadOption} setUploadOption={setUploadOption} q={questions[qtags[stage.current]]} imageUpload={imageUpload} outLyers={outLyers} setOutLyers={setOutLyers} setAnswers={setAnswers} answers={answers} qtagsCurrent={[qtags[stage.current]]} checkifNotEmpty={checkifNotEmpty} setWidth={setWidth} stage={stage} setStage={setStage} qtags={qtags}  />
                                      <br/>
                                      {/* <button className="btn btn-secondary" style={{"position": "absolute", "bottom": "20px"}} onClick={(e) => {setUploadOption(null)}}>Back</button> */}
                                      </>) : "" } 
                                      { uploadOption === "None of the above" ? (<><h4><strong>Unfortunately, it looks like you don't meet the requirements to become a qualified investor. If you believe you do meet the requirements, please send an email to black@twelvevest.com and we'd be happy to take a closer look.</strong></h4>
                                      <br/>
                                      {/* <button className="btn btn-secondary" style={{"position": "absolute", "bottom": "20px"}} onClick={(e) => {setUploadOption(null)}}>Back</button> */}
                                      </>) : "" } 
                                    </>
                                    ) : ""}

                                    {qtags[stage.current] && questions[qtags[stage.current]] && questions[qtags[stage.current]].tag === "radio" && questions[qtags[stage.current]].actual_type !== "upload"? (
                                      <>
                                        <h5>{questions[qtags[stage.current]].label}</h5><br/>
                                        <div className='form-group' key={qtags[stage.current]}>
                                          <div className="mx-4">
                                            {questions[qtags[stage.current]].option.map((item, index) => (
                                              <>
                                                <label class="form-check-label" for={index}>
                                                  <input className="form-check-input" type={questions[qtags[stage.current]].type} name="" id={index} value={item} checked={answers[qtags[stage.current]] === item ? true : false} onChange={(e) => {
                                                      checkifNotEmpty(e.target.value)
                                                      setAnswers({
                                                        ...answers,
                                                        [qtags[stage.current]]: e.target.value,
                                                      })
                                                  }} />
                                                    {splitScoreOut(item)[0]}
                                                </label>
                                                <br/>
                                                <br/>
                                              </>
                                            ))}
                                          </div>
                                          <small>{questions[qtags[stage.current]].help}</small>
                                        </div>
                                      </>
                                    ) : ""}

                                    {qtags[stage.current] && questions[qtags[stage.current]] && questions[qtags[stage.current]].tag === "select" ? (
                                      <>
                                        <div className='form-group' key={qtags[stage.current]}>
                                          <label className='form-label'><h5>{questions[qtags[stage.current]].label}</h5></label><br/>
                                          <select className='form-control' type={questions[qtags[stage.current]].type}  defaultValue={answers[qtags[stage.current]]} onChange={(e) => {
                                              checkifNotEmpty(e.target.value)
                                              setAnswers({
                                                ...answers,
                                                [qtags[stage.current]]: e.target.value,
                                              })
                                              if(questions[qtags[stage.current]].option === "country"){
                                                  let c = Country.getAllCountries().filter(item => item.name === e.target.value)
                                                  setLocalCountry(c[0].isoCode)
                                                  console.log(c[0].isoCode, "c[0].isoCode")
                                                  if (c[0].isoCode !== "NG"){
                                                    // setOnlyNg(false)
                                                    // setAnswers({
                                                    //   ...answers,
                                                    //   state: "Nil",
                                                    //   city: "Nil"
                                                    // })
                                                    // setStage({
                                                    //   next: stage.next + 2,
                                                    //   prev: stage.prev - 2,
                                                    //   current: stage.current + 3
                                                    // })
                                                    setQtags([...arrayRemove(qtags, ["state", "city"])])
                                                  } else {
                                                    if (!qtags.includes("state") && !qtags.includes("city")){
                                                      qtags.splice(1, 0, "state")
                                                      qtags.splice(2, 0, "city")
                                                      setQtags(qtags)
                                                    }
                                                  }
                                              }
                                              if(questions[qtags[stage.current]].option === "state"){
                                                let c = State.getStatesOfCountry(localCountry).filter(item => item.name === e.target.value)
                                                // console.log(c)
                                                setLocalState(c[0].isoCode)
                                              }
                                            }
                                          } required> 
                                          <option></option>
                                          {questions[qtags[stage.current]].option === "country" ? (
                                            Country.getAllCountries().map(item => (
                                              <option value={item.name} selected={answers[qtags[stage.current]] === item.name ? true : false}>{item.name}</option>
                                            ))
                                          ) : ""}
                                          {/* {console.log(localCountry, State.getStatesOfCountry(localCountry && localCountry), "::::::::::")} */}
                                          {questions[qtags[stage.current]].option === "state" ? (
                                            State.getStatesOfCountry(localCountry).map(item => (
                                              <option value={item.name} selected={answers[qtags[stage.current]] === item.name ? true : false} onClick={(e) => {
                                                setLocalState(item.isoCode)
                                              }}>{item.name}</option>
                                            ))
                                          ) : ""}
                                          {questions[qtags[stage.current]].option === "city" ? (
                                            City.getCitiesOfState(localCountry, localState).map(item => (
                                              <option value={item.name} selected={answers[qtags[stage.current]] === item.name ? true : false} onClick={(e) => {
                                                setLocalCity(item.name)
                                              }}>{item.name}</option>
                                            ))
                                          ) : ""}
                                          {!["country", "city", "state"].includes(questions[qtags[stage.current]].option) ?
                                            questions[qtags[stage.current]]?.option?.map(item => (
                                              <option value={item} selected={answers[qtags[stage.current]] === item ? true : false}>{item}</option>
                                            )) : ""}
                                          </select>

                                          {answers[qtags[stage.current]] === "Others" ? (<><br/>
                                          <input type="text" className='form-control' defaultValue={answers["Other Industry"] || ""} onKeyUp={(e) => {
                                            setAnswers({
                                              ...answers,
                                              "Other Industry": `${e.target.value}`,
                                            })
                                          }}/>
                                          </>) : ""}
                                          <small>{questions[qtags[stage.current]].help}</small>
                                        </div>
                                      </>
                                    ) : ""}

                                    {qtags[stage.current] && questions[qtags[stage.current]] && questions[qtags[stage.current]].tag === "textarea" ? (
                                      <>
                                        <div className='form-group' key={qtags[stage.current]}>
                                          <label className='form-label'><h5>{questions[qtags[stage.current]].label}</h5></label><br/>
                                          <textarea className='form-control' rows="5" type={questions[qtags[stage.current]].type}  defaultValue={answers[qtags[stage.current]]} onChange={(e) => {
                                            checkifNotEmpty(e.target.value)
                                            setAnswers({
                                              ...answers,
                                              [qtags[stage.current]]: e.target.value,
                                            })
                                          }} required>
                                          </textarea>
                                          <small>{questions[qtags[stage.current]].help}</small>
                                        </div>
                                      </>
                                    ) : ""}

                                    {qtags[stage.current] && questions[qtags[stage.current]] && questions[qtags[stage.current]].tag === "multiselect" ? (
                                      <>
                                        <div class="form-group row">
                                          <label class="col-12">{questions[qtags[stage.current]].label}</label> 
                                          <div class="col-12">
                                          {questions[qtags[stage.current]].option.map((item, index) => 
                                            <div class="custom-control custom-checkbox custom-control-inline" key={"ckc_"+index}>
                                              <input name="checkbox" id={"check_"+index} type="checkbox" class="custom-control-input" value={item.value} onChange={(e) => addMultiSelected(e)}  checked={multiselect.includes(item.value) ? true : false}/> 
                                              <label for={"check_"+index} class="custom-control-label">{item.label}</label>
                                            </div>
                                          )}
                                          </div>
                                        </div>
                                        {/* {multiselect.length > 0 ?
                                        <div className='form-group' key={qtags[stage.current] + "54"}>
                                          <label className='form-label'>{questions[qtags[stage.current]].label}</label>
                                          <textarea className='form-control' rows="5" defaultValue={answers["fund_source_details"]} onChange={(e) => {
                                            setAnswers({
                                              ...answers,
                                              fund_source_details: e.target.value,
                                            })
                                          }} required>
                                          </textarea>
                                          <small><ul><li>e.g: if investment, enter Type of investment, date investment purchased, value of investment, origin of funds used for investment, location of investment (where tangible)</li><li>if inheritance, What assets were inherited / gifted and in what year, from who and where that person generated their wealth from</li></ul></small>
                                        </div> : ""} */}
                                      </>
                                    ) : ""}

                                    {stage.next > qtags.length && !submitted ? (
                                      <div className='text-center'>
                                        <strong className="text-primary" style={{fontSize: "1rem"}}>You have complete the accreditation form please click submit to finalize the submission</strong>
                                      </div>
                                    ): ""}
                                    {stage.next > qtags.length && submitted ? (
                                      <div className='text-center'>
                                        {/* <h5>SUBMISSION SUCCESSFUL</h5> */}
                                        <p className="text-primary" style={{fontSize: "1.5rem"}}><i className='fa fa-2x fa-check-circle text-primary'></i><br/>
                                          Your request to become a qualified investor on Twelve has been received and now under review, you can view status <a href="/">here</a>.<br/><br/> Typically, the review proces takes 24-48hours and you receive a response email to confirm the result of your application.<br/><br/>
                                          In the meantime, If you have any questions or require further clarification, please send an email to  <a href="mailto:black@twelvevest.com">black@twelvevest.com</a> or <a href="mailto:support@twelvevest.com">support@twelvevest.com</a> 
                                        </p>
                                      </div>
                                    ): ""}
                                    <br/>
                                    {!submitted ? 
                                    <button className='btn btn-primary float-right m-1' disabled={ buttonDisabled ? true: false } onClick={() => {
                                      if(stage.next > qtags.length){
                                        submitForm()
                                      } else {
                                        setStage({
                                          next: stage.next + 1,
                                          prev: stage.prev + 1,
                                          current: stage.current + 1
                                        })
                                        setWidth(parseInt(((stage.current + 1)  / qtags.length) * 100))
                                        if (stage.next >= qtags.length){
                                          setButtonDisabled(false)
                                        } else {
                                          setButtonDisabled(true)
                                        }
                                      }
                                      console.log(stage)
                                    }}> {stage.next > qtags.length ? "Submit" : "Next"} <i className='fa fa-arrow-right' /></button> : "" }
                                    { stage.prev >= 0 && !submitted ? (qtags[stage.current] && questions[qtags[stage.current]] && questions[qtags[stage.current]].actual_type === "upload" && subParentOptions.includes(uploadOption)) ?  (<button className="btn btn-primary float-right m-1" onClick={(e) => {setUploadOption(null); setButtonDisabled(true);}}>Previous</button>) : (<button className='btn btn-primary float-right m-1' onClick={() => {
                                      setStage({
                                        next: stage.next - 1,
                                        prev: stage.prev - 1,
                                        current: stage.current - 1
                                      })
                                      setWidth(parseInt(((stage.current - 1)  / qtags.length) * 100))
                                      setButtonDisabled(false)
                                    }}> <i className='fa fa-arrow-left' /> Previous </button>) : ""}
                                      { stage.current === 0 ? (<button className='btn btn-primary float-right m-1' onClick={() => {
                                        setAgree(false)
                                        setButtonDisabled(true)
                                      }}> <i className='fa fa-arrow-left' /> Previous </button>) : ""  }
                                  </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>) : 
                  (<div className="card">
                      <div className="card-body">
                          <div className="row">
                              <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 py-3">
                                  <form className="row" onSubmit={(e) => e.preventDefault()}>
                                    <div className="col-lg-8 col-xl-8 col-md-8 col-sm-8 offset-lg-2 py-3">
                                      <h5><strong>{context.user.first_name}  {context.user.last_name}'s Onboarding Questionnaire</strong></h5>
                                      <p className='text-justify'>
                                        This onboarding questionnaire commences the process to verify you as a qualified investor on the Twelve platform. Getting verified as a qualified investor automatically gives you access to investment options with potentially higher rewards and higher risks. The onboarding requirements are designed to help you demonstrate your investment knowledge, investment experience, understanding of risk and investment capacity.
                                        <br/>Fulfilling these requirements as stipulated on the platform provides access to diverse opportunities from the investment partners we work with on a continuous basis.
                                        <br/><br/>
                                        Kindly note that you will be required to pay a fee of ${context.user.qualification_cost} once the application is approved.
                                        <br/>
                                      </p>
                                      {/* <p>Select the person or business entity you would like to submit for qualification approval</p>
                                      <br/>
                                      <div class="form-group">
                                        <div class="mx-4">
                                          <label class="form-check-label" for="0">
                                            <input class="form-check-input" type="radio" name="account_type" id="0" value={`${context.user.first_name} ${context.user.last_name}|0`} onChange={(e) => {
                                              console.log(e.target.value)
                                              setAccountType(e.target.value)
                                            }} />Myself as an individual 
                                          </label>
                                          <br/><br/>
                                          <label class="form-check-label" for="1">
                                            <input class="form-check-input" type="radio" name="account_type" id="1" value="An investment club registered as a business entity|0" onChange={(e) => {
                                              console.log(e.target.value)
                                              setAccountType(e.target.value)
                                            }} />An investment club registered as a business operative 
                                          </label>
                                          <br/><br/>
                                        </div>
                                      </div>  */}
                                      {/* {accountType ?  */}
                                      <button type="submit" className='btn btn-primary btn-block' style={{fontSize: "1rem"}} onClick={() => {
                                        setAgree(true)
                                        window.scrollTo({
                                          top: 0, 
                                          behavior: 'smooth'
                                        });
                                      }}> Proceed </button> 
                                      {/* : ""} */}
                                      {/* <div className='form-group dflex'>
                                        <input type="checkbox" id="agree_to_condition" className="formcontrol" required onChange={(e) => {
                                          setPreagree(e.target.checked)
                                        }}/>
                                        <label className='px-3' for="agree_to_condition">I agree to this conditions</label>
                                      </div> */}
                                      {/* {preagree ? 
                                      <button type="submit" className='btn btn-primary btn-block' style={{fontSize: "1rem"}} onClick={() => {
                                        setAgree(true)
                                        window.scrollTo({
                                          top: 0, 
                                          behavior: 'smooth'
                                        });
                                      }}> Proceed </button> : ""} */}
                                    </div>
                                  </form>
                              </div>
                          </div>
                      </div>
                  </div>)
                }
              </div>
            </div>
          </div>
        </Layout>
      </div>
    </>
  )
}

export default Accredation;