import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Layout from '../Layout';
import SETTINGS_ICON from '../../assets/img/icons/settings.png';
import INVEST_ICON from '../../assets/img/icons/worth.png';
import BANK_ICON from '../../assets/img/icons/bank.png';
import LOCK_ICON from '../../assets/img/icons/lock.png';
import {Context} from "../MyContext";
import API from "../../api";

class AxaSettings extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);

    }

    componentWillMount() {
        if (this.context.user.completed_onboarding === null || this.context.user.completed_onboarding === undefined) {
            window.location.reload();
        }
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
    }

    componentDidMount() {
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
    }

    render() {
        return (
            <Layout active_menu={'account'} user_state={'personal'} context={this.context}>
                <div className="section">
                    <div className="breadcrumb">
                        <h5>AXA Mansard Settings</h5>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-lg-3 col-xl-3">
                            <Link className="card-link" to="/account/axa-profile">
                                <div className="card">
                                    <div className="img-container lg-card-bg bg-green">
                                        <i className="fa fa-user-edit lg-icon" data-toggle="tooltip" title=""
                                           data-original-title="fa-user"/>
                                    </div>
                                    <div className="card-footer text-center pt-3 pb-3">
                                        <strong>Profile Settings</strong>
                                        <p className="mt-2 text-green">Update Profile</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-3 col-xl-3">
                            <Link className="card-link" to="/account/axa-balance">
                                <div className="card">
                                    <div className="img-container lg-card-bg bg-green">
                                        <i className="fa fa-money lg-icon" data-toggle="tooltip" title=""
                                           data-original-title="fa-user"/>
                                    </div>
                                    <div className="card-footer text-center pt-3 pb-3">
                                        <strong>Get Fund Balances</strong>
                                        <p className="mt-2 text-green">Get your fund balances</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default AxaSettings;
