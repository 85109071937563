import React, {useState, useEffect} from "react"
import {showLoader, hideLoader, toast} from "../../../_helpers/Loader";
import API from '../../../api';

const AccreditationEvaluation = (props) => {

  const [submitted, setSubmitted] = useState(null);
  const [data, setData] = useState({})
  const [name, setName] = useState(null)
  const [responseId, setResponseId] = useState(props.match.params.id)

  const submitRequestResponseForm = async () => {
    try {
      await API.post(`users/${props.match.params.id}/submit_accreditation_response_object/`, data);
      console.log(data, "data")
      toast.success("Response has been submitted successfully");
      setSubmitted(true)
      hideLoader();
    } catch (err) {
        toast.error("Error submitting response");
        hideLoader();
    }
  }

  const getTheRecord = async () => {
    try {
      let res = await API.get(`users/${props.match.params.id}/get_accreditation_response_object/`);
      console.log(res, "::::")
      if (res.data && res.data.username){
        setName(res.data.username)
        setSubmitted(res.data.status)
      }
    } catch (err) {
      toast.error(JSON.stringify(err))
      hideLoader();
    }
  }

  const imageUpload = async (event) => {
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].type.toLowerCase().includes("pdf")){
        showLoader()
        let res = await API.uploadFile("users/upload_resources/", event.target.files[0]);
        console.log(res.data.secure_url);
        hideLoader()
        // postData.image = res.data.secure_url;
        return res.data.secure_url
      } else{
        toast.error("Only pdf files allowed")
      }
    }
  }

  useEffect(() => {
    getTheRecord()
    return () => {
      console.log("")
    };
  }, [responseId]);

  return (
  <div className="container py-5">
    { responseId ?
    <div className="card col-8 offset-2" style={{"padding-right": "0px", "padding-left": "0px"}}>
      <h5 className="card-title bg-green py-2 mx-0">
        <img src="https://twelvevest.com/static/assets/app/logo-white.18456404.png" className="img-fluid" width="20%" alt="logo" />
      </h5>
      <div className="card-body mx-4">
        <div className="row mx-auto justify-content-center pb-3">
          <div className="row mx-auto justify-content-center pt-3">
            <p><span><strong>{name}</strong></span> has requested that you verify their status as an "qualified investor" (as defined in the SEC Rules [Section 321] and the 2019 amendment as regards definition of Qualified Institutional Investors and High Net Worth Investors).
            <br/><br/>
            {/* Please indicate below the capacity in which you are able to assert <span><strong>{name}'s</strong></span> qualification status and the appropriate evaluation method. */}
            </p>
          </div>
        </div>
        {submitted  === "False" ? 
        (<div className="row mx-2 justify-content-center">
          <form className="col-12" onSubmit={e => e.preventDefault()}>
            <div className="form-group row">
              <label htmlFor="additional_notes" className="col-md-12 col-form-label">Upload completed copy of letter</label> 
              <br/><br/>
              <div className="col-md-12">
                <input className='form-file-input' id="feedBack" type="file" onChange={async(e) => {
                      let link = await imageUpload(e)
                      setData({
                        ...data,
                        lawyer_upload: link
                      })
                  }} />
              </div>
            </div> 
            <br/>
{/* <div className="form-group row">
              <label htmlFor="role" className="col-md-12">Role</label> 
              <div className="col-md-12">
                <select id="role" name="role" className="form-control custom-select" required="required" onChange={e => {
                  setData({
                    ...data,
                    role: e.target.value
                  })
                }}>
                  <option value>Choose</option>
                  <option value="A representative of a SEC registered institution">A representative of a SEC registered institution</option>
                  <option value="Lawyer">Lawyer</option>
                </select>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-12 col-form-label">Evaluation Method</label> 
              <div className="col-md-12">
                <div className="custom-controls-stacked">
                  <div className="custom-control custom-radio">
                    <input name="evaluation_method" id="evaluation_method_0" type="radio" className="form-control custom-control-input" defaultValue="Net-worth" 
                    onChange={e => {
                      setData({
                        ...data,
                        evaluation_method: "Net-Worth"
                      })
                    }}
                    /> 
                    <label htmlFor="evaluation_method_0" className="custom-control-label">Net-worth</label>
                  </div>
                </div>
                <div className="custom-controls-stacked">
                  <div className="custom-control custom-radio">
                    <input name="evaluation_method" id="evaluation_method_1" type="radio" className="custom-control-input" defaultValue="Income" 
                      onChange={e => {
                        setData({
                          ...data,
                          evaluation_method: "Income"
                        })
                      }}
                    /> 
                    <label htmlFor="evaluation_method_1" className="custom-control-label">Income</label>
                  </div>
                </div>
                <div className="custom-controls-stacked">
                  <div className="custom-control custom-radio">
                    <input name="evaluation_method" id="evaluation_method_2" type="radio" className="custom-control-input" defaultValue="Demonstrated Knowledge" 
                      onChange={e => {
                        setData({
                          ...data,
                          evaluation_method: "Demonstrated Knowledge"
                        })
                      }}
                    /> 
                    <label htmlFor="evaluation_method_2" className="custom-control-label">Demonstrated Knowledge</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="license_number" className="col-md-12 col-form-label">License Number (if applicable)</label> 
              <div className="col-md-12">
                <input id="license_number" name="license_number" type="text" className="form-control" 
                  onChange={e => {
                    setData({
                      ...data,
                      license_number: e.target.value
                    })
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="license_jurisdiction" className="col-md-12 col-form-label">License Jurisdiction(if applicable)</label> 
              <div className="col-md-12">
                <input id="license_jurisdiction" name="license_jurisdiction" type="text" className="form-control" 
                  onChange={e => {
                    setData({
                      ...data,
                      license_jurisdiction: e.target.value
                    })
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="additional_notes" className="col-md-12 col-form-label">Additional Notes</label> 
              <div className="col-md-12">
                <textarea id="additional_notes" name="additional_notes" cols={40} rows={4} className="form-control" defaultValue={""} 
                  onChange={e => {
                    setData({
                      ...data,
                      additional_notes: e.target.value
                    })
                  }}
                />
              </div>
            </div> */}            
            <div className="form-group row">
              <div className="col-md-12">
                <button name="submit" type="submit" className="btn btn-primary btn-block" onClick={e => {
                  submitRequestResponseForm()
                }}>Submit</button>
              </div>
              </div> 
          </form>
        </div>) : 
        (<div className="row mx-auto justify-content-center">
          <div className="row mx-auto justify-content-center pb-3">
            <div className="row mx-auto justify-content-center pt-3">
              <br/>
              <div className="card">Feedback received successfully</div>
            </div>
          </div>
        </div>)}
      </div>
    </div> : 
    ""}
  </div>
  )
}

export default AccreditationEvaluation;