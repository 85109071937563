import React from 'react';
import {Link} from "react-router-dom";
import API from '../../api';
import $ from 'jquery';
import 'datatables';
import 'datatables.net-bs4';
import Layout from '../Layout'
import NumberFormat from "react-number-format";
import {hideLoader, showLoader, toast, trackMixpanel, Utils} from "../../_helpers/Loader";
import {Context} from "../MyContext";
import {modal} from 'bootstrap';

$.DataTable = require('datatables.net');

class ManagePortfolio extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            portfolio: [],
            assets: {
                lists: []
            },
            liabilities: {},
            lists: [],
            form: {
                id: '',
                name: '',
                value: '',
                category: 'asset',
                investment_type: '',
                submit: 'Add',
                currency: ""
            },
            filter: 'asset',
            form_category: '',
            delete_id: null,
            delete_name: '',
            delete_category: null
        };
        this.editAsset = this.editAsset.bind(this);
        this.addAsset = this.addAsset.bind(this);
        this.deleteAsset = this.deleteAsset.bind(this);
    }

    async componentDidMount() {
        trackMixpanel('ManagePortfolioView', false, false, this.context.user.email)
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
        showLoader();
        await this.getPortfolio();
        await this.getAssets();
        hideLoader();
    }

    componentWillMount() {
        if (this.context.user.completed_onboarding === null || this.context.user.completed_onboarding === undefined) {
            window.location.reload();
        }
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
    }


    getAssets = async () => {
        try {
            let res = await API.get('assets/?page=1');
            this.setState({
                assets: {
                    next_page: res.data.next,
                    previous_page: res.data.previous,
                    lists: res.data.results,
                    total_pages: res.data.total_pages,
                    current_page: res.data.current_page,
                }
            });
        } catch (e) {

        }
    };


    async getPortfolio() {
        try {
            let res = await API.get('portfolios/get_stats/');
            this.setState({portfolio: res.data});
        } catch (e) {
            toast.error("Something Went Wrong");
            // console.log(e.response)
        }
    }

    editAsset(event, itemId) {
        event.preventDefault();
        let filterItem;
        filterItem = this.state.assets.lists.find(item => item.id === itemId)
        this.setState({
            form: {
                id: filterItem.id,
                submit: 'Update',
                name: filterItem.name,
                category: filterItem.category,
                currency: filterItem.currency,
                value: filterItem.value,
                investment_type: filterItem.investment_type
            },
            form_category: filterItem.category
        });
    }

    async addAsset(event) {
        event.preventDefault();
        let postData = {
            category: this.state.form.type,
            name: this.state.form.name,
            value: this.state.form.value
        };
        if (this.state.form.submit === 'Update') {
            postData = {
                name: this.state.form.name,
                value: this.state.form.value,
                category: this.state.form.category,
                currency: this.state.form.currency,
                id: this.state.form.id,
                portfolio_id: this.state.portfolio.id,
                change: this.state.form_category,
                investment_type: this.state.form.investment_type
            };
            showLoader();
            try {
                let res = await API.post('assets/update_asset/', postData);
                this.setState({
                    form: {
                        id: '',
                        name: '',
                        value: '',
                        currency: '',
                        category: 'asset',
                        submit: 'Add',
                        investment_type: ''
                    }
                });
                toast.success("Asset Updated Successfully");
                await this.getAssets();
                await this.getPortfolio();
            } catch (e) {
                toast.error("Something Went Wrong");
                // console.log(e)
            }
            hideLoader();
        } else {
            showLoader();
            try {
                let res = await API.post('assets/add_asset/', this.state.form);
                toast.success("Asset Added Successfully");
                this.setState({
                    form: {
                        id: '',
                        name: '',
                        value: '',
                        currency: '',
                        category: 'asset',
                        submit: 'Add',
                        investment_type: ''
                    }
                });
                await this.getAssets();
                await this.getPortfolio();
            } catch (e) {
                toast.error("Something Went Wrong");
                // console.log(e)
            }
            hideLoader();
        }
    }

    async deleteAsset(event, item_id, category) {
        let postData = {
            id: this.state.delete_id,
            portfolio_id: this.state.portfolio.id
        };
        showLoader()
        try {
            let res = await API.post('portfolios/remove_asset/', postData);
            toast.success("Asset Removed Successfully");
            this.setState({delete_id: null, delete_category: ''});
            $('.modal').modal('hide');
            $(document.body).removeClass('modal-open');
            $('.modal-backdrop').remove();
            await this.getAssets();
            await this.getPortfolio();
            hideLoader();
        } catch (e) {
            toast.error("Something went wrong");
            // console.log(e)
            hideLoader();
        }
    }


    nextPage = async (page_num) => {
        showLoader();
        try {
            let res = await API.get('assets/?page=' + page_num);
            this.setState({
                assets: {
                    next_page: res.data.next,
                    previous_page: res.data.previous,
                    lists: res.data.results,
                    total_pages: res.data.total_pages,
                    current_page: res.data.current_page,
                }
            });
            await $("html, body").animate({scrollTop: 0}, "slow");
            await this.filterList2(this.state.filter);
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    };

    previousPage = async (page_url) => {
        showLoader();
        try {
            let res = await API.get(page_url);
            this.setState({
                assets: {
                    next_page: res.data.next,
                    previous_page: res.data.previous,
                    lists: res.data.results,
                    total_pages: res.data.total_pages,
                    current_page: res.data.current_page,
                }
            });
            await $("html, body").animate({scrollTop: 0}, "slow");
            await this.filterList2(this.state.filter);
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    };

    render() {
        return (
            <Layout active_menu={'dash'} user_state={'personal'} context={this.context}>
                <div className="section">
                    <div className="breadcrumb">
                        <Link to="/"> <span className="fa fa-chevron-left"/> Dashboard</Link>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h4>Assets & Liabilities</h4>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-7 col-xl-5 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="text-center">
                                                                <span className="text-white">
                                                        <i className="fa fa-archive"/> Total Assets (₦)</span>
                                                                <h2 className="text-white mb-0">₦{parseFloat(this.state.portfolio.total_asset_value_ngn).toLocaleString() || 0}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-67 col-xl-5 col-md-6 col-12">
                                            <div className="card bg-danger text-white">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="text-center">
                                                                <span className="text-white"><i
                                                                    className="fa fa-file-text"/> Total Liabilities (₦)</span>
                                                                <h2 className="text-white mb-0">₦{parseFloat(this.state.portfolio.total_liability_value_ngn).toLocaleString() || 0}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-7 col-xl-5 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="text-center">
                                                                <span className="text-white"><i
                                                                    className="fa fa-archive"/> Total Assets ($)</span>
                                                                <h2 className="text-white mb-0">${parseFloat(this.state.portfolio.total_asset_value_usd).toLocaleString() || 0}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-67 col-xl-5 col-md-6 col-12">
                                            <div className="card bg-danger text-white">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="text-center">
                                                                <span className="text-white"><i
                                                                    className="fa fa-file-text"/> Total Liabilities ($)</span>
                                                                <h2 className="text-white mb-0">${parseFloat(this.state.portfolio.total_liability_value_usd).toLocaleString() || 0}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-7 col-xl-5 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="text-center">
                                                                <span className="text-white"><i
                                                                    className="fa fa-archive"/> Total Assets (£)</span>
                                                                <h2 className="text-white mb-0">£{parseFloat(this.state.portfolio.total_asset_value_gbp).toLocaleString() || 0}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-67 col-xl-5 col-md-6 col-12">
                                            <div className="card bg-danger text-white">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="text-center">
                                                                <span className="text-white"><i
                                                                    className="fa fa-file-text"/> Total Liabilities (£)</span>
                                                                <h2 className="text-white mb-0">£{parseFloat(this.state.portfolio.total_liability_value_gbp).toLocaleString() || 0}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <div id="example_wrapper"
                                             className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table
                                                        className="table table-striped table-bordered border-t0 text-nowrap w-100 dataTable no-footer"
                                                        role="grid" aria-describedby="example_info">
                                                        <thead>
                                                        <tr role="row">
                                                            <th className="wd-15p sorting_asc" tabIndex="0"
                                                                aria-controls="example" rowSpan="1" colSpan="1"
                                                                aria-sort="ascending"
                                                                aria-label="First name: activate to sort column descending"
                                                                style={{width: "113px"}}>Name
                                                            </th>
                                                            <th className="wd-15p sorting" tabIndex="0"
                                                                aria-controls="example"
                                                                rowSpan="1" colSpan="1"
                                                                aria-label="Last name: activate to sort column ascending"
                                                                style={{width: "113px"}}>Value (ROI)
                                                            </th>
                                                            <th className="wd-20p sorting" tabIndex="0"
                                                                aria-controls="example"
                                                                rowSpan="1" colSpan="1"
                                                                aria-label="Position: activate to sort column ascending"
                                                                style={{width: "231.4px"}}>Investment Type
                                                            </th>
                                                            <th className="wd-15p sorting" tabIndex="0"
                                                                aria-controls="example"
                                                                rowSpan="1" colSpan="1"
                                                                aria-label="Start date: activate to sort column ascending"
                                                                style={{width: "110.6px"}}>Type
                                                            </th>
                                                            <th className="wd-10p sorting" tabIndex="0"
                                                                aria-controls="example"
                                                                rowSpan="1" colSpan="1"
                                                                aria-label="Salary: activate to sort column ascending"
                                                                style={{width: "93.8px"}}>Action
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {this.state.assets.lists.map(item => (
                                                            <tr role="row" key={item.id}>
                                                                <td className="sorting_1">{item.name}{}</td>
                                                                <td>{Utils.currencyToSym(item.currency)}{(item.category !== 'liability') ? parseFloat(item.roi).toLocaleString() : parseFloat(item.value).toLocaleString()}</td>
                                                                <td className="text-capitalize">{item.investment_type}</td>
                                                                <td className="text-capitalize">{item.category}</td>
                                                                <td>
                                                                    {(item.name.toLowerCase() === 'wallet') ? (
                                                                        ''
                                                                    ) : ((item.investment) ? (
                                                                            <button
                                                                                className="btn btn-sm btn-danger badge"
                                                                                data-target="#deleteModal"
                                                                                data-toggle="modal"
                                                                                onClick={event => this.setState({
                                                                                    delete_id: item.id,
                                                                                    delete_name: item.name,
                                                                                    delete_category: item.category
                                                                                })}>
                                                                                <i
                                                                                    className="fa fa-trash"/>
                                                                            </button>
                                                                        ) : (
                                                                            <div className="btn-group align-top">
                                                                                <button
                                                                                    className="btn btn-sm btn-blue text-white badge"
                                                                                    onClick={e => this.editAsset(e, item.id)}>Edit
                                                                                </button>
                                                                                <button
                                                                                    className="btn btn-sm btn-danger badge"
                                                                                    data-target="#deleteModal"
                                                                                    data-toggle="modal"
                                                                                    onClick={event => this.setState({
                                                                                        delete_id: item.id,
                                                                                        delete_name: item.name,
                                                                                        delete_category: item.category
                                                                                    })}>
                                                                                    <i
                                                                                        className="fa fa-trash"/>
                                                                                </button>
                                                                            </div>)
                                                                    )}

                                                                </td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {(this.state.filter === 'asset') ? (
                                    <div className="card-footer">
                                        <div className="d-flex justify-content-end">
                                            <ul className="pagination mt-3 mb-0">
                                                <li className={`${(this.state.assets.previous_page !== null) ? '' : 'disabled'} page-item`}>
                                                    <a className="page-link"
                                                       onClick={event => this.previousPage(this.state.assets.previous_page)}>‹</a>
                                                </li>
                                                {[...Array(this.state.assets.total_pages)].map((item, key) => (
                                                    <li className={`${((key + 1) === this.state.assets.current_page) ? 'active' : ''} page-item`}>
                                                        <a className="page-link"
                                                           onClick={event => this.nextPage(key + 1)}>{key + 1}</a>
                                                    </li>
                                                ))}
                                                <li className={`${(this.state.assets.next_page !== null) ? '' : 'disabled'} page-item`}>
                                                    <a className="page-link"
                                                       onClick={event => this.nextPage(this.state.assets.next_page)}>»</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="card-footer">
                                        <div className="d-flex justify-content-end">
                                            <ul className="pagination mt-3 mb-0">
                                                <li className={`${(this.state.liabilities.previous_page !== null) ? '' : 'disabled'} page-item`}>
                                                    <a className="page-link"
                                                       onClick={event => this.previousPage(this.state.liabilities.previous_page)}>‹</a>
                                                </li>
                                                {[...Array(this.state.liabilities.total_pages)].map((item, key) => (
                                                    <li className={`${((key + 1) === this.state.liabilities.current_page) ? 'active' : ''} page-item`}>
                                                        <a className="page-link"
                                                           onClick={event => this.nextPage(key + 1)}>{key + 1}</a>
                                                    </li>
                                                ))}
                                                <li className={`${(this.state.liabilities.next_page !== null) ? '' : 'disabled'} page-item`}>
                                                    <a className="page-link"
                                                       onClick={event => this.nextPage(this.state.liabilities.next_page)}>»</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <h4>Add Asset/Liability</h4>
                                    <p className="font-italic">Your current net-worth is a measure of your financial
                                        health.
                                        Checking your financial health is always a great place to start on your
                                        financial
                                        journey.
                                        Fill the form below with the worth of each of your assets and liabilities to
                                        check
                                        the status of your financial health.</p>
                                    <form onSubmit={event => this.addAsset(event)}>
                                        <div className="form-group">
                                            <label htmlFor="asset">
                                                <input onChange={event => {
                                                    this.setState({
                                                        form: {
                                                            ...this.state.form,
                                                            category: event.target.value,
                                                        }
                                                    })
                                                }} value="asset"
                                                       checked={this.state.form.category === 'asset'} type="radio"
                                                       name="category" id="asset"/> Assets
                                            </label>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="liability">
                                                <input onChange={event => {
                                                    this.setState({
                                                        form: {
                                                            ...this.state.form,
                                                            category: event.target.value,
                                                        }
                                                    })
                                                }} value="liability"
                                                       checked={this.state.form.category === 'liability'} type="radio"
                                                       name="category" id="liability"/> Liability
                                            </label>
                                        </div>

                                        <div className="">
                                            <div className="form-group">
                                                <label htmlFor="name">Name</label>
                                                <input required onChange={event => {
                                                    this.setState({
                                                        form: {
                                                            ...this.state.form,
                                                            name: event.target.value
                                                        }
                                                    })
                                                }} value={this.state.form.name} type="text" className="form-control"
                                                       id="name"/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="value">Currency</label>
                                                <select required value={this.state.form.currency} name=""
                                                        onChange={event => this.setState({
                                                            form: {
                                                                ...this.state.form,
                                                                currency: event.target.value
                                                            }
                                                        })}
                                                        id="" className="form-control pulse-out">
                                                    <option value="">---- Select an Option ----</option>
                                                    <option value="NGN">NGN</option>
                                                    <option value="USD">USD</option>
                                                    <option value="GBP">GBP</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="value">Value</label>
                                                <NumberFormat thousandSeparator={true}
                                                              prefix={Utils.currencyToSym(this.state.form.currency)}
                                                              required
                                                              onValueChange={(values) => {
                                                                  const {formattedValue, value} = values;
                                                                  this.setState({
                                                                      form: {
                                                                          ...this.state.form,
                                                                          value: value,
                                                                      }
                                                                  })
                                                              }} className="form-control"
                                                              value={this.state.form.value}/>
                                            </div>
                                            {(this.state.form.category === 'asset') ? (
                                                <div className="form-group">
                                                    <label htmlFor="value">Asset
                                                        Type</label>
                                                    <select required value={this.state.form.investment_type} name=""
                                                            onChange={event => this.setState({
                                                                form: {
                                                                    ...this.state.form,
                                                                    investment_type: event.target.value
                                                                }
                                                            })}
                                                            id="" className="form-control">
                                                        <option value="">---- Select an Option ----</option>
                                                        <option value="cash at hand">Cash at hand</option>
                                                        <option value="real estate">Real Estate</option>
                                                        <option value="agro tech">Agro Tech</option>
                                                        <option value="bonds">Bonds</option>
                                                        <option value="treasury bills">Treasury Bills</option>
                                                        <option value="mutual funds">Mutual Funds</option>
                                                        <option value="eurobonds">Eurobonds</option>
                                                        <option value="stocks">Stocks</option>
                                                        <option value="micro credit">Micro Credit</option>
                                                        <option value="private equity stake">Private Equity Stake
                                                        </option>
                                                        <option value="venture capital">Venture Capital</option>
                                                        <option value="pension fund">Pension Fund</option>
                                                        <option value="fixed deposit">Fixed Deposit</option>
                                                        <option value="savings">Savings</option>
                                                    </select>
                                                </div>
                                            ) : (
                                                <div className="form-group">
                                                    <label htmlFor="value">Liability Type</label>
                                                    <select required value={this.state.form.investment_type} name=""
                                                            onChange={event => this.setState({
                                                                form: {
                                                                    ...this.state.form,
                                                                    investment_type: event.target.value
                                                                }
                                                            })}
                                                            id="" className="form-control">
                                                        <option value="">---- Select an Option ----</option>
                                                        <option value="mortgage">Mortgage</option>
                                                        <option value="car loan">Car Loan</option>
                                                        <option value="personal loan">Personal Loan</option>
                                                        <option value="debt">Debt</option>
                                                    </select>
                                                </div>
                                            )}
                                        </div>
                                        <button type="submit"
                                                className="btn btn-blue text-white mt-1 mb-0 mr-3">{this.state.form.submit}</button>
                                        <button onClick={event => this.setState({
                                            form: {
                                                id: '',
                                                name: '',
                                                category: 'asset',
                                                investment_type: '',
                                                value: '',
                                                submit: 'Add'
                                            }
                                        })} type="button" className="btn btn-main mt-1 mb-0">Reset
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="deleteModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h4 className="modal-title" id="example-Modal3">Are you sure?</h4>
                                <button id="close-modal" type="button" className="close" data-dismiss="modal"
                                        aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <p>You are about to delete: <strong>{this.state.delete_name}</strong></p>
                                        <p>Note: This action can not be reversed.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="col-lg-12 mx-auto text-center">
                                    <button
                                        type="button"
                                        className="btn btn-outline-green mr-3"
                                        data-dismiss="modal">Close
                                    </button>
                                    <button onClick={e => this.deleteAsset(e)} className="btn btn-danger">Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default ManagePortfolio;
