import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Moment from "react-moment";
import Layout from "../Layout";
import NumberFormat from "react-number-format";
import {hideLoader, mixpanel, showLoader, toast, trackMixpanel} from "../../_helpers/Loader";
import API from "../../api";
import $ from "jquery";
import {Context} from "../MyContext";

class RecurringInvestment extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            investment: {},
            payments: [],
            amount_paid: 0,
        }
    }

    async componentDidMount() {
        trackMixpanel('ClubRecurringInvestmentView', false, false, this.context.user.email)
        showLoader();
        await this.getInvestment();
        await this.getStats();
        hideLoader();
    }

    componentWillMount() {
        if (this.context.user.completed_onboarding === null || this.context.user.completed_onboarding === undefined) {
            window.location.reload();
        }
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
    }


    getStats = async () => {
        try {
            let res = await API.get('club_investments/' + this.props.match.params.investment_id + '/get_investment_history/');
            let amount_paid = 0;
            res.data.map(item => {
                amount_paid += item.amount
            })
            this.setState({payments: res.data, amount_paid: amount_paid});
        } catch (e) {
            toast.error("Something Went Wrong");
            // console.log(e.response);
        }
    };

    async getInvestment() {
        try {
            let res = await API.get(`club_investments/${this.props.match.params.investment_id}/get_investment/`);
            this.setState({investment: res.data})
        } catch (e) {
            // console.log(e.response);
        }
    }


    render() {
        if (this.state.investment.title) {
            return (
                <Layout active_menu={'dash'} user_state={'personal'} context={this.context}>
                    <div className="section">
                        <div className="breadcrumb">
                            <Link to="/"> <span className="fa fa-chevron-left"/> Dashboard</Link>
                        </div>
                        <div className="row" id="investment">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="float-left">{this.state.investment.investment.title} by {this.state.investment.investment.company}</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                                <div className="card bg-blue-gray text-blue">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <i className="fa fa-bar-chart"/>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-white"><strong>Total Unit Price</strong></span>
                                                                    <h2 className="text-white mb-0">₦{parseFloat(this.state.investment.value).toLocaleString()}</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                                <div className="card bg-blue-gray text-blue">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <i className="fa fa-reply-all"/>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="mt-3 text-center">
                                                                        <span
                                                                            className="text-white"><strong>Expected Return (ROI)</strong></span>
                                                                    <h2 className="text-white mb-0">₦{parseFloat(this.state.investment.expected_return).toLocaleString()}</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                                <div className="card bg-blue-gray text-blue">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <i className="fa fa-signal"/>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="mt-3 text-center">
                                                                        <span
                                                                            className="text-white"><strong>Amount Paid</strong></span>
                                                                    <h2 className="text-white mb-0">₦{parseFloat(this.state.amount_paid).toLocaleString()}</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                                <div className="card bg-blue-gray text-blue">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <i className="fa fa-line-chart"/>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="mt-3 text-center">
                                                                        <span
                                                                            className="text-white"><strong>Amount to Pay</strong></span>
                                                                    <h2 className="text-white mb-0">₦{(parseFloat(this.state.investment.value) - parseFloat(this.state.amount_paid)).toLocaleString()}</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <div id="example_wrapper"
                                                 className="dataTables_wrapper dt-bootstrap4 no-footer">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <table
                                                            className="table table-striped table-bordered border-t0 text-nowrap w-100 dataTable no-footer"
                                                            role="grid" aria-describedby="example_info">
                                                            <thead>
                                                            <tr role="row">
                                                                <th className="wd-15p sorting_asc" tabIndex="0"
                                                                    aria-controls="example" rowSpan="1" colSpan="1"
                                                                    aria-sort="ascending"
                                                                    aria-label="First name: activate to sort column descending"
                                                                    style={{width: "113px"}}>Name
                                                                </th>
                                                                <th className="wd-15p sorting" tabIndex="0"
                                                                    aria-controls="example"
                                                                    rowSpan="1" colSpan="1"
                                                                    aria-label="Last name: activate to sort column ascending"
                                                                    style={{width: "113px"}}>Amount
                                                                </th>
                                                                <th className="wd-20p sorting" tabIndex="0"
                                                                    aria-controls="example"
                                                                    rowSpan="1" colSpan="1"
                                                                    aria-label="Position: activate to sort column ascending"
                                                                    style={{width: "231.4px"}}>Reference Number
                                                                </th>
                                                                <th className="wd-20p sorting" tabIndex="0"
                                                                    aria-controls="example"
                                                                    rowSpan="1" colSpan="1"
                                                                    aria-label="Position: activate to sort column ascending"
                                                                    style={{width: "231.4px"}}>Date Paid
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {this.state.payments.map(item => (
                                                                <tr key={item.id}>
                                                                    <td>{this.state.investment.investment.title} by {this.state.investment.company}</td>
                                                                    <td>₦{parseFloat(item.amount).toLocaleString()}</td>
                                                                    <td>{item.ref_code}</td>
                                                                    <td><Moment
                                                                        format="MMM D, Y">{item.created_at}</Moment>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout>
            );
        } else {
            return (<div>

            </div>)
        }
    }
}

export default RecurringInvestment;
