import React, {Component} from 'react';
import Layout from "../Layout";
import {Context} from "../MyContext";
import {Moment} from 'react-moment';
import {showLoader, hideLoader, toast, Utils, mixpanel, trackMixpanel} from "../../_helpers/Loader";
import API from '../../api';
import {PAYSTACK_publicKey} from "../../env";

const PaystackPop = window.PaystackPop;

class ClubSubscribe extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            club: {},
            package: {},
            interval: '',
            amount: '',
            plan_id: '',
            ref_code: '',
        }
    }

    async componentDidMount() {
        trackMixpanel('ClubSubscribeView', false, false, this.context.user.email)
        showLoader();
        await this.getClub();
        if (this.checkAdmin(this.state.club.club_admins)) {
            this.props.history.push("/clubs/");
        }
        hideLoader();
        if (this.state.package.name.toLowerCase() === 'free') {
            this.props.history.push(`/club/${this.props.match.params.id}/details`);
        }
    }

    getClub = async () => {
        try {
            const res = await API.get(`clubs/${this.props.match.params.id}/`);
            const sub = res.data.club_subscription[0];
            this.setState({
                club: res.data,
                package: sub.package,
                interval: (sub.yearly_payment) ? "Annual" : "Monthly",
                amount: (sub.yearly_payment) ? sub.package.annual_amount : sub.package.monthly_amount,
                plan_id: sub.plan_id,
                ref_code: sub.flwRef,
            })
        } catch (e) {
            // console.log(e.response)
        }
    };

    payWithCard = () => {
        var handler = PaystackPop.setup({
            key: PAYSTACK_publicKey,
            email: this.context.user.email,
            plan: this.state.plan_id,
            currency: "NGN",
            ref: 'paystack-sub-' + Math.floor((Math.random() * 1000000000) + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
            metadata: {
                custom_fields: [
                    {
                        display_name: "First Name",
                        variable_name: "firstname",
                        value: this.context.user.first_name,
                    }, {
                        display_name: "Last Name",
                        variable_name: "lastname",
                        value: this.context.user.last_name,
                    }, {
                        display_name: "Customer Number",
                        variable_name: "customer_phone",
                        value: this.context.user.phone,
                    }, {
                        display_name: "Club Name",
                        variable_name: "club_name",
                        value: this.state.name
                    }, {
                        display_name: "Description",
                        variable_name: "desc",
                        value: "Subscription paid for club"
                    }
                ]
            },
            callback: (response) => {
                response.pay_type = 'paystack';
                if (
                    response.status === "success"
                ) {
                    //     // redirect to a success page
                    //     API.post('clubs/', response).then((res) => {
                    //         hideLoader();
                    toast.success("Successfully Completed Transaction");
                    this.props.history.push(`/club/${this.props.match.params.id}/invite`);
                    // }, function (err) {
                    //     console.log(err.response);
                    //     toast.error("Something Went Wrong");
                    //     hideLoader()
                    // })

                } else {
                    // redirect to a failure page.
                    hideLoader();
                    toast.error("Payment Error.")
                }
            },
            onClose: function () {
                toast.info("Payment Cancelled");
                hideLoader();
            }
        });
        handler.openIframe();
    }

    checkAdmin = (admins) => {
        let admin = admins.filter(item => item.id === this.context.user.id)
        return admin.length > 0;
    }

    render() {
        return (
            <Layout active_menu={'settings'} user_state={this.props.match.params.id} context={this.context}>
                <div className="section">
                    <div className="breadcrumb">
                        <a onClick={event => window.history.back()}> <span
                            className="fa fa-chevron-left"/> Back</a>
                    </div>
                    <div className="row">
                        <div className="col-md-8 mx-auto">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <h4>Club Description</h4>
                                        </div>
                                        <div className="col-sm-6">
                                            <button onClick={event => this.payWithCard()}
                                                    className="btn btn-blue text-white text-white float-right">Pay
                                                ${this.state.amount} {this.state.interval} subscription
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="list-group">
                                        <a
                                            className="list-group-item list-group-item-action flex-column align-items-start">
                                            <div className="d-flex w-100 justify-content-between">
                                                <p className="mb-1">Club Name</p>
                                            </div>
                                            <h4 className="mb-1">{this.state.club.name}</h4>
                                        </a>
                                        <a
                                            className="list-group-item list-group-item-action flex-column align-items-start">
                                            <div className="d-flex w-100 justify-content-between">
                                                <p className="mb-1">Club Net worth</p>
                                            </div>
                                            <h4 className="mb-1">₦{parseFloat(this.state.club.networth_goal).toLocaleString()}</h4>
                                        </a>
                                        <a
                                            className="list-group-item list-group-item-action flex-column align-items-start">
                                            <div className="d-flex w-100 justify-content-between">
                                                <p className="mb-1">Contribution</p>
                                            </div>
                                            <h4 className="mb-1">₦{parseFloat((this.state.club.contribution_amount) ? this.state.club.contribution_amount : 0).toLocaleString()}</h4>
                                        </a>
                                        <a
                                            className="list-group-item list-group-item-action flex-column align-items-start">
                                            <div className="d-flex w-100 justify-content-between">
                                                <p className="mb-1">Contribution Interval</p>
                                            </div>
                                            <h4 className="mb-1">{(this.state.club.contribution_amount) ? this.state.contribution_interval : 'None'}</h4>
                                        </a>
                                        <a
                                            className="list-group-item list-group-item-action flex-column align-items-start">
                                            <div className="d-flex w-100 justify-content-between">
                                                <p className="mb-1">Club Description</p>
                                            </div>
                                            <div dangerouslySetInnerHTML={{__html: this.state.club.description}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default ClubSubscribe;
