export function authHeader() {
    // return authorization header with basic auth credentials
    let auth = JSON.parse(localStorage.getItem('auth'));

    if (auth) {
        return { 'Authorization': 'JWT ' + auth.token };

        // try {
        //     return { 'Authorization': 'JWT ' + auth.token.split("'")[1] };
        // } catch {
        //     return { 'Authorization': 'JWT ' + auth.token };
        //     // return { 'Authorization': 'JWT ' + auth.token.split("'")[1] };
        // }
    } else {
        return false;
    }
}


