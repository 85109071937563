import React, {Component} from 'react';
import {Code} from 'react-content-loader'
import {Link} from 'react-router-dom'
import Layout from '.././Layout';
import API from '../../api';
import NumberFormat from "react-number-format";
import {hideLoader, mixpanel, showLoader, toast, trackMixpanel, Utils} from "../../_helpers/Loader";
import {Context} from "../MyContext";
import $ from "jquery";


class ManageClub extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            club: '',
            form: {
                id: '',
                name: '',
                value: '',
                category: 'asset',
                submit: 'Add',
                investment_type: '',
                currency: "",
            },
            portfolio: {},
            assets: [],
            delete_form: {},
            form_category: '',
            total_assets: 0,
            total_liabilities: 0
        };

        this.editAsset = this.editAsset.bind(this);
        this.addAsset = this.addAsset.bind(this);
        this.deleteAsset = this.deleteAsset.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    async componentWillMount() {
        trackMixpanel('ManageClubView', false, false, this.context.user.email)
        if (this.context.user.completed_onboarding === null || this.context.user.completed_onboarding === undefined) {
            window.location.reload();
        }
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
    }

    async componentDidMount() {
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
        showLoader();
        await this.getClub();
        await this.getPortfolio();
        hideLoader();
        (!this.checkAdmin(this.state.club.club_members)) && this.props.history.push(`club/`);
    }

    async getClub() {
        try {
            let res = await API.get(`clubs/${this.props.match.params.id}/`);
            let count_asset = 0;
            let count_liability = 0;
            let total_data = res.data.assets;
            total_data.push.apply(total_data, res.data.liabilities);
            total_data.map(item => {
                (item.category === 'asset') && (count_asset = count_asset + item.roi);
                (item.category === 'liability') && (count_liability = count_liability + item.value);
            });
            var sorted = total_data.sort((a, b) => {
                return new Date(a.modified).getTime() - new Date(b.modified).getTime()
            });
            this.setState({
                club: res.data,
                total_assets: count_asset,
                total_liabilities: count_liability,
                assets: total_data
            });
        } catch (e) {
            toast.error("Something Went Wrong");
            hideLoader();
            // console.log(e.response)
        }
    }

    async getPortfolio() {
        try {
            let res = await API.get(`clubs/${this.props.match.params.id}/get_stats/`);
            this.setState({portfolio: res.data});
        } catch (e) {
            toast.error("Something Went Wrong");
            // console.log(e.response)
        }
    }


    editAsset(event, itemId) {
        event.preventDefault();
        let filterItem;
        this.state.club.assets.map(item => {
            if (item.id === itemId) {
                filterItem = item
            }
        });
        this.setState({
            form: {
                id: filterItem.id,
                submit: 'Update',
                name: filterItem.name,
                currency: filterItem.currency,
                category: filterItem.category,
                value: filterItem.value,
                investment_type: filterItem.investment_type,
            }, form_category: filterItem.category
        });
    }

    async addAsset(event) {
        event.preventDefault();
        let postData = {
            category: this.state.form.type,
            name: this.state.form.name,
            value: this.state.form.value
        };
        if (this.state.form.submit === 'Update') {
            postData = {
                name: this.state.form.name,
                value: this.state.form.value,
                category: this.state.form.category,
                currency: this.state.form.currency,
                id: this.state.form.id,
                club_id: this.state.club.id,
                investment_type: this.state.form.investment_type
            };
            showLoader();
            try {
                let res = await API.post('clubs/update_asset/', postData);
                this.setState({
                    form: {
                        id: '',
                        name: '',
                        value: '',
                        currency: '',
                        category: 'asset',
                        submit: 'Add',
                        investment_type: ''
                    }
                });
                toast.success("Asset Updated Successfully");
                await this.getClub();
                await this.getPortfolio();
                hideLoader();
            } catch (e) {
                toast.error("Something Went Wrong");
                hideLoader();
                // console.log(e.response)
            }
        } else {
            showLoader();
            try {
                let res = await API.post('clubs/add_asset/', {...this.state.form, club_id: this.state.club.id});
                this.setState({
                    form: {
                        id: '',
                        name: '',
                        value: '',
                        currency: '',
                        category: 'asset',
                        submit: 'Add',
                        investment_type: ''
                    }
                });
                toast.success("Asset Successfully Added");
                await this.getClub();
                await this.getPortfolio();
                hideLoader();
            } catch (e) {
                toast.error("Something Went Wrong");
                hideLoader();
                // console.log(e.response)
            }
        }
    }

    async deleteAsset(event) {
        let postData = {
            club_id: this.state.club.id,
            id: this.state.delete_form.id,
        };
        showLoader();
        try {
            const res = await API.post('clubs/remove_asset/', postData);
            await this.getClub();
            await this.getPortfolio();
            $('.modal').modal('hide');
            $(document.body).removeClass('modal-open');
            $('.modal-backdrop').remove();
            toast.success(`${this.state.delete_form.name} removed successfully`)
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    }

    goBack() {
        if (this.props.location.query) {
            this.props.history.push(this.props.location.query.back_url)
        } else {
            this.props.history.push(`/club/${this.props.match.params.id}/details`)
        }
    }

    checkAdmin(item) {
        if (item !== undefined) {
            for (let i = 0; i < item.length; i++) {
                if (item[i].user.id === this.context.user.id && item[i].user_role === 'admin') {
                    return true;
                }
            }
            return false
        } else {
            return false
        }
    }

    render() {
        if (this.state.club) {
            return (
                <Layout active_menu={'settings'} user_state={this.props.match.params.id}
                        club_admin={this.checkAdmin(this.state.club.club_members)} context={this.context}>
                    <div className="section">
                        <div className="breadcrumb">
                            <a onClick={this.goBack}> <span
                                className="fa fa-chevron-left"/> Back</a>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-sm-12 col-lg-3 col-xl-3">
                                <Link className="card-link" to={{
                                    pathname: `/club/${this.props.match.params.id}/invite`,
                                    query: {back_url: this.props.location.pathname}
                                }}>
                                    <div className="card">
                                        <div className="img-container lg-card-bg bg-blue">
                                            <i className="fa fa-users fa-4x" data-toggle="tooltip" title=""
                                               data-original-title="fa-user"/>
                                        </div>
                                        <div className="card-footer text-center pt-3 pb-3">
                                            <strong>Manage Members</strong>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-6 col-sm-12 col-lg-3 col-xl-3">
                                <Link className="card-link" to={`/club/${this.context.club_id}/marketplace`}>
                                    <div className="card">
                                        <div className="img-container lg-card-bg bg-blue">
                                            <i className="fa fa-pie-chart fa-4x" data-toggle="tooltip" title=""
                                               data-original-title="fa-user"/>
                                        </div>
                                        <div className="card-footer text-center pt-3 pb-3">
                                            <strong>Find Investments</strong>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-6 col-sm-12 col-lg-3 col-xl-3">
                                <Link className="card-link" to={`/club/${this.props.match.params.id}/edit`}>
                                    <div className="card">
                                        <div className="img-container lg-card-bg bg-blue">
                                            <i className="fa fa-edit fa-4x" data-toggle="tooltip" title=""
                                               data-original-title="fa-user"/>
                                        </div>
                                        <div className="card-footer text-center pt-3 pb-3">
                                            <strong>Edit Club Details</strong>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-6 col-sm-12 col-lg-3 col-xl-3">
                                <Link className="card-link" to={`/club/${this.context.club_id}/contributions`}>
                                    <div className="card">
                                        <div className="img-container lg-card-bg bg-blue">
                                            <i className="fa fa-handshake-o fa-4x" data-toggle="tooltip" title=""
                                               data-original-title="fa-user"/>
                                        </div>
                                        <div className="card-footer text-center pt-3 pb-3">
                                            <strong>View Contributions</strong>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-6 col-sm-12 col-lg-3 col-xl-3">
                                <Link className="card-link" to={`/club/add-account/${this.props.match.params.id}`}>
                                    <div className="card">
                                        <div className="img-container lg-card-bg bg-blue">
                                            <i className="fa fa-bank fa-4x" data-toggle="tooltip" title=""
                                               data-original-title="fa-user"/>
                                        </div>
                                        <div className="card-footer text-center pt-3 pb-3">
                                            <strong>Club Contributions Settings</strong>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-6 col-sm-12 col-lg-3 col-xl-3">
                                <Link className="card-link" to={`/club/add-account-only/${this.props.match.params.id}`}>
                                    <div className="card">
                                        <div className="img-container lg-card-bg bg-blue">
                                            <i className="fa fa-address-card fa-4x" data-toggle="tooltip" title=""
                                               data-original-title="fa-user"/>
                                        </div>
                                        <div className="card-footer text-center pt-3 pb-3">
                                            <strong>Add Club Account</strong>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-6 col-sm-12 col-lg-3 col-xl-3">
                                <Link className="card-link" to={`/club/${this.props.match.params.id}/wallet`}>
                                    <div className="card">
                                        <div className="img-container lg-card-bg bg-blue">
                                            <i className="fa fa-wallet fa-4x" data-toggle="tooltip" title=""
                                               data-original-title="fa-user"/>
                                        </div>
                                        <div className="card-footer text-center pt-3 pb-3">
                                            <strong>Wallet</strong>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-6 col-sm-12 col-lg-3 col-xl-3">
                                <Link className="card-link" to={`/manage-club/${this.props.match.params.id}/otp-settings`}>
                                    <div className="card">
                                        <div className="img-container lg-card-bg bg-blue">
                                            <i className="fa fa-code fa-4x" data-toggle="tooltip" title=""
                                               data-original-title="fa-user"/>
                                        </div>
                                        <div className="card-footer text-center pt-3 pb-3">
                                            <strong>Otp Settings</strong>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            {/*<div className="col-md-6 col-sm-12 col-lg-3 col-xl-3">*/}
                            {/*    <Link className="card-link" to={`/club/${this.props.match.params.id}/wallet`}>*/}
                            {/*        <div className="card">*/}
                            {/*            <div className="img-container lg-card-bg bg-green">*/}
                            {/*                <i className="fa fa-wallet fa-4x" data-toggle="tooltip" title=""*/}
                            {/*                   data-original-title="fa-user"/>*/}
                            {/*            </div>*/}
                            {/*            <div className="card-footer text-center pt-3 pb-3">*/}
                            {/*                <strong>Club Wallet</strong>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </Link>*/}
                            {/*</div>*/}
                        </div>
                        <div className="row">
                            <div className="col-md-8">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h4>Assets & Liabilities</h4>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-7 col-xl-5 col-md-6 col-12">
                                                <div className="card bg-blue-gray text-blue">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="text-center">
                                                                    <span className="text-blue"><i
                                                                        className="fa fa-archive text-blue"/> Total Assets (₦)</span>
                                                                    <h2 className="text-blue mb-0">₦{parseFloat(this.state.portfolio.total_asset_value_ngn).toLocaleString()}</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-67 col-xl-5 col-md-6 col-12">
                                                <div className="card bg-danger text-white">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="text-center">
                                                                    <span
                                                                        className="text-white"><i
                                                                        className="fa fa-file-text text-white"/> Total Liabilities (₦)</span>
                                                                    <h2 className="text-white mb-0">₦{parseFloat(this.state.portfolio.total_liability_value_ngn).toLocaleString()}</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-7 col-xl-5 col-md-6 col-12">
                                                <div className="card bg-blue-gray text-blue">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="text-center">
                                                                    <span className="text-blue"><i
                                                                        className="fa fa-archive text-blue"/> Total Assets ($)</span>
                                                                    <h2 className="text-blue mb-0">${parseFloat(this.state.portfolio.total_asset_value_usd).toLocaleString()}</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-67 col-xl-5 col-md-6 col-12">
                                                <div className="card bg-danger text-white">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="text-center">
                                                                    <span
                                                                        className="text-white"><i
                                                                        className="fa fa-file-text text-white"/> Total Liabilities ($)</span>
                                                                    <h2 className="text-white mb-0">${parseFloat(this.state.portfolio.total_liability_value_usd).toLocaleString()}</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-7 col-xl-5 col-md-6 col-12">
                                                <div className="card bg-blue-gray text-blue">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="text-center">
                                                                <span className="text-blue"><i
                                                                    className="fa fa-archive text-blue"/> Total Assets (£)</span>
                                                                    <h2 className="text-blue mb-0">£{parseFloat(this.state.portfolio.total_asset_value_gbp).toLocaleString() || 0}</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-67 col-xl-5 col-md-6 col-12">
                                                <div className="card bg-danger text-white">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="text-center">
                                                                <span className="text-white"><i
                                                                    className="fa fa-file-text text-white"/> Total Liabilities (£)</span>
                                                                    <h2 className="text-white mb-0">£{parseFloat(this.state.portfolio.total_liability_value_gbp).toLocaleString() || 0}</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <div id="example_wrapper"
                                                 className="dataTables_wrapper dt-bootstrap4 no-footer">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <table id="example"
                                                               className="table table-striped table-bordered border-t0 text-nowrap w-100 dataTable no-footer"
                                                               role="grid" aria-describedby="example_info">
                                                            <thead>
                                                            <tr role="row">
                                                                <th className="wd-15p sorting_asc" tabIndex="0"
                                                                    aria-controls="example" rowSpan="1" colSpan="1"
                                                                    aria-sort="ascending"
                                                                    aria-label="First name: activate to sort column descending"
                                                                    style={{width: "113px"}}>Name
                                                                </th>
                                                                <th className="wd-15p sorting" tabIndex="0"
                                                                    aria-controls="example"
                                                                    rowSpan="1" colSpan="1"
                                                                    aria-label="Last name: activate to sort column ascending"
                                                                    style={{width: "113px"}}>Value
                                                                </th>
                                                                <th className="wd-20p sorting" tabIndex="0"
                                                                    aria-controls="example"
                                                                    rowSpan="1" colSpan="1"
                                                                    aria-label="Position: activate to sort column ascending"
                                                                    style={{width: "231.4px"}}>Investment Type
                                                                </th>
                                                                <th className="wd-15p sorting" tabIndex="0"
                                                                    aria-controls="example"
                                                                    rowSpan="1" colSpan="1"
                                                                    aria-label="Start date: activate to sort column ascending"
                                                                    style={{width: "110.6px"}}>Type
                                                                </th>
                                                                <th className="wd-10p sorting" tabIndex="0"
                                                                    aria-controls="example"
                                                                    rowSpan="1" colSpan="1"
                                                                    aria-label="Salary: activate to sort column ascending"
                                                                    style={{width: "93.8px"}}>Action
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {this.state.assets.map(item => (
                                                                <tr role="row" key={item.id}>
                                                                    <td className="sorting_1">{item.name}</td>
                                                                    <td>{Utils.currencyToSym(item.currency)}{(item.category !== 'liability') ? parseFloat(item.roi).toLocaleString() : parseFloat(item.value).toLocaleString()}</td>
                                                                    <td className="text-capitalize">{item.investment_type}</td>
                                                                    <td className="text-capitalize">{item.category}</td>
                                                                    <td>
                                                                        {(item.name === 'wallet') ? '' : (!item.investment) ? (
                                                                            <div className="btn-group align-top">
                                                                                <button
                                                                                    onClick={e => this.editAsset(e, item.id)}
                                                                                    className="btn btn-sm btn-blue text-white badge"
                                                                                    type="button">Edit
                                                                                </button>
                                                                                <button
                                                                                    data-toggle='modal'
                                                                                    data-target="#deleteModal"
                                                                                    className="btn btn-sm btn-danger badge"
                                                                                    onClick={event => this.setState({
                                                                                        delete_form: item
                                                                                    })}>
                                                                                    <i
                                                                                        className="fa fa-trash"/>
                                                                                </button>
                                                                            </div>

                                                                        ) : (<span className="fa fa-eye-slash"/>)}

                                                                    </td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <h4>Add Asset/Liability</h4>
                                        <form onSubmit={event => this.addAsset(event)}>
                                            <div className="form-group">
                                                <label htmlFor="asset">
                                                    <input onChange={event => {
                                                        this.setState({
                                                            form: {
                                                                ...this.state.form,
                                                                category: event.target.value,
                                                            }
                                                        })
                                                    }} value="asset"
                                                           checked={this.state.form.category === 'asset'} type="radio"
                                                           name="category" id="asset"/> Assets
                                                </label>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="liability">
                                                    <input onChange={event => {
                                                        this.setState({
                                                            form: {
                                                                ...this.state.form,
                                                                category: event.target.value,
                                                            }
                                                        })
                                                    }} value="liability"
                                                           checked={this.state.form.category === 'liability'}
                                                           type="radio"
                                                           name="category" id="liability"/> Liability
                                                </label>
                                            </div>

                                            <div className="">
                                                <div className="form-group">
                                                    <label htmlFor="name">Name</label>
                                                    <input required onChange={event => {
                                                        this.setState({
                                                            form: {
                                                                ...this.state.form,
                                                                name: event.target.value,
                                                            }
                                                        })
                                                    }} value={this.state.form.name} type="text" className="form-control"
                                                           id="name"/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="value">Currency</label>
                                                    <select required value={this.state.form.currency} name=""
                                                            onChange={event => this.setState({
                                                                form: {
                                                                    ...this.state.form,
                                                                    currency: event.target.value
                                                                }
                                                            })}
                                                            id="" className="form-control pulse-out">
                                                        <option value="">---- Select an Option ----</option>
                                                        <option value="NGN">NGN</option>
                                                        <option value="USD">USD</option>
                                                        <option value="GBP">GBP</option>
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="value">Value</label>
                                                    <NumberFormat thousandSeparator={true}
                                                                  prefix={Utils.currencyToSym(this.state.form.currency)}
                                                                  required
                                                                  onValueChange={(values) => {
                                                                      const {formattedValue, value} = values;
                                                                      this.setState({
                                                                          form: {
                                                                              ...this.state.form,
                                                                              value: value,
                                                                          }
                                                                      })
                                                                  }} className="form-control"
                                                                  value={this.state.form.value}/>
                                                </div>
                                                {(this.state.form.category === 'asset') ? (
                                                    <div className="form-group">
                                                        <label htmlFor="value">Asset
                                                            Type</label>
                                                        <select required value={this.state.form.investment_type} name=""
                                                                onChange={event => this.setState({
                                                                    form: {
                                                                        ...this.state.form,
                                                                        investment_type: event.target.value
                                                                    }
                                                                })}
                                                                id="" className="form-control">
                                                            <option value="">---- Select an Option ----</option>
                                                            <option value="cash at hand">Cash at hand</option>
                                                            <option value="real estate">Real Estate</option>
                                                            <option value="agro tech">Agro Tech</option>
                                                            <option value="bonds">Bonds</option>
                                                            <option value="treasury bills">Treasury Bills</option>
                                                            <option value="mutual funds">Mutual Funds</option>
                                                            <option value="eurobonds">Eurobonds</option>
                                                            <option value="stocks">Stocks</option>
                                                            <option value="micro credit">Micro Credit</option>
                                                            <option value="private equity stake">Private Equity Stake
                                                            </option>
                                                            <option value="venture capital">Venture Capital</option>
                                                            <option value="pension fund">Pension Fund</option>
                                                            <option value="fixed deposit">Fixed Deposit</option>
                                                            <option value="savings">Savings</option>
                                                        </select>
                                                    </div>
                                                ) : (
                                                    <div className="form-group">
                                                        <label htmlFor="value">Liability Type</label>
                                                        <select required value={this.state.form.investment_type} name=""
                                                                onChange={event => this.setState({
                                                                    form: {
                                                                        ...this.state.form,
                                                                        investment_type: event.target.value
                                                                    }
                                                                })}
                                                                id="" className="form-control">
                                                            <option value="">---- Select an Option ----</option>
                                                            <option value="mortgage">Mortgage</option>
                                                            <option value="car loan">Car Loan</option>
                                                            <option value="personal loan">Personal Loan</option>
                                                            <option value="debt">Debt</option>
                                                        </select>
                                                    </div>
                                                )}
                                            </div>
                                            <button type="submit"
                                                    className="btn btn-blue text-white mt-1 mr-2 mb-0">{this.state.form.submit}</button>
                                            <button onClick={event => this.setState({
                                                form: {
                                                    category: 'asset',
                                                    submit: 'Add',
                                                    name: '',
                                                    investment_type: '',
                                                    value: ''
                                                }
                                            })} type="button" className="btn btn-main mt-1 mb-0">Reset
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="deleteModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header bg-blue">
                                    <h4 className="modal-title" id="example-Modal3">Are you sure?</h4>
                                    <button id="close-modal" type="button" className="close" data-dismiss="modal"
                                            aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <p>You are about to delete: <strong>{this.state.delete_form.name}</strong>
                                            </p>
                                            <p>Note: This action can not be reversed.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <div className="col-lg-12 mx-auto text-center">
                                        <button
                                            type="button"
                                            className="btn btn-outline-green mr-3"
                                            data-dismiss="modal">Close
                                        </button>
                                        <button onClick={e => this.deleteAsset(e)} className="btn btn-danger">Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout>
            );
        } else {
            return (
                <div>
                    <Layout context={this.context}>
                        <Code/>
                    </Layout>
                </div>
            )
        }
    }
}

export default ManageClub;
