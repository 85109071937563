import React, {useContext, useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Moment from 'react-moment';
import ReactTooltip from 'react-tooltip'
import API from '../../api';

import Layout from '../Layout';
import {hideLoader, showLoader, toast, Utils} from "../../_helpers/Loader";
import {Context} from "../MyContext";
import $ from 'jquery';

// const moment = require('moment');

const OverviewInterestedClub = (props) =>{
    const [orders, setOrders] = useState([]);
    const [investment, setInvestment] = useState(null)
    const [current_order_for, setCurrent_order_for] = useState('individual')
    const [club, setClub] = useState(null);
    const [interestedStats, setInterestStats] = useState({})
    const [units, setUnits] = useState(0);
    const [balance, setBalance] = useState(0);
    const [total, setTotal] = useState(0);
    const [stated, setStated] = useState({
        investment: {},
        orders: [],
        stats: {},
        ind_stats: {},
        sub_stats: {},
        club_stats: {},
        total_stats: {},
        total_amount: null,
        payment_method_stats: {},
        status: 'paid',
        buffer: [],
        csv_data: [],
        current_page: 1,
        num_pages: 1,
        export: false,
        export_title: "Export File",
        recurring_activity: [],
        initiate_modal: '',
        payout_page: 1,
        file_payout_page: 1,
        file_payment_page: 1,
        payout_file: "",
        user_id: null,
        user_mail: null,
        penalty_order_id: null,
        current_order_for: 'individual',
    });
    const context = useContext(Context);

    useEffect(() => {
        showLoader();
        console.log(props.match.params.investment_id, props.match.params.id, "hello there")
        // if (Object.keys(stated.investment).length == 0){
        //     getInvestment();
        // }
        getInvestment()
        getClub();
        // await getInvestmentStats();
        getInterestedStats();
        getOrders();
        ReactTooltip.rebuild();
        var maxHeight = Math.max.apply(null, $(".boxes .card").map(function () {
            return $(this).height();
        }).get());
        $(".boxes .card").height(maxHeight);
        hideLoader();

        return () => {
            console.log("")
        };
    }, []);

    const getInvestment = async () => {
        try {
            let res = await API.get('investments/' + props.match.params.investment_id + '/');
            setInvestment(res.data)
        } catch (e) {

        }
    };

    const getClub = async () => {
        //get_members_that_are_interested
      try{
        let res = await API.get('clubs/' + props.match.params.id + '/');
        setClub(res.data)
        setBalance(res.data.club_deposits[0].total_deposits)
      }catch(e){}
    }

    const getOrders = async () => {
        showLoader()
        try {
            let res = await API.get(`show_club_interest/${props.match.params.id}/get_members_that_are_interested/?investment_id=${props.match.params.investment_id}&page=${stated.current_page}`);
            console.log(res.data, "dsfsdfd", current_order_for)
            setStated({ ...stated, 
                current_page: res.data.current_page,
                num_pages: res.data.total_pages
            });
            setOrders(res.data.results)
        } catch (e) {
            console.log(e)
        }
        hideLoader()
    };

    const getInterestedStats = async() => {
        showLoader()
        try {
            let res = await API.get(`show_club_interest/${props.match.params.id}/get_interest_stats/?investment_id=${props.match.params.investment_id}`);
            setInterestStats(res.data)
            setTotal(parseFloat(interestedStats && interestedStats.total_amount))
        } catch (e) {
            console.log(e)
        }
        hideLoader()
    };

    const filter = async () => {
        showLoader();

        try {
            let res = await API.get(`show_club_interest/?page=${1}`);
            setStated({ ...stated, 
                orders: res.data.results,
                status: res.data.status,
                current_page: res.data.current_page,
                num_pages: res.data.num_pages
            });
            let myElement = document.getElementById('orders');
            let topPos = myElement.offsetTop;
            hideLoader();
        } catch (e) {
            showLoader();
        }
    };

    const nextPage = async (page) => {
        showLoader();
        let postData = {
            investment: props.match.params.id
        };
        try {
            let res = await API.get(`show_club_interest/${props.match.params.id}/get_members_that_are_interested/?investment_id=${props.match.params.investment_id}&page=${page}`);
            setStated({ ...stated, 
                orders: res.data.results,
                status: res.data.status,
                current_page: res.data.current_page,
                num_pages: res.data.num_pages
            });
            let myElement = document.getElementById('orders');
            let topPos = myElement.offsetTop;
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    };


    const handleSearch = async (e) => {
        if (e.key === 'Enter') {
            await searchFields(e.target.value);
        }
    };

    const searchFields = async (search_value) => {
        showLoader();
        try {
            let res = await API.get(`show_club_interest/?page=${1}&search=${search_value}`);
            setStated({ ...stated, 
                orders: res.data.results,
                current_page: res.data.current_page,
                num_pages: res.data.num_pages,
                status: res.data.status
            });
            console.log(stated.orders, "=======>")
            let myElement = document.getElementById('orders');
            let topPos = myElement.offsetTop;
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    };

    const changeUserOrder = async (value) => {
        console.log(value, "vvalue value")
        showLoader();
        await getOrders(value);
        hideLoader();
    };

    const getBase64 = async(file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const exportTransactionExcel = async (event) => {
        setStated({ ...stated, export_title: 'Exporting...'})
        const req_data = {
            method: 'get',
            url: `admin/investments/${props.match.params.id}/export_transactions/`,
            responseType: "blob",
        }
        try {
            const res = await API.downloadData(req_data);
            var blob = new Blob([res.data], {type: res.headers['content-type']});
            var fileURL = URL.createObjectURL(blob);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = fileURL;
            a.download = `${stated.investment.title}_by_${stated.investment.company}.xlsx`;
            a.click();
            window.URL.revokeObjectURL(fileURL);
            setStated({ ...stated, export_title: 'Export File'})
        } catch (e) {
            setStated({ ...stated, export_title: 'Export File'})
        }
    }

    const currencyToNGN = (currency) => {
        let totl = interestedStats && interestedStats.total_amount || 0
        let club_tag = investment.club_tag ? true : false
        if (!club_tag) {
            if (currency === "USD") {
                return parseFloat(totl) * parseFloat(context.user.usd)
            } else if (currency === "GBP") {
                return parseFloat(totl) * parseFloat(context.user.gbp)
            } else {
                return parseFloat(totl)
            }
        } else {
            if (currency === 'USD') {
                return parseFloat(context.user.usd) * parseFloat(totl / investment.maturity_cycle)
            } else if (currency === "GBP") {
                return parseFloat(context.user.gbp) * parseFloat(totl / investment.maturity_cycle)
            } else {
                return parseFloat(totl / investment.maturity_cycle)
            }
        }
    }

    //club pay for invesment
    const walletAction = async() => {
        let reference = "wallet-vest" + Math.floor((Math.random() * 1000000000) + 1);
        let postData = {
            amount: currencyToNGN(investment.currency),
            txRef: reference,
            phone: context.user.phone,
            id: investment.id,
            units: interestedStats && interestedStats.member_units,
            wallet: true,
            club_id: context.club_id,
            pay_type: 'wallet'
        };
        // console.log(postData, "postData")
        showLoader();

        try {
            let res = await API.put('investments/' + investment.id + '/confirm_payment/', postData);
            toast.success("Successfully Completed Transaction");
            document.getElementById('close-modal').click();
            $('.modal-backdrop').remove();
            props.history.push(`/club/${context.club_id}/investments`)
        } catch (e) {
            if (e.response.status === 400 && e.response.data) {
                toast.error("Insufficient Funds. Kindly Fund club your Wallet");
                hideLoader();
                setTimeout(() => {
                    document.getElementById('close-modal').click();
                    $('.modal-backdrop').remove();
                    props.history.push('/club/${context.club_id}/investments')
                }, 2000)
            } else {
                toast.error("Something went wrong");
            }
            // console.log(e.response);
            hideLoader();
        }
        hideLoader();
    }

    const walletBalance = () => {
        let totl = interestedStats && interestedStats.total_amount
        if (investment && investment.club_tag) {
            if (investment && investment.currency === "NGN") {
                return (
                    <p className="mb-0">Balance after transaction:
                        ₦{parseFloat(parseFloat(balance - (totl / parseFloat(investment && investment.maturity_cycle))).toFixed(2)).toLocaleString()}</p>
                )
            } else if (investment && investment.currency === "USD") {
                return (
                    <p className="mb-0">Balance after transaction:
                        ₦{parseFloat(parseFloat(balance - ((totl * parseFloat(context.user.usd)) / parseFloat(investment && investment.maturity_cycle))).toFixed(2)).toLocaleString()}</p>
                )
            } else if (investment && investment.currency === "GBP") {
                return (
                    <p className="mb-0">Balance after transaction:
                        ₦{parseFloat(parseFloat(balance - ((totl * parseFloat(context.user.gbp)) / parseFloat(investment && investment.maturity_cycle))).toFixed(2)).toLocaleString()}</p>
                )
            }
        } else {
            if (investment && investment.currency === "USD") {
                return (
                    <p className="mb-0">Balance after transaction:
                        ₦{parseFloat((balance - (totl * parseFloat(context.user.usd))).toFixed(2)).toLocaleString()}</p>
                )
            } else if (investment && investment.currency === "GBP") {
                return (
                    <p className="mb-0">Balance after transaction:
                        ₦{parseFloat((balance - (totl * parseFloat(context.user.gbp))).toFixed(2)).toLocaleString()}</p>
                )
            } else {
                console.log(totl)
                return (
                    <p className="mb-0">Balance after transaction:
                        ₦{parseFloat(balance - totl).toLocaleString()}</p>
                )
            }
        }
    }


    return (
        <Layout context={context} active_menu={'investment'} title={'Investment Interest'}>
            <ReactTooltip/>
            <div className="section">
            
                <div className="breadcrumb">
                    <h5><Link to={`/club/${props.match.params.id}/details`}><span className="fa fa-chevron-left"/> Club Dashboard</Link></h5>
                </div>
                <div className="row" id="orders">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header bg-green row">
                                <h4 className='col-lg-8'>{investment ? "Members Interested in " + investment.title +  " (" + investment.currency + ")"  : ""}
                                </h4>
                                <div className="col-lg-4">
                                    { (interestedStats && interestedStats.member_units) == (interestedStats && interestedStats.total_units) ? (
                                        <button className="btn btn-primary float-right" style={{"color":"white","background-color":"#2b2b2b"}} data-toggle="modal"
                                        data-target="#wallet2Modal">Invest Now</button>
                                    ) : ""}
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                        <div className="card bg-blue-gray text-blue">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="mt-3 text-center">
                                                                <span
                                                                    className="text-blue"><strong>Total Amount</strong>  <i
                                                                    data-tip="Total Amount"
                                                                    className="fa fa-info-circle text-right text-blue"/>
                                                                </span>
                                                                <br/>
                                                                <span className="text-blue">
                                                                    <strong>{(interestedStats && interestedStats.total_amount && interestedStats.total_amount.toLocaleString())}</strong>
                                                                </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                        <div className="card bg-blue-gray text-blue">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="mt-3 text-center">
                                                                <span
                                                                    className="text-blue"><strong>Total Amount Raised</strong>  <i
                                                                    data-tip="Total Amount Raised"
                                                                    className="fa fa-info-circle text-right text-blue"/>
                                                                </span>
                                                                <br/>
                                                                <span className="text-blue">
                                                                    <strong>{(interestedStats && interestedStats.amount_raised && interestedStats.amount_raised.toLocaleString())}</strong>
                                                                </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                        <div className="card bg-blue-gray text-blue">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="mt-3 text-center">
                                                                <span
                                                                    className="text-blue"><strong>Total Amount Left</strong>  <i
                                                                    data-tip="Total Amount Left"
                                                                    className="fa fa-info-circle text-right text-blue"/>
                                                                </span>
                                                                <br/>
                                                                <span className="text-blue">
                                                                    <strong>{(interestedStats && interestedStats.amount_left && interestedStats.amount_left.toLocaleString())}</strong>
                                                                </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                        <div className="card bg-blue-gray text-blue">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="mt-3 text-center">
                                                                <span
                                                                    className="text-blue"><strong>Total Units</strong>  <i
                                                                    data-tip="Total Amount Left"
                                                                    className="fa fa-info-circle text-right text-blue"/>
                                                                </span>
                                                                <br/>
                                                                <span className="text-blue">
                                                                    <strong>{interestedStats && interestedStats.member_units}/{interestedStats && interestedStats.total_units}</strong>
                                                                </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='text-primary'><h4><strong>Investment Status:</strong> {investment && investment.status}</h4></div>
                                <div id="example_wrapper table-responsive"
                                     className="dataTables_wrapper dt-bootstrap4 no-footer table-responsive ">
                                    <table
                                        className="table table-striped table-bordered border-t0 table-responsive-xl text-nowrap w-100 dataTable no-footer"
                                        role="grid" aria-describedby="example_info">
                                        <thead>
                                        <tr role="row">
                                            <th className="wd-15p sorting_asc" tabIndex="0"
                                                aria-controls="example" rowSpan="1" colSpan="1"
                                                aria-sort="ascending"
                                                aria-label="First name: activate to sort column descending"
                                                style={{width: "15px"}}>S/N
                                            </th>
                                            <th className="wd-15p sorting_asc" tabIndex="0"
                                                aria-controls="example" rowSpan="1" colSpan="1"
                                                aria-sort="ascending"
                                                aria-label="First name: activate to sort column descending"
                                                style={{width: "113px"}}>{current_order_for == 'individual' ? 'Individuals' : current_order_for == 'child' ? 'Parent' : 'Clubs' }
                                            </th>
                                            <th className="wd-15p sorting_asc" tabIndex="0"
                                                aria-controls="example" rowSpan="1" colSpan="1"
                                                aria-sort="ascending"
                                                aria-label="First name: activate to sort column descending"
                                                style={{width: "113px"}}>Email
                                            </th>
                                            <th className="wd-15p sorting_asc" tabIndex="0"
                                                aria-controls="example" rowSpan="1" colSpan="1"
                                                aria-sort="ascending"
                                                aria-label="First name: activate to sort column descending"
                                                style={{width: "113px"}}>Units
                                            </th>
                                            <th className="wd-15p sorting_asc" tabIndex="0"
                                                aria-controls="example" rowSpan="1" colSpan="1"
                                                aria-sort="ascending"
                                                aria-label="First name: activate to sort column descending"
                                                style={{width: "113px"}}>Amount
                                            </th>
                                            <th className="wd-15p sorting_asc" tabIndex="0"
                                                aria-controls="example" rowSpan="1" colSpan="1"
                                                aria-sort="ascending"
                                                aria-label="First name: activate to sort column descending"
                                                style={{width: "113px"}}>Created At
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {orders.map((item, key) => (
                                            <tr role="row" key={item.id}>
                                                <td>{((stated.current_page - 1) * 30) + (key + 1)}</td>
                                                <td className="sorting_1">{item.member.user.first_name} {item.member.user.last_name}
                                                </td>
                                                <td>{item.member.user.email}</td>
                                                <td>
                                                    {Utils.NumberDecimal(item.proposed_unit)}
                                                </td>
                                                <td>{item.amount}</td>
                                                <td><Moment format="MMM D, Y">{item.created_at}</Moment></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="d-flex justify-content-center">
                                    <ul className="pagination mt-3 mb-0">
                                        <li className={`${(stated.current_page > 1) ? '' : 'disabled'} page-item`}>
                                            <a className="page-link"
                                               onClick={event => nextPage(stated.current_page - 1)}>‹</a>
                                        </li>
                                        {[...Array(stated.num_pages)].map((item, key) => (
                                            <li className={`${((key + 1) === stated.current_page) ? 'active' : ''} page-item`}>
                                                <a className="page-link"
                                                   onClick={event => nextPage(key + 1)}>{key + 1}</a>
                                            </li>
                                        ))}
                                        <li className={`${(stated.current_page === stated.num_pages) ? 'disabled' : ''} page-item`}>
                                            <a className="page-link"
                                               onClick={event => nextPage(stated.current_page + 1)}>»</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div class="modal" id="myModal">
                    <div class="modal-dialog">
                        <div class="modal-content">

                        <div class="modal-header">
                            <h4 class="modal-title">Modal Heading</h4>
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>

                        <div class="modal-body">
                            Modal body..
                        </div>

                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>

                        </div>
                    </div>
                </div> */}
            </div>
            <div className="modal fade" id="wallet2Modal" tabIndex="1" role="dialog"
                     aria-labelledby="wallet2ModalLabel">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-green">
                            <h5 className="modal-title" id="exampleModalLabel">Confirm wallet transaction</h5>
                            <button id="close-modal" type="button" className="close" data-dismiss="modal"
                                    aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p className="mb-0">Wallet balance:
                                ₦{parseFloat(balance).toLocaleString()}</p>
                            {walletBalance()}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-green mr-3" data-dismiss="modal">Close
                            </button>
                            {(investment && investment.club_tag) ?
                                (((balance - parseFloat(total) / parseFloat(investment && investment.maturity_cycle)) >= 0) ? (
                                    <button onClick={event => walletAction(event)} type="button"
                                            className="btn btn-main"><i
                                        className="fas fa-wallet"/>Pay with wallet</button>
                                ) : (
                                    <button disabled onClick={event => walletAction(event)} type="button"
                                            className="btn btn-main"><i
                                        className="fas fa-wallet"/>Pay with wallet</button>
                                    // <button onClick={event => {
                                    //     document.getElementById('close-modal').click();
                                    //     $('.modal-backdrop').remove();
                                    //     props.history.push("/account/wallet")
                                    // }}
                                    //         type="button" className="btn btn-main"><i
                                    //     className="fas fa-wallet"/> Fund wallet</button>
                                ))
                                : (((balance - parseFloat(interestedStats && interestedStats.total_amount)) >= 0) ? (
                                    <button onClick={event => walletAction(event)} type="button"
                                            className="btn btn-main"><i
                                        className="fas fa-wallet"/>Pay with wallet</button>
                                ) : (
                                    <button disabled onClick={event => walletAction(event)} type="button"
                                            className="btn btn-main"><i
                                        className="fas fa-wallet"/>{balance - parseFloat(interestedStats && interestedStats.total_amount)} Pay with wallet</button>
                                    // <button onClick={event => {
                                    //     document.getElementById('close-modal').click();
                                    //     $('.modal-backdrop').remove();
                                    //     props.history.push("/account/wallet")
                                    // }}
                                    //         type="button" className="btn btn-main"><i
                                    //     className="fas fa-credit-card"/> Fund wallet</button>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}


export default OverviewInterestedClub;
