import React from 'react'
import {Link} from "react-router-dom";
import API from '../../api'
import Layout from '../Layout'
import {hideLoader, mixpanel, showLoader, toast, trackMixpanel} from "../../_helpers/Loader";
import {Context} from "../MyContext";

class ClubInvites extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            invites: [],
            club: {},
        };
        this.acceptClub = this.acceptClub.bind(this);
    }

    async componentDidMount() {
        trackMixpanel('ClubInvitesView', false, false, this.context.user.email)
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
        if (this.context.user.accounts.length < 1) {
            this.setState({redirect: true, redirect_url: '/account/bank-details'});
        }
        if (!this.context.user.next_of_kin_name || !this.context.user.next_of_kin_phone) {
            this.setState({redirect: true, redirect_url: '/profile'});
        }
        showLoader();
        await this.getInvites();
        await this.getClub();
        hideLoader();
    }

    componentWillMount() {
        if (this.context.user.completed_onboarding === null || this.context.user.completed_onboarding === undefined) {
            window.location.reload();
        }
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
    }

    defaultRedirect = () => {
        toast.info("Please update your profile");
        setTimeout(() =>
            this.props.history(this.state.redirect_url), 2000
        )
    };

    async getInvites() {
        try {
            let res = await API.get('club_invites/');
            this.setState({invites: res.data})
        } catch (e) {
            toast.error("Something Went Wrong");
            // console.log(e.response)
        }
    }

    async rejectClub(e, item_id) {
        let postData = {invite_id: item_id};
        showLoader();
        try {
            let res = await API.post('club_invites/decline_club_invite/', postData);
            toast.success("Club Rejected Successfully");
            this.getInvites();
            hideLoader();
        } catch (e) {
            toast.error("Something Went Wrong");
            hideLoader();
            // console.log(e.response)
        }
    }

    async acceptClub(e, item_id) {
        let postData = {invite_id: item_id};
        showLoader();
        try {
            let res = await API.post('club_invites/accept_club_invite/', postData);
            this.context.updateAll();
            toast.success("Club Successfully Joined");
            this.props.history.push('/clubs');
        } catch (e) {
            toast.error("Something Went Wrong");
            hideLoader();
            // console.log(e.response)
        }
    }

    async getClub() {
        try {
            let res = await API.get(`clubs/${this.context.club_id}/`);
            this.setState({club: res.data});
        } catch (e) {
            hideLoader();
            // console.log(e.response)
        }
    }

    checkAdmin(item) {
        if (item !== undefined) {
            for (let i = 0; i < item.length; i++) {
                if (item[i].user.id === this.context.user.id && item[i].user_role === 'admin') {
                    return true;
                }
            }
            return false
        } else {
            return false
        }
    }

    render() {
        return (
            <Layout active_menu={'invite'} club_admin={this.checkAdmin(this.state.club.club_members)}
                    context={this.context}>
                <div className="section">
                    <div className="breadcrumb">
                        <h5>Club Invites</h5>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-lg-10 col-xl-10 mx-auto">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row text-center">
                                        <div className="col-sm-12 mb-3">
                                            <h3>Club Invitations</h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="table-responsive">
                                                <table className="table table-striped mb-0 text-nowrap">
                                                    <tbody>
                                                    <tr>
                                                        <th>Sender's Name</th>
                                                        <th>Club Name</th>
                                                        <th>Status</th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                    {this.state.invites.map(item => (
                                                        <tr key={item.id}>
                                                            <td>{item.sender.first_name} {item.sender.last_name}</td>
                                                            <td>{item.club.name}</td>
                                                            <td>
                                                                {item.status === 'pending' && (
                                                                    <div
                                                                        className="text-info text-capitalize">{item.status}</div>)}
                                                                {item.status === 'confirmed' && (
                                                                    <div
                                                                        className="text-success text-capitalize">Accepted</div>)}
                                                                {item.status === 'rejected' && (
                                                                    <div
                                                                        className="text-danger text-capitalize">{item.status}</div>)}
                                                            </td>
                                                            <td>
                                                                {(item.status === 'pending') ? (<div>
                                                                    <span
                                                                        onClick={event => this.acceptClub(event, item.id)}
                                                                        className="btn btn-sm badge bg-green m-b-5">Accept</span>
                                                                </div>) : ''}
                                                            </td>
                                                            <td>
                                                                {(item.status === 'pending') ? (<div>
                                                                    <span
                                                                        onClick={event => this.rejectClub(event, item.id)}
                                                                        className="btn btn-sm badge badge-danger m-b-5">Decline</span>
                                                                </div>) : ''}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default ClubInvites
