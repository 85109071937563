import React, {Component} from 'react';
import API from "../api";
import {toast} from "../_helpers/Loader";

const Context = React.createContext({});

class MyContext extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            clubs: JSON.parse(localStorage.getItem('clubs')),
            user_state: (localStorage.getItem('userState')) ? localStorage.getItem('userState') : 'Personal',
            club_id: (localStorage.getItem('clubID') != undefined) ? localStorage.getItem('clubID') : false,
            sub_investor_id: (localStorage.getItem('SubInvestorID') != undefined) ? localStorage.getItem('SubInvestorID') : false,
            notification: false,
            notification_list: [],
            sub_investors: JSON.parse(localStorage.getItem('sub_investors')),
            select_club: {},
            select_sub_investor: {},
            is_club_admin: (localStorage.getItem('is_club_admin')) ? JSON.parse(localStorage.getItem('is_club_admin')) : false
        };

        this.updateUser = this.updateUser.bind(this);
        this.changeUserState = this.changeUserState.bind(this);
        this.resetState = this.resetState.bind(this);
        this.clearState = this.clearState.bind(this);
    }

    async componentWillMount() {
        try {
            await this.createNotification();
        } catch (e) {
        }
    }

    updateClubs = async () => {
        try {
            let res = await API.get('clubs/');
            await localStorage.setItem('clubs', JSON.stringify(res.data));
            let club_filter = res.data.filter(item => item.id == this.state.club_id)
            await this.setState({clubs: res.data})
            if(club_filter.length > 0){
                await this.setState({user_state: club_filter[0].name})
            }
        } catch (e) {
            toast.error("Something Went Wrong");
        }
    };


    createNotification = () => {
        let msg = '', data_list = [], note = false;
        if (this.state.user.accounts.length < 1) {
            msg = {message: "Update your account information", action: '/profile', icon: 'fa fa-bank'};
            data_list.push(msg);
            note = true;
        }
        if (this.state.user.next_of_kin_name === "" || this.state.user.next_of_kin_phone === "" || this.state.user.next_of_kin_name == null || this.state.user.next_of_kin_phone == null ) {
            msg = {message: "Update your profile information here", action: '/profile', icon: 'fa fa-user'};
            data_list.push(msg);
            note = true
        }
        if (((this.user.user_type === "twelveblack") && (this.state.user.address === "" || this.state.user.address == null))){
            msg = {message: "Update your address information", action: '/profile', icon: 'fa fa-location'};
            data_list.push(msg);
            note = true
        }
        if (((this.user.user_type === "twelveblack") && (!this.state.user.kyc_verified))){
            msg = {message: "Update your verification and upload ID information", action: '/profile', icon: 'fa fa-location'};
            data_list.push(msg);
            note = true
        }
        if (((this.user.user_type === "twelveblack") && (!this.state.user.bvn_verified))){
            msg = {message: "Do bvn verification by creating virtual account here", action: '/account/wallet', icon: 'fa fa-location'};
            data_list.push(msg);
            note = true
        }
        this.setState({notification: note, notification_list: data_list});
    };

    async changeUserState(layout_state, state_id) {
        if (layout_state === 'personal') {
            localStorage.setItem('userState', 'Personal');
            localStorage.setItem('clubID', '');
            this.setState({user_state: 'Personal'});
            this.setState({club_id: false, sub_investor_id: false});
        } else if (layout_state === 'club') {
            let is_admin = (JSON.parse(localStorage.getItem('clubs')).filter(item => state_id == item.id)[0].club_admins.filter(item => item.user == this.state.user.id).length > 0);
            await this.state.clubs.map(item => {
                (state_id == item.id) && localStorage.setItem('userState', item.name);
                (state_id == item.id) && localStorage.setItem('clubID', item.id);
                (state_id == item.id) && localStorage.setItem('is_club_admin', is_admin);
                (state_id == item.id) && this.setState({user_state: item.name});
                (state_id == item.id) && this.setState({
                    club_id: state_id,
                    is_club_admin: is_admin
                });
            });
        } else if (layout_state === 'subinvestor') {
            let select = this.state.sub_investors.filter(item => item.id === state_id)[0]
            localStorage.setItem('userState', 'subinvestor');
            localStorage.setItem('clubID', '');
            localStorage.setItem('SubInvestorID', state_id);
            await this.setState({
                user_state: 'subinvestor',
                club_id: false,
                sub_investor_id: state_id,
                select_sub_investor: select,
            });
        }
    }

    toggleUserState() {
        this.setState({user_state: (this.state.user_state === 'personal') ? 'club' : 'personal'})
    }

    async updateUser() {
        try {
            let res = await API.get('users/');
            await localStorage.setItem('user', JSON.stringify(res.data[0]));
            localStorage.setItem('sub_investors', JSON.stringify(res.data[0].user_subinvestor));
            this.setState({
                user: JSON.parse(localStorage.getItem('user')),
                sub_investors: res.data[0].user_subinvestor
            });
            await this.createNotification();
        } catch (e) {
            // console.log(e.response);
        }
    }

    async updateSubInvestor() {
        try {
            const res = await API.get('sub_investor/');
            let data = res.data.filter(item => item.status === 'active')
            localStorage.setItem('sub_investors', JSON.stringify(data));
            this.setState({
                sub_investors: data
            });
        } catch (e) {
        }
    }

    async resetState() {
        this.setState({
            user: JSON.parse(localStorage.getItem('user')),
            clubs: JSON.parse(localStorage.getItem('clubs')),
            user_state: (localStorage.getItem('userState')) ? localStorage.getItem('userState') : 'Personal',
            club_id: JSON.parse(localStorage.getItem('clubID')),
            sub_investors: JSON.parse(localStorage.getItem('sub_investors')),
            sub_investor_id: JSON.parse(localStorage.getItem('SubInvestorID'))
        })
    }

    async clearState() {
        this.setState({
            user: {},
            clubs: [],
            user_state: null,
            club_id: null
        })
    }

    updateAll = async () => {
        await this.updateUser();
        await this.updateClubs()
    };

    isAdmin(){

    }

    render() {
        return (
            <Context.Provider value={{
                user: this.state.user,
                updateUser: this.updateUser,
                clubs: this.state.clubs,
                sub_investors: this.state.sub_investors,
                sub_investor_id: this.state.sub_investor_id,
                select_sub_investor: this.state.select_sub_investor,
                club_id: this.state.club_id,
                is_admin: this.state.is_club_admin,
                notification: this.state.notification,
                notification_list: this.state.notification_list,
                updateUserState: this.changeUserState,
                userState: this.state.user_state,
                updateClubs: this.updateClubs,
                updateAll: this.updateAll,
                updateSubinvestor: this.updateSubInvestor,
                main_club: this.state.main_club,
                clearState: this.clearState,
                resetState: this.resetState,
                updateNotification: this.createNotification,
            }}>
                {this.props.children}
            </Context.Provider>
        );
    }
}

export {Context, MyContext}
