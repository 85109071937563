import React from 'react';
import {BrowserRouter, Switch, Route, Redirect, withRouter} from 'react-router-dom';

import IdleTimer from 'react-idle-timer'
import {Offline, Online} from "react-detect-offline";
import {toast} from './_helpers/Loader';

import uuidv1 from 'uuid';

import {Context, MyContext} from './components/MyContext'


import Dashboard from './components/dashboard/Dashboard'
import PendingTransaction from './components/dashboard/PendingTransaction'
import Activities from './components/dashboard/Activities'
import Investments from './components/dashboard/Investments'
import ManagePortfolio from './components/dashboard/Manage-portfolio'
import RecurringInvestment from './components/dashboard/RecurringInvestment'
import ClubRecurringInvestment from './components/clubs/RecurringInvestment'
import Clubs from './components/clubs/Clubs'
import AddAccount from './components/clubs/AddAccount'
import AddAccountOnly from './components/clubs/AddAccountOnly'
import ClubContributions from './components/clubs/ClubContributions'
import ClubInvestments from './components/clubs/ClubInvestments'
import ClubActivities from './components/clubs/ClubActivities'
import ClubAndInvites from './components/clubs/ClubAndInvites'
import ClubInvites from './components/clubs/ClubInvites'
import ClubPortfolio from './components/clubs/ClubPortfolio'
import ClubMarket from './components/clubs/Marketplace'
import ClubMarketDetails from './components/clubs/MarkeplaceDetails'
import StartClub from './components/clubs/StartClub'
import ClubWallet from './components/clubs/ClubWallet'
import ClubSubscribe from './components/clubs/ClubSubscribe'
import ClubSettings from './components/clubs/ClubSettings'
import Onboarding from './components/onboarding/Onboarding'
import Login from './components/account/Login'
import CrossAuth from './components/account/CrossAuth'

import Wallet from './components/account/Wallet'
import SignUp from './components/account/SignUp'
import AxaProfile from './components/account/AxaProfile'
import AxaBalance from './components/account/AxaBalance'
import AxaSettings from './components/account/AxaSettings'
import TGEMSignUp from './components/account/TGEMSignUp'
import VerifyEmail from './components/account/VerifyEmail'
import Settings from './components/account/Settings'
import ManageFiles from './components/account/ManageFiles'
import ForgetPassword from './components/account/ForgotPassword'
import Profile from './components/account/Profile'
import SubInvestor from './components/account/SubInvestor'
import SubDashboard from './components/subinvestor/SubDashboard'
import SubMarketplace from './components/subinvestor/Marketplace'
import SubMarketPlaceDetails from './components/subinvestor/MarkeplaceDetails'
import SubManagePortfolio from './components/subinvestor/Manage-portfolio'
import InvestmentSettings from './components/account/InvestmentSettings'
import UpdatePassword from './components/account/UpdatePassword'
import UpdateOtp from './components/account/UpdateOtp'
import ResetPassword from './components/account/ResetPassword'
import ManageClub from './components/clubs/ManageClub'
import ManageClubOtp from './components/clubs/UpdateOtp'
import Chats from './components/clubs/Chats'
import MarketPlace from './components/investments/Marketplace'
import MarketPlaceDetails from './components/investments/MarkeplaceDetails'
import MarkeplaceDetailsMansard from './components/investments/MarkeplaceDetailsMansard'
import Oops from './components/Oops';


import TGEMOnboarding from './components/onboarding/TGEMOnboarding'

import {authHeader} from './_helpers/auth-header'
import {userService} from "./_services";
import HistoricalGraph from './components/investments/GraphHistory';
import Accredation from './components/account/accreditation/Acrreditation';
import AccreditationEvaluation from './components/account/accreditation/PublicAccreditation';
import ListResources from './components/resources/list';
import DetailResource from './components/resources/detail';
import DetailReport from './components/reports/index';

import OverviewInterestedClub from './components/clubs/ClubInterestedMembers';
import UpdateBVN from './components/account/UpdateBVN';

const PrivateRoute = ({component: Component, ...rest}) => (
    <Route  {...rest} render={(props) => (
        authHeader()
            ? <Component {...props} />
            : <Redirect to='/login'/>
    )}/>
);

class App extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.idleTimer = null;
        this.onIdle = this._onIdle.bind(this);
    }

    _onIdle(e) {
        console.error("So i know");
        toast.info("App is logging off due to inactivity. You can login again.");
        setTimeout(() => {
            userService.logout();
            window.location.reload();
        }, 6000);
    }


    render() {
        return (
            <MyContext>
                <IdleTimer
                    ref={ref => {
                        this.idleTimer = ref
                    }}
                    element={document}
                    onIdle={this.onIdle}
                    debounce={60000}
                    timeout={900000}/>
                <BrowserRouter>
                    <Switch>
                        <PrivateRoute exact path="/activities" component={Activities}/>
                        <PrivateRoute exact path="/pending-transactions" component={PendingTransaction}/>
                        <PrivateRoute exact path="/investments" component={Investments}/>
                        <PrivateRoute exact path="/manage-portfolio" component={ManagePortfolio}/>
                        <PrivateRoute exact path="/investment/:id(\d+)/history" component={RecurringInvestment}/>
                        <PrivateRoute exact path="/club/:id(\d+)/investment/:investment_id(\d+)/history"
                                      component={ClubRecurringInvestment}/>
                        <PrivateRoute exact path="/club/add-account/:id(\d+)" component={AddAccount}/>
                        <PrivateRoute exact path="/club/add-account-only/:id(\d+)" component={AddAccountOnly}/>
                        <PrivateRoute exact path="/club/:id(\d+)/contributions" component={ClubContributions}/>
                        <PrivateRoute exact path="/club/:id(\d+)/activities" component={ClubActivities}/>
                        <PrivateRoute exact path="/club/:id(\d+)/investments" component={ClubInvestments}/>
                        <PrivateRoute exact path="/club-invites" component={ClubInvites}/>
                        <PrivateRoute exact path="/clubs" component={Clubs}/>
                        <PrivateRoute exact path="/club/:id(\d+)/:investment_id(\d+)/interested_members" component={OverviewInterestedClub}/>
                        <PrivateRoute exact path="/club/:id(\d+)/details" component={ClubPortfolio}/>
                        <PrivateRoute exact path="/club/:id(\d+)/subscribe" component={ClubSubscribe}/>
                        <PrivateRoute exact path="/club/:id(\d+)/marketplace" component={ClubMarket}/>
                        <PrivateRoute exact path="/club/:id(\d+)/wallet" component={ClubWallet}/>
                        <PrivateRoute exact path="/club/:id(\d+)/marketplace/:market_id(\d+)"
                                      component={ClubMarketDetails}/>
                        <PrivateRoute exact path="/club/:id(\d+)/invite" component={ClubAndInvites}/>
                        <PrivateRoute exact path="/start-club" component={StartClub}/>
                        <PrivateRoute exact path="/marketplace" component={MarketPlace}/>
                        <PrivateRoute exact path="/marketplace/:id(\d+)" component={MarketPlaceDetails}/>
                        <PrivateRoute exact path="/marketplace/:id(\d+)/mansard" component={MarkeplaceDetailsMansard}/>
                        <PrivateRoute exact path="/marketplace/history/:id(\d+)" component={HistoricalGraph}/>
                        <PrivateRoute exact path="/user/onboarding" component={Onboarding}/>
                        <PrivateRoute exact path="/user/twelve/onboarding" component={TGEMOnboarding}/>
                        <Route exact path="/qualification-evaluation/:id" component={AccreditationEvaluation}/>
                        <Route componentName="good" exact path="/login" component={Login}/>
                        <Route exact path="/signup" component={SignUp}/>
                        <Route exact path="/cross-authorization/:token" component={CrossAuth}/>
                        <Route exact path="/tgem/signup" component={TGEMSignUp}/>
                        <Route exact path="/verify-email/:key" component={VerifyEmail}/>
                        <Route exact path="/forget-password" component={ForgetPassword}/>
                        <PrivateRoute exact path="/account" component={Settings}/>
                        <PrivateRoute exact path="/manage-files" component={ManageFiles}/>
                        <PrivateRoute exact path="/profile" component={Profile}/>
                        <PrivateRoute exact path="/sub-investor" component={SubInvestor}/>
                        <PrivateRoute exact path="/sub-investor/:id/dashboard" component={SubDashboard}/>
                        <PrivateRoute exact path="/sub-investor/:id/marketplace" component={SubMarketplace}/>
                        <PrivateRoute exact path="/sub-investor/:id/marketplace/:market_id(\d+)"
                                      component={SubMarketPlaceDetails}/>
                        <PrivateRoute exact path="/sub-investor/:id/manage-portfolio" component={SubManagePortfolio}/>
                        <PrivateRoute exact path="/account/networth" component={InvestmentSettings}/>
                        <PrivateRoute exact path="/account/axa-profile" component={AxaProfile}/>
                        <PrivateRoute exact path="/account/axa-balance" component={AxaBalance}/>
                        <PrivateRoute exact path="/account/axa-settings" component={AxaSettings}/>
                        <PrivateRoute exact path="/account/update-password" component={UpdatePassword}/>
                        <PrivateRoute exact path="/account/update-otp" component={UpdateOtp}/>
                        <PrivateRoute exact path="/account/verify-bvn" component={UpdateBVN}/>
                        <Route exact path="/access/password-reset/confirm/:user_id/:token" component={ResetPassword}/>
                        <PrivateRoute exact path="/account/wallet" component={Wallet}/>
                        <PrivateRoute exact path="/account/accreditation" component={Accredation}/>
                        <PrivateRoute exact path="/manage-club/:id(\d+)" component={ManageClub}/>
                        <PrivateRoute exact path="/manage-club/:id(\d+)/otp-settings" component={ManageClubOtp}/>

                        <PrivateRoute exact path="/club/:id(\d+)/edit" component={ClubSettings}/>
                        <PrivateRoute exact path="/chats" component={Chats}/>
                        <PrivateRoute exact path="/resources" component={ListResources}/>
                        <PrivateRoute exact path="/resources/:id" component={DetailResource}/>
                        <PrivateRoute exact path="/reports/" component={DetailReport}/>
                        <PrivateRoute exact path="/oops" component={Oops}/>
                        <PrivateRoute exact path="*" component={Dashboard}/>
                    </Switch>
                </BrowserRouter>
            </MyContext>
        )
    }
}

export default withRouter(App);
