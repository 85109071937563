export function getTitles() {
    return [
        {
            "Value": "2",
            "Text": "Miss.",
            "Selected": null
        },
        {
            "Value": "1",
            "Text": "Mr.",
            "Selected": null
        },
        {
            "Value": "4",
            "Text": "Mrs.",
            "Selected": null
        }
    ]
}

export function getGender() {
    return [
        {
            "Value": "2",
            "Text": "Female",
            "Selected": null
        },
        {
            "Value": "1",
            "Text": "Male",
            "Selected": null
        }
    ]
}

export function getMaritalStatus() {
    return [
        {
            "Value": "4",
            "Text": "Divorced",
            "Selected": null
        },
        {
            "Value": "2",
            "Text": "Married",
            "Selected": null
        },
        {
            "Value": "5",
            "Text": "N/A",
            "Selected": null
        },
        {
            "Value": "1",
            "Text": "Single",
            "Selected": null
        },
        {
            "Value": "3",
            "Text": "Widowed",
            "Selected": null
        }
    ]
}

export function getReligion() {
    return [
        {
            "Value": "2",
            "Text": "Christian",
            "Selected": null
        },
        {
            "Value": "1",
            "Text": "Muslim",
            "Selected": null
        },
        {
            "Value": "4",
            "Text": "Others",
            "Selected": null
        }
    ]
}

export function getOccupation() {
    return [
        {
            "Value": "65",
            "Text": "Banking",
            "Selected": null
        },
        {
            "Value": "288",
            "Text": "Construction/Building",
            "Selected": null
        },
        {
            "Value": "81",
            "Text": "Engineering",
            "Selected": null
        },
        {
            "Value": "392",
            "Text": "Insurance And Financial Services",
            "Selected": null
        },
        {
            "Value": "168",
            "Text": "Manufacturer",
            "Selected": null
        },
        {
            "Value": "371",
            "Text": "Mining",
            "Selected": null
        },
        {
            "Value": "395",
            "Text": "N/A",
            "Selected": null
        },
        {
            "Value": "396",
            "Text": "Student",
            "Selected": null
        },
        {
            "Value": "90",
            "Text": "Telecommunications",
            "Selected": null
        },
        {
            "Value": "397",
            "Text": "Unemployed",
            "Selected": null
        }
    ]
}

export function getStates() {
    return [
        {
            "Value": "6",
            "Text": "Abia",
            "Selected": null
        },
        {
            "Value": "7",
            "Text": "Adamawa",
            "Selected": null
        },
        {
            "Value": "8",
            "Text": "Akwa Ibom",
            "Selected": null
        },
        {
            "Value": "9",
            "Text": "Anambra",
            "Selected": null
        },
        {
            "Value": "10",
            "Text": "Bauchi",
            "Selected": null
        },
        {
            "Value": "11",
            "Text": "Bayelsa",
            "Selected": null
        },
        {
            "Value": "12",
            "Text": "Benue",
            "Selected": null
        },
        {
            "Value": "13",
            "Text": "Bornu",
            "Selected": null
        },
        {
            "Value": "14",
            "Text": "Cross River ",
            "Selected": null
        },
        {
            "Value": "15",
            "Text": "Delta",
            "Selected": null
        },
        {
            "Value": "16",
            "Text": "Ebonyi ",
            "Selected": null
        },
        {
            "Value": "17",
            "Text": "Edo",
            "Selected": null
        },
        {
            "Value": "18",
            "Text": "Ekiti",
            "Selected": null
        },
        {
            "Value": "19",
            "Text": "Enugu",
            "Selected": null
        },
        {
            "Value": "5",
            "Text": "Federal Capital Territory",
            "Selected": null
        },
        {
            "Value": "20",
            "Text": "Gombe",
            "Selected": null
        },
        {
            "Value": "21",
            "Text": "Imo",
            "Selected": null
        },
        {
            "Value": "22",
            "Text": "Jigawa",
            "Selected": null
        },
        {
            "Value": "23",
            "Text": "Kaduna",
            "Selected": null
        },
        {
            "Value": "24",
            "Text": "Kano",
            "Selected": null
        },
        {
            "Value": "25",
            "Text": "Katsina",
            "Selected": null
        },
        {
            "Value": "26",
            "Text": "Kebbi",
            "Selected": null
        },
        {
            "Value": "27",
            "Text": "Kogi",
            "Selected": null
        },
        {
            "Value": "28",
            "Text": "Kwara",
            "Selected": null
        },
        {
            "Value": "29",
            "Text": "Lagos",
            "Selected": null
        },
        {
            "Value": "42",
            "Text": "N/A",
            "Selected": null
        },
        {
            "Value": "30",
            "Text": "Nasarawa",
            "Selected": null
        },
        {
            "Value": "31",
            "Text": "Niger",
            "Selected": null
        },
        {
            "Value": "32",
            "Text": "Ogun",
            "Selected": null
        },
        {
            "Value": "33",
            "Text": "Ondo",
            "Selected": null
        },
        {
            "Value": "34",
            "Text": "Osun",
            "Selected": null
        },
        {
            "Value": "35",
            "Text": "Oyo",
            "Selected": null
        },
        {
            "Value": "36",
            "Text": "Plateau",
            "Selected": null
        },
        {
            "Value": "37",
            "Text": "Rivers",
            "Selected": null
        },
        {
            "Value": "38",
            "Text": "Sokoto",
            "Selected": null
        },
        {
            "Value": "39",
            "Text": "Taraba",
            "Selected": null
        },
        {
            "Value": "40",
            "Text": "Yobe",
            "Selected": null
        },
        {
            "Value": "41",
            "Text": "Zamfara",
            "Selected": null
        }
    ]
}

export function getBanks() {
    return [
        {
            "ID": 29,
            "BankName": "AB MICROFINANCE BANK",
            "InterswitchCode": 0
        },
        {
            "ID": 1,
            "BankName": "ACCESS BANK",
            "InterswitchCode": 31
        },
        {
            "ID": 3,
            "BankName": "ACCESS BANK (DIAMOND)",
            "InterswitchCode": 72
        },
        {
            "ID": 2,
            "BankName": "CITIBANK NIGERIA",
            "InterswitchCode": 126
        },
        {
            "ID": 28,
            "BankName": "CORONATION MERCHANT BANK",
            "InterswitchCode": 0
        },
        {
            "ID": 4,
            "BankName": "ECOBANK NIGERIA",
            "InterswitchCode": 47
        },
        {
            "ID": 5,
            "BankName": "ENTERPRISE BANK",
            "InterswitchCode": 125
        },
        {
            "ID": 22,
            "BankName": "EQUITORIAL TRUST BANK",
            "InterswitchCode": 75
        },
        {
            "ID": 6,
            "BankName": "FIDELITY BANK",
            "InterswitchCode": 51
        },
        {
            "ID": 7,
            "BankName": "FIRST BANK OF NIGERIA",
            "InterswitchCode": 8
        },
        {
            "ID": 8,
            "BankName": "FIRST CITY MONUMENT BANK",
            "InterswitchCode": 76
        },
        {
            "ID": 9,
            "BankName": "GUARANTY TRUST BANK",
            "InterswitchCode": 10
        },
        {
            "ID": 10,
            "BankName": "HERITAGE BANK",
            "InterswitchCode": 30
        },
        {
            "ID": 26,
            "BankName": "JAIZ BANK",
            "InterswitchCode": 0
        },
        {
            "ID": 11,
            "BankName": "KEYSTONE BANK",
            "InterswitchCode": 123
        },
        {
            "ID": 12,
            "BankName": "MAINSTREET BANK",
            "InterswitchCode": 9
        },
        {
            "ID": 23,
            "BankName": "OTHER (FOREIGN BANK)",
            "InterswitchCode": 0
        },
        {
            "ID": 13,
            "BankName": "POLARIS BANK",
            "InterswitchCode": 120
        },
        {
            "ID": 24,
            "BankName": "PROVIDUS BANK",
            "InterswitchCode": 0
        },
        {
            "ID": 27,
            "BankName": "RAND MERCHANT BANK",
            "InterswitchCode": 0
        },
        {
            "ID": 14,
            "BankName": "STANBIC IBTC BANK",
            "InterswitchCode": 17
        },
        {
            "ID": 15,
            "BankName": "STANDARD CHARTERED BANK",
            "InterswitchCode": 109
        },
        {
            "ID": 16,
            "BankName": "STERLING BANK",
            "InterswitchCode": 121
        },
        {
            "ID": 25,
            "BankName": "SUNTRUST BANK",
            "InterswitchCode": 0
        },
        {
            "ID": 17,
            "BankName": "UNION BANK OF NIGERIA",
            "InterswitchCode": 11
        },
        {
            "ID": 18,
            "BankName": "UNITED BANK FOR AFRICA",
            "InterswitchCode": 7
        },
        {
            "ID": 19,
            "BankName": "UNITY BANK",
            "InterswitchCode": 129
        },
        {
            "ID": 20,
            "BankName": "WEMA BANK",
            "InterswitchCode": 16
        },
        {
            "ID": 21,
            "BankName": "ZENITH BANK",
            "InterswitchCode": 117
        }
    ]
}

export function accountPurpose() {
    return [
        {
            "Value": "2",
            "Text": "Equity Income Fund"
        },
        {
            "Value": "1",
            "Text": "Money Market Fund"
        }
    ]
}

export function getCustomerType() {
    return [
        {
            "Value": "1",
            "Text": "Individual"
        },
        {
            "Value": "2",
            "Text": "Joint"
        },
        {
            "Value": "3",
            "Text": "Institution"
        },
        {
            "Value": "4",
            "Text": "Corporate"
        },
        {
            "Value": "5",
            "Text": "Sponsor"
        }
    ]
}

export function getCountry() {
    return [
        {
            "Value": "1",
            "Text": "Afghanistan "
        },
        {
            "Value": "195",
            "Text": "Akrotiri and Dhekelia (UK)"
        },
        {
            "Value": "2",
            "Text": "Albania "
        },
        {
            "Value": "3",
            "Text": "Algeria "
        },
        {
            "Value": "197",
            "Text": "American Samoa (US)"
        },
        {
            "Value": "4",
            "Text": "Andorra "
        },
        {
            "Value": "5",
            "Text": "Angola "
        },
        {
            "Value": "198",
            "Text": "Anguilla (UK)"
        },
        {
            "Value": "6",
            "Text": "Antigua and Barbuda "
        },
        {
            "Value": "7",
            "Text": "Argentina "
        },
        {
            "Value": "8",
            "Text": "Armenia "
        },
        {
            "Value": "199",
            "Text": "Aruba (NL)"
        },
        {
            "Value": "9",
            "Text": "Australia "
        },
        {
            "Value": "10",
            "Text": "Austria "
        },
        {
            "Value": "11",
            "Text": "Azerbaijan "
        },
        {
            "Value": "12",
            "Text": "Bahamas "
        },
        {
            "Value": "13",
            "Text": "Bahrain "
        },
        {
            "Value": "14",
            "Text": "Bangladesh "
        },
        {
            "Value": "15",
            "Text": "Barbados "
        },
        {
            "Value": "16",
            "Text": "Belarus "
        },
        {
            "Value": "17",
            "Text": "Belgium "
        },
        {
            "Value": "18",
            "Text": "Belize "
        },
        {
            "Value": "19",
            "Text": "Benin "
        },
        {
            "Value": "200",
            "Text": "Bermuda (UK)"
        },
        {
            "Value": "20",
            "Text": "Bhutan "
        },
        {
            "Value": "21",
            "Text": "Bolivia "
        },
        {
            "Value": "201",
            "Text": "Bonaire (NL)"
        },
        {
            "Value": "22",
            "Text": "Bosnia and Herzegovina "
        },
        {
            "Value": "23",
            "Text": "Botswana "
        },
        {
            "Value": "24",
            "Text": "Brazil "
        },
        {
            "Value": "202",
            "Text": "British Virgin Islands (UK)"
        },
        {
            "Value": "25",
            "Text": "Brunei "
        },
        {
            "Value": "26",
            "Text": "Bulgaria "
        },
        {
            "Value": "27",
            "Text": "Burkina Faso "
        },
        {
            "Value": "203",
            "Text": "Burma"
        },
        {
            "Value": "28",
            "Text": "Burundi "
        },
        {
            "Value": "29",
            "Text": "Cambodia "
        },
        {
            "Value": "30",
            "Text": "Cameroon "
        },
        {
            "Value": "31",
            "Text": "Canada "
        },
        {
            "Value": "32",
            "Text": "Cape Verde "
        },
        {
            "Value": "204",
            "Text": "Cayman Islands (UK)"
        },
        {
            "Value": "33",
            "Text": "Central African Republic "
        },
        {
            "Value": "34",
            "Text": "Chad "
        },
        {
            "Value": "35",
            "Text": "Chile "
        },
        {
            "Value": "36",
            "Text": "China "
        },
        {
            "Value": "205",
            "Text": "Christmas Island (AU)"
        },
        {
            "Value": "206",
            "Text": "Cocos Island (AU)"
        },
        {
            "Value": "37",
            "Text": "Colombia "
        },
        {
            "Value": "38",
            "Text": "Comoros "
        },
        {
            "Value": "39",
            "Text": "Congo (Brazzaville) "
        },
        {
            "Value": "40",
            "Text": "Congo, Democratic Republic of the "
        },
        {
            "Value": "207",
            "Text": "Cook Islands (NZ)"
        },
        {
            "Value": "41",
            "Text": "Costa Rica "
        },
        {
            "Value": "42",
            "Text": "Cote d'Ivoire "
        },
        {
            "Value": "43",
            "Text": "Croatia "
        },
        {
            "Value": "44",
            "Text": "Cuba "
        },
        {
            "Value": "208",
            "Text": "Curacao (NL)"
        },
        {
            "Value": "45",
            "Text": "Cyprus "
        },
        {
            "Value": "46",
            "Text": "Czech Republic "
        },
        {
            "Value": "47",
            "Text": "Denmark "
        },
        {
            "Value": "48",
            "Text": "Djibouti "
        },
        {
            "Value": "49",
            "Text": "Dominica "
        },
        {
            "Value": "50",
            "Text": "Dominican Republic "
        },
        {
            "Value": "51",
            "Text": "East Timor (Timor Timur) "
        },
        {
            "Value": "52",
            "Text": "Ecuador "
        },
        {
            "Value": "53",
            "Text": "Egypt "
        },
        {
            "Value": "54",
            "Text": "El Salvador "
        },
        {
            "Value": "55",
            "Text": "Equatorial Guinea "
        },
        {
            "Value": "56",
            "Text": "Eritrea "
        },
        {
            "Value": "57",
            "Text": "Estonia "
        },
        {
            "Value": "58",
            "Text": "Ethiopia "
        },
        {
            "Value": "210",
            "Text": "Falkland Islands (UK)"
        },
        {
            "Value": "211",
            "Text": "Faroe Islands (DK)"
        },
        {
            "Value": "59",
            "Text": "Fiji "
        },
        {
            "Value": "60",
            "Text": "Finland "
        },
        {
            "Value": "61",
            "Text": "France "
        },
        {
            "Value": "212",
            "Text": "French Guyana (FR)"
        },
        {
            "Value": "213",
            "Text": "French Polynesia (FR)"
        },
        {
            "Value": "214",
            "Text": "French Southern and Antarctic Lands (FR)"
        },
        {
            "Value": "62",
            "Text": "Gabon "
        },
        {
            "Value": "63",
            "Text": "Gambia"
        },
        {
            "Value": "64",
            "Text": "Georgia "
        },
        {
            "Value": "65",
            "Text": "Germany "
        },
        {
            "Value": "66",
            "Text": "Ghana"
        },
        {
            "Value": "67",
            "Text": "Greece "
        },
        {
            "Value": "215",
            "Text": "Greenland"
        },
        {
            "Value": "68",
            "Text": "Grenada "
        },
        {
            "Value": "216",
            "Text": "Guadeloupe (FR)"
        },
        {
            "Value": "217",
            "Text": "Guam (US)"
        },
        {
            "Value": "69",
            "Text": "Guatemala "
        },
        {
            "Value": "218",
            "Text": "Guernsey (UK)"
        },
        {
            "Value": "70",
            "Text": "Guinea "
        },
        {
            "Value": "71",
            "Text": "Guinea-Bissau "
        },
        {
            "Value": "72",
            "Text": "Guyana "
        },
        {
            "Value": "73",
            "Text": "Haiti "
        },
        {
            "Value": "219",
            "Text": "Heard and McDonald Islands (AU)"
        },
        {
            "Value": "74",
            "Text": "Honduras "
        },
        {
            "Value": "221",
            "Text": "Hong Kong"
        },
        {
            "Value": "75",
            "Text": "Hungary "
        },
        {
            "Value": "76",
            "Text": "Iceland "
        },
        {
            "Value": "77",
            "Text": "India "
        },
        {
            "Value": "78",
            "Text": "Indonesia "
        },
        {
            "Value": "79",
            "Text": "Iran "
        },
        {
            "Value": "80",
            "Text": "Iraq "
        },
        {
            "Value": "81",
            "Text": "Ireland "
        },
        {
            "Value": "222",
            "Text": "Isle of Man (UK)"
        },
        {
            "Value": "82",
            "Text": "Israel "
        },
        {
            "Value": "83",
            "Text": "Italy "
        },
        {
            "Value": "84",
            "Text": "Jamaica "
        },
        {
            "Value": "85",
            "Text": "Japan "
        },
        {
            "Value": "223",
            "Text": "Jersey (UK)"
        },
        {
            "Value": "86",
            "Text": "Jordan "
        },
        {
            "Value": "87",
            "Text": "Kazakhstan "
        },
        {
            "Value": "88",
            "Text": "Kenya "
        },
        {
            "Value": "89",
            "Text": "Kiribati "
        },
        {
            "Value": "224",
            "Text": "Kosovo"
        },
        {
            "Value": "92",
            "Text": "Kuwait "
        },
        {
            "Value": "93",
            "Text": "Kyrgyzstan "
        },
        {
            "Value": "94",
            "Text": "Laos "
        },
        {
            "Value": "95",
            "Text": "Latvia "
        },
        {
            "Value": "96",
            "Text": "Lebanon "
        },
        {
            "Value": "97",
            "Text": "Lesotho "
        },
        {
            "Value": "98",
            "Text": "Liberia "
        },
        {
            "Value": "99",
            "Text": "Libya "
        },
        {
            "Value": "100",
            "Text": "Liechtenstein "
        },
        {
            "Value": "101",
            "Text": "Lithuania "
        },
        {
            "Value": "102",
            "Text": "Luxembourg "
        },
        {
            "Value": "227",
            "Text": "Macedonia"
        },
        {
            "Value": "103",
            "Text": "Macedonia, Former Yugoslav Republic of "
        },
        {
            "Value": "104",
            "Text": "Madagascar "
        },
        {
            "Value": "105",
            "Text": "Malawi "
        },
        {
            "Value": "106",
            "Text": "Malaysia "
        },
        {
            "Value": "107",
            "Text": "Maldives "
        },
        {
            "Value": "108",
            "Text": "Mali "
        },
        {
            "Value": "109",
            "Text": "Malta "
        },
        {
            "Value": "110",
            "Text": "Marshall Islands "
        },
        {
            "Value": "228",
            "Text": "Martinica (FR)"
        },
        {
            "Value": "111",
            "Text": "Mauritania "
        },
        {
            "Value": "112",
            "Text": "Mauritius "
        },
        {
            "Value": "113",
            "Text": "Mexico "
        },
        {
            "Value": "229",
            "Text": "Micronesia"
        },
        {
            "Value": "115",
            "Text": "Moldova "
        },
        {
            "Value": "116",
            "Text": "Monaco "
        },
        {
            "Value": "117",
            "Text": "Mongolia "
        },
        {
            "Value": "231",
            "Text": "Montenegro"
        },
        {
            "Value": "232",
            "Text": "Montserrat (UK)"
        },
        {
            "Value": "118",
            "Text": "Morocco "
        },
        {
            "Value": "119",
            "Text": "Mozambique "
        },
        {
            "Value": "120",
            "Text": "Myanmar "
        },
        {
            "Value": "121",
            "Text": "Namibia "
        },
        {
            "Value": "122",
            "Text": "Nauru "
        },
        {
            "Value": "123",
            "Text": "Nepal "
        },
        {
            "Value": "124",
            "Text": "Netherlands "
        },
        {
            "Value": "233",
            "Text": "New Caledonia (FR)"
        },
        {
            "Value": "125",
            "Text": "New Zealand "
        },
        {
            "Value": "126",
            "Text": "Nicaragua "
        },
        {
            "Value": "127",
            "Text": "Niger "
        },
        {
            "Value": "128",
            "Text": "Nigeria "
        },
        {
            "Value": "234",
            "Text": "Niue (NZ)"
        },
        {
            "Value": "90",
            "Text": "North Korea "
        },
        {
            "Value": "235",
            "Text": "Northern Mariana Islands (US)"
        },
        {
            "Value": "129",
            "Text": "Norway "
        },
        {
            "Value": "130",
            "Text": "Oman "
        },
        {
            "Value": "131",
            "Text": "Pakistan "
        },
        {
            "Value": "132",
            "Text": "Palau "
        },
        {
            "Value": "236",
            "Text": "Palestine"
        },
        {
            "Value": "133",
            "Text": "Panama "
        },
        {
            "Value": "134",
            "Text": "Papua New Guinea "
        },
        {
            "Value": "135",
            "Text": "Paraguay "
        },
        {
            "Value": "136",
            "Text": "Peru "
        },
        {
            "Value": "137",
            "Text": "Philippines "
        },
        {
            "Value": "237",
            "Text": "Pitcairn Islands (UK)"
        },
        {
            "Value": "138",
            "Text": "Poland "
        },
        {
            "Value": "139",
            "Text": "Portugal "
        },
        {
            "Value": "238",
            "Text": "Puerto Rico (US)"
        },
        {
            "Value": "140",
            "Text": "Qatar "
        },
        {
            "Value": "141",
            "Text": "Romania "
        },
        {
            "Value": "142",
            "Text": "Russia "
        },
        {
            "Value": "143",
            "Text": "Rwanda "
        },
        {
            "Value": "239",
            "Text": "Saba (NL)"
        },
        {
            "Value": "240",
            "Text": "Saint Barthélemy (FR)"
        },
        {
            "Value": "243",
            "Text": "Saint Eustatius (NL)"
        },
        {
            "Value": "144",
            "Text": "Saint Kitts and Nevis "
        },
        {
            "Value": "145",
            "Text": "Saint Lucia "
        },
        {
            "Value": "244",
            "Text": "Saint Maarten (NL)"
        },
        {
            "Value": "241",
            "Text": "Saint Martin (FR)"
        },
        {
            "Value": "242",
            "Text": "Saint Pierre and Miquelon (FR)"
        },
        {
            "Value": "146",
            "Text": "Saint Vincent and The Grenadines "
        },
        {
            "Value": "147",
            "Text": "Samoa "
        },
        {
            "Value": "148",
            "Text": "San Marino "
        },
        {
            "Value": "149",
            "Text": "Sao Tome and Principe "
        },
        {
            "Value": "150",
            "Text": "Saudi Arabia "
        },
        {
            "Value": "151",
            "Text": "Senegal "
        },
        {
            "Value": "152",
            "Text": "Serbia and Montenegro "
        },
        {
            "Value": "153",
            "Text": "Seychelles "
        },
        {
            "Value": "154",
            "Text": "Sierra Leone "
        },
        {
            "Value": "155",
            "Text": "Singapore "
        },
        {
            "Value": "156",
            "Text": "Slovakia "
        },
        {
            "Value": "157",
            "Text": "Slovenia "
        },
        {
            "Value": "158",
            "Text": "Solomon Islands "
        },
        {
            "Value": "159",
            "Text": "Somalia "
        },
        {
            "Value": "160",
            "Text": "South Africa "
        },
        {
            "Value": "245",
            "Text": "South Georgia Islands (UK)"
        },
        {
            "Value": "91",
            "Text": "South Korea"
        },
        {
            "Value": "246",
            "Text": "South Sandwich Islands (UK)"
        },
        {
            "Value": "247",
            "Text": "South Sudan"
        },
        {
            "Value": "161",
            "Text": "Spain "
        },
        {
            "Value": "162",
            "Text": "Sri Lanka "
        },
        {
            "Value": "163",
            "Text": "Sudan "
        },
        {
            "Value": "164",
            "Text": "Suriname "
        },
        {
            "Value": "165",
            "Text": "Swaziland "
        },
        {
            "Value": "166",
            "Text": "Sweden "
        },
        {
            "Value": "167",
            "Text": "Switzerland "
        },
        {
            "Value": "168",
            "Text": "Syria "
        },
        {
            "Value": "169",
            "Text": "Taiwan "
        },
        {
            "Value": "170",
            "Text": "Tajikistan "
        },
        {
            "Value": "171",
            "Text": "Tanzania "
        },
        {
            "Value": "172",
            "Text": "Thailand "
        },
        {
            "Value": "173",
            "Text": "Togo "
        },
        {
            "Value": "248",
            "Text": "Tokelau (NZ)"
        },
        {
            "Value": "174",
            "Text": "Tonga "
        },
        {
            "Value": "175",
            "Text": "Trinidad and Tobago "
        },
        {
            "Value": "176",
            "Text": "Tunisia "
        },
        {
            "Value": "177",
            "Text": "Turkey "
        },
        {
            "Value": "178",
            "Text": "Turkmenistan "
        },
        {
            "Value": "249",
            "Text": "Turks and Caicos (UK)"
        },
        {
            "Value": "179",
            "Text": "Tuvalu "
        },
        {
            "Value": "180",
            "Text": "Uganda "
        },
        {
            "Value": "250",
            "Text": "Ukraine"
        },
        {
            "Value": "181",
            "Text": "United Arab Emirates "
        },
        {
            "Value": "182",
            "Text": "United Kingdom "
        },
        {
            "Value": "183",
            "Text": "United States "
        },
        {
            "Value": "251",
            "Text": "United States Virgin Islands (US)"
        },
        {
            "Value": "184",
            "Text": "Uruguay "
        },
        {
            "Value": "185",
            "Text": "Uzbekistan "
        },
        {
            "Value": "186",
            "Text": "Vanuatu "
        },
        {
            "Value": "187",
            "Text": "Vatican City "
        },
        {
            "Value": "188",
            "Text": "Venezuela "
        },
        {
            "Value": "189",
            "Text": "Vietnam "
        },
        {
            "Value": "196",
            "Text": "Wallis and Futuna (FR)"
        },
        {
            "Value": "191",
            "Text": "Yemen "
        },
        {
            "Value": "192",
            "Text": "Zambia "
        },
        {
            "Value": "193",
            "Text": "Zimbabwe "
        }
    ]
}

export function getRelationship() {
    return [
        {
            "Value": "8",
            "Text": "Aunty"
        },
        {
            "Value": "3",
            "Text": "Brother"
        },
        {
            "Value": "2",
            "Text": "Child"
        },
        {
            "Value": "10",
            "Text": "Cousin"
        },
        {
            "Value": "13",
            "Text": "Daughter"
        },
        {
            "Value": "6",
            "Text": "Father"
        },
        {
            "Value": "5",
            "Text": "Mother"
        },
        {
            "Value": "12",
            "Text": "Nephew"
        },
        {
            "Value": "11",
            "Text": "Niece"
        },
        {
            "Value": "9",
            "Text": "Other"
        },
        {
            "Value": "4",
            "Text": "Sister"
        },
        {
            "Value": "14",
            "Text": "Son"
        },
        {
            "Value": "1",
            "Text": "Spouse"
        },
        {
            "Value": "7",
            "Text": "Uncle"
        },
        {
            "Value": "17",
            "Text": "Wife"
        }
    ]
}

export function getSourceOfWealth() {
    return [
        {
            "Value": "3",
            "Text": "Bonus/Gift"
        },
        {
            "Value": "2",
            "Text": "Business"
        },
        {
            "Value": "4",
            "Text": "Inheritance"
        },
        {
            "Value": "5",
            "Text": "Other"
        },
        {
            "Value": "1",
            "Text": "Salary"
        }
    ]
}

export function getDiscovery() {
    return [
        {
            "Value": "2",
            "Text": "Direct Sales Staff"
        },
        {
            "Value": "5",
            "Text": "Newspaper"
        },
        {
            "Value": "1",
            "Text": "Online Advertisment"
        },
        {
            "Value": "4",
            "Text": "Radio"
        },
        {
            "Value": "6",
            "Text": "Referral "
        },
        {
            "Value": "3",
            "Text": "Television"
        }
    ]
}

export function getIncomeClass() {
    return [
        {
            "Value": "1",
            "Text": "0-1 Million"
        },
        {
            "Value": "2",
            "Text": "1-5 Million"
        },
        {
            "Value": "4",
            "Text": "10-50 Million"
        },
        {
            "Value": "3",
            "Text": "5-10 Million"
        },
        {
            "Value": "5",
            "Text": "Above 50 Million"
        }
    ]
}

export function getAccountType() {
    return [
        {
            "Value": "1",
            "Text": "Individual"
        },
        {
            "Value": "2",
            "Text": "Joint"
        },
        {
            "Value": "3",
            "Text": "Institution"
        },
        {
            "Value": "4",
            "Text": "Corporate"
        },
        {
            "Value": "5",
            "Text": "Sponsor"
        }
    ]
}
