import React, {Component} from 'react';
import {userService} from '../../_services'
import {Link} from 'react-router-dom'
import API from '../../api';
import $ from 'jquery';
import {hideLoader, showLoader, toast} from "../../_helpers/Loader";
import LOGO from "../../assets2/img/logo-white.png";
import {modal} from 'bootstrap';
import Fade from 'react-reveal/Fade';

window.jQuery = $;
window.$ = $;
global.jQuery = $;

class SignUp extends Component {

    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            errors: {},
            signup_page: 1,
            tgem_form: {},
        };

        userService.logout();

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleSubmit(event) {
        event.preventDefault();
        const postData = {...this.state.tgem_form};
        postData['user_type'] = 'twelve';
        postData['username'] = postData.email;
        postData['password2'] = postData.password1;
        showLoader();
        try {
            let res = await API.post('rest-auth/registration/', postData);
            hideLoader();
            toast.success("Account Successfully Created");
            if (res.status === 201) {
                this.setState({page: 3});
                $(".show-modal").click();
                $(".modal").css('z-index', 1048);
            }
            setTimeout(() => {
                this.props.history.push("/login")
            }, 7000)
        } catch (e) {
            this.setState({errors: e.response.data});
            if (e.response.status === 500) {
                hideLoader();
                $('#redirectModal').modal('show');
            } else {
                hideLoader();
                toast.error("Something went wrong!");
            }
        }
    }


    redirect = (event) => {
        event.preventDefault();
        if (event.target.option.value === 1) {
            window.location.href = 'https://thetgiclub.com/';
        } else {
            window.location.href = 'https://twelvevest.com/financial-networth/join-waiting-page'
        }
    };


    render() {
        return (
            <div id="app">
                <section className="section section-2"
                         style={(this.state.page === 3) ? ({display: 'none'}) : {display: ''}}>
                    <div className="row">
                        <div className="single-page single-pageimage construction-bg cover-image">
                            <div className="row">
                                <div className="col-lg-6 tgem-signup-image">
                                    <div className="log-wrapper text-center img-container">
                                        <img src={LOGO} width="50%"
                                             className="mb-2 mt-4 mt-lg-0 mb-5" alt="logo"/>
                                        <div className="overlay text-center">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="wrapper wrapper2">
                                        <form onSubmit={this.handleSubmit} className="card-body form-body-sign-up"
                                              tabIndex="500">
                                            <h3 className="text-dark">Sign Up</h3>
                                            <div className="fname text-left">
                                                <input value={this.state.tgem_form.first_name}
                                                       onChange={event => this.setState({
                                                           tgem_form: {
                                                               ...this.state.tgem_form,
                                                               first_name: event.target.value
                                                           }
                                                       })}
                                                       required type="text" name="first_name"/>
                                                <label>First Name</label>
                                            </div>
                                            <div className="lname text-left">
                                                <input value={this.state.tgem_form.last_name}
                                                       onChange={event => this.setState({
                                                           tgem_form: {
                                                               ...this.state.tgem_form,
                                                               last_name: event.target.value
                                                           }
                                                       })}
                                                       required type="text" name="last_name"/>
                                                <label>Last Name</label>
                                            </div>
                                            <div className="name text-left">
                                                <input value={this.state.tgem_form.email}
                                                       onChange={event => this.setState({
                                                           tgem_form: {
                                                               ...this.state.tgem_form,
                                                               email: event.target.value,
                                                               username: event.target.value
                                                           }
                                                       })}
                                                       autoComplete="email" required type="email" name="email"/>
                                                <label>Email</label>
                                                {(this.state.errors.email) ? (this.state.errors.email.map(item => (
                                                        <small className="text-danger">{item}</small>
                                                    ))) :
                                                    ''}
                                            </div>
                                            <br/>
                                            <div className="passwd text-left">
                                                <input value={this.state.tgem_form.password1}
                                                       onChange={event => this.setState({
                                                           tgem_form: {
                                                               ...this.state.tgem_form,
                                                               password1: event.target.value
                                                           }
                                                       })}
                                                       autoComplete="current-password" required type="password"
                                                       name="password"/>
                                                <label>Password</label>
                                                {(this.state.errors.password1) ? (this.state.errors.password1.map(item => (
                                                        <small className="text-danger">{item}</small>
                                                    ))) :
                                                    ''}
                                            </div>
                                            <small>
                                                <ul style={{listStyle: 'none'}}
                                                    className="list-group text-left">
                                                    <li className="">Your password can't be too similar to your
                                                        other
                                                        personal information such as first name, last name and
                                                        email
                                                        address.
                                                    </li>
                                                    <li className="">Your password must contain at least 8
                                                        characters.
                                                    </li>
                                                    <li className="">Your password can't be a commonly used
                                                        password.
                                                    </li>
                                                    <li className="">Your password can't be entirely numeric.
                                                    </li>
                                                </ul>
                                            </small>
                                            <br/>
                                            <div class="form-check form-check-inline">
                                                <label class="form-check-label">
                                                    <input class="form-check-input" type="checkbox" name="" id="" value="checkedValue" required /> Read and accept our <a href="https://twelvevest.com/termsandconditions/"><u className="twelve-green">Terms &amp; Conditions</u></a> , <a href="https://twelvevest.com/privacypolicy/"><u className="twelve-green">Privacy Policy</u></a> and <a href="https://twelvevest.com/EULA/"><u className="twelve-green">EULA</u></a>
                                                </label>
                                            </div>

                                            <div className="submit">
                                                <button type="submit" className="btn btn-blue text-white btn-block">Submit
                                                </button>
                                            </div>
                                            <p className="text-dark mb-0">Have an account?<Link to="/login"
                                                                                                className="ml-1">Login</Link>
                                            </p>
                                            <button id="show-modal" hidden type="button" className="btn btn-primary "
                                                    data-toggle="modal"
                                                    data-target="#exampleModal">Default modal
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="modal fade show" id="exampleModal" tabIndex="-1" role="dialog"
                     aria-labelledby="exampleModalLabel" style={{paddingRight: "16px", display: "block", zIndex: "0"}}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body text-center">
                                <p className="mb-0">Thank you for signing up.<br/>A confirmation message has been sent
                                    to your mail. Check your spam folder if not found in your inbox.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SignUp;
