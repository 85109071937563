import React from 'react';

const Tos = (props) => {
  return (
    <div className="modal fade" id="tosModal" tabIndex="-1" role="dialog"
    aria-labelledby="tosModalLabel">
      <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
              <div className="modal-header bg-green">
                  <h5 className="modal-title" id="exampleModalLabel">Please Read (Important)</h5>
                  <button id="close-modal" type="button" className="close" data-dismiss="modal"
                          aria-label="Close">
                      <span aria-hidden="true">×</span>
                  </button>
              </div>
              <div className="modal-body">
                <div style={{height: "500px", overflowY: "auto", padding: "10px"}}>
                  <h1><span ><span style={{fontSize: '16pt'}}><span style={{color: '#2f5496'}}><strong>Terms of Service</strong></span></span></span></h1>
                    <p style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><strong><span style={{fontSize: '10.0pt'}}>Terms and Conditions</span></strong></span></span></p>
                    <p style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>Please read these Terms and Conditions (“Terms”), which set forth the legally binding terms and conditions between you and TwelveVest Ltd (“Twelvevest or the Company”).&nbsp; It governs your access to and the use of Twelvevest’s website (the “Site”) and all services (the “Service”) offered by Twelvevest. </span></span></span></p>
                    <p style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>Throughout the Site, the terms “we”, “us” and “our” refer to Twelvevest.<br />
                            <br />
                            Our collection and use of personal information in connection with your access to and use of the Service is described in our <strong>Privacy Policy</strong>.</span></span></span></p>
                    <p style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>Your access to use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service and all applicable laws and all conditions or policies referenced here.&nbsp; </span></span></span></p>
                    <p style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the Terms then you may not access the Site or use the Service. </span></span></span></p>
                    <p style={{textAlign: 'justify'}}>&nbsp;</p>
                    <ol>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><strong><span style={{fontSize: '10.0pt'}}>Acceptance of Terms</span></strong></span></span></li>
                    </ol>
                    <p style={{marginLeft: '24px', textAlign: 'justify'}}><br />
                      <span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>The Service is offered subject to acceptance of all the terms and conditions contained in these Terms and all other operating rules, policies and procedures that may be published on the Site by the Company, which are incorporated by reference, including operating rules, policies and procedures of third-party service providers to the Site that are referenced herein. These Terms apply to every user of the Service.<br />
                            <br />
                            In addition, some Services offered through the Site may be subject to additional terms and conditions adopted by the Company. Your use of those services is subject to those additional terms and conditions, which are incorporated into these Terms by this reference.</span></span></span><br />
                      &nbsp;</p>
                    <ol start={2}>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><strong><span style={{fontSize: '10.0pt'}}>Interruption and Termination of Service</span></strong></span></span><br />
                        &nbsp;</li>
                    </ol>
                    <p style={{marginLeft: '24px', textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>The Company reserves the right, at its sole discretion, to modify or replace these Terms from time to time by posting the updated terms on the Site. It is your responsibility to check the Terms periodically for changes. If you object to such changes, your sole recourse will be to cease using the Site and the Service.&nbsp; Your continued use of the Service following the posting of changes to the Terms will indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes.</span></span></span></p>
                    <p style={{marginLeft: '24px', textAlign: 'justify'}}>&nbsp;</p>
                    <p style={{marginLeft: '24px', textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>The Company reserves the right to change, suspend, or discontinue the Service (including but not limited to, the availability of any feature, database, or content) at any time for any reason. The Company may also impose limits on certain features and Services or restrict your access to parts of or the entire Site without notice or liability.<br />
                            &nbsp;</span></span></span></p>
                    <p style={{marginLeft: '48px', textAlign: 'justify'}}>&nbsp;</p>
                    <ol start={3}>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><strong><span style={{fontSize: '10.0pt'}}>Purchases , Trades , Trade Errors and other Cancellations</span></strong></span></span></li>
                    </ol>
                    <p style={{marginLeft: '24px', textAlign: 'justify'}}>&nbsp;</p>
                    <p style={{marginLeft: '24px', textAlign: 'justify'}}><span ><strong><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}>3.1 If you wish to purchase any investment product or service made available through the Service (“Purchase”), you may be asked to supply certain information relevant to your Purchase including, without limitation, your debit card number, the expiration date of your debit card and your billing address.</span></span></span></strong></span></p>
                    <p style={{marginLeft: '24px', textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}>You represent and warrant that: (i) you have the legal right to use any debit card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete.&nbsp;</span></span></span></span></p>
                    <p style={{marginLeft: '24px', textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}>By submitting such information, you grant us the right to provide the information to third parties for purposes of facilitating the completion of Purchases.</span></span></span></span></p>
                    <p style={{marginLeft: '24px', textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}>None of the information contained on this Site is intended to constitute any offer to sell or an invitation or solicitation of an offer to buy any product or Service offered by Twelvevest and must not be relied upon in connection with any investment decision. Nothing in this Site should be construed as investment, tax, legal or other advice. You are advised to verify the accuracy of any information on the website before relying on it. </span></span></span></span></p>
                    <p style={{marginLeft: '24px', textAlign: 'justify'}}>&nbsp;</p>
                    <p style={{marginLeft: '24px', textAlign: 'justify'}}><span ><strong><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}>3.2 Trade Limits</span></span></span></strong></span></p>
                    <p style={{marginLeft: '24px', textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.5pt'}}><span style={{backgroundColor: 'white'}}><span style={{color: 'black'}}>Twelvevest shall profile each Client / User of the Twelvevest platform. Each Client's risk profile shall be determined based on the client’s source of funds, age, expected investment turnover / portfolio value and other information provided by the Client during the registration process. Any attempt by a user to transact and/or trade in a volume / value that is inconsistent with their risk profile shall have the trade temporarily restricted until the client’s identity and source of funds are proven to forestall and address fraud and AML/CFT related matters.</span></span></span>&nbsp;</span></span></span></p>
                    <p style={{marginLeft: '24px', textAlign: 'justify'}}>&nbsp;</p>
                    <p style={{marginLeft: '24px', textAlign: 'justify'}}><span ><strong><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}>3.3 Trade Errors and Cancellations</span></span></span></strong></span></p>
                    <p style={{marginLeft: '24px', textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}>&nbsp;The user shall be obligated to notify Twelvevest in occasions of any trade errors which occur by their error no later than two (2) hours from the time of the trade for a correction to be effected. Failure to do so within two (2) hours from the time of the trade will result in a final registration and documentation of that trade in the volume and value which was made by the user.</span></span></span></span></p>
                    <p style={{marginLeft: '24px', textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}>In the case that Twelvevest makes an error on trades, resolution shall be effected within four (4) hours, with or without notification by the affected user.</span></span></span></span></p>
                    <ol start={4}>
                      <li style={{textAlign: 'justify'}}><span ><strong><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>Accounts</span></span></strong></span>
                        <ol>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>In order to purchase an investment product or use any of our Services, you shall be required to create a Twelvevest investor account or a Twelvevest investment club account (collectively referred to as “Twelvevest Account”).</span>This is referred to as the User / Client Registration process.</span></span></li>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>If you are registering a TwelveVest Account for a company or other legal entity, you represent and warrant that you have the authority to legally bind that entity and grant us all permissions and licenses provided in these Terms.</span></span></span></li>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>If you are registering a Twelvevest investment club account, you will need to have at least two members before you can register. </span></span></span></li>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>You can register a Twelvevest Account using an email address, creating a password and providing any extra information as requested on the sign-up page.</span></span></span></li>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>You must provide accurate, current and complete information during the registration process and keep your Twelvevest Account up-to-date at all times.</span></span></span></li>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party Service. You agree not to disclose your password to any third party.</span></span></span></li>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>You agree to notify us immediately upon becoming aware of any breach of security or unauthorized use of your Twelvevest Account. </span></span></span></li>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>You may not use as a username, the name of another person or entity or that is not lawfully available for use, a name or trade mark that is subject to any rights of another person or entity other than you without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.</span></span></span></li>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>You are liable for any and all activities conducted through your Twelvevest Account, unless such activities are not authorized by you and you are not otherwise negligent (such as failing to report the unauthorized use or loss of your credentials).</span></span></span></li>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>You agree to strictly observe the security and authentication procedures of the website/service and you will log out from the website by taking proper steps at the end of every visit.</span></span></span></li>
                        </ol>
                      </li>
                    </ol>
                    <p style={{marginLeft: '53px', textAlign: 'justify'}}>&nbsp;</p>
                    <ol start={5}>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><strong><span style={{fontSize: '10.0pt'}}>Users’ Representation and Warranties</span></strong></span></span></li>
                    </ol>
                    <p style={{marginLeft: '48px', textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>Representations and warranties are statements and promises made by you to Twelvevest, which we rely on as being accurate in our dealings with you. You make the following representations and warranties to us at the time of agreeing to these Terms and every time you use our Service;</span></span></span></p>
                    <ol style={{listStyleType: 'lower-alpha'}}>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>You are over the age of 18 years;</span></span></span></li>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>You are of a sound mind and have to capacity to enter into a legally binding contract;</span></span></span></li>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>All personal Information you provided about yourself is accurate and true to the best of your knowledge;</span></span></span></li>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>You have carefully considered the risks involved with using our Service, joining an investment club and in making investments or purchasing any investment product as provided on the Site;</span></span></span></li>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>if you are registering or accepting these Terms on behalf of a legal entity such as a company, trust or partnership, you are legally authorised to do so and we may request evidence of such legal entitlement (by way of a copy of any document which shows the valid and subsisting authorisation); </span></span></span></li>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>you will obtain such professional advice as is appropriate to protect your interests, including legal, tax, accounting and other advice; and</span></span></span></li>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>you are not breaching any laws or regulations that are applicable to you or any company, trust or partnership upon whose instructions you are acting.&nbsp; </span></span></span></li>
                    </ol>
                    <p style={{marginLeft: '48px', textAlign: 'justify'}}>&nbsp;</p>
                    <ol start={6}>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><strong><span style={{fontSize: '10.0pt'}}>Investment Club</span></strong></span></span>
                        <ol>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>You can access our Service or purchase an investment product by forming an investment club on the Site.&nbsp; </span></span></span></li>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>If you already have an investment club, you will need to register a Twelvevest investment club account before accessing our Service. </span></span></span></li>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>If you are registering a Twelvevest investment club account, you will need to have at least two members before you can register.</span></span></span></li>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>All investment clubs on our platform are open-ended which means that people who visit and use our Service can join these clubs. </span></span></span></li>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>You can also invite new members to join your investment club.</span></span></span></li>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>New members who join any investment club will be bound by these Terms. </span></span></span></li>
                        </ol>
                      </li>
                    </ol>
                    <p style={{marginLeft: '96px', textAlign: 'justify'}}>&nbsp;</p>
                    <ol start={7}>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><strong><span style={{fontSize: '10.0pt'}}>Acknowledgement of Risk by Investor and Investment Club</span></strong></span></span>
                        <ol>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>All investments carry risk and all investment decisions of an individual investor or an investment club remain the responsibility of the individual investor or the investment club. There is no guarantee that the investment made will result in profits or that they will not result in losses. We encourage investors to get personal advice from professional investment advisors.</span></span></span></li>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>Twelvevest is not a financial or investment advisor and has not held itself or represented itself as one.</span></span></span></li>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>You acknowledge that you will only invest after carefully reviewing and assessing the terms of the investment.</span></span></span></li>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>You acknowledge that you are aware of the risk of making an investment.</span></span></span></li>
                        </ol>
                      </li>
                    </ol>
                    <p style={{marginLeft: '57px', textAlign: 'justify'}}>&nbsp;</p>
                    <ol start={8}>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><strong><span style={{fontSize: '10.0pt'}}>Intellectual Property</span></strong></span></span>
                        <ol>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>The Service and its original content, features and functionality are and will remain the exclusive property of Twelvevest Ltd. Our trademarks may not be used in connection with any product or service without the prior written consent of Twelvevest Ltd. </span></span></span></li>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>Twelvevest at its sole discretion, enables users to (i) create, upload, post, send, receive and store content, such as text, photos, audio, video, or other materials and information on or through the Site ("User Content"); and (ii) access and view User Content and any content that Twelvevest itself makes available on or through the Platform, including proprietary Twelvevest’s content and any content licensed or authorized for use by or through Twelvevest from a third party (“Twelvevest’s content” and together with user content, “Collective Content”).</span></span></span></li>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>You will not use, copy, adapt, modify, prepare derivative works of, distribute license, sell, transfer, publicly display, publicly perform, transmit, broadcast or otherwise exploit the Site or Collective Content, except to the extent that you are the legal owner of certain User Content or as expressly permitted in these Terms. No licenses or rights are granted to you by implication or otherwise under any intellectual property rights owned or controlled by Twelvevest or its licensors, except for the licenses and rights expressly granted in these Terms.</span></span></span></li>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>Subject to your compliance with these Terms, Twelvevest grants you a limited, non-exclusive, non-sub licensable, revocable, non-transferable licence to access and view any Collective Content made available on or through the Site and accessible to you, solely for your personal and non-commercial use.</span></span></span></li>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>Users shall not directly or indirectly: (i) decipher, decompile, disassemble, reverse engineer, or otherwise attempt to derive any source code or underlying ideas or algorithms of any part of the Service, except to the extent applicable laws specifically prohibit such restriction; (ii) modify, translate, or otherwise create derivative works of any part of the Service; or (iii) copy, rent, lease, distribute, or otherwise transfer any of the rights that you receive hereunder.</span></span></span></li>
                        </ol>
                      </li>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><strong><span style={{fontSize: '10.0pt'}}>Tax</span></strong></span></span></span></li>
                    </ol>
                    <p style={{marginLeft: '24px', textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}><span style={{backgroundColor: 'white'}}>The investment products and the Service offered by Twelvevest may have tax consequences. You acknowledge that Twelvevest does not provide tax advice. You should consult your own tax advisor in order to understand the tax consequences of the investment products and the Service described on the Site.</span></span></span></span></span></p>
                    <p style={{marginLeft: '96px', textAlign: 'justify'}}>&nbsp;</p>
                    <ol start={10}>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><strong><span style={{fontSize: '10.0pt'}}>Prohibited Activities.</span></strong> </span></span>
                        <ol>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>You shall not use the Service for any purpose that is prohibited by these Terms. You are responsible for all of your activity in connection with the Service. Violation of our rules may result in the termination and cancellation of your Twelvevest Account. You acknowledge and agree that we may terminate any Twelvevest Account at any time for any reason (including, but not limited to, our independent assessment or the receipt of claims or allegations from third parties or authorities).</span></span></span></li>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>Users shall not utilize the Site or the Service for any illegal purpose.</span></span></span></li>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>You are solely responsible for compliance with any and all laws, rules, regulations, and tax obligations that may apply to your use of the Service</span></span><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>. In connection with your use of the </span></span><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>Site, you will not and will not assist or enable others to:</span></span></span></span></li>
                        </ol>
                      </li>
                    </ol>
                    <ol style={{listStyleType: 'lower-alpha'}}>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>breach or circumvent any applicable laws or regulations, agreements with third parties, third-party rights, or our Terms;</span></span></span></li>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}>use the Site or Collective Content for any commercial or other purposes that are not expressly permitted by these Terms or in a manner that falsely implies endorsement, partnership or otherwise misleads others as to your affiliation with Twelvevest<span style={{color: 'black'}}>. </span></span></span></span></span></li>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>copy, store or otherwise access or use any information, including personally identifiable information about any other users, contained on the Site</span></span><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}> in any way that is inconsistent with</span></span><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}> Twelvevest’s</span></span> <span style={{fontSize: '10.0pt'}}>Privacy Policy&nbsp;<span style={{color: 'black'}}>or these Terms or that otherwise violates the privacy rights of users or third parties; </span></span></span></span></span></li>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>use the Site in connection with the distribution of unsolicited commercial messages ("spam");</span></span></span></span></span></li>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>offer as a trader;</span></span></span></span></span></li>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>use the Site with the intention to circumvent any Service fees or for any other reason;</span></span></span></span></span></li>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>request, accept or make any payment for orders outside of the Twelvevest Platform. If you do so, you acknowledge and agree that you: (i) would be in breach of these Terms; (ii) accept all risks and responsibility for such payment, and (iii) hold Twelvevest harmless from any liability for such payment; </span></span></span></span></span></li>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>discriminate against or harass anyone on the basis of race, tribe, origin, religion, gender, physical or mental disability, medical condition, marital status, or age, or otherwise engage in any abusive or disruptive behaviour;</span></span></span></span></span></li>
                      <li style={{textAlign: 'justify'}} value={1}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>&nbsp;use, display, mirror or frame the Platform or Collective Content, or any individual element within the Twelvevest </span></span><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>Platform, </span></span><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>Twelvevest </span></span><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>name, any </span></span><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>Twelvevest’s </span></span><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>trademark, logo or other proprietary information, or the layout and design of any page or form contained on a page in the </span></span><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>Twelvevest Platform, without Twelvevest’s express written consent; </span></span></span></span></span></li>
                      <li style={{textAlign: 'justify'}} value={10}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>dilute, tarnish or otherwise harm the Twelvevest brand in any way, including through unauthorized use of Collective Content, registering and/or using Twelvevest or derivative terms in domain names, trade names, trademarks or other source identifiers, or registering and/or using domains names, trade names, trademarks or other source identifiers that closely imitate or are confusingly similar to Twelvevest domains, trademarks, taglines, promotional campaigns or Collective Content; </span></span></span></span></span></li>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>use any robots, spider, crawler, scraper or other automated means or processes to access, collect data or other content from or otherwise interact with the Platform for any purpose;</span></span></span></span></span></li>
                      <li style={{textAlign: 'justify'}} value={50}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>avoid, bypass, remove, deactivate, impair, descramble, or otherwise attempt to circumvent any technological measure implemented by Twelvevest or any of Twelvevests’ providers or any other third party to protect the Twelvevest Platform;</span></span></span></span></span></li>
                      <li style={{textAlign: 'justify'}} value={1000}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the Twelvevest Platform;</span></span></span></span></span></li>
                      <li style={{textAlign: 'justify'}} value={14}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>take any action that damages or adversely affects, or could damage or adversely affect the performance or proper functioning of the</span></span> <span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>Platform;</span></span></span></span></span></li>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>violate or infringe anyone else’s rights or otherwise cause harm to anyone.</span></span></span></span></span></li>
                    </ol>
                    <p style={{marginLeft: '96px', textAlign: 'justify'}}>&nbsp;</p>
                    <ol>
                      <li style={{listStyleType: 'none'}}>
                        <ol start={4}>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>You acknowledge that Twelvevest has no obligation to monitor the access to or use of the Platform by any User or to review, disable access to, or edit any User Content, but has the right to do so to (i) operate, secure and improve the Platform (including without limitation for fraud prevention, risk assessment, investigation and customer support purposes); (ii) ensure Users’ compliance with these Terms; (iii) comply with applicable law or the order or requirement of a court, law enforcement or other administrative agency or governmental body; (iv) respond to User Content that it determines is harmful or objectionable; or (v) as otherwise set forth in these Terms. Users agree to cooperate with and assist Twelvevest in good faith, and to provide Twelvevest with such information and take such actions as may be reasonably requested by Twelvevest with respect to any investigation undertaken by Twelvevest or a representative of Twelvevest regarding the use or abuse of the Platform.</span></span></span></span></span></li>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>If you feel that any user you interact with, whether online or in person, is acting or has acted inappropriately, including but not limited to anyone who (i) engages in offensive, violent or sexually inappropriate behaviour, (ii) you suspect of stealing from you, or (iii) engages in any other disturbing conduct, you should immediately report such person to the appropriate authorities and then to TwelveVest by contacting us with your police station and report number (if available); provided that your report will not obligate us to take any action beyond that required by law (if any) or cause us to incur any liability to you.</span></span></span></span></span></li>
                        </ol>
                      </li>
                    </ol>
                    <p style={{marginLeft: '96px', textAlign: 'justify'}}>&nbsp;</p>
                    <ol start={11}>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><strong><span style={{fontSize: '10.0pt'}}>Disclaimer</span></strong></span></span>
                        <ol>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}>The investment products and Service offered on the Site have not been registered under the Investments and Securities Act 2007. Twelvevest does not require such registration. </span></span></span></span></li>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}>Twelvevest, its subsidiaries, affiliates, and its licensors do not warrant that they are investment advisers or portfolio managers in accordance with the provisions of the Securities and Exchange Commission Rules 2013.</span></span></span></span></li>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}>Your use of the Service is at your sole risk. The Service is provided on an “AS IS” and “AS AVAILABLE” basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.</span></span></span></span></li>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}>Twelvevest, its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.</span></span></span></span></li>
                        </ol>
                      </li>
                    </ol>
                    <p style={{marginLeft: '96px', textAlign: 'justify'}}>&nbsp;</p>
                    <ol start={12}>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><strong><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>Links to Other Web Sites</span></span></strong></span></span></span>
                        <ol>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>Our Service may contain links to third-party websites or services that are not owned or controlled by Twelvevest.</span></span></span></span></span></li>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>Twelvevest has no control over and assumes no responsibility for, the content privacy policies or practices of any third-party websites or services. </span>You further acknowledge and agree that Twelvevest shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such websites or services. We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.</span></span></span></span></li>
                        </ol>
                      </li>
                    </ol>
                    <p style={{marginLeft: '96px', textAlign: 'justify'}}>&nbsp;</p>
                    <ol start={13}>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><strong><span style={{fontSize: '10.0pt'}}>Termination</span></strong></span></span></span>
                        <ol>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}>We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms.</span></span></span></span></li>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}>Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account you may simply discontinue using the Service.</span></span></span></span></li>
                        </ol>
                      </li>
                    </ol>
                    <p style={{marginLeft: '96px', textAlign: 'justify'}}>&nbsp;</p>
                    <ol start={14}>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><strong><span style={{fontSize: '10.0pt'}}>Indemnity</span></strong></span></span></span></li>
                    </ol>
                    <p style={{marginLeft: '24px', textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}>You agree to indemnify and hold harmless Twelvevest, its affiliates and subsidiaries, its officers, directors, employees and agents, against all liabilities, costs, expenses, damages and losses (including any direct, indirect or consequential losses, loss of profit, loss of reputation and all interest penalties and legal and other reasonable professional costs and expenses) suffered or incurred as a result of:</span></span></span></span></p>
                    <ol style={{listStyleType: 'lower-alpha'}}>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}>your fraudulent or illegal use of the Service or the Site;</span></span></span></span></li>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}>your negligence or any default by you of any of these Terms;</span></span></span></span></li>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}>any inaccurate or incomplete information that you have knowingly provided to us;</span></span></span></span></li>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>your allowing any other person to access your account either with your permission or as a result of your failure to keep your username and password private;</span></span></span></span></span></li>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>any service that you have offered, whether with or without our permission to another, third party using the Service or Site;</span></span></span></span></span></li>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>any claim made against you for actual or alleged infringement of Twelvevest’s intellectual property rights or any actual or alleged infringement of a third party’s intellectual property rights arising out of or in connection with the Services or you use of the Site.</span></span></span></span></span></li>
                    </ol>
                    <p style={{marginLeft: '48px', textAlign: 'justify'}}>&nbsp;</p>
                    <ol start={15}>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{backgroundColor: 'white'}}><strong><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>Limitation of Liability</span></span></strong></span></span></span></li>
                    </ol>
                    <p style={{marginLeft: '48px', textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}><span style={{color: 'black'}}>In no event shall Twelvevest, it’s directors, employees, partners,</span> agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from:&nbsp; </span></span></span></p>
                    <ol style={{listStyleType: 'lower-alpha'}}>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>Your use of the Site or the Services or your inability to use the Site or the Service;</span></span></span></li>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>any conduct or content of any third party on the Service;</span></span></span></li>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>any unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose;</span></span></span></li>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>any legal proceedings between the you and any third parties.</span></span></span></li>
                    </ol>
                    <p style={{marginLeft: '72px', textAlign: 'justify'}}>&nbsp;</p>
                    <ol start={16}>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><strong><span style={{fontSize: '10.0pt'}}>Governing Law</span></strong></span></span>
                        <ol>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>These Terms shall be governed and construed accordance with the laws of the Federal Republic of Nigeria, without regard to its conflict of law provisions.</span></span></span></li>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect.</span></span></span><br />
                            &nbsp;</li>
                        </ol>
                      </li>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><strong><span style={{fontSize: '10.0pt'}}>Dispute Resolution</span></strong></span></span>
                        <ol>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>Any disputes arising under or in connection with the validity, interpretation and performance of these Terms between Twelvevest and any third parties that cannot be resolved amicably by the parties through negotiation within 30 (thirty) days shall be resolved by Arbitration at the Lagos Court of Arbitration (LCA) before a single arbitrator in accordance with the Arbitration and Conciliation Act, Cap A18, Laws of the Federation of Nigeria.</span></span></span></li>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>The Parties shall endeavour in good faith to mutually agree on the selection of an arbitrator. If the Parties cannot mutually agree on the selection of an arbitrator within ten (10) days of the request, they shall apply to the LCA to appoint an arbitrator. Arbitration proceedings shall be conducted in Lagos. The arbitrator will be requested to render an award within ninety (90) days and to provide, in writing the reasoning for the award. The decision of any such arbitrator shall be final and binding on the parties. </span></span></span></li>
                          <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>Each party shall bear its cost in connection with the Arbitration and the arbitrator’s fees shall be split equally between both parties. </span></span></span></li>
                        </ol>
                      </li>
                    </ol>
                    <p style={{marginLeft: '96px', textAlign: 'justify'}}>&nbsp;</p>
                    <ol start={18}>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><strong><span style={{fontSize: '10.0pt'}}>Feedback</span></strong></span></span></li>
                    </ol>
                    <p style={{marginLeft: '24px', textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>We welcome and encourage you to provide feedback, comments and suggestions for improvements to Twelvevest’s Site or Services. You may submit Feedback by emailing us at </span><a href="mailto:support@twelvevest.com" style={{color: 'blue', textDecoration: 'underline'}}><span style={{fontSize: '10.0pt'}}>support@twelvevest.com</span></a><span style={{fontSize: '10.0pt'}}>.</span></span></span></p>
                    <p style={{marginLeft: '24px', textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>Any feedback you submit to us will be considered non-confidential and non-proprietary to you. By submitting your feedback to us, you grant us a non-exclusive, worldwide, royalty-free, irrevocable, sub-licensable, perpetual license to use and publish those ideas and materials for any purpose, without compensation to you.</span></span></span></p>
                    <p style={{marginLeft: '24px', textAlign: 'justify'}}>&nbsp;</p>
                    <ol start={19}>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><strong><span style={{fontSize: '10.0pt'}}>Changes to Terms &amp; Conditions</span></strong></span></span></li>
                    </ol>
                    <p style={{marginLeft: '24px', textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>Twelvevest reserves the right, in its sole discretion, to change the Terms under which the Site is offered. The most current version of the Terms will supersede all previous versions. Twelvevest encourages you to periodically review the Terms to stay informed of our updates.</span></span></span></p>
                    <p style={{marginLeft: '24px', textAlign: 'justify'}}>&nbsp;</p>
                    <ol start={20}>
                      <li style={{textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><strong><span style={{fontSize: '10.0pt'}}>Contact Us</span></strong></span></span></li>
                    </ol>
                    <p style={{marginLeft: '24px', textAlign: 'justify'}}><span ><span style={{fontSize: '11pt'}}><span style={{fontSize: '10.0pt'}}>If you have any questions about these Terms, please contact us at </span><a href="mailto:support@twelvevest.com" style={{color: 'blue', textDecoration: 'underline'}}><span style={{fontSize: '10.0pt'}}>support@twelvevest.com</span></a><span style={{fontSize: '10.0pt'}}>.</span></span></span></p>
                    <p>&nbsp;</p>
                </div>

              </div>
              <div className="modal-footer">
                  <button type="button" className="btn btn-outline-green mr-3" data-dismiss="modal" onClick={e => props.setState({
                    tos: false
                  })}>Cancel
                  </button>
                  <button type="button" className="btn btn-blue text-white mr-3" data-dismiss="modal" onClick={e => props.setState({
                    tos: true
                  })}>I Agree
                  </button>
              </div>
          </div>
      </div>
    </div>
  )
}

export default Tos;