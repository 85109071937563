import Chart from "chart.js";
import React from "react";
import { Link } from "react-router-dom";
import { hideLoader, showLoader } from "../../_helpers/Loader";
import Layout from "../Layout";
import { Context } from "../MyContext";
import API from '../../api';

class HistoricalGraph extends React.Component {
  static contextType = Context;

  constructor(props){
    super(props);
    this.state = {
      chartYearly: {},
      chart: {},
      setChart: 1,
      monthly: {},
      yearly: {},
      // investmentGraph: 
    };
  }

  async componentDidMount(){
    await this.getInvestments();
    this.drawChartHistory();
    // this.drawYearlyHistory();
  }

  async getInvestments(){
    showLoader();
    try {
      let res = await API.get(`admin/risevest/get_thirdparty_single_investment/?id=${this.props.investment_id}`);
      let returns = res.data;
      
      let sumedUpDatesMonths = [],
          pricesMonths = [],
          sumedUpDatesYears = [],
          pricesYears = [];

      
      const isDateSumedUpM = (date) => {
          return sumedUpDatesMonths.indexOf(date.substring(0, 7)) !== -1;
      }
      
      const isDateSumedUpY = (date) => {
          return sumedUpDatesYears.indexOf(date.substring(0, 4)) !== -1;
      }

      const sumUpDateM = (date) => {
          let sumM = 0;
      
          returns.forEach(t => {
              if(t.createdAt.substring(0, 7) === date.substring(0, 7)) {
                  sumM += parseFloat(t.overallReturns);
              }
          });
          sumedUpDatesMonths.push(date.substring(0, 7));
          pricesMonths.push(sumM);
      }

      const sumUpDateY = (date) => {
        let sumY = 0;

        returns.forEach(t => {
          if(t.createdAt.substring(0, 4) === date.substring(0, 4)) {
              sumY += parseFloat(t.overallReturns);
            }
        });
        sumedUpDatesYears.push(date.substring(0, 4));
        pricesYears.push(sumY);
      }
      
      returns.forEach(t => {
          if(!isDateSumedUpM(t.createdAt)) {
              sumUpDateM(t.createdAt);
          }
      });

      returns.forEach(t => {
          if(!isDateSumedUpY(t.createdAt)) {
              sumUpDateY(t.createdAt);
          }
      });

      let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      sumedUpDatesMonths = sumedUpDatesMonths.map(item => { return item.split('-')[0] + "-" + months[+item.split('-')[1] - 1]})
      this.setState({
        monthly: {labels: sumedUpDatesMonths, data:pricesMonths},
        yearly: {labels: sumedUpDatesYears, data:pricesYears}
      })

      hideLoader();
    } catch (e) {
        // console.log(e)
    }
  }

  drawChartHistory() {
      let ctx = document.getElementById("historyChart");
      
      var chart = new Chart(ctx, {
        type: 'line',
        data: {
            labels: this.state.monthly.labels,
            datasets: [{
                label: '# Months',
                data: this.state.monthly.data, //[12, 19, 3, 5, 2, 3],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    // 'rgba(75, 192, 192, 0.2)',
                    // 'rgba(153, 102, 255, 0.2)',
                    // 'rgba(255, 159, 64, 0.2)'
                ],
                borderColor: [
                    'rgba(255,99,132,1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    // 'rgba(75, 192, 192, 1)',
                    // 'rgba(153, 102, 255, 1)',
                    // 'rgba(255, 159, 64, 1)'
                ],
                borderWidth: 1
            }]
        },
        options: {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero:true
                    }
                }]
            }
        }
      });
      this.setState({
        chart: chart
      })
      return chart;
  }

  drawYearlyHistory() {

      let ctx = document.getElementById("historyChart");
      
      var chart = new Chart(ctx, {
        type: 'line',
        data: {
            labels: this.state.yearly.labels,
            datasets: [{
                label: '# Year',
                data: this.state.yearly.data, //[12, 19, 3, 5, 2, 3],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    // 'rgba(255, 206, 86, 0.2)',
                    // 'rgba(75, 192, 192, 0.2)',
                    // 'rgba(153, 102, 255, 0.2)',
                    // 'rgba(255, 159, 64, 0.2)'
                ],
                borderColor: [
                    'rgba(255,90,132,1)',
                    'rgba(54, 142, 235, 1)',
                    // 'rgba(255, 206, 86, 1)',
                    // 'rgba(75, 192, 192, 1)',
                    // 'rgba(153, 102, 255, 1)',
                    // 'rgba(255, 159, 64, 1)'
                ],
                borderWidth: 1
            }]
        },
        options: {
          plugins: {
            title: {
              display: true,
              text: '# History of ROI on Rise Vest Investment yearly'
            }
          },
          scales: {
              yAxes: [{
                  ticks: {
                      beginAtZero:true
                  }
                }
              ]
            }
        }
      });
      this.setState({
        chartYearly: chart
      })
      return chart;
  }

  riseVestHistory(){
      return (
            <div className="section">
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h4>ROI INVESTMENT HISTORY</h4>
                            </div>
                            <div className="card-body">
                                <canvas id="historyChart" width="600" height="400"></canvas>
                            </div>
                            <div className="py-3 text-center">
                              <button type="button" className="btn btn-blue text-white btn-large mx-2" onClick={event => {
                                this.setState({setChart: 1})
                                this.drawChartHistory()
                                }}> Monthly </button>
                              <button type="button" className="btn btn-blue text-white btn-large mx-2" onClick={event => {
                                this.setState({setChart: 2})
                                this.drawYearlyHistory()
                            }}> Yearly </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      ) 
  }

  render (){
    return this.riseVestHistory();
  }
}

export default HistoricalGraph;
