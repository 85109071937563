import React, {useEffect, useState, useContext} from "react";
import { Link } from "react-router-dom";
import { hideLoader, showLoader } from "../../_helpers/Loader";
import Layout from "../Layout";
import { Context } from "../MyContext";
import API from '../../api';
import "./style.css";

const DetailResource = (props) => {
  const [resourceObj, setResourceObj] = useState({});
  const context = useContext(Context);

  const getResource = async() => {
    showLoader()
    try {
        let res = await API.get(`resources/${props.match.params.id}/`);
        setResourceObj(res.data);
    } catch (e) {
        // console.log(e.response);
    }
    hideLoader()
  }

  useEffect(() => {
    getResource();
    return () => {
      console.log("")
    };
  }, []);

  return (
          <Layout context={context} active_menu={'resources'} title={'User(s) Guide'}>
              <div className="section">
                  {/* <div className="breadcrumb">
                      <h5><a href="/resources"><span className="fa fa-chevron-left"/> User(s) Guide
                        </a></h5>
                  </div> */}
                  <div className="row">
                      <div className="col-sm-12">
                          <div className="card">
                                <div className="card-body mx-lg-10">
                                    <h6>
                                        <strong className="text-bold">
                                            <Link to="/resources/" class="twelve-blue"><strong>Resources</strong></Link>
                                            <span class="breadcrumb__next-arrow"></span>
                                            <Link to={`/resources/?category=${resourceObj.category && resourceObj.category.title}`} className="twelve-blue text-capitalize"><strong>{resourceObj.category && resourceObj.category.title}</strong></Link>
                                        </strong>
                                    </h6>
                                    <div className='text-left'><h2>{resourceObj.title}</h2></div>
                                    <p>{new Date(resourceObj.created_at).toDateString()}</p>
                                    <div className='text-center py-2'><img src={resourceObj.image ? resourceObj.image : "https://res.cloudinary.com/py/image/upload/v1664890290/twelvevest/m8aaq0uocjl9jzakaxi6.jpg"} className="img-fluid" alt={resourceObj.title} /></div>
                                    <br/>
                                    <div className="mx-auto" dangerouslySetInnerHTML={{__html: resourceObj.body}} />
                                </div>
                          </div>
                      </div>
                  </div>
              </div>
          </Layout>
      )
}

export default DetailResource;