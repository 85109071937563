import React, {useEffect, useState, useContext} from "react";
import { Link } from "react-router-dom";
import { hideLoader, showLoader } from "../../_helpers/Loader";
import Layout from "../Layout";
import { Context } from "../MyContext";
import API from '../../api';

const ListResources = () => {
  const [resources, setResources] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(null);
  const context = useContext(Context);
  const [totalPost, setTotalPost] = useState(0);
  const [next, setNext] = useState(null);

  const getResources = async() => {
    showLoader()
    try {
        let res = await API.get('resources/');
        setResources(res.data.results);
        setTotalPost(res.data.count);
        setNext(res.data.next)
    } catch (e) {
        // console.log(e.response);
    }
    hideLoader()
  }

  const getResourcesCat = async(value) => {
    showLoader()
    if (value == "all"){
      getResources();
      return;
    }
    try {
        let res = await API.get('resources/?category='+ value.toLowerCase());
        setResources(res.data.results);
        setTotalPost(res.data.count);
        setNext(res.data.next);
    } catch (e) {
        // console.log(e.response);
    }
    hideLoader()
  }

  const getCategories = async() => {
    try {
        let res = await API.get('categories/');
        setCategories(res.data);
    } catch (e) {
        // console.log(e.response);
    }
  }

  const getResourcesNext = async(next) => {
    if (next){
      showLoader()
      try {
        let res = await API.get(next);
        setResources([...resources, ...res.data.results]);
        setTotalPost(res.data.count);
        setNext(res.data.next);
      } catch (e) {
          // console.log(e.response);
      }
      hideLoader()
    }
  }

  function handleInfiniteScroll(){
    let content_x_container = document.getElementById("blog_list");
    const endOfPage =
    content_x_container.scrollTop + content_x_container.clientHeight + 200 >= content_x_container.scrollHeight;
    
    if (endOfPage && document.querySelectorAll("[id^='post_']").length < parseInt(totalPost)) {   
      getResourcesNext(next)
    } 

    if (document.querySelectorAll("[id^='post_']").length == parseInt(totalPost)){
        window.removeEventListener('scroll', handleInfiniteScroll, {
            passive: true
        })
        window.removeEventListener('scroll', handleInfiniteScroll, {
            passive: true
        })
    }
  };

  window.addEventListener('scroll', handleInfiniteScroll, {
      passive: true
  })

  useEffect(() => {
    getCategories();
    getResources();

    let xCat = window.location.search
    const queryParams = new URLSearchParams(xCat)
    const cat = queryParams.get("category")
    if (cat){
      getResourcesCat(cat)
    }
    return () => {
      console.log("")
    };
  }, []);

  return (
          <Layout context={context} active_menu={'resources'} title={'Resources'}>
              <div className="section">
                  {/* <div className="breadcrumb">
                      <h5><span className="fa fa-chevron-left"/> User(s) Guide</h5>
                  </div> */}
                  <div className="row">
                      <div className="col-sm-12">
                          <div className="card">
                              {/* <div className="card-header bg-green">
                                  <h4>Resources</h4>
                              </div> */}
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-6 offset-md-6 pb-0">
                                    <div className="form-group float-right">
                                      <select className="form-control text-capitalize" onChange={event => getResourcesCat(event.target.value)}>
                                        <option value="all">All Categories</option>
                                        {categories.length && categories.map(item => {
                                          return (<option value={item.title} className="text-capitalize">{item.title}</option>)
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-columns" id="blog_list"> 
                                  {resources.map(item => (
                                      <a href={`/resources/${item.id}`} className="card my-3" id={`post_${item.id}`}>
                                        <img className="card-img-top img-fluid" src={item.image ? item.image : "https://res.cloudinary.com/py/image/upload/v1664890290/twelvevest/m8aaq0uocjl9jzakaxi6.jpg"} alt={item.title} style={{"width":"100%", height: "200px"}} />
                                        <div className="card-body">
                                          <p className="text-capitalize" style={{"cursor": "pointer"}} onClick={event=> getResourcesCat(item.category && item.category.title)}>{item.category && item.category.title}</p>
                                          {/* <a href={`/resources/${item.id}`}> */}
                                            <h4 className="card-title">{(item.title).substring(0, 40)}...</h4>
                                          {/* </a> */}
                                          {/* <p className="card-text">{item.excerpt}</p> */}
                                          <p className="card-text">{new Date(item.created_at).toDateString()}</p>
                                        </div>
                                      </a>
                                    ))}
                                </div>                                  
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </Layout>
      )
}


export default ListResources;