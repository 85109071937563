import React from 'react'
import Layout from '../Layout'
import {Link} from 'react-router-dom'
import NumberFormat from 'react-number-format';
import API from '../../api';
import {hideLoader, mixpanel, showLoader, toast, trackMixpanel} from "../../_helpers/Loader";
import {userContext} from "../../_helpers/userContext";
import $ from 'jquery'
import {Context} from "../MyContext";

const mainUser = userContext;

class Clubs extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            clubs: [],
            club_members: [],
            redirect: false,
            redirect_msg: '',
            redirect_url: this.props.history.location.pathname,

        }
    }

    async componentDidMount() {
        trackMixpanel('ClubsView', false, false, this.context.user.email)
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
        if (this.context.user.accounts.length < 1) {
            this.setState({redirect: true, redirect_url: '/account/bank-details'});
        }
        if (!this.context.user.next_of_kin_name || !this.context.user.next_of_kin_phone) {
            this.setState({redirect: true, redirect_url: '/profile'});
        }
        showLoader();
        await this.getClubs();
        (this.context.club_id !== null) && await this.getStateClub();
        hideLoader();
    }

    componentWillMount() {
        if (this.context.user.completed_onboarding === null || this.context.user.completed_onboarding === undefined) {
            window.location.reload();
        }
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
    }

    async getStateClub() {
        try {
            let res = await API.get(`clubs/${this.context.club_id}/`);
            this.setState({club_members: res.data.club_members});
        } catch (e) {
            hideLoader();
            // console.log(e.response)
        }
    }

    async getClubs() {
        try {
            let res = await API.get('clubs/');
            this.setState({clubs: res.data})
        } catch (e) {
            toast.error("Something Went Wrong");
            // console.log(e.response)
        }
    }

    defaultRedirect = () => {
        toast.info("Please update your profile");
        setTimeout(() =>
            this.props.history(this.state.redirect_url), 2000
        )
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        let cards = $(".card");
        let height = 0;
        cards.map((ind, item) => {
            ($(item).height() > height) && (height = $(item).height());
        });
        cards.height(height);
    }

    checkAdmin(item) {
        if (item !== undefined) {
            for (let i = 0; i < item.length; i++) {
                if (item[i].user === this.context.user.id) {
                    return true;
                }
            }
            return false
        } else {
            return false
        }
    }

    checkAdmin2(item) {
        if (item !== undefined) {
            for (let i = 0; i < item.length; i++) {
                if (item[i].user.id === this.context.user.id && item[i].user_role === 'admin') {
                    return true;
                }
            }
            return false
        } else {
            return false
        }
    }

    render() {
        return (
            <Layout active_menu={'club'} user_state={this.context.club_id}
                    club_admin={this.checkAdmin2(this.state.club_members)} context={this.context}>
                <div className="section">
                    <div className="breadcrumb" style={{paddingRight: '0'}}>
                        <div className="col-sm-6">
                            <h5>Clubs</h5>
                        </div>
                        <div className="col-sm-6 float-right text-right" style={{paddingRight: '0'}}>
                            <Link to="/start-club" className="btn  btn-social m-b-5 btn-blue text-white"><i
                                className="fa fa-plus-square"/> Add Club
                            </Link>
                        </div>
                    </div>

                    <div className="row">
                        {this.state.clubs.map(item => (
                            <div className="col-md-6 col-sm-12 col-lg-6 col-xl-4" key={item.id}>
                                <div className="card">
                                    <div className="card-header">
                                        {this.checkAdmin(item.club_admins) ? (
                                            <div className="row">
                                                <div className="col-md-12 col-lg-6 col-xl-6 col-sm-12">
                                                    <h4>{item.name}</h4>
                                                </div>
                                                <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12 text-right">
                                                    <Link to={{
                                                        pathname: `/manage-club/${item.id}`,
                                                        query: {back_url: this.props.location.pathname}
                                                    }} className="btn  btn-social m-b-5 btn-blue text-white text-white text-right"><i
                                                        className="fa fa-cog"/> Settings
                                                    </Link>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12">
                                                    <h4>{item.name}</h4>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="card-body">
                                        <div className="row mb-4">
                                            <div className="col-sm-12">
                                                Created by
                                            </div>
                                            <div className="col-sm-12">
                                                <h4>{item.owner.first_name} {item.owner.last_name}</h4>
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        Total Net Worth
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <h4>₦{parseFloat(item.total_asset_value).toLocaleString()}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        Target Net Worth
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <h4>₦{parseFloat(item.networth_goal).toLocaleString()}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        Members
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <h4>{item.club_members.length}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        Contribution
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <h4>₦{parseFloat(item.contribution_amount).toLocaleString()}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-lg-12">
                                                {(item.subscribed_status === 'paid' || item.subscribed_status === 'free') ? (
                                                    <Link
                                                        onClick={event => this.context.updateUserState('club', item.id)}
                                                        to={`/club/${item.id}/details`}
                                                        className="btn btn-blue text-white btn-block">View</Link>
                                                ) : (
                                                    <Link
                                                        onClick={event => this.context.updateUserState('club', item.id)}
                                                        to={`/club/${item.id}/subscribe`}
                                                        className="btn btn-blue text-white btn-block">Pay Subscription Fee to
                                                        Continue</Link>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Layout>
        );
    }
}

export default Clubs

