import React from 'react'
import API from '../../api'
import {Link, withRouter} from 'react-router-dom'
import Moment from 'react-moment';
import ReactSafeHtml from 'react-safe-html'
import Layout from '../Layout'
import {hideLoader, mixpanel, showLoader, toast, trackMixpanel, Utils} from "../../_helpers/Loader";
import {Context} from "../MyContext";
import {PAYSTACK_publicKey, MONNIFY_contractCode, MONNIFY_APIKey} from "../../env";
import Fade from 'react-reveal/Fade';
import uuidv1 from 'uuid';

import $ from 'jquery';

const PaystackPop = window.PaystackPop;

class MarketPlaceDetails extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            investment: {},
            page: 1,
            units: '',
            total: '',
            payment_type: 'bank',
            bank_ref_code: '',
            user: {},
            balance: '',
            completion_rate: '',
            comments: [],
            review_msg: '',
            review_page: 1,
            number_of_pages: 0,
            animate: false,
            not_eligible: false,
            reply_text: '',
            fraud: false,
            bank_transfer_details: {}
        };

        this.detailForm = this.detailForm.bind(this);
        this.onChangeUnit = this.onChangeUnit.bind(this);
        this.bankAction = this.bankAction.bind(this);
        this.walletAction = this.walletAction.bind(this);
        this.onBack = this.onBack.bind(this);
        this.payWithCard = this.payWithCard.bind(this);
        this.lessBalance = this.lessBalance.bind(this);
        this.payWithMonnify = this.payWithMonnify.bind(this);
    }

    async componentDidMount() {
        trackMixpanel('SubInvestorMarketplaceDetailView', false, false, this.context.user.email)
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
        this.setState({not_eligible: this.context.notification});
        showLoader();
        await this.getInvestment();
        await this.setState({user: this.context.user});
        await this.getWalletInfo();
        // await this.getReviews();
        hideLoader();
    }

    componentWillMount() {
        if (this.context.user.completed_onboarding === null || this.context.user.completed_onboarding === undefined) {
            window.location.reload();
        }
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
    }

    async getInvestment() {
        try {
            let res = await API.get(`investments/${this.props.match.params.market_id}/`);
            const init_completion_rate = ((res.data.total_units_sold / res.data.units) * 100).toFixed(0);
            const completion_rate = (init_completion_rate > 100) ? 100 : init_completion_rate;
            this.setState({investment: res.data, completion_rate: completion_rate})
        } catch (e) {
            toast.error("Something Went Wrong");
            // console.log(e.repsonse)
        }
    }

    createReview = async (event) => {
        showLoader();
        event.preventDefault();
        let postData = {
            body: this.state.review_msg
        };
        try {
            let res = await API.post(`investments/${this.props.match.params.market_id}/make_review/`, postData);
            this.setState({review_msg: ''});
            this.getReviews();
            var el = document.getElementById('reviews');
            el.scrollIntoView();
            var height = el.style.clientHeight;
            window.scrollBy(0, height - window.innerHeight);
            hideLoader();
        } catch (e) {
            // console.log(e.response)
        }
    };

    getReviews = async () => {
        try {
            let res = await API.get(`investments/${this.props.match.params.market_id}/get_reviews/`, {params: {page: this.state.review_page}});
            this.setState({comments: res.data.reviews, number_of_pages: res.data.pages})
        } catch (e) {
            // console.log(e)
        }
    };

    getMoreReview = async (page) => {
        try {
            let res = await API.get(`investments/${this.props.match.params.market_id}/get_reviews/`, {params: {page: page}});
            this.setState({
                comments: res.data.reviews,
                number_of_pages: res.data.pages,
                review_page: page,
            });
        } catch (e) {
            // console.log(e)
        }
    };

    async getWalletInfo() {
        try {
            let res = await API.get('wallet/get_my_wallet/');
            this.setState({balance: (res.data.length > 0) ? res.data[0].current_balance : 0})
        } catch (e) {
            toast.error("Something Went Wrong");
            // console.log(e.response)
        }
    }

    async walletAction() {
        let reference = "wallet-vest" + Math.floor((Math.random() * 1000000000) + 1);
        let postData = {
            amount: this.state.total,
            txRef: reference,
            phone: this.context.user.phone,
            id: this.state.investment.id,
            units: this.state.units,
            wallet: true,
            investment_id: this.state.investment.id
        };
        showLoader();
        try {
            let res = await API.post('sub_investor/' + this.context.sub_investor_id + '/confirm_payment/', postData);
            toast.success("Successfully Completed Transaction");
            document.getElementById('close-modal').click();
            $('.modal-backdrop').remove();
            this.props.history.push(`/sub-investor/${this.context.sub_investor_id}/dashboard`)
        } catch (e) {
            if (e.response.status === 400 && e.response.data) {
                toast.error("Insufficient Funds. Kindly Fund your Wallet");
                hideLoader();
                setTimeout(() => {
                    document.getElementById('close-modal').click();
                    $('.modal-backdrop').remove();
                    this.props.history.push('/account/wallet')
                }, 2000)
            } else {
                toast.error("Something went wrong");
            }
            hideLoader();
        }
    }

    detailForm(event) {
        event.preventDefault();
        const {unit} = event.target;
        this.setState({page: this.state.page + 1, animate: true})
    }

    onChangeUnit(event) {
        if (event.target.value < 1) {
            this.setState({units: '', total: ''});
        } else {
            this.setState({units: event.target.value, total: event.target.value * this.state.investment.unit_price});
        }
    }

    onBack() {
        this.setState({page: this.state.page - 1})
    }

    createdArray(num) {
        let array_obj = [];
        for (var i = 1; i <= num; i++) {
            array_obj.push(i)
        }
        return array_obj
    }

    async bankAction(event) {
        let code = "TVEST" + Math.floor((Math.random() * 100000) + 1);
        showLoader();
        this.setState({
            page: this.state.page + 1,
            payment_type: 'bank',
            bank_ref_code: code
        });
        hideLoader();
    }

    successTransaction = () => {
        window.location.href = "/manage-portfolio";
    };

    async payWithMonnify() {
        let postData = {
            investment: this.state.investment.id,
            purchased_units: parseFloat(this.state.units),
            payment_type: 'monnify_invoice',
            club_id: this.context.club_id
        };
        showLoader();
        try {
            let res = await API.post(`sub_investor/${this.context.sub_investor_id}/create_invoice/`, postData);
            // console.log(res.data);
            this.setState({bank_transfer_details: res.data});
            hideLoader();
            $('#bankModal').modal('show');
        } catch (e) {
            hideLoader();
        }
    }

    redirect = () => {
        toast.info("Kindly update your information first");
        setTimeout(() => {
            this.props.history.push({
                pathname: '/profile',
                query: {back_url: this.props.location.pathname}
            })
        }, 3000);
    };

    replyComment = async (event, id) => {
        event.preventDefault();
        let postData = {
            body: ($(`#reply-text-${id}`).val()).replace(/^\s+|\s+$/gm, ''),
            id: id
        };
        try {
            let res = await API.post(`investments/${this.props.match.params.market_id}/make_reply/`, postData);
            $(`#reply-text-${id}`).val('');
            this.getReviews();
        } catch (e) {
            // console.log(e.response)
        }
    };

    likeComment = async (event, id, el, type_comment) => {
        event.preventDefault();
        let postData = {
            id: id,
            type_comment
        };
        showLoader();
        try {
            let res = await API.post(`investments/${this.props.match.params.market_id}/make_like/`, postData);
            $(`#reply-text-${id}`).val('');
            this.getReviews();
            hideLoader()
        } catch (e) {
            hideLoader()
            // console.log(e.response)
        }
    };

    detail() {
        return (
            <Layout active_menu={'market'} user_state="subinvestor" context={this.context}>
                <Fade right={(!this.state.animate) ? false : true}>
                    <div className="section">
                        <div className="breadcrumb">
                            <Link onClick={event => window.history.back()}><span
                                className="fa fa-chevron-left"/> Offers</Link>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-lg-7 col-xl-7">
                                <div className="card">
                                    <div className="img-container mb-4 full-block">
                                        <img className="img-crop-md" src={this.state.investment.image} alt=""/>
                                        <div className="text-overlay-left-bottom text-left">
                                            {this.state.investment.in_trust ? (
                                                <i className="fas fa-shield-alt text-dark"/>) : ''}
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <ReactSafeHtml html={this.state.investment.description}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-lg-5 col-xl-5">
                                <div className="card">
                                    <div className="card-header bg-green">
                                        <strong>{this.state.investment.title} by {this.state.investment.company}</strong>
                                    </div>
                                    <div className="card-body">
                                        <dl className="row">
                                            <dt className="col-sm-4 text-right"><strong>Trade Cycle</strong></dt>
                                            <dd className="col-sm-8">{this.state.investment.trade_cycle}</dd>

                                            <dt className="col-sm-4 text-right"><strong>Tenor</strong></dt>
                                            <dd className="col-sm-8">{this.state.investment.maturity_cycle} Months</dd>

                                            <dt className="col-sm-4 text-right"><strong>ROI</strong></dt>
                                            <dd className="col-sm-8">{this.state.investment.inv_roi}% {this.state.investment.tenor_type == "p.a" ? "Per Annum" : "Flat"}</dd>

                                            <dt className="col-sm-4 text-right"><strong>Per Unit</strong></dt>
                                            <dd className="col-sm-8">{this.state.investment.currency === "USD" ? '$' : '₦'}{parseFloat(this.state.investment.unit_price).toLocaleString()}</dd>

                                            <dt className="col-sm-4 text-right"><strong>Ends on</strong></dt>
                                            <dd className="col-sm-8"><Moment
                                                format="MM-DD-YYYY">{this.state.investment.closing_date}</Moment></dd>
                                        </dl>
                                        <div className="row">
                                            {(this.state.total && this.state.investment.club_tag) ? (
                                                <div className="col-sm-12 mx-auto mb-3 text-orange font-italic">
                                                    Note: This investment is paid in 12 installments at
                                                    NGN{parseFloat(this.state.total / 12).toLocaleString()} per month.
                                                    You will have the opportunity to review transaction details before
                                                    making a
                                                    payment.
                                                </div>) : ''
                                            }
                                            <div className="col-sm-12">
                                                <h4 className="text-left">How many units will you like to purchase?</h4>
                                            </div>
                                        </div>
                                        <form onSubmit={this.detailForm} autoComplete="off">
                                            <div className="">
                                                <div className="form-group">
                                                    <label htmlFor="units">Units</label>
                                                    <input min="1" onChange={this.onChangeUnit} value={this.state.units}
                                                           type="number" className="form-control" id="units"
                                                           placeholder="Enter Units"/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="amount">Total Cost</label>
                                                    <input readOnly style={{color: 'black'}}
                                                           value={(this.state.total) ? `${this.state.investment.currency === "USD" ? '$' : '₦'}${parseFloat(this.state.total).toLocaleString()}` : ''}
                                                           type="text" className="form-control" id="amount"/>
                                                </div>
                                                {this.state.investment.currency === "USD" ? (
                                                    <div className="form-group">
                                                        <label htmlFor="amount">Total Cost (₦)</label>
                                                        <input readOnly style={{color: 'black'}}
                                                               value={(this.state.total) ? `₦${(parseFloat(this.state.total) * parseFloat(this.context.user.usd)).toLocaleString()}` : ''}
                                                               type="text" className="form-control" id="amount"/>
                                                    </div>
                                                ) : ''}
                                                <div className="form-group">
                                                    <label htmlFor="amount">Total Earnings</label>
                                                    <input aria-disabled disabled
                                                           value={(this.state.total) ? `₦${((parseFloat(this.state.total) * parseFloat(this.state.investment.inv_roi) / 100) + parseFloat(this.state.total)).toLocaleString()}` : ''}
                                                           type="text" className="form-control" id="amount"/>
                                                </div>
                                            </div>
                                            {(this.state.units > 0 && this.state.not_eligible) ? (
                                                <button type="button" onClick={event => this.redirect()}
                                                        className="btn btn-blue text-white mt-1 mb-0 btn-block">Update Profile
                                                </button>
                                            ) : (this.state.total >= this.state.investment.unit_price) ? ((this.state.investment.status.toLowerCase() !== 'active' || this.state.completion_rate >= 100) ? (
                                                <button disabled type="button"
                                                        className="btn btn-blue text-white mt-1 mb-0 btn-block">Investment Closed
                                                </button>
                                            ) : ((this.state.investment.club_tag) ? (
                                                <button disabled type="button"
                                                        className="btn btn-blue text-white mt-1 mb-0 btn-block">Coming soon
                                                </button>
                                            ) : (
                                                <button type="submit"
                                                        className="btn btn-blue text-white mt-1 mb-0 btn-block">Continue
                                                </button>
                                            ))) : ''}
                                        </form>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="col-md-12 col-sm-12 col-lg-7 col-xl-7">*/}
                            {/*    <div className="card">*/}
                            {/*        <div className="card-header bg-green">*/}
                            {/*            <strong>Reviews</strong>*/}
                            {/*        </div>*/}
                            {/*        <div className="card-body">*/}
                            {/*            <div className="row">*/}
                            {/*                <div className="col-md-12">*/}
                            {/*                    <div className="status-upload mt-2 mb-4 d-inline-block w-100">*/}
                            {/*                        <form onSubmit={this.createReview}>*/}
                            {/*                            {(this.state.comments.length < 1) ? (*/}
                            {/*                                <div className="mb-3">*/}
                            {/*                                    <strong>No reviews. <br/>*/}
                            {/*                                        Be the first to write a review on this*/}
                            {/*                                        investment</strong>*/}
                            {/*                                </div>*/}
                            {/*                            ) : ''}*/}
                            {/*                            <textarea value={this.state.review_msg} rows="5"*/}
                            {/*                                      maxLength="5000"*/}
                            {/*                                      required*/}
                            {/*                                      onChange={event => this.setState({review_msg: event.target.value})}*/}
                            {/*                                      className="form-control"*/}
                            {/*                                      placeholder="Add review here"/>*/}

                            {/*                            <div className="pull-left">*/}
                            {/*                                <small>{5000 - this.state.review_msg.length} Words Max*/}
                            {/*                                </small>*/}
                            {/*                            </div>*/}
                            {/*                            <button type="submit"*/}
                            {/*                                    className="btn btn-blue text-white btn-sm pull-right mt-2"> Post*/}
                            {/*                            </button>*/}
                            {/*                        </form>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*                <div className="col-md-12" id="reviews">*/}
                            {/*                    {this.state.comments.map(item => (*/}
                            {/*                        <div key={item.id}>*/}
                            {/*                            <div className="media mt-0">*/}
                            {/*                                <div className="media-left"><img*/}
                            {/*                                    src={(item.user.profile_photo) ? item.user.profile_photo : 'https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png'}*/}
                            {/*                                    alt=""*/}
                            {/*                                    className="media-object"/></div>*/}
                            {/*                                <div className="media-body">*/}
                            {/*                                    <h4 className="media-heading text-dark">{item.user.first_name} {item.user.last_name} {' '}*/}
                            {/*                                        <i className="fa fa-certificate text-green"/> Verified<br/>*/}
                            {/*                                        <small className="text-muted"><span*/}
                            {/*                                            className="fa fa-clock"/> <Moment*/}
                            {/*                                            format="LLLL">{item.modified_at}</Moment>*/}
                            {/*                                        </small>*/}
                            {/*                                    </h4>*/}
                            {/*                                    <p className="text-justify text-main">{item.body}</p>*/}
                            {/*                                    <ul className="nav nav-pills pull-left">*/}
                            {/*                                        <li><a id={`likeNum-${item.id}`} href="#"*/}
                            {/*                                               onClick={event => this.likeComment(event, item.id, $(`#likeNum-${item.id}`), 1)}><span*/}
                            {/*                                            className="fa fa-thumbs-up"></span> {(item.likes_num > 0) ? item.likes_num : ''}*/}
                            {/*                                        </a></li>*/}
                            {/*                                        <li><a href="" onClick={event => {*/}
                            {/*                                            event.preventDefault();*/}
                            {/*                                            $(`#replybox-${item.id}`).toggleClass("d-none");*/}
                            {/*                                        }}><span*/}
                            {/*                                            className="fa fa-share"></span> {(item.reply.length > 0) ? item.reply.length : ''}*/}
                            {/*                                        </a></li>*/}
                            {/*                                    </ul>*/}
                            {/*                                    <br/>*/}
                            {/*                                    {(item.reply.length > 0) ?*/}
                            {/*                                        <div>*/}
                            {/*                                            {item.reply.map(reply => (*/}
                            {/*                                                <div className="media mb-2">*/}
                            {/*                                                    <div className="media-left"><a*/}
                            {/*                                                        href="javascript:void(0)"> <img*/}
                            {/*                                                        alt="64x64"*/}
                            {/*                                                        src={(reply.user.profile_photo) ? reply.user.profile_photo : 'https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png'}*/}
                            {/*                                                        className="media-object"/>*/}
                            {/*                                                    </a></div>*/}
                            {/*                                                    <div className="media-body">*/}
                            {/*                                                        <h4 className="media-heading">{reply.user.first_name} {reply.user.last_name} {' '}*/}
                            {/*                                                            <span*/}
                            {/*                                                                className="fa fa-user-cog"/>*/}
                            {/*                                                        </h4>*/}
                            {/*                                                        <p>{reply.body}</p>*/}
                            {/*                                                        <ul className="nav nav-pills pull-left">*/}
                            {/*                                                            <li><a href="#"*/}
                            {/*                                                                   onClick={event => this.likeComment(event, item.id, $(`#likeNum-${item.id}`), 2)}*/}
                            {/*                                                            ><span*/}
                            {/*                                                                className="fa fa-thumbs-up"></span> {(reply.likes_num > 0) ? reply.likes_num : ''}*/}
                            {/*                                                            </a>*/}
                            {/*                                                            </li>*/}
                            {/*                                                            <li><a href="#"*/}
                            {/*                                                                   onClick={event => {*/}
                            {/*                                                                       event.preventDefault();*/}
                            {/*                                                                       $(`#replybox-${item.id}`).toggleClass("d-none");*/}
                            {/*                                                                   }}><span*/}
                            {/*                                                                className="fa fa-share"></span>*/}
                            {/*                                                            </a></li>*/}
                            {/*                                                        </ul>*/}
                            {/*                                                    </div>*/}
                            {/*                                                </div>*/}
                            {/*                                            ))}*/}
                            {/*                                        </div>*/}
                            {/*                                        : ''}*/}
                            {/*                                    <div className="col-md-12 d-none"*/}
                            {/*                                         id={`replybox-${item.id}`}>*/}
                            {/*                                        <div*/}
                            {/*                                            className="status-upload mt-2 mb-3 d-inline-block w-100">*/}
                            {/*                                            <form*/}
                            {/*                                                onSubmit={event => this.replyComment(event, item.id)}>*/}
                            {/*                                                <textarea id={`reply-text-${item.id}`}*/}
                            {/*                                                          className="form-control"*/}
                            {/*                                                          placeholder="Comment here"></textarea>*/}
                            {/*                                                <br/>*/}
                            {/*                                                <button type="submit"*/}
                            {/*                                                        className="btn btn-blue text-white btn-sm pull-right"> Reply*/}
                            {/*                                                </button>*/}
                            {/*                                            </form>*/}
                            {/*                                        </div>*/}
                            {/*                                    </div>*/}
                            {/*                                </div>*/}
                            {/*                            </div>*/}
                            {/*                            <hr/>*/}
                            {/*                        </div>*/}
                            {/*                    ))}*/}
                            {/*                    {(this.state.number_of_pages > 1) ? (*/}
                            {/*                        <div className="row">*/}
                            {/*                            <div className="dataTables_paginate paging_simple_numbers"*/}
                            {/*                                 id="example_paginate">*/}
                            {/*                                <ul className="pagination">*/}
                            {/*                                    {(this.state.review_page > 1 && this.state.number_of_pages > 1) ? (*/}
                            {/*                                        <li onClick={event => this.getMoreReview(this.state.review_page - 1)}*/}
                            {/*                                            className="paginate_button page-item previous"*/}
                            {/*                                            id="example_previous"><a*/}
                            {/*                                            aria-controls="example"*/}
                            {/*                                            data-dt-idx="0"*/}
                            {/*                                            tabIndex="0"*/}
                            {/*                                            className="page-link">Previous</a>*/}
                            {/*                                        </li>*/}
                            {/*                                    ) : ''}*/}
                            {/*                                    {this.createdArray(this.state.number_of_pages).map(item => (*/}
                            {/*                                        <li onClick={event => this.getMoreReview(item)}*/}
                            {/*                                            className={`paginate_button page-item ${(this.state.review_page === item) ? 'active' : ''}`}>*/}
                            {/*                                            <a aria-controls="example"*/}
                            {/*                                               data-dt-idx="1"*/}
                            {/*                                               tabIndex="0"*/}
                            {/*                                               className="page-link">{item}</a>*/}
                            {/*                                        </li>*/}
                            {/*                                    ))}*/}
                            {/*                                    {(this.state.number_of_pages > 1 && this.state.review_page < this.state.number_of_pages) ? (*/}
                            {/*                                        <li onClick={event => this.getMoreReview(this.state.review_page + 1)}*/}
                            {/*                                            className="paginate_button page-item next"*/}
                            {/*                                            id="example_next"><a aria-controls="example"*/}
                            {/*                                                                 data-dt-idx="6"*/}
                            {/*                                                                 tabIndex="0"*/}
                            {/*                                                                 className="page-link">Next</a>*/}
                            {/*                                        </li>*/}
                            {/*                                    ) : ''}*/}
                            {/*                                </ul>*/}
                            {/*                            </div>*/}
                            {/*                            <div className="col-12 text-center">*/}
                            {/*                                {(!this.state.review_page_end) ? ((this.state.comments.length > 0) ? (*/}
                            {/*                                        <button type="button"*/}
                            {/*                                                onClick={this.getMoreReview}*/}
                            {/*                                                className="btn btn-blue text-white text-white text-center">Load*/}
                            {/*                                            more*/}
                            {/*                                        </button>) : ('')*/}
                            {/*                                ) : (*/}
                            {/*                                    <p>No more comments.</p>*/}
                            {/*                                )}*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}
                            {/*                    ) : ''}*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </Fade>
            </Layout>
        )
    }

    lessBalance() {
        toast.info("Insufficient fund.");
        setTimeout(() => {
            toast.info("Add money to wallet.");
        }, 1000);
        setTimeout(() => {
            this.props.history.push({
                pathname: '/account/wallet',
                query: {back_url: this.props.location.pathname}
            })
        }, 2000);
    }

    payWithCard = () => {
        const script = document.createElement("script");

        script.src = "https://js.paystack.co/v1/inline.js";
        script.async = true;

        let ref_start = this.state.investment.club_tag ? 'sub-paystack-estate' : 'sub-paystack-vest';

        document.body.appendChild(script);
        let amount = this.state.investment.club_tag ? parseFloat(Utils.charges(parseFloat(this.state.total / this.state.investment.maturity_cycle).toFixed(2))) : parseFloat(this.state.total);
        let main_amount = (this.state.investment.currency === "USD") ? (amount * parseFloat(this.context.user.usd)).toFixed(2) : amount
        var handler = PaystackPop.setup({
            key: PAYSTACK_publicKey,
            email: this.state.user.email,
            amount: main_amount * 100,
            currency: "NGN",
            ref: ref_start + Math.floor((Math.random() * 1000000000) + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
            metadata: {
                custom_fields: [
                    {
                        display_name: "First Name",
                        variable_name: "firstname",
                        value: this.state.user.first_name,
                    }, {
                        display_name: "Last Name",
                        variable_name: "lastname",
                        value: this.state.user.last_name,
                    }, {
                        display_name: "Customer Number",
                        variable_name: "customer_phone",
                        value: this.state.user.phone,
                    }, {
                        display_name: "Investment",
                        variable_name: "investment_title",
                        value: `${this.state.investment.title} by ${this.state.investment.company}`
                    },
                ]
            },
            callback: (response) => {
                response.amount = this.state.total;
                response.txRef = response.reference;
                response.phone = this.state.user.phone;
                response.id = this.state.investment.id;
                response.units = this.state.units;
                response.pay_type = 'paystack';
                response.investment_id = this.state.investment.id
                showLoader();
                if (
                    response.status === "success"
                ) {
                    // redirect to a success page
                    API.post('sub_investor/' + this.context.sub_investor_id + '/confirm_payment/', response).then((res) => {
                        hideLoader();
                        toast.success("Successfully Completed Transaction");
                        this.props.history.push(`/sub-investor/${this.context.sub_investor_id}/dashboard`)
                    }, function (err) {
                        hideLoader();
                        console.log(err.response)
                        toast.error("Something Went Wrong")
                    })

                } else {
                    // redirect to a failure page.
                    hideLoader();
                    toast.error("Payment Error.")
                }
                //alert('success. transaction ref is ' + response.reference);
            },
            onClose: function () {
                toast.info("Payment Cancelled");
                hideLoader();
            }
        });
        handler.openIframe();
    };

    summary() {
        return (
            <Layout active_menu={'market'} context={this.context}>
                <div className="section">
                    <div className="breadcrumb">
                        <a onClick={this.onBack}><span className="fa fa-chevron-left"></span> Back</a>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-lg-6 col-xl-6 mx-auto">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row mb-4">
                                        <div className="col-sm-12">
                                            <h4 className="text-center">Transaction Summary</h4>
                                            <p className="text-center">{this.state.investment.title} by {this.state.investment.company}</p>
                                        </div>

                                        <div className="col-sm-12">
                                            {(this.state.total && this.state.investment.club_tag) ? (
                                                <div className="col-sm-12 mx-auto mb-3 text-orange font-italic">
                                                    Note: This investment is paid in 12 installments at
                                                    NGN{parseFloat(this.state.total / 12).toLocaleString()} per
                                                    month.
                                                    You will have the opportunity to review transaction details
                                                    before
                                                    making a
                                                    payment.
                                                </div>) : ''
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-9 mx-auto">
                                            <dl className="row mx-auto">
                                                <dt className="col-sm-6 text-right"><strong>Unit Price</strong></dt>
                                                <dd className="col-sm-6">₦{parseFloat(this.state.investment.unit_price).toLocaleString()}</dd>

                                                <dt className="col-sm-6 text-right"><strong>Purchasing Unit</strong>
                                                </dt>
                                                <dd className="col-sm-6">{this.state.units}</dd>

                                                <dt className="col-sm-6 text-right"><strong>Total</strong></dt>
                                                <dd className="col-sm-6">{this.state.investment.currency === "USD" ? '$' : '₦'}{parseFloat(this.state.total).toLocaleString()}</dd>

                                                <dt className="col-sm-6 text-right"><strong>Amount To Pay</strong>
                                                </dt>
                                                <dd className="col-sm-6">{this.state.investment.currency === "USD" ? '$' : '₦'}
                                                    {
                                                        (this.state.investment.club_tag) ?
                                                            parseFloat(parseFloat(this.state.total / 12).toFixed(2)).toLocaleString() :
                                                            parseFloat(this.state.total).toLocaleString()
                                                    }
                                                </dd>
                                                {this.state.investment.currency === "USD" ? (
                                                    <React.Fragment>
                                                        <dt className="col-sm-6 text-right"><strong>Amount To Pay
                                                            (₦)</strong>
                                                        </dt>
                                                        <dd className="col-sm-6">₦
                                                            {
                                                                (this.state.investment.club_tag) ?
                                                                    parseFloat(parseFloat(this.context.user.usd) * parseFloat(this.state.total / this.state.investment.maturity_cycle).toFixed(2)).toLocaleString() :
                                                                    (parseFloat(this.context.user.usd) * parseFloat(this.state.total)).toLocaleString()
                                                            }
                                                        </dd>
                                                    </React.Fragment>
                                                ) : ''}
                                            </dl>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-sm-12">
                                            <div className="form-check">
                                                <input onChange={event => this.setState({fraud: !this.state.fraud})}
                                                       className="form-check-input" type="checkbox"
                                                       checked={this.state.fraud}
                                                       id="defaultCheck1"/>
                                                <label className="form-check-label" htmlFor="defaultCheck1"><strong>
                                                    <sup className="text-danger">*</sup> Tick to confirm you are not
                                                    involved in money laundering to
                                                    continue.</strong>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            {(this.state.investment.card_payment) ? (
                                                <button disabled={!this.state.fraud}
                                                        onClick={event => this.payWithCard()}
                                                        className="btn btn-blue text-white btn-lg btn-block"><i
                                                    className="fa fa-credit-card"/> Pay with Card
                                                </button>
                                            ) : ''}
                                            {/* {(this.state.investment.transfer_payment) ? (
                                                <button disabled={!this.state.fraud}
                                                        onClick={event => this.payWithMonnify()}
                                                        className="btn btn-blue text-white btn-lg btn-block"><i
                                                    className="fa fa-bank"/> Pay with
                                                    Bank Transfer
                                                </button>
                                            ) : ''} */}
                                            {(this.state.investment.wallet_payment) ? ((this.state.balance >= this.state.total) ? (
                                                <button disabled={!this.state.fraud} data-toggle="modal"
                                                        data-target="#walletModal"
                                                        className="btn btn-blue text-white btn-lg btn-block"><i
                                                    className="fa fa-wallet"/> Pay with
                                                    Wallet
                                                </button>
                                            ) : (
                                                <button disabled={!this.state.fraud} data-toggle="modal"
                                                        data-target="#walletModal"
                                                        onClick={event => this.lessBalance}
                                                        className="btn btn-blue text-white btn-lg btn-block"><i
                                                    className="fa fa-wallet"/> Pay with
                                                    Wallet
                                                </button>
                                            )) : ''}
                                            {(this.state.investment.club_tag) ? (
                                                <small className="text-orange">Note: Transactions charges may
                                                    apply</small>
                                            ) : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="walletModal" tabIndex="-1" role="dialog"
                     aria-labelledby="walletModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h5 className="modal-title" id="exampleModalLabel">Confirm</h5>
                                <button id="close-modal" type="button" className="close" data-dismiss="modal"
                                        aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="mb-0">Current balance:
                                    ₦{parseFloat(this.state.balance).toLocaleString()}</p>
                                <p className="mb-0">Balance after transaction:
                                    ₦{parseFloat((this.state.investment.currency === "USD") ? (this.state.balance - (this.state.total * parseFloat(this.context.user.usd))).toFixed(2) : this.state.balance - this.state.total).toLocaleString()}</p>
                                {((this.state.balance - this.state.total) >= 0) ? (
                                    <p className="mb-0">Click pay to continue with payment</p>

                                ) : ''}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-green mr-3" data-dismiss="modal">Close
                                </button>
                                {(this.state.investment.club_tag) ?
                                    ((this.state.balance - parseFloat(this.state.total) / parseFloat(this.state.investment.maturity_cycle)) >= 0) ? (
                                        <button onClick={event => this.walletAction(event)} type="button"
                                                className="btn btn-main"><i
                                            className="fas fa-wallet"/> Pay with wallet</button>
                                    ) : (
                                        <button onClick={event => {
                                            document.getElementById('close-modal').click();
                                            $('.modal-backdrop').remove();
                                            this.props.history.push("/account/wallet")
                                        }}
                                                type="button" className="btn btn-main"><i
                                            className="fas fa-wallet"/> Fund wallet</button>
                                    )
                                    : ((this.state.balance - this.state.total) >= 0) ? (
                                        <button onClick={event => this.walletAction(event)} type="button"
                                                className="btn btn-main"><i
                                            className="fas fa-wallet"/> Pay with wallet</button>
                                    ) : (
                                        <button onClick={event => {
                                            document.getElementById('close-modal').click();
                                            $('.modal-backdrop').remove();
                                            this.props.history.push("/account/wallet")
                                        }}
                                                type="button" className="btn btn-main"><i
                                            className="fas fa-credit-card"/> Fund wallet</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal show" id="bankModal" tabIndex="-1" role="dialog"
                     aria-labelledby="bankModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green text-center">
                                <h4 className="modal-title" id="bankModalLabel">Bank Transfer Details</h4>
                                <button id="close-modal" type="button" className="close" data-dismiss="modal"
                                        aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <dl className="row">
                                            <dt className="col-sm-6 text-right">Account Name</dt>
                                            <dd className="col-sm-6">
                                                <strong>{this.state.investment.title} by {this.state.investment.company}</strong>
                                            </dd>
                                            <dt className="col-sm-6 text-right">Account Number</dt>
                                            <dd className="col-sm-6">
                                                <strong>{this.state.bank_transfer_details.monnify_account_number}</strong>
                                            </dd>
                                            <dt className="col-sm-6 text-right">Bank</dt>
                                            <dd className="col-sm-6">
                                                <strong>{this.state.bank_transfer_details.monnify_account_bank}</strong>
                                            </dd>
                                            <dt className="col-sm-6 text-right">Expiry Datetime</dt>
                                            <dd className="col-sm-6">
                                                <strong>{this.state.bank_transfer_details.monnify_expiry_date}</strong>
                                            </dd>
                                            <dt className="col-sm-6 text-right">Invoice Number</dt>
                                            <dd className="col-sm-6">
                                                <strong>{this.state.bank_transfer_details.monnify_account_name}</strong>
                                            </dd>
                                        </dl>
                                        <p>Once payment is confirmed, you will get a notification about the completion
                                            of your
                                            purchase.</p>
                                        <p>Invoice is only valid before the expiry date and time.</p>
                                        <p>You can always create a new request, if you need to.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-blue text-white text-white" data-dismiss="modal">Close
                                </button>
                                <button onClick={event => {
                                    $('.close').click();
                                    $('.modal-backdrop').remove();
                                    this.props.history.push(`/sub-investor/${this.context.sub_investor_id}/dashboard`)
                                }} type="button"
                                        className="btn btn-blue text-white">Check Portfolio
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }

    payWithBank() {
        return (
            <Layout active_menu={'market'} user_state={'personal'} context={this.context}>
                <div className="section">
                    <div className="breadcrumb">
                        <a onClick={this.onBack}><span className="fa fa-chevron-left"/> Back</a>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-lg-6 col-xl-6 mx-auto">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h4 className="text-center">Transaction Summary</h4>
                                            <p className="text-center">{this.state.investment.title} by {this.state.investment.company}</p>
                                            <hr style={{border: '2px solid #23414D'}}/>
                                        </div>

                                        <div className="col-sm-12">
                                            {(this.state.total && this.state.investment.club_tag) ? (
                                                <div className="col-sm-12 mx-auto mb-3 text-orange font-italic">
                                                    Note: This investment is paid in 12 installments at
                                                    NGN{parseFloat(this.state.total / 12).toLocaleString()} per month.
                                                    You will have the opportunity to review transaction details before
                                                    making a
                                                    payment.
                                                </div>) : ''
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <p className="text-center"><i className="text-dark">Please transfer funds to
                                                the account number below with the following
                                                code: <span
                                                    className="bg-orange text-main" style={{
                                                    fontSize: '20px',
                                                    padding: '4px',
                                                    paddingRight: '8px',
                                                    fontStyle: 'initial'
                                                }}>{this.state.bank_ref_code} </span></i>
                                            </p>
                                            <dl className="row">
                                                <dt style={{fontWeight: 100}} className="col-sm-6 text-right">Account
                                                    Name
                                                </dt>
                                                <dd className="col-sm-6"><strong>Twelvevest</strong></dd>
                                                <dt className="col-sm-6 text-right">Account Number</dt>
                                                <dd className="col-sm-6"><strong>0825927041</strong></dd>
                                                <dt className="col-sm-6 text-right">Bank</dt>
                                                <dd className="col-sm-6"><strong>Access Bank</strong></dd>
                                            </dl>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12" onClick={this.confirmBank}>
                                            <button className="btn btn-blue text-white btn-block">Click to confirm</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }

    render() {
        if (this.state.page === 1) {
            return this.detail()
        } else if (this.state.page === 2) {
            return this.summary()
        } else if (this.state.page === 3 && this.state.payment_type === 'bank') {
            return this.payWithBank();
        }
    }
}


export default MarketPlaceDetails
