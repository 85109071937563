import React, {Component} from 'react';
import {Link} from 'react-router-dom'

import {authReducer} from '../../reducers/authReducers'

import {showLoader, hideLoader, toast, Utils, trackMixpanel} from "../../_helpers/Loader";

import {userService} from '../../_services';
import API from '../../api'


import LOGO from '../../assets2/img/logo-white.png'
import {authHeader} from "../../_helpers";
import {Context} from "../MyContext";
import NumberFormat from "react-number-format";
import $ from 'jquery';


class Login extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        userService.logout();

        this.state = {
            email: '',
            password: '',
            submitted: false,
            loading: false,
            error: false,
            otp: '',
            page: 1
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentWillMount() {
        hideLoader();
        localStorage.clear();
        await this.context.clearState();
    }

    componentDidMount() {
        trackMixpanel('LoginView', false, false, false)
    }

    async handleSubmit(e) {

        e.preventDefault();

        this.setState({submitted: true});

        const email = this.state.email;
        const password = this.state.password;

        // stop here if form is invalid
        if (!(email && password)) {
            return;
        }
        if (email == "eriye@outlook.com") {
            toast.error("Login unsuccessful. Contact Admin")
            return;
        }
        showLoader();
        this.setState({otp: ''})
        try {
            let res = await API.post(`rest-auth/login/`, {email, password});
            // if (res.status === 203) {
            //     hideLoader();
            //     $("#otpModal").modal('show');
            // }
            if (res.status === 200) {
                localStorage.setItem('auth', JSON.stringify(res.data));
                await API.set_header(authHeader());
                try {
                    let res2 = await API.get('users/');
                    // if(res2.data[0].user_type === 'twelve'){
                    //     window.location.href = `http://localhost:3001/cross-authorization/${res.data.token}`
                    // }
                    trackMixpanel('Login', {
                        email: res2.data[0].email,
                        firstName: res2.data[0].first_name,
                        lastName: res2.data[0].last_name,
                    }, {
                        email: res2.data[0].email,
                        firstName: res2.data[0].first_name,
                        lastName: res2.data[0].last_name,
                    }, res2.data[0].email)
                    localStorage.setItem('user', JSON.stringify(res2.data[0]));
                    localStorage.setItem('sub_investors', JSON.stringify(res2.data[0].user_subinvestor));
                    try {
                        let res3 = await API.get('clubs/');
                        localStorage.setItem('clubs', JSON.stringify(res3.data));
                        this.context.resetState();
                        setTimeout(timeout => {
                            this.props.history.push('/');
                        }, 2000)
                    } catch (e) {
                        hideLoader();
                        // console.log(e)
                    }
                } catch (e) {
                    hideLoader();
                    console.log(e.response);
                }
            }
        } catch (e) {
            if (e.response.status === 400) {
                this.setState({error: e.response.data.non_field_errors})
            } else {
                hideLoader();
                //console.log(e.response)
            }
            hideLoader()
        }
    }

    processOtp = async (event) => {
        event.preventDefault();
        showLoader();
        try {
            const res = await API.post('rest-auth/confirm-otp/', {email: this.state.email, otp: this.state.otp})
            if (res.status === 200) {
                $('.modal').modal('hide');
                $(document.body).removeClass('modal-open');
                $('.modal-backdrop').remove();
                await localStorage.setItem('auth', JSON.stringify(res.data));
                await API.set_header(authHeader());
                try {
                    let res2 = await API.get('users/');
                    // if(res2.data[0].user_type === 'twelve'){
                    //     window.location.href = `http://localhost:3001/cross-authorization/${res.data.token}`
                    // }
                    localStorage.setItem('user', JSON.stringify(res2.data[0]));
                    localStorage.setItem('sub_investors', JSON.stringify(res2.data[0].user_subinvestor));
                    try {
                        let res3 = await API.get('clubs/');
                        localStorage.setItem('clubs', JSON.stringify(res3.data));
                        this.context.resetState();
                        setTimeout(timeout => {
                            this.props.history.push('/');
                        }, 2000)
                    } catch (e) {
                        hideLoader();
                        // console.log(e)
                    }
                } catch (e) {
                    hideLoader();
                    console.log(e.response);
                }
            }
        } catch (e) {
            hideLoader();
            toast.error("OTP incorrect or expired")
        }
    }

    resendVerification = async (event) => {
        event.preventDefault();
        showLoader();
        try {
            const res = await API.post('resend-verification/', {email: this.state.email})
            toast.success("Verification link sent to your email successfully");
            hideLoader();
        } catch (e) {
            hideLoader();
            toast.error("Something went wrong. Kindly try again")
        }
    }

    loginView() {
        return (
            <div id="app">
                <section className="section section-2">
                    <div className="row">
                        <div className="single-page single-pageimage construction-bg cover-image">
                            <div className="row">
                                <div className="col-lg-6 login-image">
                                    <div className="log-wrapper text-center img-container">
                                        <img src={LOGO} width="50%"
                                             className="mb-2 mt-4 mt-lg-0 mb-5" alt="logo"/>
                                        <div className="overlay text-center">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 ">
                                    <div className="wrapper wrapper2">
                                        <form onSubmit={this.handleSubmit} className="card-body form-body"
                                              tabIndex="500">
                                            <h3 className="text-dark">Login</h3>
                                            <div className="mail text0left">
                                                <input required
                                                       onChange={event => this.setState({email: event.target.value})}
                                                       type="email" name="mail"/>
                                                <label>Email Address</label>
                                            </div>
                                            <div className="passwd text0left">
                                                <input required
                                                       onChange={event => this.setState({password: event.target.value})}
                                                       type="password" name="password"/>
                                                <label>Password</label>
                                            </div>
                                            {(this.state.error) ? (
                                                <p className="text-left text-danger">{this.state.error}</p>
                                            ) : ''}
                                            <div className="submit">
                                                <input type="submit" value="Login" className="btn btn-blue text-white btn-block"/>
                                            </div>
                                            <p className="mb-2"><Link to="/forget-password">Forgot Password</Link></p>
                                            <p className="text-dark mb-2">Don't have an account?<Link to="/signup"
                                                                                                      className="ml-1">Sign
                                                Up</Link></p>
                                            <p className="text-dark mb-0">Didn't get an email verification link?<Link
                                                to="/login"
                                                onClick={event => this.setState({
                                                    page: 2, email: '', password: '', submitted: false,
                                                    loading: false,
                                                    error: false,
                                                })}
                                                className="ml-1">Resend</Link></p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div id="otpModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h4 className="modal-title" id="example-Modal3">OTP</h4>
                                <button onClick={event => this.setState({otp: ''})} id="close-modal"
                                        type="button"
                                        className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <form onSubmit={event => this.processOtp(event)}>
                                <div className="modal-body">
                                    <div className="form-group mb-0">
                                        <label htmlFor="message-text"
                                               className="form-control-label">Enter OTP:</label>
                                        <input onChange={event => this.setState({otp: event.target.value})}
                                               type="number" className="form-control"
                                               placeholder="Enter opt code sent to your mail"/>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <div className="col-lg-12 mx-auto text-center">
                                        <button onClick={event => this.setState({otp: ''})}
                                                type="button"
                                                className="btn btn-outline-green mr-3"
                                                data-dismiss="modal">Close
                                        </button>
                                        <button type="submit" className="btn btn-main">Continue</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    resendMail() {
        return (
            <div id="app">
                <section className="section section-2">
                    <div className="row">
                        <div className="single-page single-pageimage construction-bg cover-image">
                            <div className="row">
                                <div className="col-lg-6 login-image">
                                    <div className="log-wrapper text-center img-container">
                                        <img src={LOGO} width="50%"
                                             className="mb-2 mt-4 mt-lg-0 mb-5" alt="logo"/>
                                        <div className="overlay text-center">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 ">
                                    <div className="wrapper wrapper2">
                                        <form onSubmit={event => this.resendVerification(event)}
                                              className="card-body form-body"
                                              tabIndex="500">
                                            <h3 className="text-dark">Resend Email Verification</h3>
                                            <div className="mail text0left">
                                                <input required
                                                       onChange={event => this.setState({email: event.target.value})}
                                                       type="email" name="mail"/>
                                                <label>Email Address</label>
                                            </div>
                                            {(this.state.error) ? (
                                                <p className="text-left text-danger">{this.state.error}</p>
                                            ) : ''}
                                            <div className="submit">
                                                <input type="submit" value="Send" className="btn btn-blue text-white btn-block"/>
                                            </div>
                                            <p className="mb-2"><Link to="/login"
                                                                      onClick={event => this.setState({
                                                                          page: 1,
                                                                          email: ''
                                                                      })}>Login
                                                to your account</Link></p>
                                            <p className="text-dark mb-0"><Link to="/signup"
                                                                                className="ml-1">Sign
                                                Up</Link></p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }

    render() {
        if (this.state.page === 1) {
            return this.loginView()
        } else {
            return this.resendMail()
        }
    }


}

export default Login;
