import React, {useState, useContext} from 'react';
import {showLoader, hideLoader, toast} from "../../../_helpers/Loader";
import API from '../../../api';
import {Context} from "../../MyContext";

const SubAccreditation = (props) => {
  // const [qualification, setQualification] = useState(null);
  const context = useContext(Context);

  const [emailing, setEmailing] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "" 
  });
  const [emailSent, setEmailSent] = useState(false);

  const [letter, setLetter] = useState(null)
  const [sameperson, setSameperson] = useState(null)
  const [uploadLetterIfnotSent, setUploadLetterIfnotSent] = useState(null)

  const sendEmail = async() => {
    showLoader()
    console.log(props.qtagsCurrent, "props.qtagsCurrent >>>")

    if (emailing.email === context.user.email || (emailing.firstName === context.user.first_name && emailing.lastName === context.user.last_name)){
      setSameperson(true)
    } else {
      try {
        let res = await API.post('users/send_accreditation_mail_request/', emailing);
        // console.log(postData, "postData")
        // setEmailSent(true)
        props.setAnswers({
          ...props.answers,
          [props.qtagsCurrent]: {
            "Send_a_verification_request_to_my_lawyer" : `${res.data.request_id}`
          },
        })

        window.Swal.fire({
          title: 'Email Sent!',
          text: `Email sent successfully to ${emailing.email} to verify your qualification status.`,
          icon: 'success',
          confirmButtonText: 'Ok',
          confirmButtonColor: "#1F5277",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          // props.checkifNotEmpty(true, "email_sent", true)
          props.setUploadOption(null)
          props.setStage({
            next: props.stage.next + 1,
            prev: props.stage.prev + 1,
            current: props.stage.current + 1
          })
          props.setWidth(parseInt(((props.stage.current + 1)  / props.qtags.length) * 100))
        })

        // //so that the admin can view the reponse create a link with the id
        // props.setOutLyers({
        //   ...props.outLyers,
        //   [props.q.label]: {
        //     "Send_a_verification_request_to_my_lawyer" : `create a table the store the response of the feedback from lawyer/sec. ${res.data.request_id}`
        //   }
        // })

        toast.success(`Request has been successfully sent to ${emailing.firstName} ${emailing.lastName}`);

        hideLoader();
      } catch (err) {
        props.checkifNotEmpty(false, "email_sent", false)
        toast.error("Could not send email, contact the admin");
        hideLoader();
      } 
    }
    hideLoader()
  }


  return (
    <div className="mx-4">
      {/* {!qualification ? (
      <>
        <h5>Select an option below to verify your qualification status</h5><br/>
        <div className='' key={'qtags[stage.current]'}>
          <div className="mx-4">
            <div className="form-group">
              <label class="form-check-label" for={'index1'} >
                <input className="form-check-input" type={props.q.type} name="" id={'index1'} value={"Upload a letter from a registered SEC institution or Lawyer|0"} checked={qualification === "Upload a letter from a registered SEC institution or Lawyer|0" ? true : false} onChange={(e) => {
                    // checkifNotEmpty(e.target.value)
                    // setAnswers({
                    //   ...answers,
                    //   [qtags[stage.current]]: e.target.value,
                    // })
                    // console.log(splitScoreOut(e.target.value)[0])
                    // setUploadOption(splitScoreOut(e.target.value)[0])
                    setQualification(e.target.value)
                }} />
                  Upload a letter from a registered SEC institution or Lawyer
              </label>
            </div>
            <div className="form-group">
              <label class="form-check-label" for={"index2"} >
                <input className="form-check-input" type={props.q.type} name="" id={"index2"} value={"Send a qualification request to my Lawyer or Sec-registered Institution|0"} checked={qualification === "Send a qualification request to my Lawyer or Sec-registered Institution|0" ? true : false} onChange={(e) => {
                    // checkifNotEmpty(e.target.value)
                    // setAnswers({
                    //   ...answers,
                    //   [qtags[stage.current]]: e.target.value,
                    // })
                    // console.log(splitScoreOut(e.target.value)[0])
                    // setUploadOption(splitScoreOut(e.target.value)[0])
                    setQualification(e.target.value)
                }} />
                  Send a qualification request to my Lawyer or Sec-registered Institution
              </label>
              </div>
          </div>
        </div>
      </>): ""} */}
      {props.uploadOption === "Upload a qualification status letter from a registered SEC-registered Institution or Lawyer" || uploadLetterIfnotSent === true ? (
        <>
          <label>
            <span>You can confirm your qualification status by uploading a PDF letter from one of these representatives:</span>
            <br/>
            <ul>
              <li>A representative of a SEC-registered institution</li>
              <li>A licensed attorney (Lawyer)</li>
            </ul><br/>
            <br/>
            <input className='form-file-input' type="file" onChange={async(e) => {
                let link = await props.imageUpload(e)
                setLetter(link)
              
               props.setOutLyers({
                  ...props.outLyers,
                  [props.q.label]: JSON.stringify({
                    "Upload_a_verified_status_letter_from_a_registered_SEC_institution_or_Lawyer": link
                  })
                })
                props.setAnswers({
                  ...props.answers,
                  [props.qtagsCurrent]: JSON.stringify({
                    "Upload_a_verified_status_letter_from_a_registered_SEC_institution_or_Lawyer" : link
                  })
                })
            }} /> 
            {/* <span className='btn btn-primary'>Upload File</span> */}
          </label><br/><br/>
          <small><b style={{"cursor": "pointer"}} onClick={(e) => {
              window.Swal.fire({
                title: 'What is a qualification status letter?',
                html: `<p>A qualification status letter provides an independent representation from a licensed professional that you understand how to make informed investment decisions, understand the difference between your ability to take risks and willingness to take risks  and have an active  investment portfolio. It should include your name and the name of the issuing party on a company letterhead and a statement confirming the accuracy of the information provided.<br/><br/>An example of this letter can be <a href="/Verification-Status-Letter.pdf" target={"_blank"} download><b>downloaded here</b></a></p>`,
                icon: 'info',
                confirmButtonText: 'Ok',
                confirmButtonColor: "#1F5277",
              })
            }}>What is a qualification status letter?</b></small>
          <br/>
          <small>The letter should be on the company's letterhead and must state that you qualify as an investor (as defined in the SEC Rules [Section 321] and the 2019 amendment as regards definition of Qualified Institutional Investors and High Net Worth Investors).</small>
          <br/>
          <span id="link_to_uploaded_license">{letter ? (<span className="text-success" style={{color: "green"}}>Letter Uploaded Successfully <i className="fa fa-check-circle text-success" style={{color: "green"}}></i></span>) : ""}</span>
          {/* <span id="link_to_uploaded_license">{letter ? (<a href={letter} target={"_blank"}>Preview Letter <br/><span className="text-success" style={{color: "green"}}>Letter Uploaded Successfully <i className="fa fa-check-circle text-success" style={{color: "green"}}></i></span></a>) : ""}</span> */}
          <br/><br/>
          <label className='form-check-label px-lg-4' for={props.q.name}>
            <input className='form-check-input' id={props.q.name} key={props.q.label} type="checkbox" placeholder={props.q.placeholder} defaultValue={props.answers[props.qtagsCurrent]} onChange={(e) => {
              if (letter){
                props.checkifNotEmpty(e.target.checked, "letter", e.target.checked)
              }
                // props.setAnswers({
                //   ...props.answers,
                //   [props.qtagsCurrent]: e.target.checked ? e.target.value : "",
                // })
            }} required />
            <p>{"I represent that the documents uploaded are true and complete"}</p>
          </label>
          {/* <button className='btn btn-primary' onClick={(e) => setQualification(null)}>Back</button> */}
        </>
      ):""}
      {props.uploadOption === "Send a qualification request to my Lawyer or Sec-registered Institution" && !uploadLetterIfnotSent ? (
        <>
          <h5><strong>Qualification request letter from Lawyer OR Sec registered institution</strong></h5>
          <p>We will reach out to the person you identify below to request that they verify your qualification status. After they respond, we will be able to process your application
          </p>
          {sameperson ? (<>
            <div class="alert alert-danger" style={{"animation": "none"}}>
            Unfortunately, you can not self-certify, if you hold an applicable professional qualification such as ICAN, ACCA or CPA, please upload certification <a href="#" className="text-white" onClick={() => {
                props.setUploadOption("A Professional license that demonstrates my investment knowledge")
              }}><u><b>here</b></u></a>
            </div>
          </>) : ""}
          { !emailSent ? 
            (<form className="" onSubmit={(e) => {sendEmail()}}>
              <div className="form-group">
                <label>First Name:</label> 
                <input type="text" className="form-control" onChange={(e) => {
                  setEmailing({
                    ...emailing,
                    firstName: e.target.value
                  })
                }} required/>
              </div>
              <div className="form-group">
                <label>Last Name:</label> 
                <input type="text" className="form-control" onChange={(e) => {
                  setEmailing({
                    ...emailing,
                    lastName: e.target.value
                  })
                }} required/>
              </div>
              <div className="form-group">
                <label>Email Address:</label> 
                <input type="email" className="form-control" onChange={(e) => {
                  setEmailing({
                    ...emailing,
                    email: e.target.value
                  })
                }} required/>
              </div>
              <div className="form-group">
                <label>Role:</label>
                <select className="form-control" onChange={(e) => {
                  setEmailing({
                      ...emailing,
                      role: e.target.value
                    })
                  }} required>
                  <option></option>
                  <option>Investment Advisor (SEC Registered)</option>
                  <option>Lawyer</option>
                  {/* <option>Enrolled Agent</option> */}
                </select>
              </div>
              <div className="form-group">
                <button className='btn btn-primary float-right' type="submit">Send Request</button>
              </div>
            </form>) : ("")}
            {/*<p className='text-center'>Email sent successfully to {emailing.email} to verify your qualification status.</p>)
            // <button className='btn btn-secondary' onClick={e => {
            //   props.checkifNotEmpty(true)
            //   // props.setAnswers({
            //   //   ...props.answers,
            //   //   [props.qtagsCurrent]: JSON.stringify({
            //   //     "Send_a_verification_request_to_my_lawyer" : "sent"
            //   //   }),
            //   // })
            // }}>{""}</button>
            
          }
          {/* <button className='btn btn-primary' onClick={(e) => setQualification(null)}>Back</button> */}
        </>
      ):""}
      </div>
  )
}

export default SubAccreditation;