import React, {Component} from 'react';
import API from '../../api';
import CONGRATS from "../../assets/img/congrats.svg";
import NumberFormat from "react-number-format";
import {hideLoader, showLoader, toast, trackMixpanel} from "../../_helpers/Loader";
import {Context} from "../MyContext";
import $ from "jquery";


class Onboarding extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            card: 1,
            personal: false,
            club: false,
            age: '',
            diverse: false,
            increase: false,
            annual: '',
            target: '',
            extra: false,
            btnDisabled: false,
            bvn: null
        };

        this.firstSubmit = this.firstSubmit.bind(this);
        this.secondSubmit = this.secondSubmit.bind(this);
        this.thirdSubmit = this.thirdSubmit.bind(this);
    }

    componentWillMount() {
        if (this.context.user.completed_onboarding) {
            this.props.history.push("/")
        }
        if (this.context.user.user_type === 'twelve') {
            this.props.history.push("/user/twelve/onboarding")
        }
    }

    componentDidMount() {
        trackMixpanel('OnboardingView', false, false, this.context.user.email)
        hideLoader();
    }

    firstSubmit(event) {
        event.preventDefault();
        let value = event.target.start_type.value;
        trackMixpanel('UserOnboarding', false, {
            userStarted: value,
        }, this.context.user.email)

        if (value === 'personal') {
            this.setState({card: this.state.card + 1, personal: true, club: false});
        } else {
            this.setState({card: this.state.card + 1, personal: false, club: true});
        }
    }

    async secondSubmit(event) {
        event.preventDefault();
        console.log(event.target, "event.target")
        const {age, diverse, increase, annual, target, extra} = event.target;
        if (increase.checked || diverse.checked || extra.checked) {
            let postData = {
                age_range: age.value,
                investment_goal: {
                    networth: increase.checked,
                    diversify: diverse.checked,
                    income: extra.checked
                },
                target_networth: this.state.target,
                earnings: this.state.annual
            };
            showLoader();
            try {
                await API.post('users/initData/', postData);
                trackMixpanel('StartPersonalPortfolio', false, {
                    utmContent: this.state.personal ? "personal" : "club",
                    ageRange: age.value,
                    annualIncome: this.state.annual,
                    netWorth: this.state.target,
                    optionsSelected: increase.checked ? 'increase my net worth' : 'diversify my portfolio',
                }, this.context.user.email)
                console.log(this.state.card + 1, "this.statte.card")
                this.setState({card: this.state.card + 1});
            } catch (e) {
                hideLoader();
            }
            hideLoader()
        }
    }

    async thirdSubmit(event) {
        event.preventDefault();

        const {bvn} = event.target;
        console.log(event.target, "event.target", "bvn", bvn, this && this.context)

        if (bvn.value != '') {
            showLoader();
            let postData = {
                bvn: bvn.value
            }

            try {
                //verify bvn
                let res = await API.post('users/verify_bvn/', postData);
                if (res){
                    await this.context.updateUser();
                }

                setTimeout(() => {
                    (this.state.club) && this.props.history.push("/start-club");
                    (this.state.personal) && this.props.history.push("/");
                }, 2000);
                toast.success("Successfully Created");
            } catch (e) {
                console.log(e)
                toast.error("Error BVN cant be validated");
                hideLoader();
            }
        } else {
            await this.context.updateUser();
        }
    }

    async skip(){
        showLoader()
        await this.context.updateUser();
        setTimeout(() => {
            showLoader()
            (this.state.club) && this.props.history.push("/start-club");
            (this.state.personal) && this.props.history.push("/");
        }, 2000);
        hideLoader()
    }

    async finalSubmitForm(event) {
        event.preventDefault();
        this.secondSubmit(event)
    }

    goBack() {
        this.setState(card => this.state.card - 1)
    }


    first_render() {
        return (
            <div id="app">
                <div className="main-wrapper">
                    <nav className="navbar navbar-expand-lg main-navbar">
                        <a className="header-brand" href="index.html">
                            <img src="/assets/img/logo.png" className="header-brand-img" alt="Twelvevest Logo"/>
                        </a>
                    </nav>
                    <div className="app-content mt-5" style={{marginLeft: 'unset'}}>
                        <div className="section">
                            <div className="row">
                                <div className="col-lg-8 col-xl-8 col-md-12 col-sm-12 mx-auto">
                                    <div className="card">
                                        <div className="card-header green text-center">
                                            <h4>Welcome To Twelvevest</h4>
                                        </div>
                                        <div className="card-body">
                                            <form onSubmit={this.firstSubmit}>
                                                <div className="row">
                                                    <div className="col-lg-6 col-lx-6 col-md-12 col-sm-12">
                                                        <div className="row">
                                                            <div
                                                                className="col-lg-7 col-md-12 col-12 col-sm-12 mx-auto">
                                                                <div className="card">
                                                                    <div className="img-container lg-card-bg">
                                                                        <i className="fa fa-user lg-icon"
                                                                           data-toggle="tooltip" title=""
                                                                           data-original-title="fa-user"/>
                                                                    </div>
                                                                    <div className="card-footer text-center">
                                                                        <strong>Start a Personal Portfolio</strong>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-11 mx-auto">
                                                                <p className="text-center">Create a portfolio of
                                                                    investments. Invest in new
                                                                    opportunities in our
                                                                    marketplace. Consolidate all your assets and
                                                                    liabilities, and make
                                                                    informed decisions to grow your net worth.</p>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <input value="personal" required type="radio"
                                                                       name="start_type"
                                                                       className="form-control"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-lx-6 col-md-12 col-sm-12">
                                                        <div className="row">
                                                            <div
                                                                className="col-lg-7 col-md-12 col-12 col-sm-12 mx-auto">
                                                                <div className="card">
                                                                    <div className="img-container lg-card-bg">
                                                                        <i className="fa fa-users lg-icon"
                                                                           data-toggle="tooltip" title=""
                                                                           data-original-title="fa-user"/>
                                                                    </div>
                                                                    <div className="card-footer text-center">
                                                                        <strong>Start an Investment Club</strong>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-11 mx-auto">
                                                                <p className="text-center">Up your money game by
                                                                    collaborating with your
                                                                    tribe to invest in bigger investment opportunities
                                                                    that
                                                                    earn you
                                                                    higher Returns on Investment (ROI).</p>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <input value="club" required type="radio"
                                                                       name="start_type"
                                                                       className="form-control"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-sm-12 text-center">
                                                        <button type="submit"
                                                                className="btn mt-1 mb-0 btn-blue text-white">Next
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    second_render() {
        return (
            <div id="app">
                <div className="main-wrapper">
                    <nav className="navbar navbar-expand-lg main-navbar">
                        <a className="header-brand" href="index.html">
                            <img src="/assets/img/logo.png" className="header-brand-img" alt="Twelvevest Logo"/>
                        </a>
                    </nav>


                    <div className="app-content mt-5" style={{marginLeft: 'unset'}}>
                        <div className="section">
                            <div className="row">
                                <div className="col-lg-8 col-xl-8 col-md-12 col-sm-12 mx-auto">
                                    <div className="card">
                                        <div className="card-header green text-center">
                                            <h4>Basic Information</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="col-sm-12 mx-auto text-center mb-2">
                                                <small>Just before you make a list of what you owe and what you own, we
                                                    would like to
                                                    know a few things about you.
                                                    Think about it as a friend getting to know a new friend better.
                                                </small>
                                            </div>
                                            <form onSubmit={this.secondSubmit} id="onbdingjs">
                                                <div className="">
                                                    <div className="form-group">
                                                        <label htmlFor="age-range">Age Range</label>
                                                        <select required name="age" id="age-range"
                                                                className="form-control">
                                                            <option value="">Select Age Range</option>
                                                            <option value="18-24">18-24</option>
                                                            <option value="25-35">25-35</option>
                                                            <option value="36-45">36-45</option>
                                                            <option value="46-55">46-55</option>
                                                            <option value="56-65">56-65</option>
                                                            <option value="66-75">66-75</option>
                                                            <option value="76-85">76-85</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="income">My Annual Income in (NGN)</label>
                                                        <NumberFormat required name="annual" className="form-control"
                                                                      value={this.state.annual}
                                                                      thousandSeparator={true}
                                                                      prefix={'₦'} onValueChange={(values) => {
                                                            const {formattedValue, value} = values;
                                                            this.setState({annual: value})
                                                        }}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="target">My Target Net-Worth is (NGN)</label>
                                                        <NumberFormat required name="target" className="form-control"
                                                                      value={this.state.target}
                                                                      thousandSeparator={true}
                                                                      prefix={'₦'} onValueChange={(values) => {
                                                            const {formattedValue, value} = values;
                                                            this.setState({target: value})
                                                        }}/>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="increase"
                                                           className="col-lg-4 col-xl-4 col-md-12 col-sm-12">
                                                        <input id="increase" name="increase" type="checkbox"
                                                               className="form-check-inline"/>Increase My Net Worth
                                                    </label>
                                                    <label htmlFor="diverse"
                                                           className="col-lg-4 col-xl-4 col-md-12 col-sm-12">
                                                        <input id="diverse" name="diverse" type="checkbox"
                                                               className="form-check-inline"/>Diversify My Portfolio
                                                    </label>
                                                    <label htmlFor="extra"
                                                           className="col-lg-4 col-xl-4 col-md-12 col-sm-12">
                                                        <input id="extra" name="extra" type="checkbox"
                                                               className="form-check-inline"/>Extra stream of income
                                                        Net Worth
                                                    </label>
                                                </div>
                                                <div className="text-center mb-3">
                                                    <button type="submit" id="bsubmit" className="btn mt-1 mb-0 btn-blue text-white">{"Next"}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    third_render() {
        return (
            <div id="app">
                <div className="main-wrapper">
                    <nav className="navbar navbar-expand-lg main-navbar">
                        <a className="header-brand" href="index.html">
                            <img src="/assets/img/logo.png" className="header-brand-img" alt="Twelvevest Logo"/>
                        </a>
                    </nav>


                    <div className="app-content mt-5" style={{marginLeft: 'unset'}}>
                        <div className="section">
                            <div className="row">
                                <div className="col-lg-8 col-xl-8 col-md-12 col-sm-12 mx-auto">
                                    <div className="card">
                                        <div className="card-header green text-center">
                                            <h4>Please Verify Your BVN</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="col-sm-12 col-lg-6 mx-auto text-center mb-2">
                                                <small>This is part of the KYC implementation needed.
                                                </small>
                                            </div>
                                            <form onSubmit={this.thirdSubmit} id="onbbvn" class="text-center mx-auto col-sm-12 col-lg-6">
                                                <div className="">
                                                    <div className="form-group">
                                                        <label htmlFor="bvn">Enter BVN</label>
                                                        <input required name="bvn" id="bvn"
                                                                className="form-control" onChange={(e) => this.setState({bvn: e.target.value})}/>
                                                    </div>
                                                    
                                                </div>
                                                <div className="text-center mb-3">
                                                    <button type="submit" id="bsubmit" className="btn mt-1 mx-2 mb-0 btn-blue text-white">{"Verify"}
                                                    </button>
                                                    <a href="#" id="ssubmit" className="btn mt-1 mb-0 btn-warning" onClick={(e) => {this.skip()}}>{"Skip"}
                                                    </a>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    last_render() {
        return (
            <div>
                <div className="container" style={{paddingTop: '2rem'}}>
                    <div className="row">
                        <div className="col-md-8 col-lg-8 col-sm-12 mx-auto">
                            <div className="card">
                                <div className="card-header text-center bg-green">
                                    <h5><strong>All Done!</strong></h5>
                                </div>
                                <div className="card-body">
                                    <div className="row mx-auto text-center">
                                        <div className="col-12">
                                            <img width="50%" className="img-responsive"
                                                 src={CONGRATS}/>
                                        </div>
                                        <div className="col-12 text-center congrats-text">
                                            <h1>Congratulations!</h1>
                                        </div>
                                        <div className="col-md-12 col-lg-12 col-sm-12 mx-auto">
                                            <p className="text-center">You have successfully created your
                                                Twelvevest account.<br/>
                                                Buckle up your seatbelt, things are about to get very
                                                interesting on your
                                                financial journey.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        if (this.state.card === 1) {
            return this.first_render()
        } else if (this.state.card === 2) {
            return this.second_render()
        } else if (this.state.card === 3) {
            return this.third_render()
        } else {
            return this.last_render()
        }

    }
}

export default Onboarding;
