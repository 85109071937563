import React, {Component} from 'react';
import Layout from "../Layout";
import {Context} from "../MyContext";
import ReactTooltip from "react-tooltip";
import {Link} from "react-router-dom";
import {CircularProgressbar} from "react-circular-progressbar";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import moment from 'moment';
import API from '../../api';
import {hideLoader, mixpanel, showLoader, toast, trackMixpanel, Utils} from "../../_helpers/Loader";
import $ from "jquery";
import excludeList from '../excludeList';

class SubDashboard extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            investments: [],
            total_investment: '',
            expect_return: '',
            percentage: '',
            portfolio_currency: "NGN",
            roi: '',
            investment_currency: "ALL",
            total_asset: '',
            total_liability: '',
            to_networth: '',
            fraction_networth: 0,
            total_amount: 0,
            total_amount_usd: 0,
            total_amount_gbp: 0,
            total_return: 0,
            total_return_usd: 0,
            total_return_gbp: 0,
            percentage_interest: 0,
            percentage_interest_usd: 0,
            percentage_interest_gbp: 0,
            investment_order: false,
            modal_investment_order: false
        }
    }

    async componentDidMount() {
        trackMixpanel('SubInvestorDashboardView', false, false, this.context.user.email)
        showLoader();
        await this.getInvestment();
        await this.getStats();
        hideLoader();
        this.popInvestmentRollOver();

    }

    removeHeight() {
        var maxHeight = Math.max.apply(null, $(".boxes .card").map(function () {
            return $(this).height();
        }).get());
        $(".boxes .card").css({"height": 'unset'});
    }

    getStats = async () => {
        try {
            const res = await API.get(`sub_investor/${this.context.sub_investor_id}/get_investments/`);
            let data = res.data
            let fraction_networth = parseFloat(data.stats.total_asset) / parseFloat(this.context.select_sub_investor.target_networth) * 100;
            (fraction_networth < 0) && (this.setState({radial_colour: 'circle-danger'}));
            this.setState({
                total_asset: data.stats.total_asset,
                total_asset_usd: data.stats.total_asset_usd,
                total_asset_gbp: data.stats.total_asset_gbp,
                total_liability: data.stats.total_liability,
                total_liability_usd: data.stats.total_liability_usd,
                total_liability_gbp: data.stats.total_liability_gbp,
                fraction_networth: fraction_networth,
                target_networth: this.context.select_sub_investor.target_networth
            })
        } catch (e) {
            // console.log(e.response)
        }
    }

    async getInvestment() {
        try {
            let res = await API.get(`sub_investor/${this.context.sub_investor_id}/get_investments_stats/`);
            if (res) {
                this.setState({
                    investments: res.data.investments,
                    total_amount: res.data.total_amount,
                    total_amount_usd: res.data.total_amount_usd,
                    total_amount_gbp: res.data.total_amount_gbp,
                    total_return: res.data.expected_return,
                    total_return_usd: res.data.expected_return_usd,
                    total_return_gbp: res.data.expected_return_gbp,
                    percentage_interest: (res.data.percentage) ? (res.data.percentage) : 0,
                    percentage_interest_usd: (res.data.percentage_usd) ? (res.data.percentage_usd) : 0,
                    percentage_interest_gbp: (res.data.percentage_gbp) ? (res.data.percentage_gbp) : 0,
                });
            }
        } catch (e) {
            toast.error("Something Went Wrong");
            // console.log(e.response);
        }
    }

    convertCurrency = (value, currency) => {
        if (currency === 'USD') {
            return parseFloat(value) / parseFloat(this.context.user.display_usd);
        } else if (currency === "GBP") {
            return parseFloat(value) / parseFloat(this.context.user.display_gbp);
        } else {
            return parseFloat(this.context.user.display_usd) * parseFloat(value);
        }
    }

    totalSummary(category) {
        if (category === 'current_networth') {
            if (this.state.portfolio_currency === "NGN") {
                return (
                    <strong>₦{parseFloat(parseFloat(this.state.total_asset) + this.convertCurrency(this.state.total_asset_usd, 'NGN') + this.convertCurrency(this.state.total_asset_gbp, "NGN")).toLocaleString()}</strong>
                )
            } else if (this.state.portfolio_currency === "USD") {
                return (
                    <strong>${parseFloat(parseFloat(this.state.total_asset) + this.convertCurrency(this.state.total_asset_usd, 'USD') + this.convertCurrency(this.convertCurrency(this.state.total_asset_gbp, "NGN"), "USD")).toLocaleString()}</strong>
                )
            } else if (this.state.portfolio_currency === "GBP") {
                return (
                    <strong>£{parseFloat(parseFloat(this.state.total_asset_gbp) + this.convertCurrency(this.state.total_asset_gbp, 'GBP') + this.convertCurrency(this.convertCurrency(this.state.total_asset_usd, "NGN"), "GBP")).toLocaleString()}</strong>
                )
            } else {
                return (
                    <strong>₦{parseFloat(this.state.total_asset).toLocaleString()}</strong>
                )
            }
        } else if (category === 'target_networth') {
            if (this.state.portfolio_currency === "NGN") {
                return (
                    <strong>₦{parseFloat(this.state.target_networth).toLocaleString()}</strong>
                )
            } else if (this.state.portfolio_currency === "USD") {
                return (
                    <strong>${parseFloat(this.convertCurrency(this.state.target_networth, "USD")).toLocaleString()}</strong>
                )
            } else if (this.state.portfolio_currency === "GBP") {
                return (
                    <strong>£{parseFloat(this.convertCurrency(this.state.target_networth, "GBP")).toLocaleString()}</strong>
                )
            } else {
                return (
                    <strong>₦{parseFloat(this.state.target_networth).toLocaleString()}</strong>
                )
            }
        } else if (category === 'total_asset') {
            if (this.state.portfolio_currency === "NGN") {
                return (
                    <strong>₦{parseFloat((parseFloat(this.state.total_asset) + this.convertCurrency(this.state.total_asset_usd, "NGN") + this.convertCurrency(this.state.total_asset_gbp, "NGN")).toFixed(2)).toLocaleString()}</strong>
                )
            } else if (this.state.portfolio_currency === "USD") {
                return (
                    <strong>${parseFloat((parseFloat(this.state.total_asset_usd) + this.convertCurrency(this.state.total_asset_ngn, "USD") + this.convertCurrency(this.convertCurrency(this.state.total_asset_gbp, "NGN"), "USD")).toFixed(2)).toLocaleString()}</strong>
                )
            } else if (this.state.portfolio_currency === "GBP") {
                return (
                    <strong>£{parseFloat((parseFloat(this.state.total_asset_gbp) + this.convertCurrency(this.state.total_asset_ngn, "GBP") + this.convertCurrency(this.convertCurrency(this.state.total_asset_usd, "NGN"), "GBP")).toFixed(2)).toLocaleString()}</strong>
                )
            } else {
                return (
                    <strong>₦{parseFloat(parseFloat(this.state.total_asset).toFixed(2)).toLocaleString()}</strong>
                )
            }
        } else if (category === 'total_liability') {
            if (this.state.portfolio_currency === "NGN") {
                return (
                    <strong>₦{parseFloat((parseFloat(this.state.total_liability) + this.convertCurrency(this.state.total_liability_usd, "NGN") + this.convertCurrency(this.state.total_liability_gbp, "NGN")).toFixed(2)).toLocaleString()}</strong>
                )
            } else if (this.state.portfolio_currency === "USD") {
                return (
                    <strong>${parseFloat((parseFloat(this.state.total_liability_usd) + this.convertCurrency(this.state.total_liability, "USD") + this.convertCurrency(this.convertCurrency(this.state.total_liability_gbp, "NGN"), "USD")).toFixed(2)).toLocaleString()}</strong>
                )
            } else if (this.state.portfolio_currency === "GBP") {
                return (
                    <strong>£{parseFloat((parseFloat(this.state.total_liability_gbp) + this.convertCurrency(this.state.total_liability, "GBP") + this.convertCurrency(this.convertCurrency(this.state.total_liability_usd, "NGN"), "GBP")).toFixed(2)).toLocaleString()}</strong>
                )
            } else {
                return (
                    <strong>₦{parseFloat(parseFloat(this.state.total_liability).toFixed(2)).toLocaleString()}</strong>
                )
            }
        }
    }

    updateInvestmentOrder = async (e, id, data) => {
        e.preventDefault();
        console.log(e, id, data)

        showLoader()
        try {
            let res = await API.put(`sub_investor/${id}/`, data);
            if (res) {
                hideLoader()
                toast.success("Investment Order has been updated to roll over!");
                setTimeout(() => {
                    window.location.reload() 
                }, 3000);
            }
        } catch (e) {
            hideLoader()
            toast.error("Something Went Wrong");
        }
    }

    popInvestmentRollOver = async () => {
        try {
            let res = await API.get('sub_investor/get_roll_over_investment/');
            if (res) {
                if(res.data && res.data.roll_over == null && res.data.investment.can_roll_over == true){
                    this.setState({
                        modal_investment_order: res.data
                    });
                    $('#alert').modal('show');
                }
            }
        } catch (e) {
            // toast.error("Something Went Wrong");
            console.log("No matching investment")
        } 
    }

    render() {
        return (
            <Layout active_menu={'dash'} user_state={'subinvestor'} context={this.context}>
                <ReactTooltip/>
                <div className="section">
                    <div className="breadcrumb" style={{marginBottom: 0}}>
                        <h5>Dashboard</h5>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12 col-xl-9 col-md-6 col-sm-12">
                                            <div className="row">
                                                <div className="col-lg-4 col-xl-4 col-md-12 col-sm-12">
                                                    <button onClick={event => {
                                                        document.getElementById('portfolio').scrollIntoView({'behavior': 'smooth'})
                                                    }} className="btn btn-block btn-social m-b-5 btn-blue text-white"><i
                                                        className="fa fa-folder-open"/> My Portfolio
                                                    </button>
                                                </div>
                                                <div className="col-lg-4 col-xl-4 col-md-12 col-sm-12">
                                                    <button onClick={event => {
                                                        document.getElementById('investment').scrollIntoView({'behavior': 'smooth'})
                                                    }} className="btn btn-block btn-social m-b-5 btn-blue text-white"><i
                                                        className="fa fa-bar-chart"/> My Investments
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col-lg-4 col-xl-3 col-md-6 col-sm-12 text-xl-right mr-sm-auto">
                                            <Link to={`/sub-investor/${this.context.sub_investor_id}/manage-portfolio`}
                                                  className="btn btn-social btn-blue text-white text-white m-b-5 btn-sm-block btn-xl-block">
                                                <i className="fa fa-cog"/> Manage Portfolio
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" id="portfolio">
                        <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <h4>My Portfolio</h4>
                                        </div>
                                        <div className="col-sm-6">
                                            <select
                                                onChange={event => this.setState({portfolio_currency: event.target.value})}
                                                value={this.state.portfolio_currency}
                                                className="form-control col-lg-7 col-xl-5 text-right float-right pulse-out">
                                                <option value="">Total NGN, USD and GBP</option>
                                                <option value="NGN">Total NGN value</option>
                                                <option value="USD">Total USD value</option>
                                                <option value="GBP">Total GBP value</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row mb-4">
                                        <div className="col-lg-6 mx-auto justify-content-center text-center">
                                            <div className="mb-4 mt-4 circular-chart"><h5 className="text-blue"
                                                                                          style={{
                                                                                              fontWeight: 'bolder',
                                                                                              fontSize: '16px'
                                                                                          }}>
                                                {(parseInt(this.state.fraction_networth) <= 0) && 'How are you doing with your goal? Taking action?'}
                                                {(parseInt(this.state.fraction_networth) <= 39 && parseInt(this.state.target) >= 1) && 'Keep going. You are in it to win it!'}
                                                {(parseInt(this.state.fraction_networth) <= 70 && parseInt(this.state.target) >= 40) && 'You are phenomenal, you are almost there. Keep going.'}
                                                {(parseInt(this.state.fraction_networth) <= 99 && parseInt(this.state.target) >= 71) && 'You have taken this so seriously. Keep pushing.'}
                                                {(parseInt(this.state.fraction_networth) === 100) && 'Congratulations. You have achieved your goal!'}
                                                {(parseInt(this.state.fraction_networth) > 100) && 'You are on a roll. You are an overachiever!'}</h5>
                                            </div>
                                            <div
                                                data-tip={`You have achieved ${this.state.target}% of your net worth goal`}>
                                                <CircularProgressbar
                                                    className={`mx-auto ${this.state.radial_colour} text-center`}
                                                    styles={{width: '70%'}}
                                                    value={parseInt(this.state.fraction_networth).toFixed(2)}
                                                    text={`${parseInt(this.state.fraction_networth)}%`}>
                                                </CircularProgressbar></div>
                                            <br/><br/>
                                            <strong className="text-blue">{parseInt(this.state.fraction_networth)}% of
                                                target
                                                net worth achieved.</strong>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-lg-3">
                                            <div className="list-group">
                                                <a href="#"
                                                   className="list-group-item list-group-item-action flex-column align-items-start">
                                                    <div className="d-sm-flex w-100 justify-content-between">
                                                        <h5 className="mb-1">
                                                            {this.totalSummary('current_networth')}
                                                        </h5>
                                                    </div>
                                                    <p className="mb-1">Current Net Worth</p>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="list-group">
                                                <a href="#"
                                                   className="list-group-item list-group-item-action flex-column align-items-start">
                                                    <div className="d-sm-flex w-100 justify-content-between">
                                                        <h5 className="mb-1">
                                                            {this.totalSummary('target_networth')}
                                                        </h5>
                                                    </div>
                                                    <p className="mb-1">Target Net Worth</p>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="list-group">
                                                <a href="#"
                                                   className="list-group-item list-group-item-action flex-column align-items-start">
                                                    <div className="d-sm-flex w-100 justify-content-between">
                                                        <h5 className="mb-1">
                                                            {this.totalSummary('total_asset')}
                                                        </h5>
                                                    </div>
                                                    <p className="mb-1">Total Assets</p>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="list-group">
                                                <a href="#"
                                                   className="list-group-item list-group-item-action flex-column align-items-start">
                                                    <div className="d-sm-flex w-100 justify-content-between">
                                                        <h5 className="mb-1">
                                                            {this.totalSummary('total_liability')}
                                                        </h5>
                                                    </div>
                                                    <p className="mb-1">Total Liability</p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {(this.state.portfolio_currency === "") ? (
                                        <div>
                                            <div className="row mb-2">
                                                <div className="col-lg-3">
                                                    <div className="list-group">
                                                        <a href="#"
                                                           className="list-group-item list-group-item-action flex-column align-items-start">
                                                            <div className="d-sm-flex w-100 justify-content-between">
                                                                <h5 className="mb-1">
                                                                    <strong>${parseFloat(this.state.total_asset_usd).toLocaleString()}</strong>
                                                                </h5>
                                                            </div>
                                                            <p className="mb-1">Current Net Worth</p>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="list-group">
                                                        <a href="#"
                                                           className="list-group-item list-group-item-action flex-column align-items-start">
                                                            <div className="d-sm-flex w-100 justify-content-between">
                                                                <h5 className="mb-1">
                                                                    <strong>${parseFloat(parseFloat(this.context.select_sub_investor.target_networth) / parseFloat(this.context.user.display_usd)).toFixed(2).toLocaleString()}</strong>
                                                                </h5>
                                                            </div>
                                                            <p className="mb-1">Target Net Worth</p>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="list-group">
                                                        <a href="#"
                                                           className="list-group-item list-group-item-action flex-column align-items-start">
                                                            <div className="d-sm-flex w-100 justify-content-between">
                                                                <h5 className="mb-1">
                                                                    <strong>${parseFloat(this.state.total_asset_usd).toLocaleString()}</strong>
                                                                </h5>
                                                            </div>
                                                            <p className="mb-1">Total Assets</p>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="list-group">
                                                        <a href="#"
                                                           className="list-group-item list-group-item-action flex-column align-items-start">
                                                            <div className="d-sm-flex w-100 justify-content-between">
                                                                <h5 className="mb-1">
                                                                    <strong>${parseFloat(this.state.total_liability_usd).toLocaleString()}</strong>
                                                                </h5>
                                                            </div>
                                                            <p className="mb-1">Total Liability</p>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <div className="list-group">
                                                        <a href="#"
                                                           className="list-group-item list-group-item-action flex-column align-items-start">
                                                            <div className="d-sm-flex w-100 justify-content-between">
                                                                <h5 className="mb-1">
                                                                    <strong>£{parseFloat(this.state.total_asset_gbp).toLocaleString()}</strong>
                                                                </h5>
                                                            </div>
                                                            <p className="mb-1">Current Net Worth</p>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="list-group">
                                                        <a href="#"
                                                           className="list-group-item list-group-item-action flex-column align-items-start">
                                                            <div className="d-sm-flex w-100 justify-content-between">
                                                                <h5 className="mb-1">
                                                                    <strong>£{parseFloat(parseFloat(this.context.select_sub_investor.target_networth) / parseFloat(this.context.user.display_gbp)).toFixed(2).toLocaleString()}</strong>
                                                                </h5>
                                                            </div>
                                                            <p className="mb-1">Target Net Worth</p>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="list-group">
                                                        <a href="#"
                                                           className="list-group-item list-group-item-action flex-column align-items-start">
                                                            <div className="d-sm-flex w-100 justify-content-between">
                                                                <h5 className="mb-1">
                                                                    <strong>£{parseFloat(this.state.total_asset_gbp).toLocaleString()}</strong>
                                                                </h5>
                                                            </div>
                                                            <p className="mb-1">Total Assets</p>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="list-group">
                                                        <a href="#"
                                                           className="list-group-item list-group-item-action flex-column align-items-start">
                                                            <div className="d-sm-flex w-100 justify-content-between">
                                                                <h5 className="mb-1">
                                                                    <strong>£{parseFloat(this.state.total_liability_gbp).toLocaleString()}</strong>
                                                                </h5>
                                                            </div>
                                                            <p className="mb-1">Total Liability</p>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : ''}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" id="investment">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-lg-6 col-sm-12">
                                            <h4 className="float-left">My Investments</h4>
                                        </div>
                                        <div
                                            className="col-lg-6 col-sm-12 text-xl-right text-lg-right text-md-left">
                                            <Link className="btn btn-blue text-white text-white m-b-5 mr-3"
                                                  to={`/sub-investor/${this.context.sub_investor_id}/marketplace`}>Find
                                                Investments
                                            </Link>
                                            <Link to="/investments"
                                                  className="btn m-b-5 btn-blue text-white text-white"> View All
                                            </Link>
                                            <select
                                                onChange={event => {
                                                    this.setState({investment_currency: event.target.value});
                                                    this.removeHeight()
                                                }}
                                                value={this.state.investment_currency}
                                                className="form-control col-lg-7 col-xl-5 text-right float-right ml-3">
                                                <option value="ALL">Total NGN, USD and GBP</option>
                                                <option value="NGN">Total NGN value</option>
                                                <option value="USD">Total USD value</option>
                                                <option value="GBP">Total GBP value</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row boxes">
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                    <span className="text-blue"><i
                                                                        className="fa fa-bar-chart text-blue"/> <strong>Total Investment</strong> <i
                                                                        data-tip="Total Amount Invested "
                                                                        className="fa fa-info-circle text-blue"/></span>
                                                                {["NGN"].includes(this.state.investment_currency) ? (
                                                                    <h3 className="text-blue mb-0 mt-2">₦{parseFloat(parseFloat(this.state.total_amount).toFixed(2)).toLocaleString()}</h3>
                                                                ) : ["USD"].includes(this.state.investment_currency) ? (
                                                                    <React.Fragment>
                                                                        <h3 className="text-blue mb-0">${parseFloat(parseFloat(this.state.total_amount_usd).toFixed(2)).toLocaleString()}</h3>
                                                                    </React.Fragment>
                                                                ) : ["GBP"].includes(this.state.investment_currency) ? (
                                                                    <React.Fragment>
                                                                        <h3 className="text-blue mb-0">£{parseFloat(parseFloat(this.state.total_amount_gbp).toFixed(2)).toLocaleString()}</h3>
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <React.Fragment>
                                                                        <h3 className="text-blue mb-0 mt-2">₦{parseFloat(parseFloat(this.state.total_amount).toFixed(2)).toLocaleString()}</h3>
                                                                        <hr className="line-2"/>
                                                                        <h3 className="text-blue mb-0">${parseFloat(parseFloat(this.state.total_amount_usd).toFixed(2)).toLocaleString()}</h3>
                                                                        <hr className="line-2"/>
                                                                        <h3 className="text-blue mb-0">£{parseFloat(parseFloat(this.state.total_amount_gbp).toFixed(2)).toLocaleString()}</h3>
                                                                    </React.Fragment>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-blue"><i
                                                                        className="fa fa-reply-all text-blue"/> <strong>Expected ROI</strong>  <i
                                                                        data-tip="Expected return on investment"
                                                                        className="fa fa-info-circle text-right text-blue"/></span>
                                                                {["NGN"].includes(this.state.investment_currency) ? (
                                                                    <h3 className="text-blue mb-0 mt-2">₦{parseFloat(parseFloat(this.state.total_return).toFixed(2)).toLocaleString()}</h3>
                                                                ) : ["USD"].includes(this.state.investment_currency) ? (
                                                                    <React.Fragment>
                                                                        <h3 className="text-blue mb-0">${parseFloat(parseFloat(this.state.total_return_usd).toFixed(2)).toLocaleString()}</h3>
                                                                    </React.Fragment>
                                                                ) : ["GBP"].includes(this.state.investment_currency) ? (
                                                                    <React.Fragment>
                                                                        <h3 className="text-blue mb-0">£{parseFloat(parseFloat(this.state.total_return_gbp).toFixed(2)).toLocaleString()}</h3>
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <React.Fragment>
                                                                        <h3 className="text-blue mb-0 mt-2">₦{parseFloat(parseFloat(this.state.total_return).toFixed(2)).toLocaleString()}</h3>
                                                                        <hr className="line-2"/>
                                                                        <h3 className="text-blue mb-0">${parseFloat(parseFloat(this.state.total_return_usd).toFixed(2)).toLocaleString()}</h3>
                                                                        <hr className="line-2"/>
                                                                        <h3 className="text-blue mb-0">£{parseFloat(parseFloat(this.state.total_return_gbp).toFixed(2)).toLocaleString()}</h3>
                                                                    </React.Fragment>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-blue"><i
                                                                        className="fa fa-signal text-blue"/> <strong>Interest</strong> <i
                                                                        data-tip="Total amount you will receive from your investments"
                                                                        className="fa fa-info-circle text-right text-blue"/></span>
                                                                {["NGN"].includes(this.state.investment_currency) ? (
                                                                    <h3 className="text-blue mb-0 mt-2">₦{parseFloat(parseFloat(this.state.total_return - this.state.total_amount).toFixed(2)).toLocaleString()}</h3>
                                                                ) : ["USD"].includes(this.state.investment_currency) ? (
                                                                    <React.Fragment>
                                                                        <h3 className="text-blue mb-0">${parseFloat(parseFloat(this.state.total_return_usd - this.state.total_amount_usd).toFixed(2)).toLocaleString()}</h3>
                                                                    </React.Fragment>
                                                                ) : ["GBP"].includes(this.state.investment_currency) ? (
                                                                    <React.Fragment>
                                                                        <h3 className="text-blue mb-0">£{parseFloat(parseFloat(this.state.total_return_gbp - this.state.total_amount_gbp).toFixed(2)).toLocaleString()}</h3>
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <React.Fragment>
                                                                        <h3 className="text-blue mb-0 mt-2">₦{parseFloat(parseFloat(this.state.total_return - this.state.total_amount).toFixed(2)).toLocaleString()}</h3>
                                                                        <hr className="line-2"/>
                                                                        <h3 className="text-blue mb-0">${parseFloat(parseFloat(this.state.total_return_usd - this.state.total_amount_usd).toFixed(2)).toLocaleString()}</h3>
                                                                        <hr className="line-2"/>
                                                                        <h3 className="text-blue mb-0">£{parseFloat(parseFloat(this.state.total_return_gbp - this.state.total_amount_gbp).toFixed(2)).toLocaleString()}</h3>
                                                                    </React.Fragment>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-blue"><i
                                                                        className="fa fa-line-chart text-blue"/> <strong>Percentage Interest</strong> <i
                                                                        data-tip="Percentage Interest gained from  your investments"
                                                                        className="fa fa-info-circle text-right text-blue"/></span>
                                                                {["NGN"].includes(this.state.investment_currency) ? (
                                                                    <h3 className="text-blue mb-0 mt-2">₦{parseFloat(this.state.percentage_interest).toFixed(2).toLocaleString()}</h3>
                                                                ) : ["USD"].includes(this.state.investment_currency) ? (
                                                                    <React.Fragment>
                                                                        <h3 className="text-blue mb-0">${parseFloat(parseFloat(this.state.percentage_interest_usd).toFixed(2)).toLocaleString()}</h3>
                                                                    </React.Fragment>
                                                                ) : ["GBP"].includes(this.state.investment_currency) ? (
                                                                    <React.Fragment>
                                                                        <h3 className="text-blue mb-0">£{parseFloat(parseFloat(this.state.percentage_interest_gbp).toFixed(2)).toLocaleString()}</h3>
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <React.Fragment>
                                                                        <h3 className="text-blue mb-0 mt-2">{parseFloat(this.state.percentage_interest).toFixed(2)}%</h3>
                                                                        <hr className="line-2"/>
                                                                        <h3 className="text-blue mb-0">{parseFloat(parseFloat(this.state.percentage_interest_usd).toFixed(2)).toLocaleString()}</h3>
                                                                        <hr className="line-2"/>
                                                                        <h3 className="text-blue mb-0">{parseFloat(parseFloat(this.state.percentage_interest_gbp).toFixed(2)).toLocaleString()}</h3>
                                                                    </React.Fragment>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped mb-0 text-nowrap">
                                            <tbody>
                                            <tr>
                                                <th>Name</th>
                                                <th>Amount</th>
                                                <th>Unit/Token</th>
                                                <th>ROI(%)</th>
                                                <th>Expected Return</th>
                                                <th>Purchased Date</th>
                                                <th>Maturity Date</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                            {this.state.investments.map(item => (
                                                <tr key={item.id}>
                                                    <td>{item.investment.in_trust ? (
                                                                    <i className="fas fa-shield-alt text-dark"/>) : ''} {item.investment.title} by {item.investment.company}</td>
                                                    <td><NumberFormat
                                                        renderText={value => <div>{value}</div>}
                                                        prefix={Utils.currencyToSym(item.currency)}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        value={parseFloat(item.amount).toFixed(2)}/>
                                                    </td>
                                                    <td>{item.purchased_units}</td>
                                                    <td>{item.roi}{item.investment.payout_interval ?  "/" + `${item.investment.payout_interval}` : ""}</td>
                                                    <td><NumberFormat
                                                        renderText={value => <div>{value}</div>}
                                                        prefix={Utils.currencyToSym(item.currency)}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        value={parseFloat(item.expected_return).toFixed(2)}/></td>
                                                    <td><Moment
                                                        format="MMM D, Y">{item.created_at}</Moment>
                                                    </td>
                                                    <td><Moment
                                                        format="MMM D, Y">{item.maturity_date}</Moment>
                                                    </td>
                                                    <td>
                                                    {item.investment.id == 27 && excludeList.includes(this.context.user.email.toLowerCase()) ? "Active" : (item.investment.payout_status == "pending" || item.investment.payout_status == "none" ? "Active" : "Completed")}
                                                    </td>
                                                    {(item.club_tag) ?
                                                        <td><span className="fa fa-list"/></td> : (item.investment.multiple_payout) ? (
                                                                            <td><span
                                                                                onClick={event => {
                                                                                    this.processDates(item.investment.payout_dates, item.value, item.investment.inv_roi, item.payout_times)
                                                                                    this.setState({investment_order: item})
                                                                                }}
                                                                                data-target="#dateModal"
                                                                                data-toggle="modal"
                                                                                style={{'cursor': 'pointer'}}
                                                                                className="fa fa-eye"/>
                                                                            </td>
                                                                        ) :
                                                        <td><span className="fa fa-ban"/></td>
                                                    }
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                        {this.state.investments.length > 0 ? 
                                            <div className='text-blue text-center'><Link to="/investments"
                                                      className="text-blue"> See more </Link></div> : ""
                                            }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="dateModal" tabIndex="-1" role="dialog"
                         aria-labelledby="dateModalLabel">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header bg-green">
                                    <h4 className="modal-title" id="dateModalLabel">Payout Dates</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="text-red text-center">
                                        {this.state.investment_order ? this.state.investment_order.roll_over == null && this.state.investment_order.investment.can_roll_over && 5 < (moment(this.state.investment_order.maturity_date).diff(moment(new Date()), 'days')) < 40 ? (<i className="text-primary text-center">* This investment will mature in {this.state.investment_order.maturity_date}. Do you want to roll over this investment? <br/><br/> 
                                        <div className="text-center"><button onClick={ e => this.updateInvestmentOrder(e, this.state.investment_order.id, {roll_over: false})} className="btn btn-negative-primary btn-outline-primary text-primary"> No </button>&nbsp;&nbsp;<button onClick={ e => this.updateInvestmentOrder(e, this.state.investment_order.id, {roll_over: true, roll_over_date: new Date().toISOString().split('T')[0]})} className="btn-primary btn"> Yes </button><br/><br/></div></i>) : "" : ""}
                                    </div>
                                    <div className="list-group">
                                        {(this.state.multiple_data || []).map(item => (
                                            <a href="#"
                                               className="list-group-item list-group-item-action flex-column align-items-start">
                                                <div className="d-flex w-100 justify-content-between">
                                                    <h5 className="mb-1">{item.date}</h5>
                                                    <small>{(item.paid) ? 'Completed' : 'Upcoming payout'}</small>
                                                </div>
                                                <p className="mb-1">₦{item.amount.toLocaleString()}</p>
                                            </a>
                                        ))}
                                    </div>
                                </div>
                                <div className="modal-footer text-center">
                                    <button type="button" className="btn no-outline-green"
                                            data-dismiss="modal">Close
                                    </button>
                                </div>
                            </div>
                        </div>
                </div>
                <div className="modal fade" id="alert" tabIndex="-1" role="dialog"
                        aria-labelledby="alertLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h4 className="modal-title" id="alertLabel">Investment Payout</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">

                                <div className="list-group text-center">
                                    <a href="#"
                                        className="list-group-item list-group-item-action flex-column align-items-start">
                                            {this.state.modal_investment_order && this.state.modal_investment_order != false ? (<p className="mb-1">Your investment <b>{this.state.modal_investment_order &&this.state.modal_investment_order.investment && this.state.modal_investment_order.investment.title}</b> will mature on {this.state.modal_investment_order.maturity_date}. Do you want to rollover this investment? <br/><br/><div className="text-center"><button onClick={ e => this.updateInvestmentOrder(e, this.state.modal_investment_order.id, {roll_over: false})} className="btn btn-social btn-negative-primary btn-outline-primary text-primary"> No </button>&nbsp;&nbsp;<button onClick={ e => this.updateInvestmentOrder(e, this.state.modal_investment_order.id, {roll_over: true, roll_over_date: new Date().toISOString().split('T')[0]})} className="btn-primary btn"> Yes </button><br/><br/></div></p>) : ""}
                                    </a>
                                </div>
                            </div>
                            <div className="modal-footer text-center">
                                <button type="button" className="btn no-outline-green"
                                        data-dismiss="modal">Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default SubDashboard;
