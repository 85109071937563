import React from "react";

const GeneralInfo = () => {
  return (
    <>
      <div className="row py-2">
        <h4 className="col-12"><b>General Information</b></h4>
        <span className="col-12" style={{fontSize: "11px"}}>
          Twelve provides this account statement as a summary of your investment, which will be provided every month in which your account has activity, and at minimum, quarterly. <br/><br/>
          Activity shows your detailed transaction information during the statement period, including the transaction date, settlement date, security purchased or sold, quantities and prices. All activity is listed in date order. <br/><br/>
          Alternative Asset refer to assets that are not defined as traditional asset classes. Alternative Assets include certain publicly or non-publicly traded alternative investment assets like Agro-finance projects, real estate, and venture capital funds etc.<br/><br/>
          Asset Allocation Summary refers to how different assets are currently allocated in your investment portfolio. This includes domestic (Nigerian) equities, fixed income securities, mutual funds, short-term investments (cash equivalents, money market funds, etc.), and alternative assets. <br/><br/>
          The portfolio allocations are separated and sorted into whichever asset class they best fit based on holdings data provided by a third-party vendor. The Alternative Assets category includes non-asset class holdings (i.e., identified holdings that cannot be categorized as stocks or short-term investments). Any instrument which qualifies as an alternative asset will be categorized and so named Alternative Assets. The purpose of this breakdown is to help you manage your investment risk by asset class. You should regularly review to make sure that the asset allocation reflects your risk tolerance and investment goals. Note that unsettled transactions may materially impact the asset allocation information presented by inflating either a particular asset class or the available short-term investments. <br/><br/>
          Balances Summary: The Transaction Date Balance and Settlement Date Balances are displayed here for quick reference. <br/><br/>
          Broker: This means the firm, entity or company that has primarily made a security available for trade and transactions on Twelve. <br/><br/>
          Vendor means the firm, entity or company that has made an alternative asset available for transactions on Twelve. <br/><br/>
          Fund Manager means the firm, entity or company managing the securities portfolio of investors on Twelve.<br/><br/>
          Currency shows your aggregated Balances Summary as maintained on the Twelve digital platform reflected in the denomination of the asset.<br/><br/>
          Available cash means uninvested cash held in your wallet. <br/><br/>
          Holdings - the holdings section of your statement includes only completed transactions and is organized by asset class type. The Unrealized Gain/Loss is the hypothetical value of the gain or loss that would be realized if assets were sold at the indicated price.<br/><br/>
          The Total Cost represents the aggregate value across all transactions in a particular security or alternative asset type available on Twelve and is inclusive of all applicable fees, commissions, or adjustments; is not available for money market mutual funds and is sometimes not available for securities transferred in without a cost basis. <br/><br/>
          For agro-finance assets, Twelve will display all expected and unrealized gains as determined by the vendor with investors noting said unrealized gains as the hypothetical value of the asset that would be realized upon maturity and in the event of no defaults from the vendor. <br/><br/>
          For assets in the real estate category, Twelve will duly display its cost price inclusive or exclusive of applicable fees, including but not limited to regulatory and management fees and commissions as specifically communicated to investors by the vendors through Twelve before the assets are made available for transaction.<br/><br/>
          For assets in venture capital funds category, investors shall note the minimum and maximum fund life as communicated by the fund manager. Twelve, relying on information periodically provided by the fund manager may give hypothetical projections of gains or losses, which projections will be deemed only as mere projections till the end of the commitment period to confirm or disprove said projections.<br/><br/>
          Market Price means the price of the asset as obtained from its broker and/or vendor. Values displayed for securities and other assets classified as alternative assets may be based on the closing price, the mean between the bid and ask, or other method. <br/><br/>
          Certain Alternative Assets may not have an available Market Price as it may be difficult or impossible to ascertain. Any estimated value as received from the vendor who made the asset available on Twelve  or calculated by the Twelve digital platform  and which is reflected on the account statement is for informational purposes only, may not be current, and may be significantly different than the actual market value or the liquidation value of such Alternative Asset. <br/><br/>
          The investor shall note that market prices shall not mean actual market value and Twelve will not be held responsible for any changes that occur. The investor shall also note that all investments carry risk and all investment decisions remain the responsibility of the individual/holder of the account. Twelve makes no guarantee that the investment made will result in profits or that they will not result in losses as investors are encouraged to get personal advice from professional investment advisors. The investor acknowledges that the he/she  will only invest after carefully reviewing and assessing the terms of the investment and further acknowledges that the investor is aware of the risk of making an investment.<br/><br/>
          Settlement Date Balance means the balance of your account on the date that the transaction settles.<br/><br/>
          Interest will be paid on interest-earning assets i.e. fixed income securities, mutual funds, short-term investments, Eurobonds, cash equivalents, money market funds within *insert period* and appear on your statement as interest on investment. <br/><br/>
          Fixed income securities, mutual funds, short-term investments, Eurobonds, cash equivalents, money market funds are held with *insert broker*.<br/><br/>
          Transaction means activities involving the buying and selling of assets on Twelve, assets being securities and alternative assets <br/><br/>
          Transaction Date Balance means the balance of your account on the date that you make a transaction on the assets available on Twelve. <br/><br/>
          Valuation Summary provides a summary which reflects the total value of holdings in your account. The change in your account value is organized by cash flow activity followed by investment value changes. <br/><br/>
          This Period represents the value from the last statement issuance date to the current statement period.<br/><br/>
          This Year represents the accumulated value from January 1st of the current year to the period the statement is issued. <br/><br/>
          The Net Change in Portfolio Value represents any additions, subtractions, and change in the market value of your investments since the last statement period. 
        </span>
      </div>
    </>
  )
}

export default GeneralInfo;