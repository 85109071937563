import React, {useEffect, useState, useContext} from "react";
import { Link } from "react-router-dom";
import { hideLoader, showLoader } from "../../_helpers/Loader";
import Layout from "../Layout";
import { Context } from "../MyContext";
import API from '../../api';
import $ from 'jquery';
import moment from 'moment'
import 'bootstrap-datepicker';
// import { pdfFromReact } from "generate-pdf-from-react-html";
import { pdfFromReact } from "./pdfFromReactCustom";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import { mixpanel, toast, trackMixpanel, Utils} from "../../_helpers/Loader";
import './style.css';
import _ from 'lodash';
import GeneralInfo from './generalInfo';

// import ReactPDF, { Document, Page, Text, Image, Font } from '@react-pdf/renderer';


const ClubDetailReport = (props) => {
    const [resourceObj, setResourceObj] = useState({});
    const [page, setPage] = useState([]);

    const [assets, setAssets] = useState([]);
    const [display, setDisplay] = useState(false);
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const context = useContext(Context);

    const getClubInvestment = async(event=null)=> {
        if(event) event.preventDefault();
        showLoader()
        let url = `clubs/get_investments_report/`
        if (startDate && endDate){
            url = `clubs/get_investments_report/?start_date=${startDate}&end_date=${endDate}`
        }
        try {
                let res = await API.get(url);
                console.log(res);
                if (res) {
                    setPage(res.data);
                    if (res.data.length > 0){
                    setDisplay(true)
                    }
                }
        } catch (e) {
            // console.log(e.response);
        }
        hideLoader()
    }

    const getClubAsset = async () => {
        try {
            let res = await API.get('clubs/get_asset_list_report/');
            if (res) {
                setAssets(res.data);
                if (res.data.length > 0){
                    setDisplay(true)
                }
            }
        } catch (e) {
            // console.log(e.response);
        }
        hideLoader()
    }

    const rateConverted = async(currency, from_amount, to_amount)=>{
        console.log(currency, from_amount, to_amount, "!!!!!!!!!!!!!!!!!!!!!11111")
        try{
            if (currency !== "NGN") {
                return (parseFloat(from_amount) / parseFloat(to_amount)).toFixed(2).toLocaleString()
            } else {
                return parseFloat(1).toFixed(2).toLocaleString()
            }
        } catch(e){
            return parseFloat(0).toLocaleString()
        }
    }

    const getReport = async () => {
        showLoader()
        let url = `clubs/get_report_download/`
        if (startDate && endDate){
            url = `clubs/get_report_download/?start_date=${startDate}&end_date=${endDate}`
        }
        try {
            let res = await API.get(url);
            if (res) {
                var blob = new Blob([res.data], {type: res.headers['content-type']});
                var fileURL = URL.createObjectURL(blob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = fileURL;
                a.download = `${context.user.first_name} ${context.user.last_name} - club report.pdf`;
                a.click();
                window.URL.revokeObjectURL(fileURL);
            }
        } catch (e) {
            // console.log(e.response);
        }
        hideLoader()
    }


    const style = {
      "width": "1200px",
      "minHeight": "540px",
      "fontSize": "11px"
    }

    useEffect(() => {
      showLoader()
        if (context.user.completed_onboarding === null || context.user.completed_onboarding === undefined) {
        window.location.reload();
        }
        if (context.user.completed_onboarding === false) {
            return props.history.push("/user/onboarding");
        }
        // getStats();
        getClubInvestment();
        getClubAsset()
        // $('#end_date').datepicker({
        // format: "yyyy-mm-dd",
        // autoclose: true
        // }).on("changeDate", (e) => {
        //     let date = moment(e.date).format("YYYY-MM-DD");
        //     setEndDate(date);
        // });
        // $('#start_date').datepicker({
        //     format: "yyyy-mm-dd",
        //     autoclose: true
        // }).on("changeDate", (e) => {
        //     let date = moment(e.date).format("YYYY-MM-DD");
        //     setStartDate(date);
        // });
        hideLoader()

        return () => {
        console.log("")
        };
    }, []);


    return (
      <>
        <div className="card-body">
            <form className="" onSubmit={(e) => {
                e.preventDefault(e)
            }}>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="form-group">
                            <label>Start Date</label>
                            <div className="input-group date">
                                <input autoComplete="off" id="start_date" required defualtValue={startDate}
                                        type="date" className="form-control bd-right datepicker" onChange={(e) => {
                                            setStartDate(e.target.value)
                                        }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="form-group">
                            <label>End Date</label>
                            <div className="input-group date">
                                <input autoComplete="off" id="end_date" required type="date" defualtValue={endDate}
                                        className="form-control bd-right datepicker" style={{"border": "1px solid"}} onChange={(e) => {
                                            setEndDate(e.target.value)
                                        }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 row">
                        <div className="form-group col-3">
                            <label><br/></label>
                            <div className="input-group date">
                                <button className="btn btn-warning" onClick={(e) => {
                                    getClubInvestment(e)
                                }}>Go</button>
                            </div>
                        </div>
                        {/* <div className="form-group col-3">
                            <label><br/></label>
                            <div className="input-group date">
                                <button className="btn btn-secondary" onClick={(e) => {
                                    setStartDate(null)
                                    setEndDate(null)
                                }}>Clear</button>
                            </div>
                        </div> */}
                    </div>
                </div>
            </form>
        {display ? (
            <>
                <div className="row">
                    <div className="col-6">
                        <h5>Club Statement Overview</h5>
                    </div>
                    <div className="col-6 float-right">
                        <button  className="btn btn-primary float-right"
                            onClick={() =>
                                getReport()
                            }
                            // { pdfFromReact(".element-to-print", "investment_report", "l", true, true) }
                            >Print</button>
                    </div>
                </div>
                <div className="card-body element-to-print">
                    <div className="" style={{fontSize: "11px"}}>
                        <div className="text-center mb-4"><img src="/assets/img/logo.png" width={"20%"} /></div>
                        <div className="text-left row mt-2">
                            <p className="col-12">
                                Twelve is a digital investment community for Africa and diaspora, working with SEC-licensed fund managers to curate credible investment opportunities for investment clubs, syndicates and communities.
                            </p> 
                            <div className="col-6">
                                <b>Club Investment Statement:</b><br/>
                                <b>Printed on: </b>{new Date().toString()}
                            </div>
                            <div className="col-6">
                                <b>Name:</b> {context.user.first_name}{' '}{context.user.last_name}<br/>
                                <b>Email:</b> {context.user.email}
                            </div>
                        </div>                   
                    </div>
                    <div className="table-responsive" style={{paddingTop: "30px"}}>
                        <div id="example_wrapper"
                                className="dataTables_wrapper dt-bootstrap4 no-footer" style={{fontSize: "11px"}}>
                            <div className="row">
                                <div className="col-sm-12">
                                    {page.length > 0 ? 
                                        page.map(itm => {
                                        return(
                                            <>
                                            <p><strong>{itm.name}  Club</strong></p>
                                            <table
                                                className="table table-striped table-bordered border-t0 no-footer"
                                                role="grid" aria-describedby="example_info">
                                                <thead>
                                                <tr role="row">
                                                    <th className="wd-15p" tabIndex="0"
                                                        aria-controls="example" rowSpan="1" colSpan="1"
                                                        aria-sort="ascending"
                                                        aria-label="First name: activate to sort column descending"
                                                        style={{width: "50px"}}>Name
                                                    </th>
                                                    <th className="wd-15p" tabIndex="0"
                                                        aria-controls="example"
                                                        rowSpan="1" colSpan="1"
                                                        aria-label="Last name: activate to sort column ascending"
                                                        style={{width: "50px"}}>Amount
                                                    </th>
                                                    <th className="wd-15p" tabIndex="0"
                                                        aria-controls="example"
                                                        rowSpan="1" colSpan="1"
                                                        aria-label="Last name: activate to sort column ascending"
                                                        style={{width: "50px"}}>Currency
                                                    </th>
                                                    <th className="wd-20p" tabIndex="0"
                                                        aria-controls="example"
                                                        rowSpan="1" colSpan="1"
                                                        aria-label="Position: activate to sort column ascending"
                                                        style={{width: "50px"}}>Unit/Token
                                                    </th>
                                                    <th className="wd-20p" tabIndex="0"
                                                        aria-controls="example"
                                                        rowSpan="1" colSpan="1"
                                                        aria-label="Position: activate to sort column ascending"
                                                        style={{width: "50px"}}>ROI(%)/Annum
                                                    </th>
                                                    <th className="wd-20p" tabIndex="0"
                                                        aria-controls="example"
                                                        rowSpan="1" colSpan="1"
                                                        aria-label="Position: activate to sort column ascending"
                                                        style={{width: "50px"}}>Expected Return
                                                    </th>
                                                    <th className="wd-20p" tabIndex="0"
                                                        aria-controls="example"
                                                        rowSpan="1" colSpan="1"
                                                        aria-label="Position: activate to sort column ascending"
                                                        style={{width: "50px"}}>Purchased Date
                                                    </th>
                                                    <th className="wd-20p-" tabIndex="0"
                                                        aria-controls="example"
                                                        rowSpan="1" colSpan="1"
                                                        aria-label="Position: activate to sort column ascending"
                                                        style={{width: "50px"}}>Maturity Date
                                                    </th>
                                                    <th className="wd-20p-" tabIndex="0"
                                                        aria-controls="example"
                                                        rowSpan="1" colSpan="1"
                                                        aria-label="Position: activate to sort column ascending"
                                                        style={{width: "50px"}}>Status
                                                    </th>
                                                </tr>
                                                </thead> 
                                                <tbody>
                                                {itm.stats.investments.length > 0 ?  
                                                (itm.stats.investments.map((item) => {
                                                    return (
                                                    <tr key={item.id} style={{"font-size": "10px"}}>
                                                        <td className="break-word">{item.investment.title} by {item.investment.company}</td>
                                                        <td>
                                                            <NumberFormat
                                                                renderText={value => <div>{value}</div>}
                                                                
                                                                displayType={'text'}
                                                                thousandSeparator={true}
                                                                value={parseFloat(item.value || 0).toFixed(2)}/>
                                                        </td>
                                                        <td>{Utils.currencyToSym(item.currency) == "₦" ? "Naira NGN" : Utils.currencyToSym(item.currency) == "£" ? "British Pounds £": Utils.currencyToSym(item.currency) == "$" ? "USD $" : ""}</td>
                                                        <td>{parseFloat(item.units).toFixed(2)}</td>
                                                        <td>{item.roi}{item.investment.payout_interval ?  "/" + `${item.investment.payout_interval}` : ""}</td>
                                                        <td><NumberFormat
                                                            renderText={value => <div>{value}</div>}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            value={item.expected_return}/></td>
                                                        <td><Moment
                                                            format="MMM D, Y">{item.created_at}</Moment>
                                                        </td>
                                                        <td><Moment
                                                            format="MMM D, Y">{item.maturity_date}</Moment>
                                                        </td>
                                                        <td>
                                                            {item.investment.payout_status == "pending" || item.investment.payout_status == "none" ? "Active" : "Completed"}
                                                        </td>
                                                    </tr>
                                                    )})) : "No investment yet"}
                                                </tbody>
                                            </table>
                                            <div className="" style={{"margin-top": "-10px"}}>
                                                <p style={{"font-size":"7px"}}>
                                                    <li>Agro Investments by AGRO PARTNERSHIPS & GROUP FARMA</li>
                                                    <li>Real Estate Investments by MIXTA AFRICA & REHOBOTH PROPERTIES INTERNATIONAL (RPI)</li>
                                                    <li>Venture Capital (VC) Investments by LOFTY INC & VENTURE PLATFORM</li>
                                                    <li>EuroBond Investments from SEPLAT, ECOBANK, ACCESS BANK & FGN</li>
                                                    <li>Naira Bond Investments from LAGOS STATE BOND</li>
                                                </p>
                                            </div>
                                            <div className="" style={{"margin-top": "0px", fontSize: "10px"}}>
                                              <u><p><strong> {itm.name} {" "} Club Investment Summary</strong></p></u>
                                              <div className="row" style={{fontSize: "10px"}}>
                                                  <div className="col-4">
                                                      <span ><strong>Percentage Interest (%)</strong></span><br/>
                                                      Percentage Interest (Naira): <b>{parseFloat(itm.stats.percentage || 0).toLocaleString()}</b><br/>
                                                      Percentage Interest (USD): <b>{parseFloat(itm.stats.percentage_usd || 0).toLocaleString()}</b><br/>
                                                      Percentage Interest (GBP): <b>{parseFloat(itm.stats.percentage_gbp || 0).toLocaleString()}</b><br/>
                                                  </div>
                                                  <div className="col-4">
                                                      <p><strong>Total Amount (Currency)</strong></p>
                                                      Total Amount (Naira): <b>{parseFloat(itm.stats.total_amount || 0).toLocaleString()}</b><br/>
                                                      Total Amount (USD): <b>{parseFloat(itm.stats.total_amount_usd || 0).toLocaleString()}</b><br/>
                                                      Total Amount (GBP): <b>{parseFloat(itm.stats.total_amount_gbp || 0).toLocaleString()}</b><br/>
                                                  </div>
                                                  <div className="col-4">
                                                      <p><strong>Total Return (Currency)</strong></p>
                                                      Total Return (Naira): <b>{parseFloat(itm.stats.total_return || 0).toLocaleString()}</b><br/>
                                                      Total Return (USD): <b>{parseFloat(itm.stats.total_return_usd || 0).toLocaleString()}</b><br/>
                                                      Total Return (GBP): <b>{parseFloat(itm.stats.total_return_gbp || 0).toLocaleString()}</b><br/>
                                                  </div>
                                              </div>
                                            </div>
                                            <div className="py-5"></div>
                                            </>
                                        )
                                    }) : "No investment yet"}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="py-5"></div>

                    <div className="pt-4"><u><h6><strong>Club Assets</strong></h6></u></div>
                    {/* {Assets Starts} */}

                    <div className="table-responsive">
                        <div id="example_wrapper"
                            className="dataTables_wrapper dt-bootstrap4 no-footer" style={{fontSize: "11px"}}>
                            <div className="row">
                                <div className="col-sm-12">
                                {assets.length > 0 ? 
                                        assets.map(itm => {
                                        return(
                                            Object.keys(itm.assets).length > 0 ? (<>
                                                <p><strong>{itm.name} {" "} Club</strong></p>
                                                <table
                                                    className="table table-striped table-bordered border-t0 no-footer"
                                                    role="grid" aria-describedby="example_info">
                                                    <thead>
                                                    <tr role="row">
                                                        <th className="wd-15p" tabIndex="0"
                                                            aria-controls="example" rowSpan="1" colSpan="1"
                                                            aria-sort="ascending"
                                                            aria-label="First name: activate to sort column descending"
                                                            style={{width: "50px"}}>Name
                                                        </th>
                                                        <th className="wd-15p" tabIndex="0"
                                                            aria-controls="example"
                                                            rowSpan="1" colSpan="1"
                                                            aria-label="Last name: activate to sort column ascending"
                                                            style={{width: "50px"}}>Value(ROI)
                                                        </th>
                                                        <th className="wd-15p" tabIndex="0"
                                                            aria-controls="example"
                                                            rowSpan="1" colSpan="1"
                                                            aria-label="Last name: activate to sort column ascending"
                                                            style={{width: "50px"}}>Currency
                                                        </th>
                                                        <th className="wd-20p" tabIndex="0"
                                                            aria-controls="example"
                                                            rowSpan="1" colSpan="1"
                                                            aria-label="Position: activate to sort column ascending"
                                                            style={{width: "50px"}}>Investment Type
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {Object.keys(itm).length > 0 ?  
                                                    (itm.assets.map(item => {
                                                    return (
                                                        <tr key={item.id}>
                                                            <td className="break-word"> {item.name}</td>
                                                            <td>
                                                                <NumberFormat
                                                                    renderText={value => <div>{value}</div>}
                                                                
                                                                    displayType={'text'}
                                                                    thousandSeparator={true}
                                                                    value={item.roi}/>
                                                            </td>
                                                            <td>{Utils.currencyToSym(item.currency) == "₦" ? "Naira NGN" : Utils.currencyToSym(item.currency) == "£" ? "British Pounds £": Utils.currencyToSym(item.currency) == "$" ? "USD $" : ""}</td>
                                                            <td>{item.investment_type}</td>
                                                        </tr>
                                                    )})) : "No asset yet"}
                                                    </tbody>
                                                </table>                                                            
                                            </>) : "-"
                                        )
                                    }) : "No asset yet"}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="py-5"></div>
                    {/* Assets Ends */}
                    <GeneralInfo />
                </div>
            </>

        ) : ""}

        <button  className="btn btn-primary float-right"
            onClick={() =>
                getReport()
            }
            >Print</button>
        </div>
      </>
  )
}

export default ClubDetailReport;