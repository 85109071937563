import React, {Component} from 'react';
import {Link} from "react-router-dom";
import uuidv1 from 'uuid'
import Moment from "react-moment";
import Layout from "../Layout";
import {Context} from "../MyContext";
import API from "../../api";
import {hideLoader, showLoader, toast} from "../../_helpers/Loader";
import $ from "jquery";
import NumberFormat from "react-number-format";

class AxaBalance extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            orders: []
        }
    }


    async componentDidMount() {
        showLoader();
        await this.getBalance();
        hideLoader();
    }

    getBalance = async () => {
        try {
            const res = await API.post('axa_mansard/get_balance/');
            console.log(res)
        }catch (e) {
            console.log(e.response)
        }
    }

    render() {
        return (
            <Layout active_menu={'dash'} user_state={'personal'} context={this.context}>
                <div className="section">
                    <div className="breadcrumb">
                        <Link to="/"> <span className="fa fa-chevron-left"/> Dashboard</Link>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="float-left">Pending Transactions</h4>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <div id="example_wrapper"
                                             className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="card-body">
                                                    <div className="row boxes">
                                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                                            <div className="card bg-blue-gray text-blue">
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-md-6"
                                                                             style={{paddingLeft: 0}}>
                                                                            <i className="fa fa-bar-chart"/>
                                                                        </div>
                                                                        <div className="col-md-6 float-right text-right"
                                                                             style={{paddingRight: 0}}>
                                                                            <i data-tip="Total Amount Invested "
                                                                               className="fa fa-info-circle text-right float-right"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <div className="mt-3 text-center">
                                                                                <span className="text-white"><strong>Total Investment</strong></span>
                                                                                <h2 className="text-white mb-0">₦{parseFloat(parseFloat(this.state.total_amount).toFixed(2)).toLocaleString()}</h2>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                                            <div className="card bg-blue-gray text-blue">
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-md-6"
                                                                             style={{paddingLeft: 0}}>
                                                                            <i className="fa fa-reply-all"/>
                                                                        </div>
                                                                        <div className="col-md-6 float-right text-right"
                                                                             style={{paddingRight: 0}}>
                                                                            <i data-tip="Expected return on investment"
                                                                               className="fa fa-info-circle text-right"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-white"><strong>Expected ROI</strong></span>
                                                                                <h2 className="text-white mb-0">₦{parseFloat(parseFloat(this.state.total_return).toFixed(2)).toLocaleString()}</h2>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                                            <div className="card bg-blue-gray text-blue">
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-md-6"
                                                                             style={{paddingLeft: 0}}>
                                                                            <i className="fa fa-signal"/>
                                                                        </div>
                                                                        <div className="col-md-6 float-right text-right"
                                                                             style={{paddingRight: 0}}>
                                                                            <i data-tip="Total amount you will receive from your investments"
                                                                               className="fa fa-info-circle text-right"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-white"><strong>Interest</strong></span>
                                                                                <h2 className="text-white mb-0">₦{parseFloat(parseFloat(this.state.total_return - this.state.total_amount).toFixed(2)).toLocaleString()}</h2>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                                            <div className="card bg-blue-gray text-blue">
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-md-6"
                                                                             style={{paddingLeft: 0}}>
                                                                            <i className="fa fa-line-chart"/>
                                                                        </div>
                                                                        <div className="col-md-6 float-right text-right"
                                                                             style={{paddingRight: 0}}>
                                                                            <i data-tip="Percentage Interest gained from  your investments"
                                                                               className="fa fa-info-circle text-right"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-white"><strong>Percentage Interest</strong></span>
                                                                                <h2 className="text-white mb-0">{parseFloat(this.state.percentage_interest).toFixed(2)}%</h2>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default AxaBalance;
