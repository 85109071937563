import axios from 'axios'
import {BASE_URL} from './env';
import {showLoader, hideLoader, toast} from "./_helpers/Loader";

import {authHeader} from './_helpers/auth-header'

const request = axios.create({
    baseURL: BASE_URL,
    headers: authHeader()
});

class API {
    get = async (url, postData) => {
        try {
            let res = await request.get(url, postData);
            return res;
        } catch (e) {
            // console.log("}}}", e.response);
            if (e.response.statusText === 'Unauthorized') {
                showLoader();
                toast.info("Trying to authorize user. Kindly login again", {preventDuplicated: true});
                localStorage.clear();
                setTimeout(() => {
                    //window.location.href = "/login";
                }, 4000)
            }
            throw e;
        }
    };

    set_header = async (header) => {
        request.defaults.headers = header;
    };

    post = async (url, postData) => {
        try {
            let res = await request.post(url, postData);
            return res;
        } catch (e) {
            if (e.response.statusText === 'Unauthorized') {
                showLoader();
                toast.info("Trying to authorize user. Kindly login again");
                localStorage.clear();
                setTimeout(() => {
                    //window.location.href = "/login";
                }, 4000)
            }
            throw e;
        }
    };

    put = async (url, postData) => {
        try {
            let res = await request.put(url, postData);
            return res;
        } catch (e) {
            if (e.response.statusText === 'Unauthorized') {
                showLoader();
                toast.info("Trying to authorize user. Kindly login again");
                localStorage.clear();
                setTimeout(() => {
                    //window.location.href = "/login";
                }, 4000)
            }
            throw e;
        }
    };

    delete = async (url, postData) => {
        try {
            let res = await request.delete(url, postData);
            return res;
        } catch (e) {
            if (e.response.statusText === 'Unauthorized') {
                showLoader();
                toast.info("Trying to authorize user. Kindly login again");
                localStorage.clear();
                setTimeout(() => {
                    //window.location.href = "/login";
                }, 4000)
            }
            throw e;
        }
    };

    uploadFile = async (url, file) => {
        try {
            const postData = new FormData();
            postData.append('file', file);
            let res = await request.post(url, postData, {headers: {'Content-Type': 'multipart/form-data'}});
            return res;
        } catch (e) {
            if (e.response.statusText === 'Unauthorized') {
                showLoader();
                toast.info("Trying to authorize user. Kindly login again");
                localStorage.clear();
                setTimeout(() => {
                    //window.location.href = "/login";
                }, 4000)
            } else if (e.response.statusText === "Forbidden") {
                toast.info("Not Allowed");
            }
            throw e;
        }
    }

}

const axios_request = new API();

export default axios_request;

