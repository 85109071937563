import React, {Component} from 'react';
import Layout from "../Layout";
import API from '../../api'
import {hideLoader, showLoader, toast, trackMixpanel, Utils} from "../../_helpers/Loader";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import {Context} from "../MyContext";
import {PAYSTACK_publicKey} from "../../env";
import Zoom from 'react-reveal/Fade';
import uuidv4 from 'uuid';
import ReactTooltip from 'react-tooltip'
import $ from "jquery";
import jsPDF from "jspdf";
import "jspdf-autotable";
const PaystackPop = window.PaystackPop;

class Wallet extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            wallet_history: [],
            balance: 0,
            account_detail: {},
            total_amount_spent: 0,
            form_amount: '',
            form_real_amount: '',
            page: 1,
            withdraw_amount: '',
            withdraw_button: true,
            calc_amount: '',
            calc_display: 0,
            pay_type: 'card',
            notification: false,
            monnify_status: true,
            otp: '',
            withdraw_page: 1,
            wallet_locked: null,
            start_date: null,
            end_date: null,
            print: "Print Wallet Statement",
            bvn: null,
            phone: null,
            custom_filter: null,
            now: new Date()
        };

        this.payWithCard = this.payWithCard.bind(this);
        this.generateWalletAccount = this.generateWalletAccount.bind(this);
    }

    componentWillMount() {
        if (this.context.user.completed_onboarding === null || this.context.user.completed_onboarding === undefined) {
            window.location.reload();
        }
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
    }

    async componentDidMount() {
        trackMixpanel('WalletView', false, false, this.context.user.email)
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
        this.setState({notification: this.context.notification});
        showLoader();
        await this.getWallet();
        await this.getWalletHistory();
        var maxHeight = Math.max.apply(null, $(".boxes .card").map(function () {
            return $(this).height();
        }).get());
        $(".boxes .card").height(maxHeight);
        hideLoader()
    }

    notify = () => {
        toast.info("Kindly update your information first");
        setTimeout(() => {
            this.props.history.push({
                pathname: '/profile',
                query: {back_url: this.props.location.pathname}
            })
        }, 3000);
    };

    async getWalletHistory() {
        let url = 'wallet/wallet_history/'
        if(this.state.start_date && this.state.end_date){
            url = `wallet/wallet_history/?start_date=${this.state.start_date}&end_date=${this.state.end_date}`
            showLoader();
        }
        try {
            let spent_count = 0;
            let res = await API.get(url);
            this.setState({wallet_history: res.data});
            res.data.map(item => {
                if (item.status.toLowerCase() === 'debit' && item.withdrawn === false) {
                    spent_count = spent_count + parseFloat(item.amount)
                }
            });
            this.setState({total_amount_spent: spent_count})
        } catch (e) {
            toast.error("Something Went Wrong");
            // console.log(e.response)
        }
        hideLoader();
    }

    async exportPDF() {
        this.setState({
            print: "Printing"
        })
        try{
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "portrait"; // portrait or landscape
        
            const marginLeft = 40;
            const doc = new jsPDF(orientation, unit, size);
        
            doc.setFontSize(15);
            
            let title = `Twelve Vest Wallet Statement`;
    
            if(this.state.start_date && this.state.end_date){
                title = `Twelve Vest Wallet Statement (${this.state.start_date} - ${this.state.end_date})`;
            }
            const headers = [["REFERENCE", "AMOUNT", "STATUS", "BALANCE", "DATE"]];
        
            const data = this.state.wallet_history.map(elt=> [elt.ref_code, elt.amount, elt.status, elt.balance, new Date(elt.created_at).toLocaleString()]);
        
            let content = {
              startY: 50,
              head: headers,
              body: data,
              headStyles: {fillColor: [71, 196, 170]}
            };
        
            doc.text(title, marginLeft, 40);
            doc.autoTable(content);
            doc.save("Twelve Vest Wallet Statement.pdf")
            this.setState({
                print: "Print Wallet Statement"
            })
        } catch(e){
            toast.error(e)
            this.setState({
                print: "Print Wallet Statement"
            })
        }
    }

    async getWallet() {
        try {
            let res = await API.get('wallet/get_my_wallet/');
            console.log(res, "res =>>>>>>>>>")
            this.setState({
                balance: (res.data.length > 0) ? res.data[0].current_balance : 0,
                wallet_locked: (res.data.length > 0) ? res.data[0].locked : true
            });
            if (res && res.data && res.data.length > 0) {
                if ((res && res.data && res.data[0] && res.data[0].account_details.length) > 0 && (res && res.data && res.data[0] && res.data[0].account_details[0].account_number != "")) {
                    this.setState({account_detail: res.data[0].account_details[0]})
                } else {
                    this.setState({account_detail: null})
                }
            }
        } catch (e) {
            console.log(e, "Hello there mama")
            if (e.response && e.response.status === 503) {
                // this.setState({monnify_status: false})
                console.log(e.response)
            } else {
                toast.error("Something Went Wrong");
            }
            // console.log(e.response);
        }
    }

    fundWallet = (event) => {
        event.preventDefault();
        (this.state.pay_type == 'card') && this.payWithCard();
        (this.state.pay_type == 'bank') && this.payWithBank();
    };

    async payWithCard() {
        const script = document.createElement("script");

        script.src = "https://js.paystack.co/v1/inline.js";
        script.async = true;

        document.body.appendChild(script);
        var handler = PaystackPop.setup({
            key: PAYSTACK_publicKey,
            email: this.context.user.email,
            amount: Utils.charges(this.state.form_amount) * 100,
            currency: "NGN",
            ref: "paystack-wallet" + Math.floor((Math.random() * 1000000000) + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
            metadata: {
                custom_fields: [
                    {
                        display_name: "First Name",
                        variable_name: "firstname",
                        value: this.context.user.first_name,
                    }, {
                        display_name: "Last Name",
                        variable_name: "lastname",
                        value: this.context.user.last_name,
                    }, {
                        display_name: "Customer Number",
                        variable_name: "customer_phone",
                        value: this.context.user.phone,
                    }, {
                        display_name: "Payment for",
                        variable_name: "payment_for",
                        value: "Funding Wallet"
                    },
                ]
            },
            callback: (response) => {
                response.amount = parseFloat(Utils.charges(this.state.form_amount));
                const amount = this.state.form_amount
                response.real_amount = parseFloat(this.state.form_amount);
                showLoader();
                if (response.status === "success") {
                    // redirect to a success page
                    API.put('wallet/save_for_amount/', response).then((res) => {
                        trackMixpanel('WalletFunded', false, {fundsAdded: amount}, this.context.user.email)
                        hideLoader();
                        toast.success("Amount Successfully Added to Wallet");
                        this.getWallet();
                        this.getWalletHistory();
                        this.setState({form_amount: '', page: 1});
                        window.location.reload();
                        hideLoader();
                    }, function (err) {
                        hideLoader();
                        toast.error("Something Went Wrong")
                    })

                } else {
                    // redirect to a failure page.
                    hideLoader();
                    toast.error("Payment Error.")
                }
                //alert('success. transaction ref is ' + response.reference);
            },
            onClose: function () {
                toast.info("Payment Cancelled");
                hideLoader();
            }
        });
        handler.openIframe();
    }

    setWithdrawAmount(value) {
        if (parseFloat(value) + 50 <= parseFloat(this.state.balance)) {
            this.setState({withdraw_button: false, withdraw_amount: value})
        } else {
            this.setState({withdraw_button: true, withdraw_amount: value})
        }
    }

    setCalcAmount(value) {
        this.setState({calc_display: 0})
        let amount = parseFloat(value);
        let charge = amount * 0.0075;
        if (charge > 100) {
            charge = 100;
        }
        let vat = charge * 0.075;
        this.setState({calc_display: parseFloat((vat + charge).toFixed(2)) + amount, calc_amount: amount});
    }

    async withdrawWallet(e) {
        e.preventDefault();
        showLoader();
        try {
            const res = await API.post('wallet/process_withdraw_otp/', {amount: this.state.withdraw_amount})
            this.setState({withdraw_page: 2})
            toast.success(res.data.detail)
            hideLoader();
        } catch (e) {
            if(e){
                toast.error(e.response.data['detail'] || e.response.data['message']); 
            } else {
                toast.error("Withdrawal could not be processed at this time. Kindly contact admin")
            }
            hideLoader();
        }
    }

    async otpProcess(e) {
        e.preventDefault();
        showLoader();
        try {
            const res = await API.post('wallet/withdraw_wallet/', {otp: this.state.otp});
            toast.success("Withdrawal has been sent for processing");
            this.getWallet();
            this.getWalletHistory();
            $('.modal').modal('hide');
            $(document.body).removeClass('modal-open');
            $('.modal-backdrop').remove();
            this.setState({otp: ''})
            hideLoader();
        } catch (e) {
            console.log(e, "otp process")
            if (e.response !== undefined) {
                if (e.response.status === 406) {
                    toast.error(e.response.data['detail'] || e.response.data['message']);
                }
            }
            toast.error("Withdrawal could not be processed at this time. Kindly contact admin")
            hideLoader();
        }
    }

    withdrawCharges(amount) {
        if (amount === '' || amount === 0) {
            return 0
        } else {
            return parseFloat(amount) + 50
        }
    }

    async generateWalletAccount(e){
        e.preventDefault();
        showLoader();
        var data = {
            bvn: this.state.bvn, 
            phone: this.state.phone || this.context.user.phone 
        }
        console.log(data, "data")

        try {
            const res = await API.post('wallet/create_paystack_customer/', data);
            console.log(res, "wallet generation")
            toast.success("Virtual Account Creation in Progress");
            this.context.updateUser();
            // this.getWallet();
            this.getWalletHistory();
            $('.modal').modal('hide');
            $(document.body).removeClass('modal-open');
            $('.modal-backdrop').remove();
            this.setState({bvn: null, phone: null})
            hideLoader();
        } catch (e) {
            console.log(e, "WALLE GENRATION PROCESS")
            if (e.response !== undefined) {
                if ( 400 >= e.response.status <= 500 ) {
                    let message = e.response.data['detail'] || e.response.data['details']
                    toast.error(message);
                    if (message == "Add a Nigerian account number to your profile first"){
                        return this.props.history.push("/profile");
                    }
                }
            }
            toast.error("Something went wrong while verifying Identity")
            hideLoader();
        }
    }

    mainPage() {
        let now = new Date()
        return (
            <Layout active_menu={'wallet'} user_state={'personal'} context={this.context}>
                <ReactTooltip/>
                <div className="section">
                    <div className="breadcrumb">
                        <h5>Wallet</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                {/* <h4 className="float-left">My Wallet</h4> */}
                                {(this.state.notification || !this.context.user.bvn_verified) ? (
                                    <button onClick={event => this.notify()
                                }
                                            className="btn m-b-5 btn-warning float-right"> Calculate Transfer
                                            Amount
                                    </button>
                                ) : (
                                    <React.Fragment>
                                        {/* <button onClick={event => this.setState({withdraw_page: 1})} data-toggle="modal"
                                                data-target="#withdrawModal"
                                                className="btn m-b-5 btn-blue text-white ml-3 float-right" disabled={this.state.wallet_locked ? true: false}> Withdraw
                                        </button> */}
                                        {/* <button onClick={event => this.setState({page: 2})}
                                                className="btn m-b-5 btn-blue text-white text-white float-right"> Fund
                                            Wallet
                                        </button> */}
                                        <button onClick={event => this.setState({calc_amount: ''})} data-toggle="modal"
                                                data-target="#calcModal"
                                                className="btn m-b-5 btn-blue text-white mr-3 float-right"><i
                                            className="fa fa-calculator"/> Calculate Transfer
                                            Amount
                                        </button>
                                    </React.Fragment>
                                )}
                            </div>
                            <div className="card-body">
                                <div className="row boxes">
                                    <div className="col-lg-6 col-xl-4 col-md-6 col-sm-6">
                                        <div className="card bg-blue-gray text-blue">
                                            <div className="card-body">
                                                <div className="row">
                                                    <i className="fa fa-wallet text-blue"/>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="mt-3 text-center">
                                                            <span
                                                                className="text-blue"><strong>Balance</strong></span>
                                                            <h2 className="text-blue mb-0">₦{parseFloat(this.state.balance).toLocaleString()}</h2>
                                                            {(this.state.notification || !this.context.user.bvn_verified) ? (
                                                                <button disabled={true} onClick={event => this.notify()
                                                                    }
                                                                                className="btn m-b-5 btn-blue text-white text-white mt-2"> Fund
                                                                            Wallet
                                                                        </button>
                                                                    ): <button onClick={event => this.setState({page: 2})}
                                                                    className="btn m-b-5 btn-blue text-white text-white mt-2" disabled={true}> Fund Wallet 
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-4 col-md-6 col-sm-6">
                                        <div className="card bg-blue-gray text-blue">
                                            <div className="card-body">
                                                <div className="row">
                                                    <i className="fa fa-money text-blue"/>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-blue"><strong>Total Amount Spent</strong></span>
                                                            <h2 className="text-blue mb-0">₦{parseFloat(this.state.total_amount_spent).toLocaleString()}</h2>
                                                            {/* {(this.state.notification || !this.context.user.bvn_verified)} */}
                                                            {(this.state.notification) ? (
                                                                <button disabled={true} onClick={event => this.notify()
                                                            }
                                                                        className="btn m-b-5 btn-blue text-white text-white mt-2"> Withdraw
                                                                </button>
                                                            ) : (<button onClick={event => this.setState({withdraw_page: 1})} data-toggle="modal"
                                                                            data-target="#withdrawModal"
                                                                            className="btn m-b-5 btn-blue text-white text-white mt-2" disabled={this.state.wallet_locked ? true: false }> Withdraw
                                                                    </button>)
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-4 col-md-12 col-sm-12">
                                    {(this.context.user.paystack_virtual_account) ? (
                                        <div className={this.state.account_detail ? "card bg-blue-gray text-blue text-left": "card bg-blue-gray text-blue text-center"}>
                                            <div className="card-body">
                                                <i data-tip="Make transfer to this account"
                                                   className="fa fa-info-circle float-right text-blue"/>
                                                    {this.state.account_detail ? 
                                                    (<div className='py-5'>
                                                    <p className="mb-0 text-blue"><strong>Virtual Wallet Account
                                                    Details</strong></p>
                                                    <div className="pt-2">
                                                        <p className="mb-0">Account
                                                            Name: <strong>{this.state.account_detail.account_name}</strong>
                                                        </p>
                                                        <p className="mb-0">Account
                                                            Number: <strong>{this.state.account_detail.account_number}</strong>
                                                        </p>
                                                        <p className="mb-0">Bank: <strong>{this.state.account_detail.bank_name}</strong>
                                                        </p>
                                                    </div></div>) : (
                                                        <div className='py-5'>
                                                            <p className="mb-0 text-blue">
                                                                <strong>Virtual Wallet Account Details</strong>
                                                            </p>
                                                            <div className="pt-2">
                                                                <button className="btn btn-blue text-white text-white" onClick={e => {
                                                                    this.getWallet();
                                                                }}>Check Account Status</button>
                                                            </div>
                                                        </div>
                                                    )}
                                                
                                            </div>
                                        </div>) : (
                                        <div className="card bg-blue-gray text-blue text-center">
                                            <div className="card-body">
                                                <i data-tip="Make transfer to this account"
                                                className="fa fa-info-circle float-right"/>
                                                <div className='py-5'>
                                                    <p className="mb-0 text-blue"><strong>Your Wallet is not yet activated</strong></p>
                                                        <div>
                                                            <button className="btn btn-warning text-blue mt-2" data-toggle="modal"
                                                    data-target="#virtualModal" disabled={true}>Setup Virtual Wallet</button>
                                                        </div>
                                                </div>
                                                    
                                                </div>
                                            </div>)}
                                    </div>
                                </div>
                                <div className='dataTables_filter col-sm-12 col-md-6 col-lg-6 float-right mb-1 row' style={{"padding-right": "0px"}}>
                                    <div className='col-lg-8 col-sm-12'>
                                        {!this.state.custom_filter ? (
                                            <div className="input-group">
                                                <select className='bd-right form-control' onChange={e => {
                                                    (e.target.value == "true" || e.target.value == true) ? this.setState({
                                                        custom_filter: e.target.value
                                                    }) : this.setState({
                                                        start_date: (new Date(now.setDate(now.getDate() - parseInt(e.target.value)))).toISOString().split("T")[0],
                                                        end_date: (new Date()).toISOString().split("T")[0]
                                                    })

                                                    now = new Date()
                                                }}>
                                                    <option value={false}>Select</option>
                                                    <option value={"7"}>7 days</option>
                                                    <option value={"30"}>30 days</option>
                                                    <option value={"180"}>6 Months</option>
                                                    <option value={true}>Custom</option>
                                                </select>
                                                <button onClick={e => this.getWalletHistory()} className='btn btn-warning formcontrol formcontrol-sm mr-2 ml-1'>Go</button>
                                            </div>
                                        )
                                        :(<div className="input-group">
                                            <input type="date" className="formcontrol formcontrol-sm bd-right" title='Start date' onChange={e => {
                                                        this.setState({
                                                            start_date: e.target.value
                                                        })
                                                    }} />
                                            <div className="input-group-prepend mx-1">
                                                <span className="input-group-text" style={{padding: "0px", "border": "unset"}}>-</span>
                                            </div>
                                            <input type="date" className="formcontrol formcontrol-sm bd-right" title='End date' onChange={e => {
                                                        this.setState({
                                                            end_date: e.target.value
                                                        })
                                                    }} />
                                            <button onClick={e => this.getWalletHistory()} className='btn btn-warning formcontrol formcontrol-sm mr-0 ml-1'>Go</button>
                                        </div>)}
                                    </div>
                                    <div className='col-lg-4 col-sm-4'>
                                        <button onClick={event => this.exportPDF()} 
                                                className="btn btn-blue text-white ml-3 float-right"><i
                                            className="fa fa-print"/> {this.state.print}
                                        </button>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-striped mb-0 text-nowrap">
                                        <tbody>
                                        <tr>
                                            <th>Reference Number</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                            <th>Balance</th>
                                            <th>Date &#8212; Time</th>
                                        </tr>
                                        {this.state.wallet_history.map(item => (
                                            <tr key={uuidv4()}>
                                                <td>{item.ref_code}</td>
                                                <td>₦{parseFloat(item.amount).toLocaleString()}</td>
                                                <td>{(item.status.toLowerCase() === 'credit') ? (
                                                    <span className="badge bg-blue m-b-5">Credit</span>
                                                ) : (item.status.toLowerCase() === 'debit') ? (
                                                    <span className="badge bg-yellow m-b-5">Debit</span>
                                                ) : (item.status.toLowerCase() === 'processing') ? (
                                                    <span className="badge badge-primary m-b-5">Processing</span>
                                                ) : (
                                                    <span className="badge badge-danger m-b-5">Failed</span>
                                                )}
                                                </td>
                                                <td>₦{parseFloat(item.balance).toLocaleString()}</td>
                                                <td><Moment format="MMM D, Y &#8212; HH:mm">{item.created_at}</Moment>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="withdrawModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header bg-blue">
                                    <h4 className="modal-title" id="example-Modal3">Withdraw from wallet</h4>
                                    <button onClick={event => this.setState({form_amount: ''})} id="close-modal"
                                            type="button"
                                            className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                {(this.state.withdraw_page === 1) ? (
                                    <form onSubmit={event => this.withdrawWallet(event)}>
                                        <div className="modal-body">
                                            <div className="form-group mb-0">
                                                <label htmlFor="message-text"
                                                       className="form-control-label">Amount:</label>
                                                <NumberFormat min="50" className="form-control"
                                                              value={this.state.withdraw_amount}
                                                              thousandSeparator={true}
                                                              prefix={'₦'} onValueChange={(values) => {
                                                    const {formattedValue, value} = values;
                                                    this.setWithdrawAmount(value)
                                                }}/>
                                            </div>
                                            <p className="mt-2">Total amount including payment charge:
                                                ₦{parseFloat(this.withdrawCharges(this.state.withdraw_amount)).toLocaleString()}</p>
                                        </div>
                                        <div className="modal-footer">
                                            <div className="col-lg-12 mx-auto text-center">
                                                <button onClick={event => this.setState({
                                                    form_amount: '',
                                                    otp: '',
                                                    withdraw_button: true,
                                                    withdraw_amount: ''
                                                })}
                                                        type="button"
                                                        className="btn btn-outline-blue mr-3"
                                                        data-dismiss="modal">Close
                                                </button>
                                                <button disabled={this.state.withdraw_button} type="submit"
                                                        onClick={event => this.setState({otp: '', withdraw_page: 1, withdraw_button: false})}
                                                        className="btn btn-main">Withdraw <i
                                                    className="fas fa-money-bill"/></button>
                                            </div>
                                        </div>
                                    </form>
                                ) : (
                                    <form onSubmit={event => {
                                            this.otpProcess(event)
                                            this.setState({withdraw_button: true})
                                        }}>
                                        <div className="modal-body">
                                            <div className="form-group mb-0">
                                                <label htmlFor="message-text"
                                                       className="form-control-label">Enter OTP:</label>
                                                <input value={this.state.otp}
                                                       onChange={event => this.setState({otp: event.target.value})}
                                                       type="text" className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <div className="col-lg-12 mx-auto text-center">
                                                <button onClick={event => this.setState({
                                                    form_amount: '',
                                                    otp: '',
                                                    withdraw_button: true,
                                                    withdraw_amount: ''
                                                })}
                                                        type="button"
                                                        className="btn btn-outline-blue mr-3"
                                                        data-dismiss="modal">Close
                                                </button>
                                                <button disabled={this.state.withdraw_button} type="submit"
                                                        className="btn btn-main">Confirm <i
                                                    className="fas fa-money-bill"/></button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                    <div id="calcModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header bg-blue">
                                    <h4 className="modal-title" id="example-Modal3">Amount to Transfer</h4>
                                    <button type="button"
                                            className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                {(this.state.withdraw_page === 1) ? (
                                    <form onSubmit={event => this.withdrawWallet(event)}>
                                        <div className="modal-body">
                                            <div className="form-group mb-0">
                                                <label htmlFor="message-text"
                                                       className="form-control-label">Amount:</label>
                                                <NumberFormat min="50" className="form-control"
                                                              value={this.state.calc_amount}
                                                              thousandSeparator={true}
                                                              prefix={'₦'} onValueChange={(values) => {
                                                    const {formattedValue, value} = values;
                                                    this.setCalcAmount(value)
                                                }}/>
                                            </div>
                                            <p className="mt-2">Total amount including payment charge:
                                                ₦{parseFloat((this.state.calc_display) ? this.state.calc_display : 0).toLocaleString()}</p>
                                        </div>
                                        <div className="modal-footer">
                                            <div className="col-lg-12 mx-auto text-center">
                                                <button type="button"
                                                        className="btn btn-outline-blue mr-3"
                                                        data-dismiss="modal">Close
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                ) : (
                                    <form onSubmit={event => {
                                            this.otpProcess(event)
                                            this.setState({withdraw_button: true})
                                        }}>
                                        <div className="modal-body">
                                            <div className="form-group mb-0">
                                                <label htmlFor="message-text"
                                                       className="form-control-label">Enter OTP:</label>
                                                <input value={this.state.otp}
                                                       onChange={event => this.setState({otp: event.target.value})}
                                                       type="text" className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <div className="col-lg-12 mx-auto text-center">
                                                <button onClick={event => this.setState({
                                                    form_amount: '',
                                                    otp: '',
                                                    withdraw_button: true,
                                                    withdraw_amount: ''
                                                })}
                                                        type="button"
                                                        className="btn btn-outline-blue mr-3"
                                                        data-dismiss="modal">Close
                                                </button>
                                                <button disabled={this.state.withdraw_button} type="submit"
                                                        className="btn btn-main">Confirm <i
                                                    className="fas fa-money-bill"/></button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                    <div id="virtualModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header bg-blue">
                                    <h4 className="modal-title" id="example-Modal3">Generate Virtual Account Number</h4>
                                    <button onClick={event => this.setState({
                                        bvn: null, phone: null})} id="close-modal"
                                            type="button"
                                            className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <form onSubmit={event => this.generateWalletAccount(event)}>
                                    <div className="modal-body">
                                        <p className="mt-2">Phone number and BVN is needed to proceed</p>
                                        <div className="form-group mb-0">
                                            <label htmlFor="message-text"
                                                    className="form-control-label">Enter BVN:</label>
                                            <input type="number" className="form-control" onChange={e => this.setState({
                                                bvn: e.target.value
                                            })}/>
                                        </div>

                                        {this.context.user.phone ? "" : 
                                        <div className="form-group mb-0">
                                            <label htmlFor="message-text"
                                                    className="form-control-label">Phone:</label>
                                            <input type="number" className="form-control" onChange={e => this.setState({
                                                phone: e.target.value
                                            })}/>

                                        </div>}

                                    </div>
                                    <div className="modal-footer">
                                        <div className="col-lg-12 mx-auto text-center">
                                            <button onClick={event => {
                                                    this.setState({
                                                        bvn: null, 
                                                        phone: null
                                                    });
                                                    console.log("closed")
                                                }}
                                                    type="button"
                                                    className="btn btn-outline-blue mr-3"
                                                    data-dismiss="modal">Close
                                            </button>
                                            <button type="submit"
                                                    onClick={event => 
                                                        // this.setState({})
                                                        console.log("Generating acocunt number")
                                                    }
                                                    className="btn btn-main">Generate <i
                                                className="fas fa-money-bill"/></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }

    payPage() {
        return (
            <Layout active_menu={'wallet'} user_state={'personal'} context={this.context}>
                <Zoom>
                    <div className="section">
                        <div className="breadcrumb">
                            <h5 className="pointer-cursor" onClick={event => this.setState({page: 1})}><span
                                className="fa fa-chevron-left"/> Wallet</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 mx-auto">
                            <div className="card">
                                <div className="card-header bg-blue">
                                    <h4>Payment Method</h4>
                                </div>
                                <div className="card-body">
                                    <div className="col-sm-12 pb-4">
                                        <h3 className="text-center">Fund Wallet</h3>
                                    </div>
                                    <div className="col-sm-12 pb-4">
                                        <button data-target="#fundModal" data-toggle="modal"
                                                onClick={event => this.setState({pay_type: 'card'})}
                                                className="btn btn-block btn-blue text-white btn-lg">Fund with bank card <i
                                            className="fa fa-credit-card"/></button>
                                    </div>
                                    <div className="col-sm-12">
                                        <button data-target="#monnifyModal" data-toggle="modal"
                                                className="btn btn-block btn-blue text-white btn-lg">Fund by bank
                                            transfer <i className="fa fa-bank"/></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="fundModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header bg-blue">
                                    <h4 className="modal-title" id="example-Modal3">Fund Wallet</h4>
                                    <button onClick={event => this.setState({form_amount: ''})} id="close-modal"
                                            type="button"
                                            className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <form onSubmit={event => this.fundWallet(event)}>
                                    <div className="modal-body">
                                        <div className="form-group mb-0">
                                            <label htmlFor="message-text"
                                                   className="form-control-label">Amount:</label>
                                            <NumberFormat min="50" className="form-control"
                                                          value={this.state.form_amount}
                                                          thousandSeparator={true}
                                                          prefix={'₦'} onValueChange={(values) => {
                                                const {formattedValue, value} = values;
                                                this.setState({form_amount: value})
                                            }}/>
                                        </div>
                                        <p className="mt-2">Total amount including payment charge:
                                            ₦{parseFloat(Utils.charges(this.state.form_amount)).toLocaleString()}</p>
                                    </div>
                                    <div className="modal-footer">
                                        <div className="col-lg-6 mx-auto text-center">
                                            <button onClick={event => this.setState({form_amount: ''})}
                                                    type="button"
                                                    className="btn btn-outline-blue mr-3"
                                                    data-dismiss="modal">Close
                                            </button>
                                            <button type="submit" className="btn btn-main">Pay <i
                                                className="fas fa-credit-card"/></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="monnifyModal" tabIndex="-1" role="dialog"
                         aria-labelledby="monnifyModalLabel">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header bg-blue">
                                    <h4 className="modal-title" id="monnifyModalLabel">Wallet Account Funding
                                        Details</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {(this.state.monnify_status) ? (
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <dl className="row mx-auto">
                                                    <dt className="col-sm-6 text-right"><strong>Account
                                                        Name</strong>
                                                    </dt>
                                                    <dd className="col-sm-6">{this.state.account_detail.account_name}</dd>

                                                    <dt className="col-sm-6 text-right"><strong>Account
                                                        Number</strong>
                                                    </dt>
                                                    <dd className="col-sm-6">{this.state.account_detail.account_number}</dd>

                                                    <dt className="col-sm-6 text-right"><strong>Bank</strong></dt>
                                                    <dd className="col-sm-6">{this.state.account_detail.bank_name}</dd>
                                                </dl>
                                                <p className="text-justify text-main font-weight-bold">Important Note:
                                                    This is a
                                                    virtual account number and it is solely dedicated to you.<br/>
                                                    Payment made using this account details will be acknowledged and
                                                    instantly credited into you wallet once verified. You can check your
                                                    Twelvevest wallet after payment to confirm your wallet is
                                                    credited. <br/>
                                                    To make it easy for you to transfer next time, you can save the
                                                    account details as a beneficiary on your banking app and transfer
                                                    money to your Twelvevest wallet at any time. <br/>
                                                    Charges Apply - 0.75% of amount transferred up to a maximum of ₦100
                                                    plus VAT will be deducted per transaction. <br/>
                                                </p>
                                            </div>
                                        </div>
                                    ) : (
                                        <p className="text-center"><i className="text-dark">Service Currently
                                            Unavailable <br/>Try again some other time.</i></p>
                                    )}
                                </div>
                                <div className="modal-footer text-center">
                                    <button type="button" className="btn no-outline-green"
                                            data-dismiss="modal">Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Zoom>
            </Layout>
        )
    }

    render() {
        if (this.state.page === 1) {
            return this.mainPage()
        } else {
            return this.payPage();
        }
    }
}

export default Wallet;
