import React, {Component} from 'react';
import {Link} from "react-router-dom";
import uuidv1 from 'uuid'
import Moment from "react-moment";
import Layout from "../Layout";
import API from "../../api";
import {hideLoader, mixpanel, showLoader, toast, trackMixpanel} from "../../_helpers/Loader";
import $ from "jquery";
import {Context} from "../MyContext";

class Activities extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            activities: []
        }
    }

    async componentDidMount() {
        trackMixpanel('ActivityView', false, false, this.context.user.email)
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
        showLoader();
        await this.getActivities();
        $(".table").dataTable({
            'search': {
                caseInsensitive: true,
            },
            order: [],
        });
        hideLoader();
    }

    componentWillMount() {
        if (this.context.user.completed_onboarding === null || this.context.user.completed_onboarding === undefined) {
            window.location.reload();
        }
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
    }

    async getActivities() {
        try {
            let res = await API.get('activity_logs/');
            this.setState({activities: res.data});
        } catch (e) {
            toast.error("Something Went Wrong");
            // console.log(e.response)
        }
    }

    render() {
        return (
            <Layout active_menu={'dash'} user_state={'personal'} context={this.context}>
                <div className="section">
                    <div className="breadcrumb">
                        <Link to="/"> <span className="fa fa-chevron-left"/> Dashboard</Link>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="float-left">Activities</h4>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <div id="example_wrapper"
                                             className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table
                                                        className="table table-striped table-bordered border-t0 text-nowrap w-100 dataTable no-footer"
                                                        role="grid" aria-describedby="example_info">
                                                        <thead>
                                                        <tr role="row">
                                                            <th className="wd-15p sorting_asc" tabIndex="0"
                                                                aria-controls="example" rowSpan="1" colSpan="1"
                                                                aria-sort="ascending"
                                                                aria-label="First name: activate to sort column descending"
                                                                style={{width: "113px"}}>Action
                                                            </th>
                                                            <th className="wd-15p sorting" tabIndex="0"
                                                                aria-controls="example"
                                                                rowSpan="1" colSpan="1"
                                                                aria-label="Last name: activate to sort column ascending"
                                                                style={{width: "113px"}}>Date
                                                            </th>
                                                            <th className="wd-20p sorting" tabIndex="0"
                                                                aria-controls="example"
                                                                rowSpan="1" colSpan="1"
                                                                aria-label="Position: activate to sort column ascending"
                                                                style={{width: "113px"}}>Time
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {this.state.activities.map(item => (
                                                            <tr key={uuidv1()}>
                                                                <td>{item.action}</td>
                                                                <td><Moment
                                                                    format="MMM D Y">{item.created_at}</Moment>
                                                                </td>
                                                                <td><Moment
                                                                    format="HH:mm">{item.created_at}</Moment>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default Activities;
