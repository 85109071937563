import React, {Component} from 'react';
import {userService} from '../../_services'
import {Link} from 'react-router-dom'
import API from '../../api';
import $ from 'jquery';
import {hideLoader, showLoader, toast, trackMixpanel} from "../../_helpers/Loader";


window.jQuery = $;
window.$ = $;
global.jQuery = $;

class SignUp extends Component {

    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            errors: {},
        };
    }

    async componentDidMount() {
        trackMixpanel('VerifyEmailView', false, false, false)
        await this.verifyEmail(this.props.match.params.key);
    }

    async verifyEmail(key) {
        showLoader();
        let postData = {
            key: key,
        };
        try {
            let res = await API.post(`rest-auth/registration/verify-email/`, postData);
            toast.success("Email Has Been Successfully verified");
            hideLoader();
            setTimeout(() => {
                window.location.href = "/login";
            }, 4000)
        } catch (e) {
            toast.error("Something Went Wrong");
            hideLoader();
            // console.log(e.response);
        }
    }

    render() {
        return (
            <div id="app">
                <div className="modal fade show" id="exampleModal" tabIndex="-1" role="dialog"
                     aria-labelledby="exampleModalLabel" style={{paddingRight: "16px", display: "block", zIndex: "0"}}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body text-center">
                                <p className="mb-0">Thank you for signing up.<br/>Your Account has being verified. <br/>You
                                    can login now.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SignUp;
