import React, {Component} from 'react';
import {Link} from "react-router-dom";
import API from '../../api'
import Layout from "../Layout";
import {userContext} from '../../_helpers/userContext'
import {showLoader, hideLoader, mixpanel, trackMixpanel} from "../../_helpers/Loader";
import NumberFormat from 'react-number-format';
import {toast} from '../../_helpers/Loader';
import {Context} from "../MyContext";


const user = userContext;

class UpdateOtp extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            mode: '',
            ga_otp_key: null,
            ga_otp_key_url: null,
            errors: []
        };

        this.updateDetails = this.updateDetails.bind(this);
    }

    componentWillMount() {
        trackMixpanel('UpdateOtpView', false, false, this.context.user.email)
        if (this.context.user.completed_onboarding === null || this.context.user.completed_onboarding === undefined) {
            window.location.reload();
        }
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
    }

    componentDidMount() {
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
        this.getCurrentOtpMode();
    }

    async getCurrentOtpMode(){
        try {
            let res = await API.get('wallet/otp_settings/');
            this.setState({
                mode: res.data.otp_mode,
                ga_otp_key: res.data.ga_otp_key,
                ga_otp_key_url:  "https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl="+ res.data.ga_otp_key_url,
            })
            hideLoader();
        } catch (err) {
            toast.error("OTP Fetch Failed");
            this.setState({errors: err});
            hideLoader();
        }
    }

    async updateDetails(event) {
        event.preventDefault();
        showLoader();
        let postData = {mode: this.state.mode};
        try {
            let res = await API.post('wallet/otp_settings/', postData);
            this.setState({
                mode: res.data.otp_mode
            })
            toast.success("Updated Successfully");
            window.location.reload();
            hideLoader();
        } catch (err) {
            toast.error("OTP Update Failed");
            this.setState({errors: err});
            hideLoader();
        }
    }

    render() {
        return (
            <Layout active_menu={'account'} user_state={'personal'} context={this.context}>
                <section>
                    <div className="breadcrumb">
                        <Link to="/account"><span className="fa fa-chevron-left"/> Settings</Link>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-md-12 mx-auto">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4 className="text-center">Update OTP</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={this.updateDetails}>
                                        <div className="form-group">
                                            <label className='' style={{fontWeight: 900,fontSize: "20px", paddingBottom: "20px"}}>Current OTP Mode: {this.state.mode == "GA" ? "Google Authenticator App": this.state.mode}</label> <br/>
                                            <label>Select OTP Mode</label>
                                            <select onChange={event => this.setState({mode: event.target.value})} 
                                            value={this.state.mode}
                                            className="form-control">
                                                <option></option>
                                                <option value="EMAIL">EMAIL</option>
                                                {/* <option>SMS</option> */}
                                                <option value="GA">Google Authenticator</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            { this.state.mode && this.state.ga_otp_key ? <>
                                            <p className='text-center'>Enter the below key into your Google Authenticator App</p>
                                            <code className='text-center'><h3><strong>{this.state.ga_otp_key}</strong></h3></code>
                                            <br/>
                                            <h4 className='text-center'>--OR--</h4>
                                            <p className='text-center'>Scan the below QRCODE using the google authenticator app</p><br/>
                                            <div class="text-center">
                                                <img className="text-center" src={this.state.ga_otp_key_url}/>
                                            </div>
                                            </> : ""}
                                        </div>
                                        <br/>
                                        <div className="form-group">
                                            <button className="btn btn-blue text-white btn-block">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        );
    }
}

export default UpdateOtp;
