import React, {Component} from 'react';
import Layout from '.././Layout';
import {Link} from "react-router-dom";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import moment from 'moment';
import API from "../../api";
import {hideLoader, mixpanel, showLoader, toast, trackMixpanel, Utils} from "../../_helpers/Loader";
import $ from "jquery";
import {Context} from "../MyContext";
import excludeList from '../excludeList';

class ClubInvestments extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            club: {},
            investments: [],
            total_amount: 0,
            total_amount_usd: 0,
            total_amount_gbp: 0,
            total_return: 0,
            total_return_usd: 0,
            total_return_gbp: 0,
            percentage_interest: 0,
            percentage_interest_usd: 0,
            percentage_interest_gbp: 0,
            multiple_data: [],
            investment_order: false,
            modal_investment_order: false
        };
    }

    async componentDidMount() {
        trackMixpanel('ClubInvestmentsView', false, false, this.context.user.email)
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
        showLoader();
        await this.getInvestment();
        await this.getPortfolio();

        $(".table").dataTable({
            'search': {
                caseInsensitive: true,
            },
            ordering: false,
        });
        hideLoader();
        this.popInvestmentRollOver();

    }

    componentWillMount() {
        if (this.context.user.completed_onboarding === null || this.context.user.completed_onboarding === undefined) {
            window.location.reload();
        }
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
    }

    dateToNum(d) {
        d = d.split("-");
        return Number(d[2] + d[1] + d[0]);
    }

    processDates(payout_dates, the_amount, roi, paid_times) {
        let multiple_data = []
        let count = paid_times
        let dateToNum = this.dateToNum
        // payout_dates.sort(function (a, b) {
        //     return dateToNum(a) - dateToNum(b);
        // });
        // console.log(payout_dates)
        payout_dates.map((item, key) => {
            let amount = 0;
            if (key + 1 < payout_dates.length) {
                amount = parseFloat(the_amount) * ((parseFloat(roi) / payout_dates.length) / 100)
            } else {
                amount = parseFloat(the_amount) * ((parseFloat(roi) / payout_dates.length) / 100) + parseFloat(the_amount)
            }
            multiple_data.push({
                date: item, paid: (count > 0), amount: amount
            })
            count = count - 1
        })
        this.setState({multiple_data: multiple_data})
    }

    async getPortfolio() {
        console.log(this.props.match.params.id, "verification")
        try {
            let res = await API.get(`clubs/${this.props.match.params.id}/`);
            let data = res.data;
            this.setState({
                club: data,
            });
        } catch (e) {
            toast.error("Something Went Wrong");
            // console.log(e.response)
        }
    }

    async getInvestment() {
        try {
            let res = await API.get(`clubs/${this.props.match.params.id}/get_investments/`);
            if (res) {
                this.setState({
                    investments: res.data.investments,
                    total_amount: res.data.total_amount,
                    total_amount_usd: res.data.total_amount_usd,
                    total_amount_gbp: res.data.total_amount_gbp,
                    total_return: res.data.expected_return,
                    total_return_usd: res.data.expected_return_usd,
                    total_return_gbp: res.data.expected_return_gbp,
                    percentage_interest: (res.data.percentage) ? (res.data.percentage) : 0,
                    percentage_interest_usd: (res.data.percentage_usd) ? (res.data.percentage_usd) : 0,
                    percentage_interest_gbp: (res.data.percentage_gbp) ? (res.data.percentage_gbp) : 0
                });
            }
        } catch (e) {
            toast.error("Something Went Wrong");
            console.log(e.response);
        }
    }

    checkAdmin(item) {
        if (item !== undefined) {
            for (let i = 0; i < item.length; i++) {
                if (item[i].user.id === this.context.user.id && item[i].user_role === 'admin') {
                    return true;
                }
            }
            return false
        } else {
            return false
        }
    }

    getAdminEmail(item) {
        if (item !== undefined) {
            for (let i = 0; i < item.length; i++) {
                if (item[i].user.id === this.context.user.id && item[i].user_role === 'admin') {
                    return item[i].user.email.toLowerCase();
                }
            }
            return false
        } else {
            return false
        }
    }

    rateConverted(currency, from_amount, to_amount) {
        if (currency !== "NGN") {
            return parseFloat(from_amount) / parseFloat(to_amount)
        } else {
            return parseFloat(1)
        }
    }

    updateInvestmentOrder = async (e, id, data) => {
        e.preventDefault();
        console.log(e, id, data)

        showLoader()
        try {
            let res = await API.put(`club_investments/${id}/`, data);
            if (res) {
                hideLoader()
                toast.success("Investment Order has been updated to roll over!");
                setTimeout(() => {
                    window.location.reload() 
                }, 3000);
            }
        } catch (e) {
            hideLoader()
            toast.error("Something Went Wrong");
        }
    }

    popInvestmentRollOver = async () => {
        try {
            let res = await API.get('club_investments/get_roll_over_investment/');
            if (res) {
                if(res.data && res.data.roll_over == null && res.data.investment.can_roll_over == true){
                    this.setState({
                        modal_investment_order: res.data
                    });
                    $('#alert').modal('show');
                }
            }
        } catch (e) {
            // toast.error("Something Went Wrong");
            console.log("No matching investment")
        } 
    }

    render() {
        return (
            <Layout active_menu={'club-detail'} user_state={this.props.match.params.id}
                    club_admin={this.checkAdmin(this.state.club.club_members)} context={this.context}>
                <div className="section">
                    <div className="breadcrumb">
                        <Link to={`/club/${this.props.match.params.id}/details`}> <span
                            className="fa fa-chevron-left"/> Back</Link>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="float-left">Club Investments</h4>
                                    <Link to="/marketplace" className="btn btn-blue text-white text-white m-b-5 mr-3 float-right"> Find
                                        Investments
                                    </Link>
                                </div>
                                <div className="card-body">
                                    <div className="row boxes">
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                    <span className="text-blue"><i
                                                                        className="fa fa-bar-chart text-blue"/> <strong>Total Investment</strong> <i
                                                                        data-tip="Total Amount Invested "
                                                                        className="fa fa-info-circle text-blue"/></span>
                                                                <h3 className="text-blue mb-0 mt-2">₦{parseFloat(parseFloat(this.state.total_amount).toFixed(2)).toLocaleString()}</h3>
                                                                <hr className="line-2"/>
                                                                <h3 className="text-blue mb-0">${parseFloat(parseFloat(this.state.total_amount_usd).toFixed(2)).toLocaleString()}</h3>
                                                                <hr className="line-2"/>
                                                                <h3 className="text-blue mb-0">£{parseFloat(parseFloat(this.state.total_amount_gbp).toFixed(2)).toLocaleString()}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-blue"><i
                                                                        className="fa fa-reply-all text-blue"/> <strong>Expected ROI</strong>  <i
                                                                        data-tip="Expected return on investment"
                                                                        className="fa fa-info-circle text-right text-blue"/></span>
                                                                <h3 className="text-blue mb-0 mt-2">₦{parseFloat(parseFloat(this.state.total_return).toFixed(2)).toLocaleString()}</h3>
                                                                <hr className="line-2"/>
                                                                <h3 className="text-blue mb-0">${parseFloat(parseFloat(this.state.total_return_usd).toFixed(2)).toLocaleString()}</h3>
                                                                <hr className="line-2"/>
                                                                <h3 className="text-blue mb-0">£{parseFloat(parseFloat(this.state.total_return_gbp).toFixed(2)).toLocaleString()}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-blue"><i
                                                                        className="fa fa-signal text-blue"/> <strong>Interest</strong> <i
                                                                        data-tip="Total amount you will receive from your investments"
                                                                        className="fa fa-info-circle text-right text-blue"/></span>
                                                                <h3 className="text-blue mb-0 mt-2">₦{parseFloat(parseFloat(this.state.total_return - this.state.total_amount).toFixed(2)).toLocaleString()}</h3>
                                                                <hr className="line-2"/>
                                                                <h3 className="text-blue mb-0">${parseFloat(parseFloat(this.state.total_return_usd - this.state.total_amount_usd).toFixed(2)).toLocaleString()}</h3>
                                                                <hr className="line-2"/>
                                                                <h3 className="text-blue mb-0">£{parseFloat(parseFloat(this.state.total_return_gbp - this.state.total_amount_gbp).toFixed(2)).toLocaleString()}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-blue"><i
                                                                        className="fa fa-line-chart text-blue"/> <strong>Percentage Interest</strong> <i
                                                                        data-tip="Percentage Interest gained from  your investments"
                                                                        className="fa fa-info-circle text-right text-blue"/></span>
                                                                <h3 className="text-blue mb-0 mt-2">{parseFloat(this.state.percentage_interest).toFixed(2)}%</h3>
                                                                <hr className="line-2"/>
                                                                <h3 className="text-blue mb-0">{parseFloat(parseFloat(this.state.percentage_interest_usd).toFixed(2)).toLocaleString()}</h3>
                                                                <hr className="line-2"/>
                                                                <h3 className="text-blue mb-0">{parseFloat(parseFloat(this.state.percentage_interest_gbp).toFixed(2)).toLocaleString()}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <div id="example_wrapper"
                                             className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table
                                                        className="table table-striped table-bordered border-t0 text-nowrap w-100 dataTable no-footer"
                                                        role="grid" aria-describedby="example_info">
                                                        <thead>
                                                        <tr role="row">
                                                            <th className="wd-15p sorting_asc" tabIndex="0"
                                                                aria-controls="example" rowSpan="1" colSpan="1"
                                                                aria-sort="ascending"
                                                                aria-label="First name: activate to sort column descending"
                                                                style={{width: "113px"}}>Name
                                                            </th>
                                                            <th className="wd-15p sorting" tabIndex="0"
                                                                aria-controls="example"
                                                                rowSpan="1" colSpan="1"
                                                                aria-label="Last name: activate to sort column ascending"
                                                                style={{width: "113px"}}>Amount
                                                            </th>
                                                            <th className="wd-20p sorting" tabIndex="0"
                                                                aria-controls="example"
                                                                rowSpan="1" colSpan="1"
                                                                aria-label="Position: activate to sort column ascending"
                                                                style={{width: "231.4px"}}>Unit/Token
                                                            </th>
                                                            <th className="wd-20p sorting" tabIndex="0"
                                                                aria-controls="example"
                                                                rowSpan="1" colSpan="1"
                                                                aria-label="Position: activate to sort column ascending"
                                                                style={{width: "231.4px"}}>ROI(%)
                                                            </th>
                                                            <th className="wd-20p sorting" tabIndex="0"
                                                                aria-controls="example"
                                                                rowSpan="1" colSpan="1"
                                                                aria-label="Position: activate to sort column ascending"
                                                                style={{width: "231.4px"}}>Expected Return
                                                            </th>
                                                            <th className="wd-20p sorting" tabIndex="0"
                                                                aria-controls="example"
                                                                rowSpan="1" colSpan="1"
                                                                aria-label="Position: activate to sort column ascending"
                                                                style={{width: "231.4px"}}>Purchased Date
                                                            </th>
                                                            <th className="wd-20p sorting" tabIndex="0"
                                                                aria-controls="example"
                                                                rowSpan="1" colSpan="1"
                                                                aria-label="Position: activate to sort column ascending"
                                                                style={{width: "231.4px"}}>Maturity Date
                                                            </th>
                                                            <th className="wd-20p sorting" tabIndex="0"
                                                                aria-controls="example"
                                                                rowSpan="1" colSpan="1"
                                                                aria-label="Position: activate to sort column ascending"
                                                                style={{width: "231.4px"}}>Status
                                                            </th>
                                                            <th className="wd-20p sorting" tabIndex="0"
                                                                aria-controls="example"
                                                                rowSpan="1" colSpan="1"
                                                                aria-label="Position: activate to sort column ascending"
                                                                style={{width: "231.4px"}}>Actions
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {this.state.investments.map(item => (
                                                            <tr key={item.id}>
                                                                <td>{item.investment.in_trust ? (
                                                                    <i className="fas fa-shield-alt text-dark"/>) : ''} {item.investment.title} by {item.investment.company}</td>
                                                                <td data-tip={`Rate at ₦${this.rateConverted(item.currency, item.amount_ngn, item.amount).toFixed(2).toLocaleString()}`}>
                                                                    <NumberFormat
                                                                        renderText={value => <div>{value}</div>}
                                                                        prefix={Utils.currencyToSym(item.currency)}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true}
                                                                        value={parseFloat(item.value).toFixed(2)}/>
                                                                </td>
                                                                <td>{parseInt(item.units)}</td>
                                                                <td>{item.roi}{item.investment.payout_interval ?  "/" + `${item.investment.payout_interval}` : ""}</td>
                                                                <td><NumberFormat
                                                                    renderText={value => <div>{value}</div>}
                                                                    prefix={Utils.currencyToSym(item.currency)}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true}
                                                                    value={item.expected_return}/></td>
                                                                <td><Moment format="MMM D, Y">{item.created_at}</Moment>
                                                                </td>
                                                                <td><Moment
                                                                    format="MMM D, Y">{item.maturity_date}</Moment></td>
                                                                <td>
                                                                    {item.investment.id == 27 && excludeList.includes(this.getAdminEmail(this.state.club.club_members)) ? "Active" : (item.investment.payout_status == "pending" || item.investment.payout_status == "none" ? "Active" : "Completed")}
                                                                </td>
                                                                {(item.investment.club_tag) ?
                                                                    <td><Link
                                                                        to={`/club/${this.state.club.id}/investment/${item.id}/history`}> <span
                                                                        className="fa fa-list"/></Link>
                                                                    </td> : (item.investment.multiple_payout) ? (
                                                                            <td><span
                                                                                onClick={event => {
                                                                                    this.processDates(item.investment.payout_dates, item.value, item.investment.inv_roi, item.payout_times)
                                                                                    this.setState({investment_order: item})
                                                                                }}
                                                                                data-target="#dateModal"
                                                                                data-toggle="modal"
                                                                                style={{'cursor': 'pointer'}}
                                                                                className="fa fa-eye"/>
                                                                            </td>
                                                                        ) :
                                                                        <td><span className="fa fa-ban"/></td>
                                                                }
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                    {this.state.investments.length > 0 ? 
                                                        <div className='text-blue text-center'><Link to="/investments"
                                                                className="text-blue"> See more </Link></div> : ""
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="dateModal" tabIndex="-1" role="dialog"
                         aria-labelledby="dateModalLabel">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header bg-green">
                                    <h4 className="modal-title" id="dateModalLabel">Payout Dates</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="text-red text-center">
                                        {this.state.investment_order ? this.state.investment_order.roll_over == null && this.state.investment_order.investment.can_roll_over && 5 < (moment(this.state.investment_order.maturity_date).diff(moment(new Date()), 'days')) < 40 ? (<i className="text-primary text-center">* This investment will mature in {this.state.investment_order.maturity_date}. Do you want to roll over this investment? <br/><br/> 
                                        <div className="text-center"><button onClick={ e => this.updateInvestmentOrder(e, this.state.investment_order.id, {roll_over: false})} className="btn btn-negative-primary btn-outline-primary text-primary"> No </button>&nbsp;&nbsp;<button onClick={ e => this.updateInvestmentOrder(e, this.state.investment_order.id, {roll_over: true, roll_over_date: new Date().toISOString().split('T')[0]})} className="btn-primary btn"> Yes </button><br/><br/></div></i>) : "" : ""}
                                    </div>

                                    <div className="list-group">
                                        {(this.state.multiple_data).map(item => (
                                            <a href="#"
                                               className="list-group-item list-group-item-action flex-column align-items-start">
                                                <div className="d-flex w-100 justify-content-between">
                                                    <h5 className="mb-1">{item.date}</h5>
                                                    <small>{(item.paid) ? 'Completed' : 'Upcoming payout'}</small>
                                                </div>
                                                <p className="mb-1">₦{item.amount.toLocaleString()}</p>
                                            </a>
                                        ))}
                                    </div>
                                </div>
                                <div className="modal-footer text-center">
                                    <button type="button" className="btn no-outline-green"
                                            data-dismiss="modal">Close
                                    </button>
                                </div>
                            </div>
                        </div>
                </div>
                <div className="modal fade" id="alert" tabIndex="-1" role="dialog"
                        aria-labelledby="alertLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h4 className="modal-title" id="alertLabel">Investment Payout</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">

                                <div className="list-group text-center">
                                    <a href="#"
                                        className="list-group-item list-group-item-action flex-column align-items-start">
                                            {this.state.modal_investment_order && this.state.modal_investment_order != false ? (<p className="mb-1">Your investment <b>{this.state.modal_investment_order &&this.state.modal_investment_order.investment && this.state.modal_investment_order.investment.title}</b> will mature on {this.state.modal_investment_order.maturity_date}. Do you want to rollover this investment? <br/><br/><div className="text-center"><button onClick={ e => this.updateInvestmentOrder(e, this.state.modal_investment_order.id, {roll_over: false})} className="btn btn-social btn-negative-primary btn-outline-primary text-primary"> No </button>&nbsp;&nbsp;<button onClick={ e => this.updateInvestmentOrder(e, this.state.modal_investment_order.id, {roll_over: true, roll_over_date: new Date().toISOString().split('T')[0]})} className="btn-primary btn"> Yes </button><br/><br/></div></p>) : ""}
                                    </a>
                                </div>
                            </div>
                            <div className="modal-footer text-center">
                                <button type="button" className="btn no-outline-green"
                                        data-dismiss="modal">Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default ClubInvestments;
