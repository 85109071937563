import React, {Component} from 'react';
import {Link} from "react-router-dom";
import API from '../../api'
import Layout from "../Layout";
import {userContext} from '../../_helpers/userContext'
import {showLoader, hideLoader, mixpanel, trackMixpanel} from "../../_helpers/Loader";
import NumberFormat from 'react-number-format';
import {toast} from '../../_helpers/Loader';
import {Context} from "../MyContext";


const user = userContext;

class UpdateOtp extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            bvn: null
        };

        this.verifyBvn = this.verifyBvn.bind(this);
    }

    componentWillMount() {
        console.log(this.context.user, ":::::")
        trackMixpanel('UpdateBVNView', false, false, this.context.user.email)
        if (this.context.user.completed_onboarding === null || this.context.user.completed_onboarding === undefined) {
            window.location.reload();
        }
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
    }

    componentDidMount() {
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
    }

    async verifyBvn(event) {
        event.preventDefault();
        showLoader();
        let postData = {bvn: this.state.bvn};
        try {
            let res = await API.post('users/verify_bvn/', postData);
            if (res){
                toast.success("BVN verified Successfully");
                await this.context.updateUser();
                if (this.context.user.accounts.length == 0) {
                    toast.error("Please update your account number as well")
                    this.props.history.push("/profile");
                }
                this.props.history.push("/");
            }
            hideLoader();
        } catch (err) {
            toast.error("BVN could not be verified");
            hideLoader();
        }
    }

    render() {
        return (
            <Layout active_menu={'account'} user_state={'personal'} context={this.context}>
                <section>
                    <div className="breadcrumb">
                        <Link to="/account"><span className="fa fa-chevron-left"/> Verify BVN</Link>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-md-12 mx-auto">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4 className="text-center">Verify BVN</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={this.verifyBvn}>
                                        <div className="form-group">
                                            <label>Enter BVN</label>
                                            <input onChange={event => this.setState({bvn: event.target.value})} 
                                            className="form-control" />
                                        </div>
                                        <br/>
                                        <div className="form-group">
                                            <button className="btn btn-blue text-white btn-block">Verify</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        );
    }
}

export default UpdateOtp;
