import React, {useEffect, useState, useContext} from "react";
import { Link } from "react-router-dom";
import { hideLoader, showLoader } from "../../_helpers/Loader";
import Layout from "../Layout";
import { Context } from "../MyContext";
import API from '../../api';
import $ from 'jquery';
import moment from 'moment'
import 'bootstrap-datepicker';
import { pdfFromReact } from "generate-pdf-from-react-html";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import { mixpanel, toast, trackMixpanel, Utils} from "../../_helpers/Loader";
import './style.css';
import _ from 'lodash';
import IndividualDetailReport from "./individual";
import ClubDetailReport from "./club";
// import ReactPDF, { Document, Page, Text, Image, Font } from '@react-pdf/renderer';


const DetailReport = (props) => {
    const [view, setView] = useState("individual");
    const context = useContext(Context);

    useEffect(() => {
        if (context.user.completed_onboarding === null || context.user.completed_onboarding === undefined) {
        window.location.reload();
        }
        if (context.user.completed_onboarding === false) {
            return props.history.push("/user/onboarding");
        }

        // $('#end_date').datepicker({
        // format: "yyyy-mm-dd",
        // autoclose: true
        // }).on("changeDate", (e) => {
        //     let date = moment(e.date).format("YYYY-MM-DD");
        //     setEndDate(date);
        // });
        // $('#start_date').datepicker({
        //     format: "yyyy-mm-dd",
        //     autoclose: true
        // }).on("changeDate", (e) => {
        //     let date = moment(e.date).format("YYYY-MM-DD");
        //     setStartDate(date);
        // });

        return () => {
        console.log("")
        };
    }, []);


    return (
          <Layout context={context} active_menu={'reports'} title={'Reports'}>
              <div className="section">
                  <div className="breadcrumb">
                      <h5><a href="/"><span className="fa fa-chevron-left"/> Back
                        </a></h5>
                  </div>
                  <div className="row">
                      <div className="col-sm-12">
                          <div className="card">
                              <div className="card-header bg-green">
                                  <h4 className="float-left">Reports</h4>
                                  <button className="btn btn-primary  float-right" onClick={(e) => {setView("club")}}><i className="fa fa-file-pdf-o"></i> Generate Club Investment Report</button>
                                  <button className="btn btn-primary mx-1 float-right" onClick={(e) => {setView("individual")}}><i className="fa fa-file-pdf-o"></i> Generate Personal Investment Report</button>
                              </div>
                              <div className="card-body">
                                {/* <form className="">
                                  <div className="row">
                                    <div className="col-4">
                                      <div className="form-group">
                                        <label>Start Date</label>
                                        <div className="input-group date">
                                          <input autoComplete="off" id="start_date" required
                                                    type="text" className="form-control datepicker"/>
                                            <div className="input-group-addon">
                                                <span className="fa fa-calendar"/>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-4">
                                      <div className="form-group">
                                          <label>End Date</label>
                                          <div className="input-group date">
                                              <input autoComplete="off" id="end_date" required type="text"
                                                      className="form-control datepicker"/>
                                              <div className="input-group-addon">
                                                  <span className="fa fa-calendar"/>
                                              </div>
                                          </div>
                                      </div>
                                    </div>
                                  </div>
                                </form> */}
                                {view == "individual" ? (
                                  <IndividualDetailReport />
                                ) : <ClubDetailReport />}
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </Layout>
      )
}

export default DetailReport;