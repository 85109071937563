import React, {Component} from 'react';
import {userService} from '../../_services'
import {Link} from 'react-router-dom'
import API from '../../api';
import $ from 'jquery';
import {hideLoader, showLoader, toast, trackMixpanel} from "../../_helpers/Loader";
import LOGO from "../../assets2/img/logo-white.png";
import {modal} from 'bootstrap';
import Fade from 'react-reveal/Fade';

window.jQuery = $;
window.$ = $;
global.jQuery = $;

class SignUp extends Component {

    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            errors: {},
            signup_page: 1,
            tgem_form: {},
            tgem_button: 'Next'
        };

        userService.logout();

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillMount() {
        (this.props.match.path === '/tgem/signup') && this.setState({page: 2})
    }

    componentDidMount() {
        trackMixpanel('SignUpView', false, false, false)
    }

    async handleSubmit(event) {
        event.preventDefault();

        if (this.state.signup_page === 1) {
            this.setState({signup_page: 2, tgem_button: 'Submit'})
            return false
        }

        if (this.state.page === 1) {
            const {first_name, last_name, email, password} = event.target;
            const postData = {
                first_name: first_name.value,
                last_name: last_name.value,
                email: email.value,
                username: email.value,
                password1: password.value,
                password2: password.value
            };
            showLoader();
            try {
                let res = await API.post('rest-auth/registration/', postData);
                trackMixpanel('SignUp', {
                    email: postData.email,
                    firstName: postData.first_name,
                    lastName: postData.last_name,
                }, {
                    email: postData.email,
                    firstName: postData.first_name,
                    lastName: postData.last_name,
                }, postData.email)
                hideLoader();
                toast.success("Account Successfully Created");
                if (res.status === 201) {
                    this.setState({page: 3});
                    $(".show-modal").click();
                    $(".modal").css('z-index', 1048);
                }
                setTimeout(() => {
                    this.props.history.push("/login")
                }, 7000)
            } catch (e) {
                this.setState({errors: e.response.data});
                if (e.response.status === 500) {
                    hideLoader();
                    $('#redirectModal').modal('show');
                } else {
                    hideLoader();
                    toast.error("Something went wrong!");
                }
            }
        }
    }

    redirect = (event) => {
        event.preventDefault();
        if (event.target.option.value == 1) {
            window.location.href = 'https://thetgiclub.com/';
        } else {
            window.location.href = 'https://twelvevest.com/financial-networth/join-waiting-page'
        }
    };

    render() {
        return (
            <div id="app">
                <section className="section section-2"
                         style={(this.state.page === 3) ? ({display: 'none'}) : {display: ''}}>
                    <div className="row">
                        <div className="single-page single-pageimage construction-bg cover-image">
                            <div className="row">
                                <div className="col-lg-6 login-image">
                                    <div className="log-wrapper text-center img-container">
                                        <img src={LOGO} width="50%"
                                             className="mb-2 mt-4 mt-lg-0 mb-5" alt="logo"/>
                                        <div className="overlay text-center">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="wrapper wrapper2">
                                        <form onSubmit={this.handleSubmit} className="card-body form-body-sign-up"
                                              tabIndex="500">
                                            <h3 className="text-dark">Sign Up</h3>
                                            <div className="fname text-left">
                                                <input required type="text" name="first_name"/>
                                                <label>First Name</label>
                                            </div>
                                            <div className="lname text-left">
                                                <input required type="text" name="last_name"/>
                                                <label>Last Name</label>
                                            </div>
                                            <div className="name text-left">
                                                <input autoComplete="email" required type="email" name="email"/>
                                                <label>Email</label>
                                                {(this.state.errors.email) ? (this.state.errors.email.map(item => (
                                                        <small className="text-danger">{item}</small>
                                                    ))) :
                                                    ''}
                                            </div>
                                            <br/>
                                            <div className="passwd text-left">
                                                <input autoComplete="current-password" required type="password"
                                                       name="password"/>
                                                <label>Password</label>
                                                {(this.state.errors.password1) ? (this.state.errors.password1.map(item => (
                                                        <small className="text-danger">{item}</small>
                                                    ))) :
                                                    ''}
                                            </div>
                                            <small>
                                                <ul style={{listStyle: 'none'}} className="list-group text-left">
                                                    <li className="">Your password can't be too similar to your other
                                                        personal information such as first name, last name and email
                                                        address.
                                                    </li>
                                                    <li className="">Your password must contain at least 8 characters.
                                                    </li>
                                                    <li className="">Your password can't be a commonly used password.
                                                    </li>
                                                    <li className="">Your password can't be entirely numeric.</li>
                                                </ul>
                                            </small>
                                            <br/>
                                            <div className="text-left">
                                                <input
                                                        type="checkbox"
                                                        required
                                                        className="custom-controlinput"
                                                        id="customCheck1"
                                                        style={{height: "15px", padding: "5px", width: "20px"}}
                                                        />I have Read and I Accept Twelvevest's  <a href="https://twelvevest.com/termsandconditions/" target="_blank"><u className="twelve-green mx-1">Terms &amp; Conditions,</u></a> <a href="https://twelvevest.com/privacypolicy/" target="_blank"><u className="twelve-green mx-1">Privacy Policy</u></a> and <a href="https://twelvevest.com/EULA/" target="_blank"><u className="twelve-green mx-1">EULA</u></a>
                                            </div>
                                            <br/>
                                            <div className="submit">
                                                <button className="btn btn-blue text-white btn-block">Sign Up
                                                </button>
                                            </div>
                                            <p className="text-dark mb-0">Have an account?<Link to="/login"
                                                                                                className="ml-1">Login</Link>
                                            </p>
                                            <button id="show-modal" hidden type="button" className="btn btn-primary "
                                                    data-toggle="modal"
                                                    data-target="#exampleModal">Default modal
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="modal fade show" id="exampleModal" tabIndex="-1" role="dialog"
                     aria-labelledby="exampleModalLabel" style={{paddingRight: "16px", display: "block", zIndex: "0"}}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body text-center">
                                <p className="mb-0">Thank you for signing up.<br/>A confirmation message has been sent
                                    to your mail. Check your spam folder if not found in your inbox.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="redirectModal" tabIndex="-1" role="dialog"
                     aria-labelledby="redirectModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h4 className="modal-title" id="redirectModalLabel"><span
                                    className="fa fa-info-circle"/> Email not on whitelist</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <form onSubmit={event => this.redirect(event)}>
                                <div className="modal-body">
                                    <p className="text-justify mb-3">
                                        {/* Twelvevest currently operates for members of a closed investment club (the green
                                        investment club).<br/>
                                        You can select the first option to find out about the Green Investment
                                        Club or select the second option to get on the waitlist and be the first to know
                                        when we launch. */}
                                        Twelvevest is currently not open to the General public. Upon receipt of a license from the Securities Exchange Commission, we will officially launch to the public.<br/>Sign up on our waitlist to be the first to know when we receive and launch officially to the public.
                                    </p>
                                    <div className="form-group">
                                        <label htmlFor="">Select redirect option: <sup
                                            className="text-danger">*</sup></label>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="t1">
                                            <input required value="1" name="option" className="mr-2" id="t1"
                                                   type="radio"/>
                                            Select to find out more and join the Green Investment Club</label>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="t2">
                                            <input required value="2" name="option" className="mr-2" id="t2"
                                                   type="radio"/>
                                            Select to join Twelvevest waiting list</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-blue text-white">Continue</button>
                                    <button type="button" className="btn btn-outline-dark" data-dismiss="modal">Close
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SignUp;
