import React, {Component} from 'react';

class Oops extends Component {
    render() {
        return (
            <div id="app">
                <section className="section">
                    <div className="container mt-5">
                        <div className="page-error">
                            <div className="page-inner">
                                <h1>Oops</h1>
                                <div className="page-description">
                                    Your network is currently unavailable. Kindly check your data or WIFI connection
                                </div>
                                <div className="page-search">
                                    <div className="mt-3">
                                        <a onClick={event => window.location.reload()}
                                           className="btn btn-blue text-white">Reload</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="simple-footer mt-5 text-white">
                            Copyright &copy; Kharna-Admin by Spruko Technologies 2018
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Oops;