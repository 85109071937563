import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Layout from '../Layout';
import SETTINGS_ICON from '../../assets/img/icons/settings.png';
import INVEST_ICON from '../../assets/img/icons/worth.png';
import BANK_ICON from '../../assets/img/icons/bank.png';
import LOCK_ICON from '../../assets/img/icons/lock.png';
import {Context} from "../MyContext";
import API from "../../api";
import {mixpanel, trackMixpanel} from "../../_helpers/Loader";

class Settings extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);

    }

    componentWillMount() {
        trackMixpanel('AccountSettingsView', false, false, this.context.user.email)
        if (this.context.user.completed_onboarding === null || this.context.user.completed_onboarding === undefined) {
            window.location.reload();
        }
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
    }

    componentDidMount() {
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
    }

    render() {
        return (
            <Layout active_menu={'account'} user_state={'personal'} context={this.context}>
                <div className="section">
                    <div className="breadcrumb">
                        <h5>Settings</h5>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-lg-3 col-xl-3">
                            <Link className="card-link" to="/profile">
                                <div className="card">
                                    <div className="img-container lg-card-bg bg-green">
                                        <i className="fa fa-user-edit fa-4x" data-toggle="tooltip" title=""
                                           data-original-title="fa-user"/>
                                    </div>
                                    <div className="card-footer text-center pt-3 pb-3">
                                        <strong>Profile Settings</strong>
                                        <p className="mt-2 text-blue">Update Profile</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-3 col-xl-3">
                            <Link className="card-link" to="account/networth">
                                <div className="card">
                                    <div className="img-container lg-card-bg bg-green">
                                        <i className="fa fa-money fa-4x" data-toggle="tooltip" title=""
                                           data-original-title="fa-user"/>
                                    </div>
                                    <div className="card-footer text-center pt-3 pb-3">
                                        <strong>Net Worth Details</strong>
                                        <p className="mt-2 text-blue">Update Net Worth Details</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-3 col-xl-3">
                            <div className="card card-disabled">
                                <div className="img-container lg-card-bg bg-green">
                                    <i className="fa fa-bank fa-4x" data-toggle="tooltip" title=""
                                       data-original-title="fa-user"/>
                                </div>
                                <div className="card-footer text-center pt-3 pb-3">
                                    <strong>Withdrawals <sup>Coming Soon</sup></strong>
                                    <p className="mt-2 text-blue">Manage Bank Withdrawals</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-3 col-xl-3">
                            <Link className="card-link" to="/account/update-password">
                                <div className="card">
                                    <div className="img-container lg-card-bg bg-green">
                                        <i className="fa fa-user-lock fa-4x" data-toggle="tooltip" title=""
                                           data-original-title="fa-user"/>
                                    </div>
                                    <div className="card-footer text-center pt-3 pb-3">
                                        <strong>Password Update</strong>
                                        <p className="mt-2 text-blue">Update Password</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-3 col-xl-3">
                            <Link className="card-link" to="/account/update-otp">
                                <div className="card">
                                    <div className="img-container lg-card-bg bg-green">
                                        <i className="fa fa-code fa-4x" data-toggle="tooltip" title=""
                                           data-original-title="fa-user"/>
                                    </div>
                                    <div className="card-footer text-center pt-3 pb-3">
                                        <strong>OTP Settings</strong>
                                        <p className="mt-2 text-blue">Update OTP Mode</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        {(this.context.user.user_type === 'tgic') ? (
                            <div className="col-md-6 col-sm-6 col-lg-3 col-xl-3">
                                <Link className="card-link" to="/sub-investor">
                                    <div className="card">
                                        <div className="img-container lg-card-bg bg-green">
                                            <i className="fa fa-child fa-4x" data-toggle="tooltip" title=""
                                               data-original-title="fa-user"/>
                                        </div>
                                        <div className="card-footer text-center pt-3 pb-3">
                                            <strong>Child Account</strong>
                                            <p className="mt-2 text-blue">Manage Child Account</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ) : ''}
                        {(this.context.user.is_axamansard) ? (
                            <div className="col-md-6 col-sm-6 col-lg-3 col-xl-3">
                                <Link className="card-link" to="/account/axa-settings">
                                    <div className="card">
                                        <div className="img-container lg-card-bg bg-green">
                                            <i className="fa fa-line-chart fa-4x" data-toggle="tooltip" title=""
                                               data-original-title="fa-user"/>
                                        </div>
                                        <div className="card-footer text-center pt-3 pb-3">
                                            <strong>Axa Mansard</strong>
                                            <p className="mt-2 text-blue">Axa Mansard Profile</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ) : ''}
                        {(!this.context.user.bvn_verified) ? (
                            <div className="col-md-6 col-sm-6 col-lg-3 col-xl-3">
                                <Link className="card-link" to="/profile">
                                    <div className="card">
                                        <div className="img-container lg-card-bg bg-green">
                                            <i className="fa fa-id-card fa-4x" data-toggle="tooltip" title=""
                                               data-original-title="fa-user"/>
                                        </div>
                                        <div className="card-footer text-center pt-3 pb-3">
                                            <strong>BVN</strong>
                                            <p className="mt-2 text-blue">Update BVN</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ) : ''}
                    </div>
                </div>
            </Layout>
        );
    }
}

export default Settings;
