import React from 'react';
import {Link} from "react-router-dom";
import uuid from 'uuid'
import API from '../../api'

import Layout from '../Layout'
import {hideLoader, mixpanel, showLoader, toast, trackMixpanel} from "../../_helpers/Loader";
import {Context} from "../MyContext";

class ClubAndInvites extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            list: [],
            club: {
                club_members: []
            },
            max_members: 0,
            members: [],
            invites: [],
        };

        this.onAdd = this.onAdd.bind(this);
        this.sendInvite = this.sendInvite.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    async componentDidMount() {
        trackMixpanel('ClubInvitationsView', false, false, this.context.user.email)
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
        showLoader();
        await this.getPortfolio();
        await this.getInvites();
        (!this.checkAdmin(this.state.club.club_members)) && this.props.history.push(`club/`);
        hideLoader();
    }

    componentWillMount() {
        if (this.context.user.completed_onboarding === null || this.context.user.completed_onboarding === undefined) {
            window.location.reload();
        }
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
    }

    onAdd(event) {
        event.preventDefault();
        const {name, email} = event.target;
        this.state.list.map(item => {
            if (this.state.list.length > 0 && item.email !== email.value) {
                this.setState({
                    list: [...this.state.list, {
                        id: uuid(),
                        name: name.value,
                        email: email.value
                    }]
                });
            }
        });
        (this.state.list.length === 0) && this.setState({
            list: [...this.state.list, {
                id: uuid(),
                name: name.value,
                email: email.value
            }]
        });
        name.value = '';
        email.value = '';
    }

    onDelete(event, item_id) {
        this.setState({list: this.state.list.filter(el => el.id !== item_id)})
    }

    async getPortfolio() {
        try {
            let res = await API.get(`clubs/${this.props.match.params.id}/`);
            let data = res.data;
            this.setState({club: data, max_members: data.club_subscription[0].package.max_members});
            (data.club_members.length > data.club_subscription[0].package.max_members) && this.props.history.push(`/club/${this.props.match.params.id}/details`)
        } catch (e) {
            toast.error("Something Went Wrong");
            // console.log(e.response)
        }
    }

    getInvites = async () => {
        try {
            let res = await API.get(`club_invites/${this.context.club_id}/get_all_invites/`);
            // console.log(res.data);
            this.setState({invites: res.data});
        } catch (e) {

        }
    };

    async sendInvite() {
        let postData = {
            club_id: parseInt(this.props.match.params.id),
            invitees: this.state.list
        };
        // console.log(postData);
        showLoader();
        try {
            let res = await API.post('clubs/send_invite/', postData);
            toast.success("Invite Sent Successfully");
            //this.props.history.push(`/club/${this.props.match.params.id}/details`)
            hideLoader();
        } catch (e) {
            toast.error("Something Went Wrong");
            hideLoader();
        }
    }

    goBack() {
        if (this.props.location.query) {
            this.props.history.push(this.props.location.query.back_url)
        } else {
            this.props.history.push(`/club/${this.props.match.params.id}/details`)
        }
    }

    checkAdmin(item) {
        if (item !== undefined) {
            for (let i = 0; i < item.length; i++) {
                if (item[i].user.id === this.context.user.id && item[i].user_role === 'admin') {
                    return true;
                }
            }
            return false
        } else {
            return false
        }
    }

    onChangeMember = async (event, user_id) => {
        let postData = {
            club_id: this.state.club.id,
            user_id: user_id
        };
        if (event.target.checked) {
            showLoader();
            try {
                await API.post('clubs/add_admin/', postData);
                toast.success("Admin Role Created");
                await this.getPortfolio();
                await this.getInvites();
                hideLoader();
            } catch (e) {
                toast.error("Something Went Wrong");
                hideLoader();
                // console.log(e.response)
            }
        } else {
            showLoader();
            try {
                await API.post('clubs/remove_admin/', postData);
                toast.success("Admin Role Changed");
                await this.getPortfolio();
                await this.getInvites();
                hideLoader();
            } catch (e) {
                toast.error("Something Went Wrong");
                hideLoader();
                // console.log(e.response)
            }
        }
    }

    render() {
        return (
            <Layout active_menu={'club'} user_state={this.props.match.params.id}
                    club_admin={this.checkAdmin(this.state.club.club_members)} context={this.context}>
                <div className="section">
                    <div className="breadcrumb">
                        <div className="col-sm-6">
                            <a onClick={this.goBack}> <span className="fa fa-chevron-left"/> Back</a>
                        </div>
                        <div className="col-sm-6 float-right text-right">

                            <a onClick={this.goBack} className="">Skip <span className="fa fa-forward"/></a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-lg-8 col-xl-8 mx-auto">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row text-center">
                                        <div className="col-sm-12 mb-3">
                                            <h3>Invite members to join the club</h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <form onSubmit={this.onAdd} className="form-horizontal">
                                                <div className="row mx-auto">
                                                    <div className="col-sm-4" style={{paddingRight: '0'}}>
                                                        <div className="form-group overflow-hidden">
                                                            <label>Name</label>
                                                            <input name="name" type="text" className="form-control"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-5" style={{paddingRight: '0'}}>
                                                        <div className="form-group overflow-hidden">
                                                            <label>Email</label>
                                                            <input name="email" type="email" className="form-control"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <label> &nbsp; </label>
                                                        <button
                                                            className="btn btn-blue text-white no-outline-green">Add
                                                            Email
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="table-responsive">
                                                <table className="table table-striped mb-0 text-nowrap">
                                                    <tbody>
                                                    {this.state.list.map(item => (
                                                        <tr key={item.id}>
                                                            <td>{item.name}</td>
                                                            <td>{item.email}</td>
                                                            <td>
                                                                <button onClick={event => this.onDelete(event, item.id)}
                                                                        className="btn btn-sm btn-danger badge"
                                                                        type="button"><i className="fa fa-trash"/>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row text-center mt-3">
                                        <div className=" col-sm-12">
                                            <button disabled={(this.state.list.length < 1)}
                                                    className="btn btn-blue text-white mr-3 mr-sm-0 mr-md-3"
                                                    onClick={this.sendInvite}>Send
                                                Invites
                                            </button>
                                            <button onClick={event => this.setState({list: []})}
                                                    className="btn btn-outline-green m-b-5 m-t-5">Clear
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row" id="members">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="float-left">Member's List</h4>
                                            <h4 className="float-right">{this.state.max_members - this.state.club.club_members.length} Member
                                                Space Available</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-striped mb-0 text-nowrap">
                                                    <tbody>
                                                    <tr>
                                                        <th>Full Name</th>
                                                        <th>Role</th>
                                                        <th>Change Role</th>
                                                        <th>
                                                            <button data-target="#messageModal" data-toggle="modal"
                                                                    className="badge badge-primary bg-green btn btn-sm">Send
                                                                Group Message <sup>beta+</sup> <i
                                                                    className='fa fa-paper-plane'/></button>
                                                        </th>
                                                        <th>Actions</th>
                                                    </tr>
                                                    {(this.state.club.club_members) ? this.state.club.club_members.map(item => (
                                                        <tr role="row" key={uuid()}>
                                                            <td className="sorting_1">{item.user.first_name} {item.user.last_name}</td>
                                                            <td style={{textTransform: 'capitalize'}}>{item.user_role}</td>
                                                            <td><label className="custom-switch">
                                                                <input
                                                                    defaultChecked={(item.user_role.toLowerCase() === 'admin')}
                                                                    onChange={event => this.onChangeMember(event, item.user.id)}
                                                                    type="checkbox" name="custom-switch-checkbox"
                                                                    className="custom-switch-input"/>
                                                                <span className="custom-switch-indicator"/>
                                                            </label></td>
                                                            <td>
                                                                {(item.user.id !== this.context.user.id) ? (
                                                                    <Link to={{
                                                                        pathname: '/chats',
                                                                        query: item
                                                                    }}>
                                                                <span
                                                                    className="badge badge-primary bg-green btn btn-sm">Send Message</span>
                                                                    </Link>
                                                                ) : ''}
                                                            </td>
                                                            <td>
                                                                <button className="btn btn-blue text-white text-white"><i
                                                                    className="fa fa-user-slash"/> Remove Member
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )) : ''}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row" id="invites">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4>Invites</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-striped mb-0 text-nowrap">
                                                    <tbody>
                                                    <tr>
                                                        <th>Email Address</th>
                                                        <th>Status</th>
                                                    </tr>
                                                    {this.state.invites.map(item => (
                                                        <tr>
                                                            <td>{item.recipient}</td>
                                                            <td>{item.status}</td>
                                                        </tr>
                                                    ))}
                                                    <tr>

                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

const styles = {
    marketplaceExpanded_section: {
        padding: '5%'
    },
    market_place_body: {},
    market_place_header: {
        marginBottom: '3%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    alignItemsToCenter: {
        textAlign: 'center'
    },
    style_body_items: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        marginBottom: '5%',
    }
};

export default ClubAndInvites
