import React, {Component} from 'react';
import {Link} from "react-router-dom";
import API from '../../api'
import Layout from "../Layout";
import {userContext} from '../../_helpers/userContext'
import {showLoader, hideLoader, toast, mixpanel, trackMixpanel} from "../../_helpers/Loader";
import NumberFormat from 'react-number-format';
import {Context} from "../MyContext";


class InvestmentSettings extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            target: 0,
            income: 0
        };

        this.updateDetails = this.updateDetails.bind(this);
    }

    componentDidMount() {
        trackMixpanel('NetworthSettingsView', false, false, this.context.user.email)
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
        this.setState({
            target: this.context.user.target_networth,
            income: this.context.user.earnings,
        });
    }

    async updateDetails(event) {
        event.preventDefault();
        await this.context.updateUser();
        showLoader();
        let postData = {target_networth: this.state.target, earnings: this.state.income};
        try {
            await API.post('users/update_networth_income/', postData);
            toast.success("Account Information Updated");
            localStorage.removeItem('user');
            try {
                let res2 = await API.get('users/');
                localStorage.setItem('user', JSON.stringify(res2.data[0]));
                await this.context.updateUser();
                this.props.history.push('/account')
            } catch (e) {
                hideLoader();
                // console.log(e.response);
            }
            hideLoader();
        } catch (err) {
            toast.error("Something Went Wrong");
            // console.log(err.response);
            hideLoader();
        }
    }

    render() {
        return (
            <Layout active_menu={'account'} user_state={'personal'} context={this.context}>
                <section>
                    <div className="breadcrumb">
                        <Link to="/account"><span className="fa fa-chevron-left"/> Settings</Link>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-md-12 mx-auto">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4 className="text-center">Net Worth Details</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={this.updateDetails}>
                                        <div className="form-group">
                                            <label>Target Net worth</label>
                                            <NumberFormat className="form-control" value={this.state.target}
                                                          thousandSeparator={true}
                                                          prefix={'₦'} onValueChange={(values) => {
                                                const {value} = values;
                                                this.setState({target: value})
                                            }}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Income</label>
                                            <NumberFormat className="form-control" value={this.state.income}
                                                          thousandSeparator={true}
                                                          prefix={'₦'} onValueChange={(values) => {
                                                const {value} = values;
                                                this.setState({income: value})
                                            }}/>
                                        </div>
                                        <div className="form-group">
                                            <button className="btn btn-blue text-white btn-block">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        );
    }
}

export default InvestmentSettings;
