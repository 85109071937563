import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import uuidv1 from 'uuid';
import {showLoader, hideLoader, toast, mixpanel, trackMixpanel, Utils} from '../../_helpers/Loader'
import Moment from 'react-moment';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import {CircularProgressbar, buildStyles} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {Context} from '../MyContext'
import Layout from '.././Layout';
import $ from 'jquery';
import API from '../../api';
import ReactTooltip from 'react-tooltip'
import "daterangepicker/daterangepicker.css";
import Chart from 'chart.js';
import excludeList from '../excludeList';
import QualificationNotification from './QualificationNotification';


class Dashboard extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            portfolio: 0,
            total_networth: 0,
            total_amount: 0,
            total_amount_usd: 0,
            total_amount_gbp: 0,
            total_return: 0,
            total_return_usd: 0,
            total_return_gbp: 0,
            investments: [],
            percentage_interest: 0,
            percentage_interest_usd: 0,
            percentage_interest_gbp: 0,
            activities: [],
            investment_currency: "ALL",
            worth_direction: 0,
            target: 0,
            user: {},
            portfolio_currency: "NGN",
            chart_data: {},
            radial_colour: 'circle-success',
            pending_transactions: [],
            receipt_info: {
                id: '',
                title: '',
                ref_code: '',
                price: '',
                units: '',
                roi: '',
            },
            modal_image: '',
            multiple_data: [],
            investment_order: false,
            modal_investment_order: false,
        };
    }


    async componentDidMount() {
        trackMixpanel('DashboardView', false, false, this.context.user.email)
        if (this.context.user.completed_onboarding === null || this.context.user.completed_onboarding === undefined) {
            window.location.reload();
        }
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
        showLoader();
        await this.getPortfolio();
        if (this.context.user.user_type !== 'twelve') {
            await this.getPieInfo();
        }
        await this.getInvestment();
        await this.getActivities();
        $(window).resize(() => {
            window.location.reload();
        });
        var maxHeight = Math.max.apply(null, $(".boxes .card").map(function () {
            return $(this).height();
        }).get());
        $(".boxes .card").height(maxHeight);
        ReactTooltip.rebuild();
        hideLoader();
        $('[data-toggle="tooltiplink"]').tooltip();  
        await this.popInvestmentRollOver();

    };

    componentWillMount() {
        if (this.context.user.completed_onboarding === null || this.context.user.completed_onboarding === undefined) {
            window.location.reload();
        }
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
        (this.props.history.pathname !== "/") && this.props.history.push("/");
        if (!this.context.user) {
            window.location.reload();
        }
        this.setState({user: this.context.user});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // this.quarterlyChart();
    }

    removeHeight() {
        var maxHeight = Math.max.apply(null, $(".boxes .card").map(function () {
            return $(this).height();
        }).get());
        $(".boxes .card").css({"height": 'unset'});
    }

    getPendingTransaction = async () => {
        try {
            let res = await API.post('my_investments/get_pending_transaction/');
            this.setState({pending_transactions: res.data});
        } catch (e) {
            toast.error("Something went wrong");
            hideLoader()
        }
    };

    dateToNum(d) {
        d = d.split("-");
        return Number(d[2] + d[1] + d[0]);
    }

    processDates(payout_dates, the_amount, roi, paid_times, payout_date_percentages) {
        let multiple_data = []
        let count = paid_times
        let dateToNum = this.dateToNum
        // payout_dates.sort(function (a, b) {
        //     return dateToNum(a) - dateToNum(b);
        // });
        // console.log(payout_dates)
        if (payout_date_percentages.length < 1){
            payout_dates.map((item, key) => {
                let amount = 0;
                if (key + 1 < payout_dates.length) {
                    amount = parseFloat(the_amount) * ((parseFloat(roi) / payout_dates.length) / 100)
                } else {
                    amount = parseFloat(the_amount) * ((parseFloat(roi) / payout_dates.length) / 100) + parseFloat(the_amount)
                }
                multiple_data.push({
                    date: item, paid: (count > 0), amount: amount
                })
                count = count - 1
            })
        } else {
            //New implemetation
            payout_dates.map((item, key) => {
                let amount = 0;
                if (key + 1 < payout_dates.length) {
                    amount = parseFloat(the_amount) * (payout_date_percentages[key] / 100)
                } else {
                    amount = parseFloat(the_amount) * (parseFloat(payout_date_percentages[key]) / 100) + parseFloat(the_amount)
                }
                multiple_data.push({
                    date: item, paid: (count > 0), amount: amount
                })
                count = count - 1
            })
        }

        this.setState({multiple_data: multiple_data})
    }

    uploadReceipt = async () => {
        const postData = new FormData();
        let up_file = $($('#upload-file'))[0].files[0];
        postData.append('file', up_file);
        postData.append('receipt_id', this.state.receipt_info.id);
        if (up_file === undefined) {
            toast.info("Kindly choose a file");
        } else {
            showLoader();
            try {
                let res = API.post('my_investments/upload_transaction_receipt/', postData);
                await this.getPendingTransaction();
                hideLoader()
            } catch (e) {
                hideLoader();
            }
        }
    };

    async getPortfolio() {
        try {
            let res = await API.get('portfolios/get_stats/');
            let data = res.data;
            this.setState({portfolio: data});
            let networth_ngn = data.total_asset_value_ngn - data.total_liability_value_ngn;
            let networth_usd = data.total_asset_value_usd - data.total_liability_value_usd;
            let networth_gbp = data.total_asset_value_gbp - data.total_liability_value_gbp;
            let target_ngn = ((networth_ngn / this.state.user.target_networth) * 100).toFixed(2);
            if (this.state.user.target_networth <= 0 || target_ngn < 0) {
                target_ngn = 0
            }
            this.setState({
                total_networth_ngn: networth_ngn,
                total_networth_usd: networth_usd,
                total_networth_gbp: networth_gbp
            });
            if (this.context.user.user_type === 'twelve') {
                let total_current = data.total_current;
                let total_total_cycle = data.total_total_cycle;
                if (total_total_cycle == 0) {
                    this.setState({target: 0});
                } else {
                    let target_ngn = ((total_current / total_total_cycle) * 100).toFixed(2)
                    this.setState({target: (target_ngn != Infinity) ? (target_ngn) : 0});
                }
            } else {
                this.setState({target: (target_ngn != Infinity) ? (target_ngn) : 0});
            }
            (target_ngn < 0) && (this.setState({radial_colour: 'circle-danger'}));
            this.setState({
                chart_data: {
                    asset: data.assets_total_quarterly,
                    liability: data.liabilities_total_quarterly,
                    networth: data.quarterly_networth
                }
            });
        } catch (e) {
            toast.error("Something Went Wrong");
            // console.log(e.response)
        }
    }

    convertCurrency = (value, currency) => {
        if (currency === 'USD') {
            return parseFloat(value) / parseFloat(this.context.user.display_usd);
        } else if (currency === "GBP") {
            return parseFloat(value) / parseFloat(this.context.user.display_gbp);
        } else {
            return parseFloat(this.context.user.display_usd) * parseFloat(value);
        }
    }

    async getInvestment() {
        try {
            let res = await API.get('my_investments/get_stats/');
            if (res) {
                this.setState({
                    investments: res.data.investments,
                    total_amount: res.data.total_amount,
                    total_amount_usd: res.data.total_amount_usd,
                    total_amount_gbp: res.data.total_amount_gbp,
                    total_return: res.data.expected_return,
                    total_return_usd: res.data.expected_return_usd,
                    total_return_gbp: res.data.expected_return_gbp,
                    percentage_interest: (res.data.percentage) ? (res.data.percentage) : 0,
                    percentage_interest_usd: (res.data.percentage_usd) ? (res.data.percentage_usd) : 0,
                    percentage_interest_gbp: (res.data.percentage_gbp) ? (res.data.percentage_gbp) : 0
                });
            }
        } catch (e) {
            toast.error("Something Went Wrong");
        }
    }

    async getActivities() {
        try {
            let res = await API.get('activity_logs/get_for_dashboard/');
            this.setState({activities: res.data});
        } catch (e) {
            toast.error("Something Went Wrong");
            // console.log(e.response)
        }
    }

    quarterlyChart(labels, dataset, is_empty) {
        var canvas = document.getElementById("myChart");
        canvas.style.width = '110%';
        var ctx = canvas.getContext('2d');
        let title = 'Add or buy asset to see your asset distribution chart here.';
        var options = {
            title: {
                display: true,
                text: title,
                position: 'top'
            },
            legend: {
                position: 'bottom'
            },
            responsive: true,
            maintainAspectRatio: true,
            rotation: -0.7 * Math.PI,
            animation: {
                duration: 500,
                easing: "easeOutQuart",
                onComplete: function () {
                    var ctx = this.chart.ctx;
                    ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontFamily, 'normal', Chart.defaults.global.defaultFontFamily);
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'bottom';

                    this.data.datasets.forEach(function (dataset) {

                        for (var i = 0; i < dataset.data.length; i++) {
                            var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                                total = dataset._meta[Object.keys(dataset._meta)[0]].total,
                                mid_radius = model.innerRadius + (model.outerRadius - model.innerRadius) / 2,
                                start_angle = model.startAngle,
                                end_angle = model.endAngle,
                                mid_angle = start_angle + (end_angle - start_angle) / 2;

                            var x = mid_radius * Math.cos(mid_angle);
                            var y = mid_radius * Math.sin(mid_angle);

                            ctx.fillStyle = '#fff';
                            var percent = String(Math.round(dataset.data[i] / total * 100)) + "%";
                            //Don't Display If Legend is hide or value is 0
                            if (dataset.data[i] != 0) {
                                // Display percent in another line, line break doesn't work for fillText
                                ctx.fillText(percent, model.x + x, model.y + y + 15);
                            }
                        }
                    });
                }
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {

                        let label = data.labels[tooltipItem.index];
                        let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        return ` ${label}: ₦${value.toLocaleString()}`

                    }
                }
            }
        };
        if (!is_empty) {
            delete options['title']
        }
        var oilData = {
            labels: labels,
            datasets: [
                {
                    data: dataset,
                    backgroundColor: [
                        "#47C4AA",
                        "#23414D",
                        "#FB703E",
                        "#F2BF5E",
                        "#F28B50",
                        "#fcb8a9",
                        "#177f68",
                        "#41738b",
                        "#99d249",
                        "#d2fb3e",
                        "#3e57fb"
                    ]
                }]
        };

        var pieChart = new Chart(ctx, {
            type: 'pie',
            data: oilData,
            options: options
        });

    }

    getPieInfo = async () => {
        try {
            const res = await API.post('portfolios/get_asset_info/')
            let data = res.data;
            let labels = Object.keys(data), dataset = [];
            labels = labels.filter(item => item !== 'is_empty');
            labels.map(item => {
                dataset.push(data[item])
            })
            this.quarterlyChart(labels, dataset, data.is_empty)
        } catch (e) {
            console.log(e.response)
        }
    }

    currencyConvert(currency, amount, ngn = true) {
        if (currency === "USD") {
            if (ngn) {
                return parseFloat(amount) * parseFloat(this.context.user.usd)
            } else {
                return parseFloat(amount) / parseFloat(this.context.user.usd)
            }
        } else if (currency === "GBP") {
            if (ngn) {
                return parseFloat(amount) * parseFloat(this.context.user.gbp)
            } else {
                return parseFloat(amount) / parseFloat(this.context.user.gbp)
            }
        } else {
            return parseFloat(amount)
        }
    }

    rateConverted(currency, from_amount, to_amount) {
        if (currency !== "NGN") {
            return parseFloat(from_amount) / parseFloat(to_amount)
        } else {
            return parseFloat(1)
        }
    }

    totalSummary(category) {
        if (category === 'current_networth') {
            if (this.state.portfolio_currency === "NGN") {
                return (
                    <strong>₦{parseFloat(parseFloat(this.state.total_networth_ngn) + this.convertCurrency(this.state.total_networth_usd, 'NGN') + this.convertCurrency(this.state.total_networth_gbp, 'NGN')).toLocaleString()}</strong>
                )
            } else if (this.state.portfolio_currency === "USD") {
                return (
                    <strong>${parseFloat(parseFloat(this.state.total_networth_usd) + this.convertCurrency(this.state.total_networth_ngn, 'USD') + this.convertCurrency(this.convertCurrency(this.state.total_networth_gbp, 'NGN'), "USD")).toLocaleString()}</strong>
                )
            } else if (this.state.portfolio_currency === "GBP") {
                return (
                    <strong>£{parseFloat(parseFloat(this.state.total_networth_gbp) + this.convertCurrency(this.state.total_networth_ngn, 'GBP') + this.convertCurrency(this.convertCurrency(this.state.total_networth_usd, 'NGN'), "GBP")).toLocaleString()}</strong>
                )
            } else {
                return (
                    <strong>₦{parseFloat(this.state.total_networth_ngn).toLocaleString()}</strong>
                )
            }
        } else if (category === 'target_networth') {
            if (this.state.portfolio_currency === "NGN") {
                return (
                    <strong>₦{parseFloat(this.state.user.target_networth).toLocaleString()}</strong>
                )
            } else if (this.state.portfolio_currency === "USD") {
                return (
                    <strong>${parseFloat(this.convertCurrency(this.state.user.target_networth, "USD")).toLocaleString()}</strong>
                )
            } else if (this.state.portfolio_currency === "GBP") {
                return (
                    <strong>£{parseFloat(this.convertCurrency(this.state.user.target_networth, "GBP")).toLocaleString()}</strong>
                )
            } else {
                return (
                    <strong>₦{parseFloat(this.state.user.target_networth).toLocaleString()}</strong>
                )
            }
        } else if (category === 'total_asset') {
            if (this.state.portfolio_currency === "NGN") {
                return (
                    <strong>₦{parseFloat((parseFloat(this.state.portfolio.total_asset_value_ngn) + this.convertCurrency(this.state.portfolio.total_asset_value_usd, "NGN") + this.convertCurrency(this.state.portfolio.total_asset_value_gbp, "NGN")).toFixed(2)).toLocaleString()}</strong>
                )
            } else if (this.state.portfolio_currency === "USD") {
                return (
                    <strong>${parseFloat((parseFloat(this.state.portfolio.total_asset_value_usd) + this.convertCurrency(this.state.portfolio.total_asset_value_ngn, "USD") + this.convertCurrency(this.convertCurrency(this.state.portfolio.total_asset_value_gbp, "NGN"), "USD")).toFixed(2)).toLocaleString()}</strong>
                )
            } else if (this.state.portfolio_currency === "GBP") {
                return (
                    <strong>£{parseFloat((parseFloat(this.state.portfolio.total_asset_value_usd) + this.convertCurrency(this.state.portfolio.total_asset_value_ngn, "GBP") + this.convertCurrency(this.convertCurrency(this.state.portfolio.total_asset_value_gbp, "NGN"), "GBP")).toFixed(2)).toLocaleString()}</strong>
                )
            } else {
                return (
                    <strong>₦{parseFloat(parseFloat(this.state.portfolio.total_asset_value_ngn).toFixed(2)).toLocaleString()}</strong>
                )
            }
        } else if (category === 'total_liability') {
            if (this.state.portfolio_currency === "NGN") {
                return (
                    <strong>₦{parseFloat((parseFloat(this.state.portfolio.total_liability_value_ngn) + this.convertCurrency(this.state.portfolio.total_liability_value_usd, "NGN") + this.convertCurrency(this.state.portfolio.total_liability_value_gbp, "NGN")).toFixed(2)).toLocaleString()}</strong>
                )
            } else if (this.state.portfolio_currency === "USD") {
                return (
                    <strong>${parseFloat((parseFloat(this.state.portfolio.total_liability_value_usd) + this.convertCurrency(this.state.portfolio.total_liability_value_ngn, "USD") + this.convertCurrency(this.convertCurrency(this.state.portfolio.total_liability_value_gbp, "NGN"), "USD")).toFixed(2)).toLocaleString()}</strong>
                )
            } else if (this.state.portfolio_currency === "GBP") {
                return (
                    <strong>£{parseFloat((parseFloat(this.state.portfolio.total_liability_value_gbp) + this.convertCurrency(this.state.portfolio.total_liability_value_ngn, "GBP") + this.convertCurrency(this.convertCurrency(this.state.portfolio.total_liability_value_usd, "NGN"), "GBP")).toFixed(2)).toLocaleString()}</strong>
                )
            } else {
                return (
                    <strong>₦{parseFloat(parseFloat(this.state.portfolio.total_liability_value_ngn).toFixed(2)).toLocaleString()}</strong>
                )
            }
        }
    }

    updateInvestmentOrder = async (e, id, data) => {
        e.preventDefault();
        console.log(e, id, data)

        showLoader()
        try {
            let res = await API.put(`my_investments/${id}/`, data);
            if (res) {
                hideLoader()
                toast.success("Investment Order has been updated to roll over!");
                setTimeout(() => {
                    window.location.reload() 
                }, 3000);
            }
        } catch (e) {
            hideLoader()
            toast.error("Something Went Wrong");
        }
    }

    popInvestmentRollOver = async () => {
        try {
            let res = await API.get('my_investments/get_roll_over_investment/');
            if (res) {
                console.log(res)
                if(res.data && res.data.roll_over == null && res.data.investment.can_roll_over == true){
                    this.setState({
                        modal_investment_order: res.data
                    });
                    $('#alert').modal('show');
                }
            }
        } catch (e) {
            // toast.error("Something Went Wrong");
            console.log("No matching investment")
        } 
    }

    increaseCount = async () => {
        try {
            let res = await API.post('users/increase_accreditation_count/', {});
            if (res.data) {
                console.log("")
                let user = localStorage.getItem('user');
                user = JSON.parse(user)
                user.cancelled_banner = true
                if(user.accreditation_notification_count == null){
                    user.accreditation_notification_count = 1
                    localStorage.setItem('user', JSON.stringify(user));
                } else {
                    user.accreditation_notification_count = 1 + parseInt(user.accreditation_notification_count)
                    localStorage.setItem('user', JSON.stringify(user));
                }
            }
        } catch (e) {
            // toast.error("Something Went Wrong");
            console.log("")
        } 

    }

    uploadTheLetter = async (e) => {
        if (e.target.files && e.target.files[0]) {
            if (e.target.files[0].type.toLowerCase().includes("pdf")){
                showLoader()
                let res = await API.uploadFile("users/upload_resources/", e.target.files[0]);
                // console.log(res.data.secure_url);

                if (res.data.secure_url){
                    await API.post("users/update_accreditation_files/", {"Upload_a_verified_status_letter_from_a_registered_SEC_institution_or_Lawyer": res.data.secure_url})
                    this.context.updateUser();
                    toast.success("Letter successfully uploaded")
                }
                setTimeout(() => {window.location.reload()}, 5000);
                hideLoader()
            } else{
                e.target.value = null
                toast.error("Only pdf files allowed")
            }
        };
    }

    render() {
        return (
            <div>
                <Layout active_menu={'dash'} user_state={'personal'} context={this.context}>
                    <ReactTooltip/>
                    <div className="section">
                        {/* {!this.context.user.accredited && (this.context.user.accreditation_notification_count < 3) && !this.context.user.accreditation_form_submitted && !this.context.user.cancelled_banner ? (<div class="alert alert-warning alert-dismissible fade show text-white text-center" role="alert" style={{animation: "none", padding: "5px 0px 5px 0px"}}>
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close" style={{padding: "5px 5px"}}>
                                <span aria-hidden="true" onClick={() => this.increaseCount()}>&times;</span>
                                <span class="sr-only" onClick={() => this.increaseCount()}>Close</span>
                            </button>
                            <strong><Link to={'/account/accreditation'} className="text-white">Click here to get accredited!</Link></strong>
                        </div>) : "" } */}
                        {/* {!this.context.user.bvn_verified  || !this.context.user.accounts.length? (<div class="alert alert-warning alert-dismissible fade show text-white text-center" role="alert" style={{animation: "none", padding: "5px 0px 5px 0px"}}>
                            <strong><Link to={'/profile'} className="text-white">Complete your account setup. Verify your BVN and provide your account details.</Link></strong>
                        </div>) : "" } */}
                        <div className="breadcrumb" style={{marginBottom: 0}}>
                            {/*<div className="row">*/}
                            <div className="col-sm-6 pl-0">
                                <h5>Dashboard</h5>
                            </div>
                            {(this.context.user.user_type === 'tgic') ? (
                                <div className="col-sm-6 float-right text-right">
                                    <Link to="/sub-investor"
                                          data-tip='Add an account for your child and invest on their behalf '
                                          className="btn btn-social btn-negative-primary"
                                          style={{color: '#2b2b2b', fontSize: '0.81rem'}}><i
                                        className="fa fa-child text-primary"/>Add Child Account</Link>
                                </div>
                            ) : ''}
                        </div>
                        {(this.state.pending_transactions.length > 0) ? (
                            <div className="row" id="pending-transactions">
                                <div className="col-md-12">
                                    <div
                                        className="alert alert-orange alert-btn alert-has-icon alert-dismissible show fade">
                                        <div className="alert-icon"><i className="ion ion-ios-lightbulb-outline"/>
                                        </div>
                                        <div className="alert-body">
                                            <button className="close" data-dismiss="alert">
                                                <span>×</span>
                                            </button>
                                            <div
                                                onClick={event => document.getElementById('pending').scrollIntoView({'behavior': 'smooth'})}
                                                className="alert-title">Info
                                            </div>
                                            You have pending transaction(s) that are yet to be confirmed. Click to
                                            view
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : ''}
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-12 col-xl-9 col-md-6 col-sm-12">
                                                <div className="row">
                                                    <div className="col-lg-4 col-xl-4 col-md-12 col-sm-12">
                                                        <button onClick={event => {
                                                            document.getElementById('portfolio').scrollIntoView({'behavior': 'smooth'})
                                                        }} className="btn btn-block btn-social m-b-5 btn-blue text-white"><i
                                                            className="fa fa-folder-open"/> My Portfolio
                                                        </button>
                                                    </div>
                                                    <div className="col-lg-4 col-xl-4 col-md-12 col-sm-12">
                                                        <button onClick={event => {
                                                            document.getElementById('investment').scrollIntoView({'behavior': 'smooth'})
                                                        }} className="btn btn-block btn-social m-b-5 btn-blue text-white"><i
                                                            className="fa fa-bar-chart"/> My Investments
                                                        </button>
                                                    </div>
                                                    <div className="col-lg-4 col-xl-4 col-md-12 col-sm-12">
                                                        <button onClick={event => {
                                                            document.getElementById('activities').scrollIntoView({'behavior': 'smooth'})
                                                        }} className="btn btn-block btn-social m-b-5 btn-blue text-white"><i
                                                            className="fa fa-newspaper-o"/> My Activities
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="col-lg-4 col-xl-3 col-md-6 col-sm-12 text-xl-right mr-sm-auto">
                                                <Link to="/manage-portfolio"
                                                      className="btn btn-social btn-blue text-white m-b-5 btn-sm-block btn-xl-block">
                                                    <i className="fa fa-cog"/> Manage Portfolio
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row" id="portfolio">
                            <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="row d-lg-flex">
                                            <div className="col-sm-6">
                                                <h4>My Portfolio</h4>
                                            </div>
                                            <div className="col-sm-6">
                                                <select
                                                    onChange={event => this.setState({portfolio_currency: event.target.value})}
                                                    value={this.state.portfolio_currency}
                                                    className="form-control col-lg-7 col-xl-5 text-right float-right">
                                                    <option value="">Total NGN, USD and GBP</option>
                                                    <option value="NGN">Total NGN value</option>
                                                    <option value="USD">Total USD value</option>
                                                    <option value="GBP">Total GBP value</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        {(this.context.user.user_type === 'tgic') ? (
                                            <div className="row mb-4">
                                                <div
                                                    className="col-lg-6 mx-auto justify-content-center text-center">
                                                    <div className="mb-4 mt-4 circular-chart"><h5
                                                        className="text-blue"
                                                        style={{
                                                            fontWeight: 'bolder',
                                                            fontSize: '16px'
                                                        }}>
                                                        {(parseInt(this.state.target) <= 0) && 'How are you doing with your goal? Taking action?'}
                                                        {(parseInt(this.state.target) <= 39 && parseInt(this.state.target) >= 1) && 'Keep going. You are in it to win it!'}
                                                        {(parseInt(this.state.target) <= 70 && parseInt(this.state.target) >= 40) && 'You are phenomenal, you are almost there. Keep going.'}
                                                        {(parseInt(this.state.target) <= 99 && parseInt(this.state.target) >= 71) && 'You have taken this so seriously. Keep pushing.'}
                                                        {(parseInt(this.state.target) === 100) && 'Congratulations. You have achieved your goal!'}
                                                        {(parseInt(this.state.target) > 100) && 'You are on a roll. You are an overachiever!'}</h5>
                                                    </div>
                                                    <div
                                                        data-tip={`You have achieved ${this.state.target}% of your net worth goal`}>
                                                        <CircularProgressbar
                                                            className={`mx-auto ${this.state.radial_colour} text-center text-blue`}
                                                            styles={{width: '70%', color: '#f88'}}
                                                            // styles={buildStyles({
                                                            //     // Rotation of path and trail, in number of turns (0-1)
                                                            //     rotation: 0.25,
                                                            
                                                            //     // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                            //     strokeLinecap: 'butt',
                                                            
                                                            //     // Text size
                                                            //     textSize: '16px',
                                                            
                                                            //     // How long animation takes to go from one percentage to another, in seconds
                                                            //     pathTransitionDuration: 0.5,
                                                            
                                                            //     // Can specify path transition in more detail, or remove it entirely
                                                            //     // pathTransition: 'none',
                                                            
                                                            //     // Colors
                                                            //     pathColor: `rgba(62, 152, 199, ${70 / 100})`,
                                                            //     textColor: '#fff',
                                                            //     trailColor: '#d6d6d6',
                                                            //     backgroundColor: '#3e98c7',
                                                            //   })}
                                                            value={parseInt(this.state.target).toFixed(2)}
                                                            text={`${parseInt(this.state.target)}%`}>
                                                        </CircularProgressbar></div>
                                                    <br/><br/>
                                                    <strong className="text-blue">{parseInt(this.state.target)}%
                                                        of
                                                        target
                                                        net worth achieved.</strong>
                                                </div>
                                                <div
                                                    className="col-lg-6 col-xl-6 justify-content-center text-center">
                                                    <div className="mb-4 mt-4 circular-chart">
                                                        <h5 className="text-blue"
                                                            style={{
                                                                fontWeight: 'bolder',
                                                                fontSize: '16px'
                                                            }}>Breakdown of total assets</h5>
                                                    </div>
                                                    <div className="" style={{marginBottom: '2.5rem'}}></div>
                                                    <div className="chart-container">
                                                        <canvas id="myChart"/>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="col-lg-6 mx-auto justify-content-center text-center text-blue">
                                                <div className="mb-3 text-blue"
                                                     data-tip={`You have achieved ${this.state.target}% of your net worth goal`}>
                                                    <CircularProgressbar
                                                        className={`mx-auto ${this.state.radial_colour} text-center text-blue`}
                                                        styles={{width: '70%'}}
                                                        value={parseInt(this.state.target).toFixed(2)}
                                                        text={`${parseInt(this.state.target)}%`}>
                                                    </CircularProgressbar></div>
                                                <strong className="text-blue mb-3">{parseInt(this.state.target)}%
                                                    of
                                                    target
                                                    net worth achieved. <br/><br/></strong>
                                            </div>
                                        )}
                                        <div className="row mb-2">
                                            <div className="col-lg-3">
                                                <div className="list-group">
                                                    <a href="#"
                                                       className="list-group-item list-group-item-action flex-column align-items-start">
                                                        <div className="d-sm-flex w-100 justify-content-between">
                                                            <h5 className="mb-1">
                                                                {this.totalSummary('current_networth')}
                                                            </h5>
                                                        </div>
                                                        <p className="mb-1">Current Net Worth</p>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="list-group">
                                                    <a href="#"
                                                       className="list-group-item list-group-item-action flex-column align-items-start">
                                                        <div className="d-sm-flex w-100 justify-content-between">
                                                            <h5 className="mb-1">
                                                                {this.totalSummary('target_networth')}
                                                            </h5>
                                                        </div>
                                                        <p className="mb-1">Target Net Worth</p>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="list-group">
                                                    <a href="#"
                                                       className="list-group-item list-group-item-action flex-column align-items-start">
                                                        <div className="d-sm-flex w-100 justify-content-between">
                                                            <h5 className="mb-1">
                                                                {this.totalSummary('total_asset')}
                                                            </h5>
                                                        </div>
                                                        <p className="mb-1">Total Assets</p>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="list-group">
                                                    <a href="#"
                                                       className="list-group-item list-group-item-action flex-column align-items-start">
                                                        <div className="d-sm-flex w-100 justify-content-between">
                                                            <h5 className="mb-1">
                                                                {this.totalSummary('total_liability')}
                                                            </h5>
                                                        </div>
                                                        <p className="mb-1">Total Liability</p>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        {(this.state.portfolio_currency === "") ? (
                                            <div>
                                                <div className="row mb-2">
                                                    <div className="col-lg-3">
                                                        <div className="list-group">
                                                            <a href="#"
                                                               className="list-group-item list-group-item-action flex-column align-items-start">
                                                                <div
                                                                    className="d-sm-flex w-100 justify-content-between">
                                                                    <h5 className="mb-1">
                                                                        <strong>${parseFloat(parseFloat(this.state.total_networth_usd).toFixed(2)).toLocaleString()}</strong>
                                                                    </h5>
                                                                </div>
                                                                <p className="mb-1">Current Net Worth</p>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="list-group">
                                                            <a href="#"
                                                               className="list-group-item list-group-item-action flex-column align-items-start">
                                                                <div
                                                                    className="d-sm-flex w-100 justify-content-between">
                                                                    <h5 className="mb-1">
                                                                        <strong>${parseFloat((this.convertCurrency(this.state.user.target_networth, "USD")).toFixed(2)).toLocaleString()}</strong>
                                                                    </h5>
                                                                </div>
                                                                <p className="mb-1">Target Net Worth</p>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="list-group">
                                                            <a href="#"
                                                               className="list-group-item list-group-item-action flex-column align-items-start">
                                                                <div
                                                                    className="d-sm-flex w-100 justify-content-between">
                                                                    <h5 className="mb-1">
                                                                        <strong>${parseFloat(parseFloat(this.state.portfolio.total_asset_value_usd).toFixed(2)).toLocaleString()}</strong>
                                                                    </h5>
                                                                </div>
                                                                <p className="mb-1">Total Assets</p>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="list-group">
                                                            <a href="#"
                                                               className="list-group-item list-group-item-action flex-column align-items-start">
                                                                <div
                                                                    className="d-sm-flex w-100 justify-content-between">
                                                                    <h5 className="mb-1">
                                                                        <strong>${parseFloat(parseFloat(this.state.portfolio.total_liability_value_usd).toFixed(2)).toLocaleString()}</strong>
                                                                    </h5>
                                                                </div>
                                                                <p className="mb-1">Total Liability</p>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-3">
                                                        <div className="list-group">
                                                            <a href="#"
                                                               className="list-group-item list-group-item-action flex-column align-items-start">
                                                                <div
                                                                    className="d-sm-flex w-100 justify-content-between">
                                                                    <h5 className="mb-1">
                                                                        <strong>£{parseFloat(parseFloat(this.state.total_networth_gbp).toFixed(2)).toLocaleString()}</strong>
                                                                    </h5>
                                                                </div>
                                                                <p className="mb-1">Current Net Worth</p>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="list-group">
                                                            <a href="#"
                                                               className="list-group-item list-group-item-action flex-column align-items-start">
                                                                <div
                                                                    className="d-sm-flex w-100 justify-content-between">
                                                                    <h5 className="mb-1">
                                                                        <strong>£{parseFloat((this.convertCurrency(this.state.user.target_networth, "GBP")).toFixed(2)).toLocaleString()}</strong>
                                                                    </h5>
                                                                </div>
                                                                <p className="mb-1">Target Net Worth</p>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="list-group">
                                                            <a href="#"
                                                               className="list-group-item list-group-item-action flex-column align-items-start">
                                                                <div
                                                                    className="d-sm-flex w-100 justify-content-between">
                                                                    <h5 className="mb-1">
                                                                        <strong>£{parseFloat(parseFloat(this.state.portfolio.total_asset_value_gbp).toFixed(2)).toLocaleString()}</strong>
                                                                    </h5>
                                                                </div>
                                                                <p className="mb-1">Total Assets</p>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="list-group">
                                                            <a href="#"
                                                               className="list-group-item list-group-item-action flex-column align-items-start">
                                                                <div
                                                                    className="d-sm-flex w-100 justify-content-between">
                                                                    <h5 className="mb-1">
                                                                        <strong>£{parseFloat(parseFloat(this.state.portfolio.total_liability_value_gbp).toFixed(2)).toLocaleString()}</strong>
                                                                    </h5>
                                                                </div>
                                                                <p className="mb-1">Total Liability</p>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.context.user.accreditation_form_submitted ? (<QualificationNotification context={this.context} />) : ""}
                        <div className="row" id="investment">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="row">
                                            <div className="col-lg-6 col-sm-12">
                                                <h4 className="float-left">My Investments</h4>
                                            </div>
                                            <div
                                                className="col-lg-6 col-sm-12 text-xl-right text-lg-right text-md-left">
                                                <Link className="btn btn-blue text-white text-white m-b-5 mr-3"
                                                      to="/marketplace">Find
                                                    Investments
                                                </Link>
                                                <Link to="/investments"
                                                      className="btn m-b-5 btn-blue text-white text-white"> View All
                                                </Link>
                                                <select
                                                    onChange={event => {this.setState({investment_currency: event.target.value}); this.removeHeight()}}
                                                    value={this.state.investment_currency}
                                                    className="form-control col-lg-7 col-xl-5 text-right float-right ml-3">
                                                    <option value="ALL">Total NGN, USD and GBP</option>
                                                    <option value="NGN">Total NGN value</option>
                                                    <option value="USD">Total USD value</option>
                                                    <option value="GBP">Total GBP value</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row boxes">
                                            <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                                <div className="card bg-blue-gray text-blue">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="mt-3 text-center">
                                                                    <span className="text-blue"><i
                                                                        className="fa fa-bar-chart text-blue"/> <strong>Total Investment</strong> <i
                                                                        data-tip="Total Amount Invested"
                                                                        className="fa fa-info-circle text-blue"/></span>
                                                                    {["NGN"].includes(this.state.investment_currency) ? (
                                                                        <h3 className="text-blue mb-0 mt-2">₦{parseFloat(parseFloat(this.state.total_amount).toFixed(2)).toLocaleString()}</h3>
                                                                    ) : ["USD"].includes(this.state.investment_currency) ? (
                                                                        <React.Fragment>
                                                                            <h3 className="text-blue mb-0">${parseFloat(parseFloat(this.state.total_amount_usd).toFixed(2)).toLocaleString()}</h3>
                                                                        </React.Fragment>
                                                                    ) : ["GBP"].includes(this.state.investment_currency) ? (
                                                                        <React.Fragment>
                                                                            <h3 className="text-blue mb-0">£{parseFloat(parseFloat(this.state.total_amount_gbp).toFixed(2)).toLocaleString()}</h3>
                                                                        </React.Fragment>
                                                                    ) : (
                                                                        <React.Fragment>
                                                                            <h3 className="text-blue mb-0 mt-2">₦{parseFloat(parseFloat(this.state.total_amount).toFixed(2)).toLocaleString()}</h3>
                                                                            <hr className="line-2"/>
                                                                            <h3 className="text-blue mb-0">${parseFloat(parseFloat(this.state.total_amount_usd).toFixed(2)).toLocaleString()}</h3>
                                                                            <hr className="line-2"/>
                                                                            <h3 className="text-blue mb-0">£{parseFloat(parseFloat(this.state.total_amount_gbp).toFixed(2)).toLocaleString()}</h3>
                                                                        </React.Fragment>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                                <div className="card bg-blue-gray text-blue">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-blue"><i
                                                                        className="fa fa-reply-all text-blue"/> <strong>Expected ROI</strong>  <i
                                                                        data-tip="Expected return on investment"
                                                                        className="fa fa-info-circle text-right text-blue"/></span>
                                                                    {["NGN"].includes(this.state.investment_currency) ? (
                                                                        <h3 className="text-blue mb-0 mt-2">₦{parseFloat(parseFloat(this.state.total_return).toFixed(2)).toLocaleString()}</h3>
                                                                    ) : ["USD"].includes(this.state.investment_currency) ? (
                                                                        <React.Fragment>
                                                                            <h3 className="text-blue mb-0">${parseFloat(parseFloat(this.state.total_return_usd).toFixed(2)).toLocaleString()}</h3>
                                                                        </React.Fragment>
                                                                    ) : ["GBP"].includes(this.state.investment_currency) ? (
                                                                        <React.Fragment>
                                                                            <h3 className="text-blue mb-0">£{parseFloat(parseFloat(this.state.total_return_gbp).toFixed(2)).toLocaleString()}</h3>
                                                                        </React.Fragment>
                                                                    ) : (
                                                                        <React.Fragment>
                                                                            <h3 className="text-blue mb-0 mt-2">₦{parseFloat(parseFloat(this.state.total_return).toFixed(2)).toLocaleString()}</h3>
                                                                            <hr className="line-2"/>
                                                                            <h3 className="text-blue mb-0">${parseFloat(parseFloat(this.state.total_return_usd).toFixed(2)).toLocaleString()}</h3>
                                                                            <hr className="line-2"/>
                                                                            <h3 className="text-blue mb-0">£{parseFloat(parseFloat(this.state.total_return_gbp).toFixed(2)).toLocaleString()}</h3>
                                                                        </React.Fragment>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                                <div className="card bg-blue-gray text-blue">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-blue"><i
                                                                        className="fa fa-signal text-blue"/> <strong>Interest</strong> <i
                                                                        data-tip="Total amount you will receive from your investments"
                                                                        className="fa fa-info-circle text-right text-blue"/></span>
                                                                    {["NGN"].includes(this.state.investment_currency) ? (
                                                                        <h3 className="text-blue mb-0 mt-2">₦{parseFloat(parseFloat(this.state.total_return - this.state.total_amount).toFixed(2)).toLocaleString()}</h3>
                                                                    ) : ["USD"].includes(this.state.investment_currency) ? (
                                                                        <React.Fragment>
                                                                            <h3 className="text-blue mb-0">${parseFloat(parseFloat(this.state.total_return_usd - this.state.total_amount_usd).toFixed(2)).toLocaleString()}</h3>
                                                                        </React.Fragment>
                                                                    ) : ["GBP"].includes(this.state.investment_currency) ? (
                                                                        <React.Fragment>
                                                                            <h3 className="text-blue mb-0">£{parseFloat(parseFloat(this.state.total_return_gbp - this.state.total_amount_gbp).toFixed(2)).toLocaleString()}</h3>
                                                                        </React.Fragment>
                                                                    ) : (
                                                                        <React.Fragment>
                                                                            <h3 className="text-blue mb-0 mt-2">₦{parseFloat(parseFloat(this.state.total_return - this.state.total_amount).toFixed(2)).toLocaleString()}</h3>
                                                                            <hr className="line-2"/>
                                                                            <h3 className="text-blue mb-0">${parseFloat(parseFloat(this.state.total_return_usd - this.state.total_amount_usd).toFixed(2)).toLocaleString()}</h3>
                                                                            <hr className="line-2"/>
                                                                            <h3 className="text-blue mb-0">£{parseFloat(parseFloat(this.state.total_return_gbp - this.state.total_amount_gbp).toFixed(2)).toLocaleString()}</h3>
                                                                        </React.Fragment>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                                <div className="card bg-blue-gray text-blue">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-blue"><i
                                                                        className="fa fa-line-chart text-blue"/> <strong>Percentage Interest</strong> <i
                                                                        data-tip="Percentage Interest gained from  your investments"
                                                                        className="fa fa-info-circle text-right text-blue"/></span>
                                                                    {["NGN"].includes(this.state.investment_currency) ? (
                                                                        <h3 className="text-blue mb-0 mt-2">₦{parseFloat(this.state.percentage_interest).toFixed(2).toLocaleString()}</h3>
                                                                    ) : ["USD"].includes(this.state.investment_currency) ? (
                                                                        <React.Fragment>
                                                                            <h3 className="text-blue mb-0">${parseFloat(parseFloat(this.state.percentage_interest_usd).toFixed(2)).toLocaleString()}</h3>
                                                                        </React.Fragment>
                                                                    ) : ["GBP"].includes(this.state.investment_currency) ? (
                                                                        <React.Fragment>
                                                                            <h3 className="text-blue mb-0">£{parseFloat(parseFloat(this.state.percentage_interest_gbp).toFixed(2)).toLocaleString()}</h3>
                                                                        </React.Fragment>
                                                                    ) : (
                                                                        <React.Fragment>
                                                                            <h3 className="text-blue mb-0 mt-2">{parseFloat(this.state.percentage_interest).toFixed(2)}%</h3>
                                                                            <hr className="line-2"/>
                                                                            <h3 className="text-blue mb-0">{parseFloat(parseFloat(this.state.percentage_interest_usd).toFixed(2)).toLocaleString()}</h3>
                                                                            <hr className="line-2"/>
                                                                            <h3 className="text-blue mb-0">{parseFloat(parseFloat(this.state.percentage_interest_gbp).toFixed(2)).toLocaleString()}</h3>
                                                                        </React.Fragment>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-striped mb-0 text-nowrap">
                                                <tbody>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Amount</th>
                                                    <th>Unit/Tokens</th>
                                                    <th>ROI(%)</th>
                                                    <th>Expected Return</th>
                                                    <th>Purchased Date</th>
                                                    <th>Maturity Date</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                                {this.state.investments.map(item => (
                                                
                                                    <tr key={item.id}>

                                                        <td>{item.investment.in_trust ? (<i className="fas fa-shield-alt text-dark"/>) : ''} {item.investment.title} by {item.investment.company}</td>
                                                        <td data-tip={`Rate at ₦${this.rateConverted(item.currency, item.amount_ngn, item.amount).toFixed(2).toLocaleString()}`}>
                                                            <NumberFormat
                                                                renderText={value => <div>{value}</div>}
                                                                prefix={Utils.currencyToSym(item.currency)}
                                                                displayType={'text'}
                                                                thousandSeparator={true}
                                                                value={parseFloat(item.amount).toFixed(2)}/>
                                                        </td>
                                                        <td>{item.purchased_units}</td>
                                                        {console.log(item.investment.payout_interval, item.investment)}
                                                        <td>{item.roi}{item.investment.payout_interval ? `/${item.investment.payout_interval}` : ""}</td>
                                                        <td><NumberFormat
                                                            renderText={value => <div>{value}</div>}
                                                            prefix={Utils.currencyToSym(item.currency)}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            value={parseFloat(item.expected_return).toFixed(2)}/>
                                                        </td>
                                                        <td><Moment
                                                            format="MMM D, Y">{item.created_at}</Moment>
                                                        </td>
                                                        <td><Moment
                                                            format="MMM D, Y">{item.maturity_date}</Moment>
                                                        </td>
                                                        <td>
                                                            {item.investment.id == 27 && excludeList.includes(this.context.user.email.toLowerCase()) ? "Active" : (item.investment.payout_status == "pending" || item.investment.payout_status == "none" ? "Active" : "Completed")}
                                                        </td>
                                                        <td>
                                                        {(item.investment.club_tag || item.investment.investment_type === 'real estate fund') ?
                                                            <Link to={`/investment/${item.id}/history`}> <span
                                                                className="fa fa-list"/></Link>
                                                            : item.investment.multiple_payout ? (
                                                                        <span
                                                                            onClick={event => {
                                                                                this.processDates(item.investment.payout_dates, item.amount, item.investment.inv_roi, item.payout_times, item.investment.payout_date_percentages)

                                                                                this.setState({investment_order: item})
                                                                                console.log(item)
                                                                            }}
                                                                            data-target="#dateModal"
                                                                            data-toggle="modal"
                                                                            style={{'cursor': 'pointer'}}
                                                                            className="fa fa-eye"/>
                                                                ) : item.investment.in_trust ?
                                                                (<Link to={`/investment/${item.id}/history`}> <span
                                                                className="fa fa-eye" title={`This invesment is held in Trust by ${item.investment.trustee}`} /></Link>) : item.investment.partner.is_partner && item.investment.partner.company_name.toLowerCase().includes("risevest") ? (<Link to={`/investment/${item.id}/history`}> <span
                                                                className="fa fa-eye"/></Link>) : ""
                                                        }
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                            {this.state.investments.length > 0 ? 
                                            <div className='text-blue text-center'><Link to="/investments"
                                                      className="text-blue"> See more </Link></div> : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row" id="activities">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="float-left">My Activities</h4>
                                        <Link to="/activities" className="btn m-b-5 btn-blue text-white text-white float-right"> View
                                            All
                                        </Link>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="table-responsive">
                                                <table className="table table-striped mb-0 text-nowrap">
                                                    <tbody>
                                                    <tr>
                                                        <th>Action</th>
                                                        <th>Date</th>
                                                        <th>Time</th>
                                                    </tr>
                                                    {this.state.activities.map(item => (
                                                        <tr key={uuidv1()}>
                                                            <td>{item.action}</td>
                                                            <td><Moment
                                                                format="MMM D ">{item.created_at}</Moment>
                                                            </td>
                                                            <td><Moment
                                                                format="HH:mm">{item.created_at}</Moment>
                                                            </td>
                                                        </tr>
                                                    ))}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal show" id="receiptModal" tabIndex="-1" role="dialog"
                         aria-labelledby="exampleModalLabel">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title" id="exampleModalLabel">Upload Receipt</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <dl className="row">
                                        <dt className="col-sm-4 text-right"><strong>Title</strong></dt>
                                        <dd className="col-sm-8">{this.state.receipt_info.title}</dd>

                                        <dt className="col-sm-4 text-right"><strong>Price</strong></dt>
                                        <dd className="col-sm-8">₦{parseFloat(this.state.receipt_info.price).toLocaleString()}</dd>

                                        <dt className="col-sm-4 text-right"><strong>Units</strong></dt>
                                        <dd className="col-sm-8">{parseFloat(this.state.receipt_info.units)}</dd>

                                        <dt className="col-sm-4 text-right"><strong>ROI</strong></dt>
                                        <dd className="col-sm-8">{this.state.receipt_info.roi}%</dd>

                                        <dt className="col-sm-4 text-right"><strong>Reference Code</strong></dt>
                                        <dd className="col-sm-8">{this.state.receipt_info.ref_code}</dd>

                                        <dt className="col-sm-4 text-right"><strong>Upload Receipt</strong></dt>
                                        <dd className="col-sm-8">
                                            <input id="upload-file" className="form-control" type="file"
                                                   accept=".application/pdf,image/*"/>
                                        </dd>
                                    </dl>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-blue text-white text-white" data-dismiss="modal">Close
                                    </button>
                                    <button onClick={this.uploadReceipt} type="button"
                                            className="btn btn-blue text-white">Upload
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div aria-hidden="true" aria-labelledby="myModalLabel" className="modal fade" id="imageModal"
                         role="dialog" tabIndex="-1">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-body mb-0 p-0">
                                    <img src={this.state.modal_image} alt=""
                                         style={{width: '100%'}}/>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-outline-primary btn-rounded btn-md ml-4 text-center"
                                            data-dismiss="modal" type="button">Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="dateModal" tabIndex="-1" role="dialog"
                         aria-labelledby="dateModalLabel">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header bg-green">
                                    <h4 className="modal-title" id="dateModalLabel">Payout Dates</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="text-red text-center">{this.state.investment_order ? this.state.investment_order.roll_over == null && this.state.investment_order.investment.can_roll_over && 5 < (moment(this.state.investment_order.maturity_date).diff(moment(new Date()), 'days')) < 40 ? (<i className="text-primary text-center">* This investment will mature in {this.state.investment_order.maturity_date}. Do you want to roll over this investment? <br/><br/> 
                                    <div className="text-center"><button onClick={ e => this.updateInvestmentOrder(e, this.state.investment_order.id, {roll_over: false})} className="btn btn-negative-primary btn-outline-primary text-primary"> No </button>&nbsp;&nbsp;<button onClick={ e => this.updateInvestmentOrder(e, this.state.investment_order.id, {roll_over: true, roll_over_date: new Date().toISOString().split('T')[0]})} className="btn-primary btn"> Yes </button><br/><br/></div></i>) : "" : ""}</div>

                                    <div className="list-group">
                                        {(this.state.multiple_data).map(item => (
                                            <a href="#"
                                               className="list-group-item list-group-item-action flex-column align-items-start">
                                                <div className="d-flex w-100 justify-content-between">
                                                    <h5 className="mb-1">{item.date}</h5>
                                                    <small>{(item.paid) ? 'Completed' : 'Upcoming payout'}</small>
                                                </div>
                                                <p className="mb-1">₦{item.amount.toLocaleString()}</p>
                                            </a>
                                        ))}
                                    </div>
                                </div>
                                <div className="modal-footer text-center">
                                    <button type="button" className="btn no-outline-green"
                                            data-dismiss="modal">Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="alert" tabIndex="-1" role="dialog"
                         aria-labelledby="alertLabel">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header bg-green">
                                    <h4 className="modal-title" id="alertLabel">Investment Payout</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    <div className="list-group text-center">
                                        <a href="#"
                                            className="list-group-item list-group-item-action flex-column align-items-start">
                                                {this.state.modal_investment_order && this.state.modal_investment_order != false ? (<p className="mb-1">Your investment <b>{this.state.modal_investment_order &&this.state.modal_investment_order.investment && this.state.modal_investment_order.investment.title}</b> will mature on {this.state.modal_investment_order.maturity_date}. Do you want to rollover this investment? <br/><br/><div className="text-center"><button onClick={ e => this.updateInvestmentOrder(e, this.state.modal_investment_order.id, {roll_over: false})} className="btn btn-negative-primary btn-outline-primary text-primary"> No </button>&nbsp;&nbsp;<button onClick={ e => this.updateInvestmentOrder(e, this.state.modal_investment_order.id, {roll_over: true, roll_over_date: new Date().toISOString().split('T')[0]})} className="btn-primary btn"> Yes </button><br/><br/></div></p>) : ""}
                                        </a>
                                    </div>
                                </div>
                                <div className="modal-footer text-center">
                                    <button type="button" className="btn no-outline-green"
                                            data-dismiss="modal">Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* UPLOADE THE QUALIFICATION LETTER */}
                    <div id="uploadLetter" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header bg-green">
                                    <h4 className="modal-title" id="example-Modal3">Upload Letter</h4>
                                    <button id="close-modal" type="button" className="close" data-dismiss="modal"
                                            aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-sm-12 mx-auto text-center">
                                            <p><strong>Qualification request letter from Lawyer OR Sec registered institution</strong></p>
                                            <p><input id='id-letter-qualification' className='form-file-input' type="file" onChange={(e) => this.uploadTheLetter(e)} /></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout>
            </div>
        );
    }
}

//Dashboard = userContext()(Dashboard);

export default Dashboard;
