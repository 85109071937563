import React, {Component} from 'react';
import API from '../../api';
import CONGRATS from "../../assets/img/congrats.svg";
import NumberFormat from "react-number-format";
import {hideLoader, showLoader, toast} from "../../_helpers/Loader";
import {Context} from "../MyContext";
import uuidv1 from 'uuid';
import $ from "jquery";


class Onboarding extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            form: {},
            btnDisabled: false
        };

    }

    componentDidMount() {
        $('.date').datepicker({
            startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 100)),
            endDate: new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
            format: 'yyyy-mm-dd',
            autoclose: true,
        })
    }

    componentWillMount() {
        if (this.context.user.completed_onboarding) {
            this.props.history.push("/")
        }
        if (this.context.user.user_type === 'tgic') {
            this.props.history.push("/user/onboarding")
        }
    }

    firstSubmit = async (event) => {
        event.preventDefault();
        this.setState({
            card: 2,
            form: {...this.state.form, date_of_birth: $('#date').val()}
        })
    }

    secondSubmit = async (event) => {
        event.preventDefault();
        (this.state.form.state !== 'Lagos') && delete this.state.form['preferred_lga']
        showLoader();
        try {
            const res = await API.post('users/initData/', this.state.form);
            await this.context.updateUser();
            setTimeout(() => {
                this.props.history.push("/");
            }, 2000);
            toast.success("Successfully Created");
        } catch (e) {
            hideLoader();
        }
    }

    finalSubmitForm = async (event) => {
        // console.log("get theat ======>")
        (this.state.form.state !== 'Lagos') && delete this.state.form['preferred_lga']
        showLoader();
        try {
            const res = await API.post('users/initData/', this.state.form);
            await this.context.updateUser();
            setTimeout(() => {
                this.props.history.push("/");
            }, 2000);
            toast.success("Successfully Created");
        } catch (e) {
            hideLoader();
        }
    }

    popUpUserAgreement = async (event) =>{

    }


    first_render() {
        return (
            <div id="app">
                <div className="main-wrapper">
                    <nav className="navbar navbar-expand-lg main-navbar">
                        <a className="header-brand" href="index.html">
                            <img src="/assets/img/logo.png" className="header-brand-img" alt="Twelvevest Logo"/>
                        </a>
                    </nav>


                    <div className="app-content mt-5" style={{marginLeft: 'unset'}}>
                        <div className="section">
                            <div className="row">
                                <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 mx-auto">
                                    <div className="card">
                                        <div className="card-header green text-center">
                                            <h4>Personal Information</h4>
                                        </div>
                                        <div className="card-body">
                                            <form onSubmit={this.firstSubmit}>
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="">Home
                                                        Address</label>
                                                    <textarea value={this.state.form.address}
                                                              onChange={event => this.setState({
                                                                  form: {
                                                                      ...this.state.form,
                                                                      address: event.target.value
                                                                  }
                                                              })} required className="form-control" rows="5"/>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label htmlFor="message-text"
                                                           className="form-control-label">Date of Birth</label>
                                                    <div className="input-group date" data-provide="datepicker">
                                                        <input value={this.state.form.date_of_birth} required id="date" type="text"
                                                               className="form-control"/>
                                                        <div className="input-group-addon">
                                                            <span className="fa fa-calendar"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="">Next of Kin
                                                        Full Name</label>
                                                    <input value={this.state.form.next_of_kin_name} required
                                                           onChange={event => this.setState({
                                                               form: {
                                                                   ...this.state.form,
                                                                   next_of_kin_name: event.target.value
                                                               }
                                                           })} className="form-control" type="text"/>
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="">Next of Kin
                                                        Phone Number</label>
                                                    <input value={this.state.form.next_of_kin_phone} required
                                                           onChange={event => this.setState({
                                                               form: {
                                                                   ...this.state.form,
                                                                   next_of_kin_phone: event.target.value
                                                               }
                                                           })} className="form-control" type="text"/>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-sm-12 text-right">
                                                        <button type="submit"
                                                                className="btn mt-1 mb-0 btn-blue text-white">Next <i
                                                            className="fa fa-chevron-right"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    second_render() {
        return (
            <div id="app">
                <div className="main-wrapper">
                    <nav className="navbar navbar-expand-lg main-navbar">
                        <a className="header-brand" href="index.html">
                            <img src="/assets/img/logo.png" className="header-brand-img" alt="Twelvevest Logo"/>
                        </a>
                    </nav>


                    <div className="app-content mt-5" style={{marginLeft: 'unset'}}>
                        <div className="section">
                            <div className="row">
                                <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 mx-auto">
                                    <div className="card">
                                        <div className="card-header green text-center">
                                            <h4>Preferred Investment Information</h4>
                                        </div>
                                        <div className="card-body">
                                            <form id="onbd" onSubmit={event => this.secondSubmit(event)}>
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="">Target Networth</label>
                                                    <NumberFormat required
                                                                  value={this.state.form.target_networth}
                                                                  thousandSeparator={true} prefix={'₦'}
                                                                  onValueChange={(values) => {
                                                                      const {formattedValue, value} = values;
                                                                      this.setState({
                                                                          form: {
                                                                              ...this.state.form,
                                                                              target_networth: value
                                                                          }
                                                                      })
                                                                  }} className="form-control"/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="inputEmail3"
                                                           className="form-label">How much can you afford monthly, for
                                                        an investment in real estate?</label>
                                                    <select required
                                                            value={this.state.form.investment_amount}
                                                            onChange={event => this.setState({
                                                                form: {
                                                                    ...this.state.form,
                                                                    investment_amount: event.target.value
                                                                }
                                                            })}
                                                            className="form-control">
                                                        <option value="">--- Select an Option ---</option>
                                                        <option value="<5000">Below 5000</option>
                                                        <option value="6000 - 10000">6,000 - 10,000</option>
                                                        <option value="20,000 - 50,000">20,000 - 50,000</option>
                                                        <option value=">100000">Above 100,000</option>
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label
                                                        className="form-label">Select a preferred location for buying
                                                        real estate.</label>
                                                    <select value={this.state.form.state} className="form-control"
                                                            onChange={event => this.setState({
                                                                form: {
                                                                    ...this.state.form,
                                                                    state: event.target.value
                                                                }
                                                            })}>
                                                        <option value="" selected="selected">--- Select an Option ---
                                                        </option>
                                                        <option value="Abuja FCT">Abuja FCT</option>
                                                        <option value="Abia">Abia</option>
                                                        <option value="Adamawa">Adamawa</option>
                                                        <option value="Akwa Ibom">Akwa Ibom</option>
                                                        <option value="Anambra">Anambra</option>
                                                        <option value="Bauchi">Bauchi</option>
                                                        <option value="Bayelsa">Bayelsa</option>
                                                        <option value="Benue">Benue</option>
                                                        <option value="Borno">Borno</option>
                                                        <option value="Cross River">Cross River</option>
                                                        <option value="Delta">Delta</option>
                                                        <option value="Ebonyi">Ebonyi</option>
                                                        <option value="Edo">Edo</option>
                                                        <option value="Ekiti">Ekiti</option>
                                                        <option value="Enugu">Enugu</option>
                                                        <option value="Gombe">Gombe</option>
                                                        <option value="Imo">Imo</option>
                                                        <option value="Jigawa">Jigawa</option>
                                                        <option value="Kaduna">Kaduna</option>
                                                        <option value="Kano">Kano</option>
                                                        <option value="Katsina">Katsina</option>
                                                        <option value="Kebbi">Kebbi</option>
                                                        <option value="Kogi">Kogi</option>
                                                        <option value="Kwara">Kwara</option>
                                                        <option value="Lagos">Lagos</option>
                                                        <option value="Nassarawa">Nassarawa</option>
                                                        <option value="Niger">Niger</option>
                                                        <option value="Ogun">Ogun</option>
                                                        <option value="Ondo">Ondo</option>
                                                        <option value="Osun">Osun</option>
                                                        <option value="Oyo">Oyo</option>
                                                        <option value="Plateau">Plateau</option>
                                                        <option value="Rivers">Rivers</option>
                                                        <option value="Sokoto">Sokoto</option>
                                                        <option value="Taraba">Taraba</option>
                                                        <option value="Yobe">Yobe</option>
                                                        <option value="Zamfara">Zamfara</option>
                                                    </select>
                                                </div>
                                                {(this.state.form.state === 'Lagos') ? (
                                                    <div className="form-group">
                                                        <label
                                                            className="form-label">Preferred location</label>
                                                        <select required
                                                                value={this.state.form.preferred_lga}
                                                                onChange={event => this.setState({
                                                                    form: {
                                                                        ...this.state.form,
                                                                        preferred_lga: event.target.value
                                                                    }
                                                                })}
                                                                className="form-control">
                                                            <option value="">--- Select an Option ---</option>
                                                            <option value="lekki">Lekki</option>
                                                            <option value="gbagada">Gbagada</option>
                                                            <option value="victoria island">Victoria Island</option>
                                                            <option value="isheri">Isheri</option>
                                                        </select>
                                                    </div>
                                                ) : ''}
                                                <div className="text-right mb-3">
                                                    <button onClick={event => this.setState({card: 1})} type="button"
                                                            className="btn btn-outline-dark mt-1 mb-0 mr-3">
                                                        <i className="fa fa-chevron-left text-dark"/> Back
                                                    </button>
                                                    <button type="submit"  className="btn mt-1 mb-0 btn-blue text-white">Next
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>              

            </div>
        )
    }

    third_render() {
        return (
            <div>
                <div className="container" style={{paddingTop: '2rem'}}>
                    <div className="row">
                        <div className="col-md-8 col-lg-8 col-sm-12 mx-auto">
                            <div className="card">
                                <div className="card-header text-center bg-green">
                                    <h5><strong>All Done!</strong></h5>
                                </div>
                                <div className="card-body">
                                    <div className="row mx-auto text-center">
                                        <div className="col-12">
                                            <img width="50%" className="img-responsive"
                                                 src={CONGRATS}/>
                                        </div>
                                        <div className="col-12 text-center congrats-text">
                                            <h1>Congratulations!</h1>
                                        </div>
                                        <div className="col-md-12 col-lg-12 col-sm-12 mx-auto">
                                            <p className="text-center">You have successfully created your
                                                Twelvevest account.<br/>
                                                Buckle up your seatbelt, things are about to get very
                                                interesting on your
                                                financial journey.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return this.second_render();
    }
}

export default Onboarding;
