import React, {Component} from 'react';
import {Link} from "react-router-dom";
import API from '../../api'
import Layout from "../Layout";
import {userContext} from '../../_helpers/userContext'
import {showLoader, hideLoader, mixpanel, trackMixpanel} from "../../_helpers/Loader";
import NumberFormat from 'react-number-format';
import {toast} from '../../_helpers/Loader';
import {Context} from "../MyContext";


const user = userContext;

class UpdatePassword extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            password1: '',
            password2: '',
            errors: []
        };

        this.updateDetails = this.updateDetails.bind(this);
    }

    componentWillMount() {
        trackMixpanel('UpdatePasswordView', false, false, this.context.user.email)
        if (this.context.user.completed_onboarding === null || this.context.user.completed_onboarding === undefined) {
            window.location.reload();
        }
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
    }

    componentDidMount() {
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
    }

    async updateDetails(event) {
        event.preventDefault();
        showLoader();
        let postData = {new_password1: this.state.password1, new_password2: this.state.password2};
        try {
            let res = await API.post('rest-auth/password/change/', postData);
            toast.success("Updated Successfully");
            hideLoader();
            setTimeout(() => {
                this.props.history.push("/login");
            }, 2000);
        } catch (err) {
            toast.error("Password Reset Failed");
            let err_list = [];
            this.setState({errors: err_list});
            hideLoader();
        }
    }

    render() {
        return (
            <Layout active_menu={'account'} user_state={'personal'} context={this.context}>
                <section>
                    <div className="breadcrumb">
                        <Link to="/account"><span className="fa fa-chevron-left"/> Settings</Link>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-md-12 mx-auto">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4 className="text-center">Update Password</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={this.updateDetails}>
                                        <div className="form-group">
                                            <label>New Password</label>
                                            <input autoCapitalize="current-password"
                                                   onChange={event => this.setState({password1: event.target.value})}
                                                   className="form-control" type="password"/>
                                        </div>
                                        <div className="form-group">
                                            <label>Reenter New Password</label>
                                            <div className="input-group">
                                                <input autoComplete="current-password"
                                                       onChange={event => this.setState({password2: event.target.value})}
                                                       type="password" className="form-control"/>
                                                <div className="input-group-addon">
                                                    {(this.state.password2.length > 0 && this.state.password1 !== this.state.password2) ? (
                                                        <i className="fa fa-times text-danger"/>
                                                    ) : (this.state.password2 === this.state.password1 && this.state.password1.length > 0) ? (
                                                        <i className="fa fa-check text-success"/>
                                                    ) : (<i className="fa fa-edit text-info"/>)}
                                                </div>
                                                {this.state.errors.map(item => (
                                                    <li className="text-left text-danger">{item}</li>
                                                ))}
                                            </div>
                                        </div>
                                        <small>
                                            <ul style={{listStyle: 'none'}} className="list-group text-left">
                                                <li className="">Your password can't be too similar to your other
                                                    personal information such as first name, last name and email
                                                    address.
                                                </li>
                                                <li className="">Your password must contain at least 8 characters.
                                                </li>
                                                <li className="">Your password can't be a commonly used password.
                                                </li>
                                                <li className="">Your password can't be entirely numeric.</li>
                                            </ul>
                                        </small>
                                        <br/>
                                        <div className="form-group">
                                            <button className="btn btn-blue text-white btn-block">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        );
    }
}

export default UpdatePassword;
