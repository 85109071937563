import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Layout from '.././Layout'
import API from '../../api'
import {hideLoader, mixpanel, showLoader, toast, trackMixpanel} from "../../_helpers/Loader";
import uuidv1 from 'uuid';
import {Context} from "../MyContext";
import NumberFormat from "react-number-format";
import $ from "jquery";


class AddAccount extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            club_account: {},
            is_contributions: false,
            account_bank: '',
            account_number: '',
            account_name: '',
            bank_code: '',
            contribution_amount: '',
            contribution_interval: '',
            invoice_limit: 0,
            bank_list: [],
            button: "Submit",
            main_button: "Retrieve Account Information",
            retrieved: false,
            club_admins: [],
        };
        this.onSubmit = this.onSubmit.bind(this)
    }

    async componentDidMount() {
        trackMixpanel('ClubAddAccountView', false, false, this.context.user.email)
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
        showLoader();
        await this.getAccount();
        (!this.checkAdmin(this.state.club_admins)) && this.props.history.push(`club/`);
        await this.getBanks();
        if (this.state.is_contributions) {
            $('.date').datepicker({
                startDate: new Date(new Date().setDate(new Date().getDate() + 1)),
                format: 'yyyy-mm-dd',
                autoclose: true,
            }).datepicker("update", new Date(new Date().setDate(new Date().getDate() + 1)));
        } else {
            $('.date').datepicker({
                startDate: new Date(new Date().setDate(new Date().getDate() + 1)),
                format: 'yyyy-mm-dd',
                autoclose: true,
            }).datepicker("update", new Date(new Date().setDate(new Date().getDate() + 1)));
        }
        hideLoader()
    }

    componentWillMount() {
        if (this.context.user.completed_onboarding === null || this.context.user.completed_onboarding === undefined) {
            window.location.reload();
        }
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
    }

    async getAccount() {
        try {
            let res = await API.get(`clubs/${this.props.match.params.id}/`);
            let data = (res.data.club_account_info.length > 0) ? res.data.club_account_info[0] : {};
            let plan = (res.data.club_paymentplan.length > 0) ? res.data.club_paymentplan[0] : {};
            if (data) {
                this.setState({
                    club_account: data,
                    club_admins: res.data.club_admins,
                    account_name: data.account_name,
                    account_number: data.account_number,
                    bank_code: data.bank_code,
                    is_contributions: res.data.is_contributions,
                    contribution_interval: plan.contribution_interval,
                    contribution_amount: plan.contribution_amount,
                    retrieved: !(res.data.is_contributions),
                });
                (res.data.club_account[0]) && await (this.setState({
                    button: 'Update',
                    main_button: (res.data.is_contributions) ? 'Retrieve Account Information' : 'Update',
                    invoice_limit: plan.invoice_limit
                }));
            }
        } catch (e) {
            hideLoader();
        }

    }

    async getBanks() {
        try {
            let res = await API.get('withdrawal_info/get_monnify_bank_list/');
            this.setState({bank_list: res.data});
        } catch (e) {
            toast.error("Something Went Wrong");
        }
    }

    async onSubmit(event) {
        event.preventDefault();
        let postData = {
            club_id: this.props.match.params.id,
            account_number: this.state.account_number,
            account_bank: this.state.bank_code,
            is_contributions: this.state.is_contributions,
            start_date: $('#date').val(),
            contribution_amount: this.state.contribution_amount,
            contribution_interval: this.state.contribution_interval,
            invoice_limit: this.state.invoice_limit
        };
        if (this.state.button.toLowerCase() === 'submit') {
            try {
                showLoader();
                let res = await API.post('clubs/create_account/', postData);
                toast.success("Account Created Successfully");
                if (this.props.location.query !== undefined) {
                    this.props.history.push(this.props.location.query.back_url)
                } else {
                    this.props.history.push(`/manage-club/${this.props.match.params.id}`)
                }
            } catch (e) {
                toast.error("Something Went Wrong");
                hideLoader();
            }
        } else {
            postData.account_id = this.state.club_account.subaccount_id;
            try {
                showLoader();
                let res = await API.post('clubs/update_account/', postData);
                toast.success("Account Updated Successfully");
                hideLoader();
                this.props.history.push(`/manage-club/${this.props.match.params.id}`);
                //redirect to update logout page
                await API.post('rest-auth/logout/')
            } catch (e) {
                hideLoader();
                toast.error("Something Went Wrong");
                // console.log(e.response)
            }
        }
    }

    checkAdmin(item) {
        if (item !== undefined) {
            for (let i = 0; i < item.length; i++) {
                if (item[i].user === this.context.user.id) {
                    return true;
                }
            }
            return false
        } else {
            return false
        }
    }

    getAccountName = async (event) => {
        event.preventDefault();
        const postData = {
            bank_name: this.state.bank_code,
            account_number: this.state.account_number
        };
        showLoader();
        try {
            let res = await API.post("withdrawal_info/get_account_name/", postData);
            if (res.data.account_name.toLowerCase() === 'not found') {
                toast.error("Details didn't return a valid information");
            } else {
                toast.success("Details valid. Click on Update/Submit button to save");
                this.setState({
                    account_name: res.data.account_name.toLowerCase(),
                    retrieved: true,
                    main_button: this.state.button
                });
            }
            hideLoader();
        } catch (e) {
            hideLoader();
            if (e.response.status === 400) {
                this.setState({account_name: '', button_state: 'Retrieve Account Information'});
                toast.error("Details didn't return a valid information");
            }
        }
    };

    render() {
        return (
            <Layout active_menu={'settings'} user_state={this.props.match.params.id}
                    club_admin={this.checkAdmin(this.state.club_admins)} context={this.context}>
                <div className="section">
                    <div className="breadcrumb">
                        <Link to={`/manage-club/${this.props.match.params.id}`}> <span
                            className="fa fa-chevron-left"/> Back</Link>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 col-xl-7 col-md-12 col-sm-12 mx-auto">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <h3>Club Bank Account Details</h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <form
                                                onSubmit={event => (this.state.retrieved) ? this.onSubmit(event) : this.getAccountName(event)}>
                                                <div className="">
                                                    <div className="form-group">
                                                        <div className="custom-control custom-checkbox">
                                                            <input checked={this.state.is_contributions}
                                                                   onChange={event => this.setState({
                                                                       is_contributions: event.target.checked,
                                                                       retrieved: !event.target.checked,
                                                                       main_button: (!event.target.checked) ? this.state.button : "Retrieve Account Information"
                                                                   })} type="checkbox" className="custom-control-input"
                                                                   id="customCheck1"/>
                                                            <label className="custom-control-label"
                                                                   htmlFor="customCheck1">Enable Automated Members’
                                                                Financial
                                                                Contributions
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label
                                                            className={!(this.state.is_contributions) ? 'disabled-label' : ''}>Contribution
                                                            Amount <sup>*</sup></label>
                                                        <NumberFormat required
                                                                      value={this.state.contribution_amount}
                                                                      disabled={!(this.state.is_contributions)}
                                                                      thousandSeparator={true} prefix={'₦'}
                                                                      onValueChange={(values) => {
                                                                          const {value} = values;
                                                                          this.setState({contribution_amount: (value ? value : null)})
                                                                      }} className="form-control"
                                                                      placeholder="Enter Amount in NGN"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label
                                                            className={!(this.state.is_contributions) ? 'disabled-label' : ''}>Contribution
                                                            Intervals <sup>*</sup></label>
                                                        <select required
                                                                disabled={!(this.state.is_contributions)}
                                                                value={this.state.contribution_interval}
                                                                onChange={event => this.setState({contribution_interval: (event.target.value !== '' ? event.target.value : null)})}
                                                                name="" id="interval" className="form-control">
                                                            <option value="">Select an option</option>
                                                            <option value="monthly">Monthly</option>
                                                            <option value="quarterly">Quarterly</option>
                                                            <option value="biannual">Biannual</option>
                                                            <option value="annual">Annually</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="message-text"
                                                               className={!(this.state.is_contributions) ? 'disabled-label' : ''}>Start
                                                            date:</label>
                                                        <div className="input-group date" data-provide="datepicker">
                                                            <input disabled={!(this.state.is_contributions)}
                                                                   required id="date" readOnly type="text"
                                                                   className="form-control"/>
                                                            <div className="input-group-addon">
                                                                <span className="fa fa-calendar"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label
                                                            className={!(this.state.is_contributions) ? 'disabled-label' : ''}>Invoice
                                                            Limits</label>
                                                        <input disabled={!(this.state.is_contributions)}
                                                               min="0" value={this.state.invoice_limit}
                                                               onChange={event => this.setState({invoice_limit: event.target.value})}
                                                               type="number" className="form-control text-capitalize"
                                                               id="name"/>
                                                        <small
                                                            className={!(this.state.is_contributions) ? 'disabled-label' : ''}>Set
                                                            to 0 if no limits</small>
                                                    </div>
                                                    <div className="form-group">
                                                        <label
                                                            className={!(this.state.is_contributions) ? 'disabled-label' : ''}>Account
                                                            Name</label>
                                                        <input
                                                            readOnly value={this.state.account_name}
                                                            onChange={event => this.setState({account_name: event.target.value})}
                                                            type="text" className="form-control text-capitalize"
                                                            id="name"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="number"
                                                               className={!(this.state.is_contributions) ? 'disabled-label' : ''}>Account
                                                            Number</label>
                                                        <input disabled={!(this.state.is_contributions)}
                                                               required value={this.state.account_number}
                                                               onChange={event => this.setState({account_number: event.target.value})}
                                                               type="number" className="form-control" id="number"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="bank"
                                                               className={!(this.state.is_contributions) ? 'disabled-label' : ''}>Bank</label>
                                                        <select disabled={!(this.state.is_contributions)}
                                                                required value={this.state.bank_code}
                                                                onChange={event => this.setState({bank_code: event.target.value})}
                                                                id="bank" className="form-control">
                                                            <option value="">Select and option</option>
                                                            {this.state.bank_list.map(item => (
                                                                <option key={uuidv1()}
                                                                        value={item.code}>{item.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <button type="submit"
                                                        className="btn btn-blue text-white mt-1 mb-0 btn-block">{this.state.main_button}
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

AddAccount.propTypes = {};

export default AddAccount;
