import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Layout from "../Layout";
import API from '../../api';
import {Context} from "../MyContext";
import {userContext} from "../../_helpers/userContext";
import {hideLoader, showLoader, toast} from "../../_helpers/Loader";
import $ from 'jquery'
import {
    accountPurpose, getBanks,
    getCountry, getDiscovery,
    getGender,
    getAccountType,
    getOccupation,
    getRelationship, getSourceOfWealth,
    getStates, getTitles
} from "../../_helpers/axaVariables";
import {getSource} from "echarts/src/data/helper/sourceHelper";

const mainUser = userContext;

class Profile extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            form: {
                "AccountType": "1",
                "TitleID": 2,
                "Surname": "Adetester",
                "Firstname": "Wole",
                "MiddleName": "Oni",
                "MothersMaidenName": "Falake",
                "Gender": 2,
                "Nationality": 128,
                "DOB": "1992-01-10",
                "CountryOfBirth": 128,
                "Occupation": 65,
                "ResidentPermitNo": "",
                "Country": 128,
                "StateID": 29,
                "ResidentialAddress": "Admalrity Lekki, Lagos",
                "MobileNo": "+23481390065452",
                "EmailAddress": "dapwill@gmail.com",
                "NOKName": "Funmi Ade",
                "NOKAddress": "Ondo Str, Ebute Meta",
                "NOKPhone": "+23455689212",
                "NOKEmail": "funmi@other.cm",
                "NOKRelationship": 8,
                "BVN": "65214455236",
                "AccountPurposeID": 2,
                "WealthSourceID": 2,
                "PoliticalExposure": "None",
                "PayoutDividend": true,
                "DiscoveryCode": 5,
                "SecurityQuestion": "Your first mobile device?",
                "SecurityAnswer": "Nokia",
                "BankID": 3,
                "AccountNo": "2145623148",
                "AccountName": "Wola Oni Wa",
            }
        }
    }

    async componentDidMount() {
        if (this.props.location.query) {
            this.setState({redirect_url: this.props.location.query.back_url})
        }
        // showLoader();
        // hideLoader();
    }

    componentWillMount() {
        if (this.context.user.completed_onboarding === null || this.context.user.completed_onboarding === undefined) {
            window.location.reload();
        }
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
        this.setState({user: this.context.user});
    }

    processForm = async (event) => {
        event.preventDefault();
        console.log(">>", this.state.form);
        if (this.state.page <= 6) {
            this.setState({page: this.state.page + 1})
        } else if (this.state.page === 7) {
            await this.submitForm();
        }
    }

    submitForm = async () => {
        showLoader();
        try {
            const res = await API.post('axa_mansard/create_mansard_account/', this.state.form)
            console.log(res.data);
            hideLoader();
        }catch (e) {
            toast.error(e.response.data.detail);
            hideLoader();
        }
    }

    back = (event) => {
        if (this.state.page === 1) {
        } else {
            this.setState({page: this.state.page - 1})
        }
    }

    render() {
        return (
            <Layout active_menu={'account'} user_state={'personal'} context={this.context}>
                <div className="section">
                    <div className="breadcrumb">
                        <Link to="/account"> <span className="fa fa-chevron-left"/> Settings</Link>
                    </div>
                    <div className="row">
                        <div className="col-lg-8 col-xl-8 col-md-12 col-sm-12 mx-auto">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4>Personal Profile</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-xl-12 col-lg-12">
                                            {(this.state.page === 1) ? (
                                                <form onSubmit={event => this.processForm(event)}
                                                      className="form-horizontal">
                                                    <h3>Personal Information</h3>
                                                    <hr/>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">Title</label>
                                                        <div className="col-md-8">
                                                            <select required
                                                                    value={this.state.form.TitleID}
                                                                    onChange={event => this.setState({
                                                                        form: {
                                                                            ...this.state.form,
                                                                            TitleID: event.target.value,
                                                                        }
                                                                    })}
                                                                    className="form-control">
                                                                <option value="">--- Select an option ---</option>
                                                                {getTitles().map(item => (
                                                                    <option value={item.Value}>{item.Text}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">First
                                                            Name</label>
                                                        <div className="col-md-8">
                                                            <input required
                                                                   value={this.state.form.Firstname} type="text"
                                                                   onChange={event => this.setState({
                                                                       form: {
                                                                           ...this.state.form,
                                                                           Firstname: event.target.value
                                                                       }
                                                                   })}
                                                                   className="form-control"/>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">Middle Name
                                                            (optional)</label>
                                                        <div className="col-md-8">
                                                            <input
                                                                value={this.state.form.MiddleName} type="text"
                                                                onChange={event => this.setState({
                                                                    form: {
                                                                        ...this.state.form,
                                                                        MiddleName: event.target.value,
                                                                    }
                                                                })}
                                                                className="form-control"/>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">Last Name</label>
                                                        <div className="col-md-8">
                                                            <input required
                                                                   value={this.state.form.Surname} type="text"
                                                                   onChange={event => this.setState({
                                                                       form: {
                                                                           ...this.state.form,
                                                                           Surname: event.target.value
                                                                       }
                                                                   })}
                                                                   className="form-control"/>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">Mother's Maiden
                                                            Name</label>
                                                        <div className="col-md-8">
                                                            <input required
                                                                   value={this.state.form.MothersMaidenName}
                                                                   type="text"
                                                                   onChange={event => this.setState({
                                                                       form: {
                                                                           ...this.state.form,
                                                                           MothersMaidenName: event.target.value
                                                                       }
                                                                   })}
                                                                   className="form-control"/>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">Gender</label>
                                                        <div className="col-md-8">
                                                            <select required
                                                                    value={this.state.form.Gender} type="text"
                                                                    onChange={event => this.setState({
                                                                        form: {
                                                                            ...this.state.form,
                                                                            Gender: event.target.value,
                                                                        }
                                                                    })}
                                                                    className="form-control">
                                                                <option value="">--- Select an option ---</option>
                                                                {getGender().map(item => (
                                                                    <option value={item.Value}>{item.Text}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">Nationality</label>
                                                        <div className="col-md-8">
                                                            <select required
                                                                    value={this.state.form.Nationality} type="text"
                                                                    onChange={event => this.setState({
                                                                        form: {
                                                                            ...this.state.form,
                                                                            Nationality: event.target.value,
                                                                        }
                                                                    })}
                                                                    className="form-control">
                                                                <option value="">--- Select an option ---</option>
                                                                {getCountry().map(item => (
                                                                    <option value={item.Value}>{item.Text}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">DOB</label>
                                                        <div className="col-md-8">
                                                            <input value={this.state.form.DOB} type="date"
                                                                   className="form-control"
                                                                   onChange={event => this.setState({
                                                                       form: {
                                                                           ...this.state.form,
                                                                           DOB: event.target.value,
                                                                       }
                                                                   })}/>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">County of
                                                            Birth</label>
                                                        <div className="col-md-8">
                                                            <select required
                                                                    value={this.state.form.CountryOfBirth}
                                                                    type="text"
                                                                    onChange={event => this.setState({
                                                                        form: {
                                                                            ...this.state.form,
                                                                            CountryOfBirth: event.target.value,
                                                                        }
                                                                    })}
                                                                    className="form-control">
                                                                <option value="">--- Select an option ---</option>
                                                                {getCountry().map(item => (
                                                                    <option value={item.Value}>{item.Text}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">Occupation</label>
                                                        <div className="col-md-8">
                                                            <select required
                                                                    value={this.state.form.Occupation} type="text"
                                                                    onChange={event => this.setState({
                                                                        form: {
                                                                            ...this.state.form,
                                                                            Occupation: event.target.value,
                                                                        }
                                                                    })}
                                                                    className="form-control">
                                                                <option value="">--- Select an option ---</option>
                                                                {getOccupation().map(item => (
                                                                    <option value={item.Value}>{item.Text}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <button onClick={event => this.back(event)}
                                                                className="btn btn-blue text-white-outline" type="button"
                                                                disabled><i className="fa fa-backward"/> Back
                                                        </button>
                                                        <button className="btn btn-blue text-white float-right"
                                                                type="submit">Next <i className="fa fa-forward"/>
                                                        </button>
                                                    </div>
                                                </form>
                                            ) : (this.state.page === 2) ? (
                                                <form onSubmit={event => this.processForm(event)}
                                                      className="form-horizontal">
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">Country</label>
                                                        <div className="col-md-8">
                                                            <select required
                                                                    value={this.state.form.Country} type="text"
                                                                    onChange={event => this.setState({
                                                                        form: {
                                                                            ...this.state.form,
                                                                            Country: event.target.value,
                                                                        }
                                                                    })}
                                                                    className="form-control">
                                                                <option value="">--- Select an option ---</option>
                                                                {getCountry().map(item => (
                                                                    <option value={item.Value}>{item.Text}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">State</label>
                                                        <div className="col-md-8">
                                                            <select required
                                                                    value={this.state.form.StateID} type="text"
                                                                    onChange={event => this.setState({
                                                                        form: {
                                                                            ...this.state.form,
                                                                            StateID: event.target.value,
                                                                        }
                                                                    })}
                                                                    className="form-control">
                                                                <option value="">--- Select an option ---</option>
                                                                {getStates().map(item => (
                                                                    <option value={item.Value}>{item.Text}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">Residential
                                                            Address</label>
                                                        <div className="col-md-8">
                                                            <textarea rows="5" required
                                                                      value={this.state.form.ResidentialAddress}
                                                                      type="text"
                                                                      onChange={event => this.setState({
                                                                          form: {
                                                                              ...this.state.form,
                                                                              ResidentialAddress: event.target.value
                                                                          }
                                                                      })}
                                                                      className="form-control"></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">Mobile No</label>
                                                        <div className="col-md-8">
                                                            <input required
                                                                   value={this.state.form.MobileNo}
                                                                   type="text"
                                                                   onChange={event => this.setState({
                                                                       form: {
                                                                           ...this.state.form,
                                                                           MobileNo: event.target.value
                                                                       }
                                                                   })}
                                                                   className="form-control"/>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">Email
                                                            Address</label>
                                                        <div className="col-md-8">
                                                            <input required readOnly
                                                                   value={this.state.form.EmailAddress}
                                                                   type="email"
                                                                   onChange={event => this.setState({
                                                                       form: {
                                                                           ...this.state.form,
                                                                           EmailAddress: event.target.value
                                                                       }
                                                                   })}
                                                                   className="form-control"/>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <button onClick={event => this.back(event)}
                                                                className="btn btn-blue text-white-outline" type="button"
                                                        ><i className="fa fa-backward"/> Back
                                                        </button>
                                                        <button className="btn btn-blue text-white float-right"
                                                                type="submit">Next <i className="fa fa-forward"/>
                                                        </button>
                                                    </div>
                                                </form>
                                            ) : (this.state.page === 3) ? (
                                                <form onSubmit={event => this.processForm(event)}
                                                      className="form-horizontal">
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">Next Of Kin
                                                            Name</label>
                                                        <div className="col-md-8">
                                                            <input required
                                                                   value={this.state.form.NOKName}
                                                                   type="text"
                                                                   onChange={event => this.setState({
                                                                       form: {
                                                                           ...this.state.form,
                                                                           NOKName: event.target.value
                                                                       }
                                                                   })}
                                                                   className="form-control"/>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">Next Of Kin
                                                            Address</label>
                                                        <div className="col-md-8">
                                                            <textarea rows="5" required
                                                                      value={this.state.form.NOKAddress}
                                                                      type="text"
                                                                      onChange={event => this.setState({
                                                                          form: {
                                                                              ...this.state.form,
                                                                              NOKAddress: event.target.value
                                                                          }
                                                                      })}
                                                                      className="form-control"></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">Next Of Kin
                                                            Phone</label>
                                                        <div className="col-md-8">
                                                            <input required
                                                                   value={this.state.form.NOKPhone}
                                                                   type="text"
                                                                   onChange={event => this.setState({
                                                                       form: {
                                                                           ...this.state.form,
                                                                           NOKPhone: event.target.value
                                                                       }
                                                                   })}
                                                                   className="form-control"/>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">Next Of Kin
                                                            Email</label>
                                                        <div className="col-md-8">
                                                            <input required
                                                                   value={this.state.form.NOKEmail}
                                                                   type="email"
                                                                   onChange={event => this.setState({
                                                                       form: {
                                                                           ...this.state.form,
                                                                           NOKEmail: event.target.value
                                                                       }
                                                                   })}
                                                                   className="form-control"/>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">Relationship</label>
                                                        <div className="col-md-8">
                                                            <select required
                                                                    value={this.state.form.NOKRelationship}
                                                                    type="text"
                                                                    onChange={event => this.setState({
                                                                        form: {
                                                                            ...this.state.form,
                                                                            NOKRelationship: event.target.value,
                                                                        }
                                                                    })}
                                                                    className="form-control">
                                                                <option value="">--- Select an option ---</option>
                                                                {getRelationship().map(item => (
                                                                    <option value={item.Value}>{item.Text}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <button onClick={event => this.back(event)}
                                                                className="btn btn-blue text-white-outline" type="button"
                                                        ><i className="fa fa-backward"/> Back
                                                        </button>
                                                        <button className="btn btn-blue text-white float-right"
                                                                type="submit">Next <i className="fa fa-forward"/>
                                                        </button>
                                                    </div>
                                                </form>
                                            ) : (this.state.page === 4) ? (
                                                <form onSubmit={event => this.processForm(event)}
                                                      className="form-horizontal">
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">BVN</label>
                                                        <div className="col-md-8">
                                                            <input required
                                                                   value={this.state.form.BVN}
                                                                   type="text"
                                                                   onChange={event => this.setState({
                                                                       form: {
                                                                           ...this.state.form,
                                                                           BVN: event.target.value
                                                                       }
                                                                   })}
                                                                   className="form-control"/>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">Account
                                                            Purpose</label>
                                                        <div className="col-md-8">
                                                            <select required
                                                                    value={this.state.form.AccountPurposeID}
                                                                    type="text"
                                                                    onChange={event => this.setState({
                                                                        form: {
                                                                            ...this.state.form,
                                                                            AccountPurposeID: event.target.value,
                                                                        }
                                                                    })}
                                                                    className="form-control">
                                                                <option value="">--- Select an option ---</option>
                                                                {accountPurpose().map(item => (
                                                                    <option value={item.Value}>{item.Text}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">Account
                                                            Type</label>
                                                        <div className="col-md-8">
                                                            <select required
                                                                    value={this.state.form.AccountType}
                                                                    type="text"
                                                                    onChange={event => this.setState({
                                                                        form: {
                                                                            ...this.state.form,
                                                                            AccountType: event.target.value,
                                                                        }
                                                                    })}
                                                                    className="form-control">
                                                                <option value="">--- Select an option ---</option>
                                                                {getAccountType().map(item => (
                                                                    <option value={item.Value}>{item.Text}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">Source Of
                                                            Wealth</label>
                                                        <div className="col-md-8">
                                                            <select required
                                                                    value={this.state.form.WealthSourceID}
                                                                    onChange={event => this.setState({
                                                                        form: {
                                                                            ...this.state.form,
                                                                            WealthSourceID: event.target.value,
                                                                        }
                                                                    })}
                                                                    className="form-control">
                                                                <option value="">--- Select an option ---</option>
                                                                {getSourceOfWealth().map(item => (
                                                                    <option value={item.Value}>{item.Text}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">Political
                                                            Exposure</label>
                                                        <div className="col-md-8">
                                                            <input rows="5" required
                                                                   value={this.state.form.PoliticalExposure}
                                                                   type="text"
                                                                   onChange={event => this.setState({
                                                                       form: {
                                                                           ...this.state.form,
                                                                           PoliticalExposure: event.target.value
                                                                       }
                                                                   })}
                                                                   className="form-control"/>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">How Did You
                                                            Discover
                                                            Us</label>
                                                        <div className="col-md-8">
                                                            <select required
                                                                    value={this.state.form.DiscoveryCode}
                                                                    onChange={event => this.setState({
                                                                        form: {
                                                                            ...this.state.form,
                                                                            DiscoveryCode: event.target.value,
                                                                        }
                                                                    })}
                                                                    className="form-control">
                                                                <option value="">--- Select an option ---</option>
                                                                {getDiscovery().map(item => (
                                                                    <option value={item.Value}>{item.Text}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">Enter A Security
                                                            Question</label>
                                                        <div className="col-md-8">
                                                            <input required
                                                                   value={this.state.form.SecurityQuestion}
                                                                   type="text"
                                                                   onChange={event => this.setState({
                                                                       form: {
                                                                           ...this.state.form,
                                                                           SecurityQuestion: event.target.value
                                                                       }
                                                                   })}
                                                                   className="form-control"/>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">Security
                                                            Answer</label>
                                                        <div className="col-md-8">
                                                            <input required
                                                                   value={this.state.form.SecurityAnswer}
                                                                   type="text"
                                                                   onChange={event => this.setState({
                                                                       form: {
                                                                           ...this.state.form,
                                                                           SecurityAnswer: event.target.value
                                                                       }
                                                                   })}
                                                                   className="form-control"/>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <button onClick={event => this.back(event)}
                                                                className="btn btn-blue text-white-outline" type="button"
                                                        ><i className="fa fa-backward"/> Back
                                                        </button>
                                                        <button className="btn btn-blue text-white float-right"
                                                                type="submit">Next <i className="fa fa-forward"/>
                                                        </button>
                                                    </div>
                                                </form>
                                            ) : (this.state.page === 5) ? (
                                                <form onSubmit={event => this.processForm(event)}
                                                      className="form-horizontal">
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">Account
                                                            Purpose</label>
                                                        <div className="col-md-8">
                                                            <select required
                                                                    value={this.state.form.AccountPurposeID}
                                                                    type="text"
                                                                    onChange={event => this.setState({
                                                                        form: {
                                                                            ...this.state.form,
                                                                            AccountPurposeID: event.target.value,
                                                                        }
                                                                    })}
                                                                    className="form-control">
                                                                <option value="">--- Select an option ---</option>
                                                                {accountPurpose().map(item => (
                                                                    <option value={item.Value}>{item.Text}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">Source Of
                                                            Wealth</label>
                                                        <div className="col-md-8">
                                                            <select required
                                                                    value={this.state.form.WealthSourceID}
                                                                    onChange={event => this.setState({
                                                                        form: {
                                                                            ...this.state.form,
                                                                            WealthSourceID: event.target.value,
                                                                        }
                                                                    })}
                                                                    className="form-control">
                                                                <option value="">--- Select an option ---</option>
                                                                {getSourceOfWealth().map(item => (
                                                                    <option value={item.Value}>{item.Text}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">Political
                                                            Exposure</label>
                                                        <div className="col-md-8">
                                                            <input rows="5" required
                                                                   value={this.state.form.PoliticalExposure}
                                                                   type="text"
                                                                   onChange={event => this.setState({
                                                                       form: {
                                                                           ...this.state.form,
                                                                           PoliticalExposure: event.target.value
                                                                       }
                                                                   })}
                                                                   className="form-control"/>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">How Did You
                                                            Discover
                                                            Us</label>
                                                        <div className="col-md-8">
                                                            <select required
                                                                    value={this.state.form.DiscoveryCode}
                                                                    onChange={event => this.setState({
                                                                        form: {
                                                                            ...this.state.form,
                                                                            DiscoveryCode: event.target.value,
                                                                        }
                                                                    })}
                                                                    className="form-control">
                                                                <option value="">--- Select an option ---</option>
                                                                {getDiscovery().map(item => (
                                                                    <option value={item.Value}>{item.Text}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <button onClick={event => this.back(event)}
                                                                className="btn btn-blue text-white-outline" type="button"
                                                        ><i className="fa fa-backward"/> Back
                                                        </button>
                                                        <button className="btn btn-blue text-white float-right"
                                                                type="submit">Next <i className="fa fa-forward"/>
                                                        </button>
                                                    </div>
                                                </form>
                                            ) : (this.state.page === 6) ? (
                                                <form onSubmit={event => this.processForm(event)}
                                                      className="form-horizontal">
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">Bank</label>
                                                        <div className="col-md-8">
                                                            <select required
                                                                    value={this.state.form.BankID}
                                                                    onChange={event => this.setState({
                                                                        form: {
                                                                            ...this.state.form,
                                                                            BankID: event.target.value,
                                                                        }
                                                                    })}
                                                                    className="form-control">
                                                                <option value="">--- Select an option ---</option>
                                                                {getBanks().map(item => (
                                                                    <option value={item.ID}>{item.BankName}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">Account
                                                            No</label>
                                                        <div className="col-md-8">
                                                            <input required
                                                                   value={this.state.form.AccountNo}
                                                                   type="text"
                                                                   onChange={event => this.setState({
                                                                       form: {
                                                                           ...this.state.form,
                                                                           AccountNo: event.target.value
                                                                       }
                                                                   })}
                                                                   className="form-control"/>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">Account
                                                            Name</label>
                                                        <div className="col-md-8">
                                                            <input required
                                                                   value={this.state.form.AccountName}
                                                                   type="text"
                                                                   onChange={event => this.setState({
                                                                       form: {
                                                                           ...this.state.form,
                                                                           AccountName: event.target.value
                                                                       }
                                                                   })}
                                                                   className="form-control"/>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <button onClick={event => this.back(event)}
                                                                className="btn btn-blue text-white-outline" type="button"
                                                        ><i className="fa fa-backward"/> Back
                                                        </button>
                                                        <button className="btn btn-blue text-white float-right"
                                                                type="submit">Next <i className="fa fa-forward"/>
                                                        </button>
                                                    </div>
                                                </form>
                                            ) : (this.state.page === 7) ? (
                                                <form onSubmit={event => this.processForm(event)}
                                                      className="form-horizontal">
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">Bank</label>
                                                        <div className="col-md-8">
                                                            <select required
                                                                    value={this.state.form.BankID} type="text"
                                                                    onChange={event => this.setState({
                                                                        form: {
                                                                            ...this.state.form,
                                                                            BankID: event.target.value,
                                                                        }
                                                                    })}
                                                                    className="form-control">
                                                                <option value="">--- Select an option ---</option>
                                                                {getBanks().map(item => (
                                                                    <option value={item.ID}>{item.BankName}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">Account
                                                            No</label>
                                                        <div className="col-md-8">
                                                            <input required
                                                                   value={this.state.form.AccountNo}
                                                                   type="text"
                                                                   onChange={event => this.setState({
                                                                       form: {
                                                                           ...this.state.form,
                                                                           AccountNo: event.target.value
                                                                       }
                                                                   })}
                                                                   className="form-control"/>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputName"
                                                               className="col-md-4 col-form-label">Account
                                                            Name</label>
                                                        <div className="col-md-8">
                                                            <input required
                                                                   value={this.state.form.AccountName}
                                                                   type="text"
                                                                   onChange={event => this.setState({
                                                                       form: {
                                                                           ...this.state.form,
                                                                           AccountName: event.target.value
                                                                       }
                                                                   })}
                                                                   className="form-control"/>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <button onClick={event => this.back(event)}
                                                                className="btn btn-blue text-white-outline" type="button"
                                                        ><i className="fa fa-backward"/> Back
                                                        </button>
                                                        <button className="btn btn-blue text-white float-right"
                                                                type="submit">Submit <i className="fa fa-forward"/>
                                                        </button>
                                                    </div>
                                                </form>
                                            ) : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default Profile;

