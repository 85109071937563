import HoldOn from 'react-hold-on';
import toastr from 'toastr';
import $ from 'jquery'
import mixpanel from 'mixpanel-browser';
import {MIXPANEL_KEY} from "../env";

mixpanel.init(MIXPANEL_KEY);

class SetToast {
    success = async (msg) => {
        const check = await this.checkInfo(msg);
        (!check) && toastr.success(msg)
    };

    checkInfo = async (msg) => {
        let div = $('#toast-container').find('.toast-message');
        for (let i = 0; i < div.length; i++) {
            if (div[i].innerText === msg) {
                return true
            }
        }
        return false;
    };

    error = async (msg) => {
        const check = await this.checkInfo(msg);
        (!check) && toastr.error(msg)
    };

    info = async (msg) => {
        const check = await this.checkInfo(msg);
        (!check) && toastr.info(msg)
    }
}

let toast = new SetToast();

var options = {
    theme: "sk-cube-grid",
    message: 'Twelvevest',
    backgroundColor: "#1847B1",
    textColor: "white"
};

var showLoader = function () {
    HoldOn.open(options);
};

var hideLoader = function () {
    HoldOn.close()
};

class UtilClass {
    Currency(value) {
        let out_value = parseFloat(parseFloat((value) ? value : 0).toFixed(2)).toLocaleString();
        return `₦${out_value}`;
    }

    NumberDecimal(value) {
        return parseFloat(parseFloat((value) ? value : 0).toFixed(2)).toLocaleString();
    }

    Validate(value) {

    }

    charges(digit) {
        let amount = (digit === '') ? 0 : parseFloat(digit);
        if (amount <= 0) {
            return 0
        }
        let decimal = 1 - 0.015;
        if (parseFloat(amount) >= 2500) {
            let total = ((amount + 100) / (decimal));
            if ((total - amount) < 2000) {
            } else {
                total = (amount + 2000);
            }
            return parseInt(total + 1)
        } else {
            let total = (amount / (decimal));
            return parseInt(Math.round(total) + 1)
        }
    };

    currencyToSym(currency) {
        if (currency === "USD") {
            return '$'
        } else if (currency === "GBP") {
            return '£'
        } else {
            return '₦'
        }
    }
}

let Utils = new UtilClass();

function trackMixpanel(event_name, profile, payload, identifier) {
    try {
        mixpanel.reset();
        if (identifier) {
            mixpanel.identify(`${identifier}`);
        }
        if (profile) {
            mixpanel.people.set(profile)
        }
        if (payload) {
            mixpanel.track(event_name, payload)
        }
        if (!payload && !profile) {
            mixpanel.track(event_name)
        }
    } catch (e) {
    }
}

export {showLoader, hideLoader, toast, Utils, trackMixpanel}
