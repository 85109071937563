import React, {useState, useHistory, useContext, useEffect} from 'react';
import {Card, Form, Button} from 'react-bootstrap';
import {showLoader, hideLoader, toast} from "../../_helpers/Loader";
import API from '../../api';
import {Context} from "../MyContext";


function IdentificationView (){
  const [verification, setVerification] = useState({})
  const context = useContext(Context)

  const vaildateImage = (e) => {
    if (e.target.files[0].type.toLowerCase().includes("pdf") || e.target.files[0].type.toLowerCase().includes("png")){
      let reader = new FileReader();
      reader.onload = (e) => {
        setVerification({...verification, kyc_doc_front: e.target.result})
      };
      reader.readAsDataURL(e.target.files[0]);
      // console.log(e.target.files, e.target.result, ">>>>>>")
    } else {
      e.target.value = null
      toast.error("Error invalid file type")
    }
  }

  const submitVerification = async (e) => {
    e.preventDefault();
    if (Object.keys(verification).length > 0){
      if (verification?.kyc_doc_type && verification?.kyc_doc_front){
        console.log(verification)
      
        showLoader()
        try {
          await API.post("users/submit_identification/", verification);
          // console.log(res.data);
          window.Swal.fire({
            title: 'Operation Successful!',
            text: `Identity Submitted Successfully`,
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: "#1F5277",
          })
          context.updateUser();
        } catch {
          toast.error('Error submitting identification')
        }
        hideLoader()
      } else {
        toast.error('Identification Type Missing or Identification File')
      }
    }
  }


  // const 

  return(<>
    { context.user.kyc_doc_type == null || context.user.kyc_verified !== true ? <Card>
      <Card.Body style={{marginTop: "-20px"}}>
      <hr/>
        <h3>Verify Identity</h3>
      <hr/>
      <Form id="submitVerification" onSubmit={(e) => submitVerification(e)}>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Identification Type</Form.Label>
          <Form.Control as="select" onChange={(e) => setVerification({...verification, kyc_doc_type: e.target.value ? e.target.value : null})} required>
            <option value={null} readonly={true}>Select Identification Type</option>
            <option value={`NIN`}>NIN</option>
            <option value={`INTERNATIONAL_PASSPORT`}>INTERNATIONAL PASSPORT</option>
            <option value={`VOTERS_CARD`}>VOTER'S CARD</option>
            <option value={`DRIVERS_LICENSE`}>DRIVER'S LICENSE</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Identification Number</Form.Label>
          <Form.Control type="text" onChange={(e) => setVerification({...verification, kyc_doc_number: e.target.value})} />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Issue Date</Form.Label>
          <Form.Control type="date" onChange={(e) => setVerification({...verification, kyc_doc_issue_date: e.target.value})} />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Expiry Date</Form.Label>
          <Form.Control type="date" onChange={(e) => setVerification({...verification, kyc_doc_expiry_date: e.target.value})} />
        </Form.Group>
        <Form.Group>
          <Form.File id="upload_identitication" label="upload Identification (pdf & png format only)" required onChange={(e) => vaildateImage(e)} />
        </Form.Group>
        <Button variant="primary" size="md" className="float-right btn-blue text-white" type="submit">Submit</Button>
      </Form>
      </Card.Body>
    </Card> : "" }
  </>)
}

export default IdentificationView;
