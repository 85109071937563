import React, {Component} from 'react';
import Layout from "../Layout";
import {hideLoader, mixpanel, showLoader, toast, trackMixpanel} from '../../_helpers/Loader'
import {Context} from "../MyContext";
import ReactSafeHtml from 'react-safe-html'
import uuidv1 from 'uuid';
import API from "../../api";
import Moment from "react-moment";
import twemoji from 'twemoji';

import $ from 'jquery';

class Chats extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            club: {},
            clubs: [],
            chats: [],
            private_people: [],
            main_id: '',
            message: '',
            chat_view: 'private',
            user_from_club: {},
            private_notification: [],
            club_notification: [],
        };

        this.interval = null;

        this.getMainChatGroup = this.getMainChatGroup.bind(this);
        this.getMessagesReload = this.getMessagesReload.bind(this);
        this.sendRender = this.sendRender.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
    }


    async componentDidMount() {
        trackMixpanel('ClubChatView', false, false, this.context.user.email)
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
        showLoader();
        this.getMessageNotification();
        await this.getPrivatePeople();
        (this.context.club_id !== null || this.context.club_id === false) && await this.getClub();
        await this.getClubChats();
        (this.props.location.query !== undefined) && this.forPrivateChat(this.props.location.query.user.id, this.props.location.query.club_id);
        (this.state.chats.length > 0) && $("#nav-private").click();
        $(".inbox_chat").find('.chat_list').click();
        hideLoader();
    }

    getMessageNotification = async () => {
        try {
            let res = await API.post('messages/check_new_message/');
            this.setState({private_notification: res.data.private, club_notification: res.data.group})
        } catch (e) {
            // console.log(e.response)
        }
    };

    forPrivateChat = async (receiver_id, club_id) => {
        this.setState({chat_view: 'private', main_id: receiver_id});
        let confirm = [];
        confirm = this.state.private_people.filter(item => item.recipient.id === receiver_id);
        if (confirm.length === 0) {
            await this.get_private_chats(receiver_id, this.context.user.id);
            let user = this.props.location.query.user;
            user['recipient'] = {recipient: {}};
            user['sender'] = {id: this.context.user.id};
            user.recipient['first_name'] = user.first_name;
            user.recipient['last_name'] = user.last_name;
            user.recipient['id'] = user.id;
            // console.log("Check", user);
            await this.setState({private_people: [user, ...this.state.private_people]});
        } else {
            let id = confirm[0].id;
            $("#private_" + id).click();
        }
    };

    get_private_chats = async (receiver_id, sender_id) => {
        showLoader();
        let postData = {};
        if (this.context.user.id === receiver_id) {
            postData = {
                receiver_id: sender_id,
                sender_id: receiver_id
            }
        } else {
            postData = {
                receiver_id: receiver_id,
                sender_id: sender_id
            }
        }
        try {
            let res = await API.post('messages/get_private_main_chat/', postData);
            await this.setState({chats: res.data, main_id: postData.receiver_id, chat_view: 'private'});
            hideLoader();
            this.checkMessages()
        } catch (e) {
            toast.error("Something went wrong");
            hideLoader();
        }
    };

    send_private_message = async () => {
        let postData = {
            message: this.state.message,
            receiver_id: this.state.main_id,
        };
        // console.log(postData);
        try {
            let res = await API.post('messages/send_private_message/', postData);
            this.sendRender(postData);
        } catch (e) {
            toast.error('Something went wrong');
            setTimeout(() => {
                toast.error('Try again')
            }, 2000);
        }
    };

    getPrivatePeople = async () => {
        try {
            let res = await API.post('messages/get_private_people/');
            // console.log("PRIVATE", res);
            this.setState({private_people: res.data})
        } catch (e) {
            // console.log(e.response)
        }
    };

    componentWillMount() {
        if (this.context.user.completed_onboarding === null || this.context.user.completed_onboarding === undefined) {
            window.location.reload();
        }
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
    }

    async getClub() {
        try {
            let res = await API.get(`clubs/${this.context.club_id}/`);
            this.setState({club: res.data});
        } catch (e) {
            hideLoader();
            // console.log(e.response)
        }
    }

    async getClubChats() {
        try {
            let res = await API.get('messages/get_group_chat/');
            this.setState({clubs: res.data})
        } catch (e) {
            // console.log(e.response);
            hideLoader();
        }
    }

    async getMainChatGroup(club_id) {
        let postData = {
            club_id: club_id
        };
        showLoader();
        try {
            let res = await API.post('messages/get_club_main_chat/', postData);
            this.setState({chats: res.data});
            this.setState({main_id: club_id, chat_view: 'group'});
            this.checkMessages();
            hideLoader();
        } catch (e) {
            hideLoader();
            toast.error("Something went wrong");
            // console.log(e.response)
        }
    }

    async getMessagesReload() {
        if (this.state.chat_view === 'group') {
            let postData = {
                club_id: this.state.main_id
            };
            try {
                let res = await API.post('messages/get_club_main_chat/', postData);
                (this.state.chats.length !== res.data.length) && this.setState({chats: res.data});
            } catch (e) {
                toast.error("Something went wrong");
                // console.log(e.response)
            }
        } else {
            let postData = {
                receiver_id: this.state.main_id,
                sender_id: this.context.user.id
            };
            try {
                let res = await API.post('messages/get_private_main_chat/', postData);
                (this.state.chats.length !== res.data.length) && this.setState({chats: res.data});
            } catch (e) {
                toast.error("Something went wrong");
                // console.log(e.response)
            }
        }
    }

    checkMessages() {
        this.interval = setInterval(() => {
            this.getMessagesReload(this.state.main_id);
        }, 5000)
    }

    async sendMessage() {
        let postData = {
            message: this.state.message.trim(),
            club_id: this.state.main_id,
        };
        try {
            let res = await API.post('messages/send_group_message/', postData);
            this.sendRender(postData);
        } catch (e) {
            toast.error("Something went wrong");
            setTimeout(() => {
                toast.error('Try again')
            }, 2000);
            // console.log(e.response)
        }

    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    sendRender(info) {
        const date = new Date();
        let data = {
            id: uuidv1(),
            sender: {
                id: this.context.user.id,
                first_name: this.context.user.first_name,
                last_name: this.context.user.last_name,
            },
            message: info.message,
            created_at: date
        };
        this.setState({chats: [...this.state.chats, data], message: ''});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== prevState) {
            let objDiv = document.getElementById("messages-div");
            objDiv.scrollTop = objDiv.scrollHeight;
        }
    }

    checkAdmin(item) {
        if (item !== undefined) {
            for (let i = 0; i < item.length; i++) {
                if (item[i].user.id === this.context.user.id && item[i].user_role === 'admin') {
                    return true;
                }
            }
            return false
        } else {
            return false
        }
    }

    chats(chat_item) {
        if (chat_item.sender.id !== this.context.user.id) {
            return (
                <div key={chat_item.id} className="incoming_msg">
                    <div className="incoming_msg_img text-center">
                        {(chat_item.sender.profile_photo === null) ? (
                            <img src="https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png"
                                 alt={`${chat_item.sender.first_name}`}/>
                        ) : (
                            <img src={chat_item.sender.profile_photo}
                                 alt={`${chat_item.sender.first_name}`}/>

                        )}
                    </div>
                    <div className="received_msg">
                        <div className="received_withd_msg">
                            <p>{chat_item.message}</p>
                            <span className="time_date"><Moment
                                format="hh:mm:A | MMM DD YYYY">{chat_item.created_at}</Moment> | {chat_item.sender.first_name[0].toUpperCase()}. {chat_item.sender.last_name}</span>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div key={chat_item.id} className="outgoing_msg">
                    <div className="sent_msg">
                        <p dangerouslySetInnerHTML={{__html: chat_item.message}}/>
                        <span className="time_date"><Moment
                            format="hh:mm:A | MMM DD YYYY">{chat_item.created_at}</Moment></span>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <Layout active_menu={'inbox'}
                    club_admin={this.checkAdmin(this.state.club.club_members)} context={this.context}>
                <div className="section">
                    <div className="breadcrumb">
                        <h5>Chats</h5>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12 mx-auto">
                            <div className="messaging">
                                <div className="inbox_msg">
                                    <div className="inbox_people">
                                        <div className="headind_srch">
                                            <nav>
                                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                    <a className={`nav-item nav-link first-chat mx-auto ${(this.state.chat_view === 'private') ? 'active' : ''} text-default`}
                                                       style={{marginRight: 0}} id="nav-home-tab"
                                                       data-toggle="tab"
                                                       href="#nav-private" role="tab" aria-controls="nav-home"
                                                       aria-selected="true">Private
                                                        Chat {(this.state.private_notification.length > 0) ? (<sup><i
                                                            className="fa fa-bell text-orange"/></sup>) : ''}</a>
                                                    <a className={`nav-item nav-link sec-chat mx-auto ${(this.state.chat_view === 'group') ? 'active' : ''} text-default`}
                                                       id="nav-profile-tab"
                                                       data-toggle="tab"
                                                       href="#nav-club" role="tab" aria-controls="nav-profile"
                                                       aria-selected="false">Club
                                                        Chat {(this.state.club_notification.length > 0) ? (<sup><i
                                                            className="fa fa-bell text-orange"/></sup>) : ''}</a>
                                                </div>
                                            </nav>
                                        </div>
                                        <div className="tab-content" id="nav-tabContent">
                                            <div className="tab-pane fade show active" id="nav-private" role="tabpanel"
                                                 aria-labelledby="nav-home-tab">
                                                <div className="inbox_chat scroll">
                                                    {this.state.private_people.map(item => (
                                                        <div id={`private_${item.id}`} className="chat_list active_chat"
                                                             onClick={event => this.get_private_chats(item.recipient.id, item.sender.id)}
                                                             key={item.id}>
                                                            <div className="chat_people">
                                                                <div className="chat_img">
                                                                    <img alt="profile_photo"
                                                                         src={(item.profile_photo == null) ? 'https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png' : item.profile_photo}/>
                                                                </div>
                                                                {(this.context.user.id !== item.sender.id) ? (
                                                                    <div className="chat_ib">
                                                                        <h5>{item.sender.first_name} {item.sender.last_name} {(this.state.private_notification.includes(item.sender.id)) ? (
                                                                            <sup><i
                                                                                className="fa fa-envelope text-orange"/></sup>) : ''}<span
                                                                            className="chat_date"><Moment
                                                                            format="MMM D, Y">{item.created_at}</Moment></span>
                                                                        </h5>
                                                                        <p>{item.message}</p>
                                                                    </div>
                                                                ) : (
                                                                    <div className="chat_ib">
                                                                        <h5>{item.recipient.first_name} {item.recipient.last_name} {(this.state.private_notification.includes(item.sender.id)) ? (
                                                                            <sup><i
                                                                                className="fa fa-envelope text-orange"/></sup>) : ''}<span
                                                                            className="chat_date"><Moment
                                                                            format="MMM D, Y">{item.created_at}</Moment></span>
                                                                        </h5>
                                                                        <p>{item.message}</p>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="nav-club" role="tabpanel"
                                                 aria-labelledby="nav-club-tab">
                                                <div className="inbox_chat scroll">
                                                    {this.state.clubs.map(item => (
                                                        <div className="chat_list"
                                                             onClick={event => this.getMainChatGroup(item.club)}
                                                             key={item.id}>
                                                            <div className="chat_people">
                                                                <div className="chat_img">
                                                                    <i className="fa fa-users text-main"
                                                                       style={{fontSize: '22pt'}}/>
                                                                </div>
                                                                <div className="chat_ib">
                                                                    <h5>{item.name} {(this.state.club_notification.includes(item.club)) ? (
                                                                        <sup><i className="fa fa-envelope text-orange"/></sup>) : ''}<span
                                                                        className="chat_date"><Moment
                                                                        format="MMM D, Y">{item.created_at}</Moment></span>
                                                                    </h5>
                                                                    <p>{item.message}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mesgs">
                                        <div className="msg_history" id="messages-div">
                                            {(this.state.chats.length < 1) ? (
                                                    <div className="text-center">
                                                        <i style={{fontSize: '900%'}} className="fa fa-comments text-dark"/>
                                                        <br/>No chats
                                                    </div>
                                                ) :
                                                (
                                                    this.state.chats.map(item => (
                                                            this.chats(item)
                                                        )
                                                    )
                                                )
                                            }

                                        </div>
                                        <div className="type_msg">
                                            <div className="input_msg_write">
                                                <input
                                                    onKeyDown={e => (e.keyCode === 13) && document.getElementById('send-message').click()}
                                                    value={this.state.message} type="text" className="write_msg"
                                                    onChange={event => this.setState({message: event.target.value.replace(/^\s+/g, '')})}
                                                    placeholder="Type a message"/>
                                                <button id="send-message"
                                                        onClick={(this.state.chat_view === 'group') ? this.sendMessage : this.send_private_message}
                                                        disabled={!(this.state.message)}
                                                        className="msg_send_btn" type="button"><i
                                                    className="fa fa-paper-plane"
                                                    aria-hidden="true"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default Chats;
