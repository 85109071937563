import React, {Component} from 'react';
import {Link} from "react-router-dom";
import uuidv1 from 'uuid'
import Moment from "react-moment";
import Layout from "../Layout";
import {Context} from "../MyContext";
import API from "../../api";
import {hideLoader, showLoader, toast} from "../../_helpers/Loader";
import $ from "jquery";

class PendingTransaction extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            orders: []
        }
    }


    async componentDidMount() {
        showLoader();
        try {
            let res = await API.post('my_investments/get_pending_transaction/');
            this.setState({orders: res.data});
            hideLoader();
            $(".table").dataTable({
                'search': {
                    caseInsensitive: true,
                },
                order: [],
            });
        } catch (e) {
            toast.error("Something went wrong");
            hideLoader()
        }
    }

    render() {
        return (
            <Layout active_menu={'dash'} user_state={'personal'} context={this.context}>
                <div className="section">
                    <div className="breadcrumb">
                        <Link to="/"> <span className="fa fa-chevron-left"/> Dashboard</Link>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="alert alert-orange">
                                Note! Pending transactions will be automatically removed 7 days after order was made.
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="float-left">Pending Transactions</h4>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <div id="example_wrapper"
                                             className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table
                                                        className="table table-striped table-bordered border-t0 text-nowrap w-100 dataTable no-footer"
                                                        role="grid" aria-describedby="example_info">
                                                        <thead>
                                                        <tr role="row">
                                                            <th className="wd-15p sorting_asc" tabIndex="0"
                                                                aria-controls="example" rowSpan="1" colSpan="1"
                                                                aria-sort="ascending"
                                                                aria-label="First name: activate to sort column descending"
                                                                style={{width: "113px"}}>Investment
                                                            </th>
                                                            <th className="wd-15p sorting" tabIndex="0"
                                                                aria-controls="example"
                                                                rowSpan="1" colSpan="1"
                                                                aria-label="Last name: activate to sort column ascending"
                                                                style={{width: "113px"}}>Amount to Pay
                                                            </th>
                                                            <th className="wd-20p sorting" tabIndex="0"
                                                                aria-controls="example"
                                                                rowSpan="1" colSpan="1"
                                                                aria-label="Position: activate to sort column ascending"
                                                                style={{width: "113px"}}>Purchased Units
                                                            </th>
                                                            <th className="wd-20p sorting" tabIndex="0"
                                                                aria-controls="example"
                                                                rowSpan="1" colSpan="1"
                                                                aria-label="Position: activate to sort column ascending"
                                                                style={{width: "113px"}}>ROI
                                                            </th>
                                                            <th className="wd-20p sorting" tabIndex="0"
                                                                aria-controls="example"
                                                                rowSpan="1" colSpan="1"
                                                                aria-label="Position: activate to sort column ascending"
                                                                style={{width: "113px"}}>Reference Code
                                                            </th>
                                                            <th className="wd-20p sorting" tabIndex="0"
                                                                aria-controls="example"
                                                                rowSpan="1" colSpan="1"
                                                                aria-label="Position: activate to sort column ascending"
                                                                style={{width: "113px"}}>Date | Time
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {this.state.orders.map(item => (
                                                            <tr key={item.id}>
                                                                <td>{item.investment.title}</td>
                                                                <td>₦{parseFloat(item.amount).toLocaleString()}</td>
                                                                <td>{parseFloat(item.purchased_units)}</td>
                                                                <td>{parseFloat(item.roi)}%/Annum</td>
                                                                <td>{item.ref_code}</td>
                                                                <td><Moment
                                                                    format="MMM D Y HH:mm">{item.created_at}</Moment>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default PendingTransaction;
