import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Moment from "react-moment";
import moment from 'moment';
import Layout from "../Layout";
import NumberFormat from "react-number-format";
import {hideLoader, mixpanel, showLoader, toast, trackMixpanel, Utils} from "../../_helpers/Loader";
import API from "../../api";
import $ from "jquery";
import {Context} from "../MyContext";
import {PAYSTACK_publicKey} from "../../env";

const PaystackPop = window.PaystackPop;

class RecurringInvestment extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            investment: {},
            payments: [],
            amount_paid: 0,
            reauthorise_attempt: 0,
            fractional_records: [],
        }
    }

    async componentDidMount() {
        trackMixpanel('RecurringInvestmentsView', false, false, this.context.user.email)
        showLoader();
        await this.getInvestment();
        await this.getStats();
        hideLoader();
    }

    componentWillMount() {
        if (this.context.user.completed_onboarding === null || this.context.user.completed_onboarding === undefined) {
            window.location.reload();
        }
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
    }


    getStats = async () => {
        try {
            let res = await API.get('my_investments/' + this.props.match.params.id + '/get_investment_history/');
            if (this.state.investment.investment.club_tag) {
                let amount_paid = 0;
                let reauthorise_attempt = 0
                res.data.map(item => {
                    amount_paid += parseFloat(item.amount)
                    reauthorise_attempt = parseInt(item.reauthorise_attempt)
                })
                this.setState({payments: res.data, amount_paid: amount_paid, reauthorise_attempt: reauthorise_attempt});
            } else {
                let amount_paid = 0;
                res.data.map(item => {
                    amount_paid += parseFloat(item.amount)
                })
                this.setState({fractional_records: res.data, amount_paid: amount_paid})
            }
        } catch (e) {
            toast.error("Something Went Wrong");
            // console.log(e.response);
        }
    };

    async getInvestment() {
        try {
            let res = await API.get(`my_investments/${this.props.match.params.id}/`);
            this.setState({investment: res.data})
        } catch (e) {
            // console.log(e.response);
        }
    }

    payWithCard = () => {

        const script = document.createElement("script");

        script.src = "https://js.paystack.co/v1/inline.js";
        script.async = true;

        document.body.appendChild(script);
        var handler = PaystackPop.setup({
            key: PAYSTACK_publicKey,
            email: this.context.user.email,
            amount: 25 * 100,
            currency: "NGN",
            ref: "authorize-" + Math.floor((Math.random() * 1000000000) + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
            metadata: {
                custom_fields: [
                    {
                        display_name: "First Name",
                        variable_name: "firstname",
                        value: this.context.user.first_name,
                    }, {
                        display_name: "Last Name",
                        variable_name: "lastname",
                        value: this.context.user.last_name,
                    }, {
                        display_name: "Customer Number",
                        variable_name: "customer_phone",
                        value: this.context.user.phone,
                    },
                ]
            },
            callback: (response) => {
                response.profile_type = 'individual';
                showLoader();
                if (
                    response.status === "success"
                ) {
                    // redirect to a success page
                    API.post('my_investments/' + this.props.match.params.id + '/reauthorise/', response).then((res) => {
                        hideLoader();
                        toast.success("Successfully completed reauthorization");
                    }, function (err) {
                        hideLoader();
                        toast.error("Something Went Wrong")
                    })

                } else {
                    // redirect to a failure page.
                    hideLoader();
                    toast.error("Payment Error.")
                }
            },
            onClose: function () {
                toast.info("Payment Cancelled");
                hideLoader();
            }
        });
        handler.openIframe();
    };

    confirmPassword = async (event) => {
        event.preventDefault();
        const postData = {
            'password': event.target.password.value
        };
        try {
            let res = await API.post(`users/confirm_password/`, postData);
            this.payWithCard();
            $('.modal').modal('hide');
            $(document.body).removeClass('modal-open');
            $('.modal-backdrop').remove();
        } catch (e) {
            toast.error("Password not correct");
        }
    }

    withdrawToWallet = async (event) => {
        showLoader()
        event.preventDefault();
        const postData = {
            payout: event.target.payout.value,
            id: this.state.investment.id
        };
        try{
            let res = await API.post(`risevest/payout_to_wallet_vest/`, postData);
            if (res.status == 200 || res.status == 201){
                this.getInvestment()
                $('.modal').modal('hide');
                $(document.body).removeClass('modal-open');
                $('.modal-backdrop').remove();
                toast.success("fund withdrawn to wallet successfully");
            }
            hideLoader();
        } catch(e){
            hideLoader();
            toast.error("Error while withdrawing to wallet");
        }
    }

    interestMaturity = (start, maturity, amount, roi) => {
        let intPerAnnum = parseFloat(roi) * .01 / 365
        return [parseFloat(intPerAnnum * (moment(maturity).diff(moment(start), 'days')) * parseFloat(amount)).toFixed(2), parseFloat(intPerAnnum * (moment(new Date()).diff(moment(start), 'days')) * parseFloat(amount)).toFixed(2)];
    }

    fractionalPage() {
        return (
            <div className="card-body">
                <div className="row">
                    <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                        <div className="card bg-blue-gray text-blue">
                            <div className="card-body">
                                <div className="row">
                                    <i className="text-blue fa fa-bar-chart"/>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="mt-3 text-center">
                                                                <span
                                                                    className="text-blue"><strong>Total Investment Amount</strong></span>
                                            <h2 className="text-blue mb-0">{Utils.currencyToSym(this.state.investment.currency)}{(parseFloat(this.state.investment.investment.unit_price) * parseFloat(this.state.investment.investment.units)).toLocaleString()}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                        <div className="card bg-blue-gray text-blue">
                            <div className="card-body">
                                <div className="row">
                                    <i className="text-blue fa fa-reply-all"/>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-blue"><strong>Total Amount Invested</strong></span>
                                            <h2 className="text-blue mb-0">{Utils.currencyToSym(this.state.investment.currency)}{parseFloat(this.state.investment.expected_return).toLocaleString()}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                        <div className="card bg-blue-gray text-blue">
                            <div className="card-body">
                                <div className="row">
                                    <i className="text-blue fa fa-signal"/>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-blue"><strong>Percentage</strong></span>
                                            <h2 className="text-blue mb-0">{parseFloat(this.state.investment.get_percentage_stake).toLocaleString()}%</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                        <div className="card bg-blue-gray text-blue">
                            <div className="card-body">
                                <div className="row">
                                    <i className="text-blue fa fa-line-chart"/>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-blue"><strong>Total Amount Paid</strong></span>
                                            <h2 className="text-blue mb-0">{Utils.currencyToSym(this.state.investment.currency)}{parseFloat(this.state.amount_paid).toLocaleString()}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <div id="example_wrapper"
                         className="dataTables_wrapper dt-bootstrap4 no-footer">
                        <div className="row">
                            <div className="col-sm-12">
                                <table
                                    className="table table-striped table-bordered border-t0 text-nowrap w-100 dataTable no-footer"
                                    role="grid" aria-describedby="example_info">
                                    <thead>
                                    <tr role="row">
                                        <th className="wd-15p sorting_asc" tabIndex="0"
                                            aria-controls="example" rowSpan="1" colSpan="1"
                                            aria-sort="ascending"
                                            aria-label="First name: activate to sort column descending"
                                            style={{width: "113px"}}>Total Amount Paid
                                        </th>
                                        <th className="wd-15p sorting" tabIndex="0"
                                            aria-controls="example"
                                            rowSpan="1" colSpan="1"
                                            aria-label="Last name: activate to sort column ascending"
                                            style={{width: "113px"}}>Amount Paid to You
                                        </th>
                                        <th className="wd-20p sorting" tabIndex="0"
                                            aria-controls="example"
                                            rowSpan="1" colSpan="1"
                                            aria-label="Position: activate to sort column ascending"
                                            style={{width: "231.4px"}}>Reference Number
                                        </th>
                                        <th className="wd-20p sorting" tabIndex="0"
                                            aria-controls="example"
                                            rowSpan="1" colSpan="1"
                                            aria-label="Position: activate to sort column ascending"
                                            style={{width: "231.4px"}}> Date Paid
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.fractional_records.map(item => (
                                        <tr key={item.id}>
                                            <td>{Utils.currencyToSym(this.state.investment.currency)}{parseFloat(item.total_amount_paid.total_amount).toLocaleString()}</td>
                                            <td>₦{parseFloat(item.amount).toLocaleString()}</td>
                                            <td>{item.ref_code}</td>
                                            <td><Moment
                                                format="MMM D, Y">{item.created_at}</Moment>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    recurringPage() {
        return (
            <div className="card-body">
                <div className="row">
                    <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                        <div className="card bg-blue-gray text-blue">
                            <div className="card-body">
                                <div className="row">
                                    <i className="text-blue fa fa-bar-chart"/>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="mt-3 text-center">
                                                                <span
                                                                    className="text-blue"><strong>Total Amount Invested</strong></span>
                                            <h2 className="text-blue mb-0">{Utils.currencyToSym(this.state.investment.currency)}{parseFloat(this.state.investment.amount).toLocaleString()}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                        <div className="card bg-blue-gray text-blue">
                            <div className="card-body">
                                <div className="row">
                                    <i className="text-blue fa fa-reply-all"/>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-blue"><strong>Expected Return (ROI)</strong></span>
                                            <h2 className="text-blue mb-0">{Utils.currencyToSym(this.state.investment.currency)}{parseFloat(this.state.investment.expected_return).toLocaleString()}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                        <div className="card bg-blue-gray text-blue">
                            <div className="card-body">
                                <div className="row">
                                    <i className="text-blue fa fa-reply-all"/>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-blue"><strong>Return (ROI)%</strong></span>
                                            <h2 className="text-blue mb-0">{parseFloat(this.state.investment.roi).toLocaleString() * parseFloat(this.state.investment.purchased_units)}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                        <div className="card bg-blue-gray text-blue">
                            <div className="card-body">
                                <div className="row">
                                    <i className="text-blue fa fa-signal"/>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-blue"><strong>Amount Paid</strong></span>
                                            <h2 className="text-blue mb-0">{Utils.currencyToSym(this.state.investment.currency)}{parseFloat(this.state.amount_paid).toLocaleString()}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                        <div className="card bg-blue-gray text-blue">
                            <div className="card-body">
                                <div className="row">
                                    <i className="text-blue fa fa-line-chart"/>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-blue"><strong>Amount to Pay</strong></span>
                                            <h2 className="text-blue mb-0">₦{(parseFloat(this.state.investment.amount) - parseFloat(this.state.amount_paid)).toLocaleString()}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="table-responsive">
                    <div id="example_wrapper"
                         className="dataTables_wrapper dt-bootstrap4 no-footer">
                        <div className="row">
                            <div className="col-sm-12">
                                <table
                                    className="table table-striped table-bordered border-t0 text-nowrap w-100 dataTable no-footer"
                                    role="grid" aria-describedby="example_info">
                                    <thead>
                                    <tr role="row">
                                        <th className="wd-15p sorting_asc" tabIndex="0"
                                            aria-controls="example" rowSpan="1" colSpan="1"
                                            aria-sort="ascending"
                                            aria-label="First name: activate to sort column descending"
                                            style={{width: "113px"}}>Name
                                        </th>
                                        <th className="wd-15p sorting" tabIndex="0"
                                            aria-controls="example"
                                            rowSpan="1" colSpan="1"
                                            aria-label="Last name: activate to sort column ascending"
                                            style={{width: "113px"}}>Amount
                                        </th>
                                        <th className="wd-20p sorting" tabIndex="0"
                                            aria-controls="example"
                                            rowSpan="1" colSpan="1"
                                            aria-label="Position: activate to sort column ascending"
                                            style={{width: "231.4px"}}>Reference Number
                                        </th>
                                        <th className="wd-20p sorting" tabIndex="0"
                                            aria-controls="example"
                                            rowSpan="1" colSpan="1"
                                            aria-label="Position: activate to sort column ascending"
                                            style={{width: "231.4px"}}>{['real estate fund', 'risevest', 'rise vest'].includes(this.state.investment.investment.investment_type) ? "Payout Date" : "Date Paid"}
                                        </th>
                                        <td>Status</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {console.log(this.state.investment.investment.payout_dates[0], "this.state.investment.payout_dates")}
                                        {console.log(this.state.investment.investment.payout_date_percentages, "this.state.investment.payout_date_percentages")}
                                        {this.state.payments.length > 0 ? (
                                            this.state.payments.map(item => (
                                                <tr key={item.id}>
                                                    <td>{this.state.investment.investment.title} {/* by {this.state.investment.company} */}</td>
                                                    <td>{Utils.currencyToSym(this.state.investment.currency)}{parseFloat(item.amount).toLocaleString()} {this.state.investment.payout_dates}</td>
                                                    <td>{item.ref_code}</td>
                                                    <td><Moment
                                                        format="MMM D, Y">{item.created_at}</Moment>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : this.state.investment.investment.payout_dates.length > 0 ?  (
                                            this.state.investment.investment.payout_dates.map((item, index) => {
                                                if ((this.state.investment.investment.payout_dates.length - 1) == index){
                                                    return(
                                                        <tr key={item.id}>
                                                            <td>{this.state.investment.investment.title} by {this.state.investment.company}</td>
                                                            <td>{Utils.currencyToSym(this.state.investment.currency)}{parseFloat(this.state.investment.amount * parseFloat((this.state.investment.investment.payout_date_percentages[index] / 100)) + parseFloat(this.state.investment.amount)).toLocaleString()}</td>
                                                            <td>{item.ref_code}</td>
                                                            <td><Moment
                                                                format="MMM D, Y">{this.state.investment.payout_dates[index]}</Moment>
                                                            </td>
                                                            <td>{this.state.investment?.paid_times == index + 1 ? "Paid": "Not Paid"}</td>
                                                        </tr>
                                                    ) 
                                                } else{
                                                    return(
                                                        <tr key={item.id}>
                                                            <td>{this.state.investment.investment.title} by {this.state.investment.company}</td>
                                                            <td>{Utils.currencyToSym(this.state.investment.currency)}{parseFloat(this.state.investment.amount * parseFloat((this.state.investment.investment.payout_date_percentages[index] / 100))).toLocaleString()}</td>
                                                            <td>{item.ref_code}</td>
                                                            <td><Moment
                                                                format="MMM D, Y">{this.state.investment?.payout_dates[index]}</Moment>
                                                            </td>
                                                            <td>{this.state.investment?.paid_times == index + 1 ? "Paid": "Not Paid"}</td>
                                                        </tr>
                                                    )
                                                }})
                                        ) : ("")}
                                    
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    risevestPage() {
        return (
            <div className="card-body">
                <div className="row">
                    <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                        <div className="card bg-blue-gray text-blue">
                            <div className="card-body">
                                <div className="row">
                                    <i className="text-blue fa fa-bar-chart"/>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="mt-3 text-center">
                                        <span
                                            className="text-blue"><strong>Total Unit Price</strong></span>
                                            <h2 className="text-blue mb-0">₦{parseFloat(this.state.investment.amount).toLocaleString()}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                        <div className="card bg-blue-gray text-blue">
                            <div className="card-body">
                                <div className="row">
                                    <i className="text-blue fa fa-reply-all"/>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="mt-3 text-center">
                                            <span
                                                className="text-blue"><strong>Expected Return</strong></span>
                                            <h2 className="text-blue mb-0">₦{parseFloat((this.state.investment.expected_return).toFixed(2).toLocaleString())}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                        <div className="card bg-blue-gray text-blue">
                            <div className="card-body">
                                <div className="row">
                                    <i className="text-blue text-blue fa fa-bar-chart"/>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="mt-3 text-center">
                                        <span
                                            className="text-blue"><strong>Interest at Maturity</strong></span>
                                            <h2 className="text-blue mb-0">₦{this.interestMaturity(this.state.investment.created_at, this.state.investment.maturity_date, this.state.investment.amount, this.state.investment.roi)[0]}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                        <div className="card bg-blue-gray text-blue">
                            <div className="card-body">
                                <div className="row">
                                    <i className="text-blue fa fa-reply-all"/>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="mt-3 text-center">
                                            <span
                                                className="text-blue"><strong>Interest to Date</strong></span>
                                            <h2 className="text-blue mb-0">₦{this.interestMaturity(this.state.investment.created_at, this.state.investment.maturity_date, this.state.investment.amount, this.state.investment.roi)[1]}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="table-responsive">
                    <div id="example_wrapper"
                        className="dataTables_wrapper dt-bootstrap4 no-footer">
                        <div className="row">
                            <div className="col-sm-12">
                                <table
                                    className="table table-striped table-bordered border-t0 text-nowrap w-100 dataTable no-footer"
                                    role="grid" aria-describedby="example_info">
                                    <thead>
                                    <tr role="row">
                                        <th className="wd-15p sorting_asc" tabIndex="0"
                                            aria-controls="example" rowSpan="1" colSpan="1"
                                            aria-sort="ascending"
                                            aria-label="First name: activate to sort column descending"
                                            style={{width: "113px"}}>Name
                                        </th>
                                        <th className="wd-15p sorting" tabIndex="0"
                                            aria-controls="example"
                                            rowSpan="1" colSpan="1"
                                            aria-label="Last name: activate to sort column ascending"
                                            style={{width: "113px"}}>Amount
                                        </th>
                                        <th className="wd-20p sorting" tabIndex="0"
                                            aria-controls="example"
                                            rowSpan="1" colSpan="1"
                                            aria-label="Position: activate to sort column ascending"
                                            style={{width: "231.4px"}}>Reference Number
                                        </th>
                                        <th className="wd-20p sorting" tabIndex="0"
                                            aria-controls="example"
                                            rowSpan="1" colSpan="1"
                                            aria-label="Position: activate to sort column ascending"
                                            style={{width: "231.4px"}}>Date Paid
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.payments.map(item => (
                                        <tr key={item.id}>
                                            <td>{this.state.investment.investment.title} by {this.state.investment.company}</td>
                                            <td>₦{parseFloat(item.amount).toLocaleString()}</td>
                                            <td>{item.ref_code}</td>
                                            <td><Moment
                                                format="MMM D, Y">{item.created_at}</Moment>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        if (this.state.investment.investment) {
            return (
                <Layout active_menu={'dash'} user_state={'personal'} context={this.context}>
                    <div className="section">
                        <div className="breadcrumb">
                            <Link to="/"> <span className="text-blue fa fa-chevron-left"/> Dashboard</Link>
                        </div>
                        <div className="row" id="investment">
                            <div className="col-md-12">
                                <div className="card">
                                    <div
                                        className="card-header">
                                        <h4 className="float-left">{this.state.investment.investment.title} by {this.state.investment.investment.company} <small><em>{this.state.investment.investment.in_trust ? `This investment is held in Trust by ${this.state.investment.investment.trustee}`: ""}</em></small></h4>
                                        <div className="float-right">
                                            {['real estate fund', 'risevest', 'rise vest'].includes(this.state.investment.investment.investment_type) || ['real estate fund', 'risevest', 'rise vest'].includes(this.state.investment.investment.partner.company_name.toLowerCase())  ? '' : (
                                            <button
                                                disabled={(this.state.reauthorise_attempt !== 0)}
                                                data-toggle="modal" data-target="#authoriseModal"
                                                className="btn btn-main float-right"><i
                                                className="text-blue fa fa-credit-card"/> Reauthorise
                                            </button>)}

                                            {['risevest', 'rise vest'].includes(this.state.investment.investment.partner.company_name.toLowerCase()) && new Date(this.state.investment.investment.closing_date) > new Date()  && this.state.investment.payout_status != "paid" ? (
                                            <button
                                                disabled={(new Date(this.state.investment.investment.closing_date) < new Date())}
                                                data-toggle="modal" data-target="#liquidate"
                                                className="btn btn-main ml-2"><i
                                                className="text-blue fa fa-credit-card"/> Liquidate
                                            </button>):("")}
                                        </div>
                                    </div>             
                                    { ['risevest', 'rise vest'].includes(this.state.investment.investment.partner.company_name.toLowerCase()) ? this.risevestPage() : ['real estate', 'rental income'].includes(this.state.investment.investment.investment_type) ? (
                                        this.fractionalPage()
                                    ) : (
                                        this.recurringPage()
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="authoriseModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header bg-green">
                                    <h5 className="modal-title" id="example-Modal3">Confirm Reauthorisation</h5>
                                    <button type="button"
                                            className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <form onSubmit={event => this.confirmPassword(event)}>
                                    <div className="modal-body">
                                        <p className="text-danger">Note: <strong>You can only change your card once for
                                            this investment.</strong></p>
                                        <p className="text-danger"><strong>You will be charged ₦25</strong></p>
                                        <div className="form-group mb-0">
                                            <label htmlFor="message-text"
                                                   className="form-control-label">Enter your password to
                                                continue:</label>
                                            <input name="password" type="password" className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button"
                                                className="btn btn-outline-green btn-sm" data-dismiss="modal">Close
                                        </button>
                                        <button type="submit" className="btn btn-sm btn-main"><i
                                            className="text-blue fa fa-user-lock"/> Confirm
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div id="liquidate" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header bg-green">
                                    <h5 className="modal-title" id="example-Modal3">Liquidate</h5>
                                    <button type="button"
                                            className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <form onSubmit={event => this.withdrawToWallet(event)}>
                                    <input name="payout" type="hidden" value={this.interestMaturity(this.state.investment.created_at, this.state.investment.maturity_date, this.state.investment.amount, this.state.investment.roi)[1]} />
                                    <div className="modal-body">
                                        <p className="text-danger">Note: <strong>A penalty of {this.state.investment.investment.liquidation_penalty}% will be deducted from your accrued interest of ₦{this.interestMaturity(this.state.investment.created_at, this.state.investment.maturity_date, this.state.investment.amount, this.state.investment.roi)[1]}</strong></p>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button"
                                                className="btn btn-outline-green btn-md" data-dismiss="modal">Close
                                        </button>
                                        <button type="submit" className="btn btn-md btn-main"><i
                                            className="text-blue fa fa-user-lock" /> Proceed
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </Layout>
            );
        } else {
            return (<div>

            </div>)
        }
    }
}

export default RecurringInvestment;
