import React, {Component} from 'react';
import Layout from "../Layout";
import {Context} from "../MyContext";
import Moment from 'react-moment';
import {showLoader, hideLoader, toast, Utils, mixpanel, trackMixpanel} from "../../_helpers/Loader";
import API from "../../api";
import NumberFormat from "react-number-format";
import $ from "jquery";

class ClubWallet extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            wallet_history: [],
            total_amount_spent: 0,
            total_amount_credit: 0,
            account_detail: {},
            monnify_status: {},
            form_amount: '',
            otp: '',
            balance: 0,
            withdraw_button: true,
            withdraw_amount: '',
            withdraw_page: 1,
        }
    }

    async componentDidMount() {
        trackMixpanel('ClubWalletView', false, false, this.context.user.email)
        showLoader()
        await this.getWallet();
        await this.getWalletHistory();
        hideLoader()
    }

    checkAdmin(item) {
        if (item !== undefined) {
            for (let i = 0; i < item.length; i++) {
                if (item[i].user.id === this.context.user.id && item[i].user_role === 'admin') {
                    return true;
                }
            }
            return false
        } else {
            return false
        }
    }

    async getWalletHistory() {
        try {
            let spent_count = 0;
            let credit_count = 0;
            let res = await API.get(`club_wallet/${this.props.match.params.id}/wallet_history/`);
            this.setState({wallet_history: res.data});
            res.data.map(item => {
                if (item.status.toLowerCase() === 'debit' && item.withdrawn === false) {
                    spent_count = spent_count + parseFloat(item.amount)
                }else if(item.status.toLowerCase() === 'credit' && item.withdrawn === false){
                    credit_count = credit_count + parseFloat(item.amount)
                }
            });
            this.setState({total_amount_spent: spent_count, total_amount_credit: credit_count})
        } catch (e) {
            toast.error("Something Went Wrong");
            hideLoader();
            // console.log(e.response)
        }
    }

    async getWallet() {
        try {
            let res = await API.get(`club_wallet/${this.props.match.params.id}/get_my_wallet/`);
            this.setState({
                balance: (res.data.length > 0) ? res.data[0].current_balance : 0,
            });
            if (res.data.length > 0) {
                if (res.data[0].account_details.length > 0) {
                    this.setState({account_detail: res.data[0].account_details[0]})
                }
            }
        } catch (e) {
            if (e.response.status === 503) {
                this.setState({monnify_status: false})
            } else {
                toast.error("Something Went Wrong");
            }
            // console.log(e.response);
        }
    }

    async withdrawWallet(e) {
        e.preventDefault();
        showLoader();
        try {
            const res = await API.post(`club_wallet/${this.props.match.params.id}/process_withdraw_otp/`, {amount: this.state.withdraw_amount})
            hideLoader();
            toast.success(res.data.detail)
            this.setState({withdraw_page: 2})
        } catch (e) {
            if(e){
                toast.error(e.response.data['detail'] || e.response.data['message']); 
            } else {
                toast.error("Withdrawal could not be processed at this time. Kindly contact admin")
            }
            hideLoader();
        }
    }

    async otpProcess(e) {
        e.preventDefault();
        showLoader();
        try {
            const res = await API.post(`club_wallet/${this.props.match.params.id}/withdraw_wallet/`, {otp: this.state.otp});
            toast.success("Withdrawal has been sent for processing");
            this.getWallet();
            this.getWalletHistory();
            $('.modal').modal('hide');
            $(document.body).removeClass('modal-open');
            $('.modal-backdrop').remove();
            this.setState({otp: '', withdraw_amount: ''})
            hideLoader();
        } catch (e) {
            if (e.response !== undefined) {
                if (e.response.status === 406) {
                    toast.error(e.response.data['detail']);
                    if (e.response.data['detail'] === 'You don\'t have any account information') {
                        let url = `/club/add-account-only/${this.props.match.params.id}`
                        setTimeout(function () {
                            toast.info("Add an account");
                            window.location.href = url;
                        }, 3000)
                    }
                }
            }
            toast.error("Withdrawal could not be processed at this time. Kindly contact admin")
            hideLoader();
        }
    }

    withdrawCharges(amount) {
        if (amount === '' || amount === 0) {
            return 0
        } else {
            return parseFloat(amount) + 50
        }
    }

    setWithdrawAmount(value) {
        if (parseFloat(value) + 50 <= parseFloat(this.state.balance)) {
            this.setState({withdraw_button: false, withdraw_amount: value})
        } else {
            this.setState({withdraw_button: true, withdraw_amount: value})
        }
    }

    render() {
        return (
            <Layout active_menu={'settings'} user_state={this.props.match.params.id} context={this.context}>
                <div className="section">
                    <div className="breadcrumb">
                        <a onClick={event => window.history.back()}> <span
                            className="fa fa-chevron-left"/> Back</a>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="float-left">Club Wallet</h4>
                                    <button onClick={event => this.setState({withdraw_page: 1, withdraw_amount: ''})}
                                            data-toggle="modal"
                                            data-target="#withdrawModal"
                                            className="btn m-b-5 btn-blue text-white ml-3 float-right"> Withdraw
                                    </button>
                                </div>
                                <div className="card-body" id="table-section">
                                    <div className="row">
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <i className="fa fa-wallet text-blue"/>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                            <span
                                                                className="text-blue"><strong>Total Current Balance</strong></span>
                                                                <h2 className="text-blue mb-0">{Utils.Currency(this.state.balance)}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <i className="fa fa-money text-blue"/>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                    <span
                                                                        className=" text-blue"><strong>Total Amount Spent</strong></span>
                                                                <h2 className=" text-blue mb-0">{Utils.Currency(this.state.total_amount_spent)}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <i className="fa fa-money text-blue"/>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-blue"><strong>Total Amount Credited</strong></span>
                                                                <h2 className="text-blue mb-0">₦{parseFloat(this.state.total_amount_credit).toLocaleString()}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6">
                                            <div id="example_filter" className="dataTables_filter"><label><input
                                                type="search" className="form-control form-control-sm"
                                                placeholder="Search"
                                                aria-controls="example"
                                                onKeyPress={event => {
                                                    if (event.key === 'Enter') {
                                                        this.handleSearch(event)
                                                    }
                                                }}/></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-responsive-xl table-striped mb-0 text-nowrap">
                                            <tbody>
                                            <tr>
                                                <th>Member</th>
                                                <th>Reference Number</th>
                                                <th>Amount</th>
                                                <th>Status</th>
                                                <th>Balance</th>
                                                <th>Date &#8212; Time</th>
                                            </tr>
                                            {this.state.wallet_history.map(item => (
                                                <tr key={item.id}>
                                                    <td>{(item.user === null) ? 'Twelve Admin' : item.user}</td>
                                                    <td>{item.ref_code}</td>
                                                    <td>{Utils.Currency(item.amount)}</td>
                                                    <td>{(item.status.toLowerCase() === 'credit') ? (
                                                        <span className="badge bg-green m-b-5">Credit</span>
                                                    ) : (item.status.toLowerCase() === 'debit') ? (
                                                        <span className="badge bg-yellow m-b-5">Debit</span>
                                                    ) : (item.status.toLocaleString() === 'processing') ? (
                                                        <span className="badge badge-primary m-b-5">Processing</span>
                                                    ) : (
                                                        <span className="badge badge-danger m-b-5">Failed</span>
                                                    )}
                                                    </td>
                                                    <td>{Utils.Currency(item.balance)}</td>
                                                    <td><Moment
                                                        format="MMM D, Y &#8212; HH:mm">{item.created_at}</Moment>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/*<div*/}
                                {/*    className="card-footer">{console.log("<<", this.paginationHelper(this.state.num_pages, this.state.current_page))}*/}
                                {/*    <div className="d-flex justify-content-center">*/}
                                {/*        <ul className="pagination mt-3 mb-0">*/}
                                {/*            <li className={`${(this.state.current_page !== 1) ? '' : 'disabled'} page-item`}>*/}
                                {/*                <a className="page-link"*/}
                                {/*                   onClick={event => this.getPageData(this.state.current_page - 1)}>‹</a>*/}
                                {/*            </li>*/}
                                {/*            {this.paginationHelper(this.state.num_pages, this.state.current_page).map(item => (*/}
                                {/*                <li className={`${((item) === this.state.current_page) ? 'active' : ''} page-item`}>*/}
                                {/*                    {(item === '...') ? (*/}
                                {/*                        <a className="page-link disabled">{item}</a>*/}
                                {/*                    ) : (*/}
                                {/*                        <a className="page-link"*/}
                                {/*                           onClick={event => this.getPageData(item)}>{item}</a>*/}
                                {/*                    )}*/}
                                {/*                </li>*/}
                                {/*            ))}*/}
                                {/*            <li className={`${(this.state.current_page !== this.state.num_pages) ? '' : 'disabled'} page-item`}>*/}
                                {/*                <a className="page-link"*/}
                                {/*                   onClick={event => this.getPageData(this.state.current_page + 1)}>»</a>*/}
                                {/*            </li>*/}
                                {/*            <span className="my-auto ml-3 mr-3"> Upto </span>*/}
                                {/*            <li className={`${(this.state.current_page !== this.state.num_pages) ? '' : 'disabled'} page-item`}>*/}
                                {/*                <a className="page-link"*/}
                                {/*                   onClick={event => this.getPageData(this.state.num_pages)}>{this.state.num_pages}</a>*/}
                                {/*            </li>*/}
                                {/*        </ul>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
                <div id="withdrawModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h4 className="modal-title" id="example-Modal3">Withdraw from wallet</h4>
                                <button onClick={event => this.setState({form_amount: ''})} id="close-modal"
                                        type="button"
                                        className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {(this.state.withdraw_page === 1) ? (
                                <form onSubmit={event => this.withdrawWallet(event)}>
                                    <div className="modal-body">
                                        <div className="form-group mb-0">
                                            <label htmlFor="message-text"
                                                   className="form-control-label">Amount:</label>
                                            <NumberFormat min="50" className="form-control"
                                                          value={this.state.withdraw_amount}
                                                          thousandSeparator={true}
                                                          prefix={'₦'} onValueChange={(values) => {
                                                const {formattedValue, value} = values;
                                                this.setWithdrawAmount(value)
                                            }}/>
                                        </div>
                                        <p className="mt-2">Total amount including payment charge:
                                            ₦{parseFloat(this.withdrawCharges(this.state.withdraw_amount)).toLocaleString()}</p>
                                    </div>
                                    <div className="modal-footer">
                                        <div className="col-lg-12 mx-auto text-center">
                                            <button onClick={event => this.setState({
                                                form_amount: '',
                                                otp: '',
                                                withdraw_button: true,
                                                withdraw_amount: ''
                                            })}
                                                    type="button"
                                                    className="btn btn-outline-green mr-3"
                                                    data-dismiss="modal">Close
                                            </button>
                                            <button disabled={this.state.withdraw_button} type="submit"
                                                    onClick={event => this.setState({otp: '', withdraw_page: 1})}
                                                    className="btn btn-main">Withdraw <i
                                                className="fas fa-money-bill"/></button>
                                        </div>
                                    </div>
                                </form>
                            ) : (
                                <form onSubmit={event => {
                                        this.otpProcess(event)
                                        this.setState({withdraw_button: true})
                                    }}>
                                    <div className="modal-body">
                                        <div className="form-group mb-0">
                                            <label htmlFor="message-text"
                                                   className="form-control-label">Enter OTP:</label>
                                            <input value={this.state.otp}
                                                   onChange={event => this.setState({otp: event.target.value})}
                                                   type="text" className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <div className="col-lg-12 mx-auto text-center">
                                            <button onClick={event => this.setState({
                                                form_amount: '',
                                                otp: '',
                                                withdraw_button: true,
                                                withdraw_amount: ''
                                            })}
                                                    type="button"
                                                    className="btn btn-outline-green mr-3"
                                                    data-dismiss="modal">Close
                                            </button>
                                            <button disabled={this.state.withdraw_button} 
                                            type="submit"
                                                    className="btn btn-main">Confirm <i
                                                className="fas fa-money-bill"/></button>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default ClubWallet;
