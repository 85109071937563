import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Layout from "../Layout";
import API from '../../api';
import {Context} from "../MyContext";
import {userContext} from "../../_helpers/userContext";
import {hideLoader, showLoader, toast, trackMixpanel} from "../../_helpers/Loader";
import {countries} from "../../_helpers/appVariables";
import uuidv1 from 'uuid';
import $ from 'jquery';
import IdentificationView from './Identification';
import {Row, Col} from 'react-bootstrap';

const mainUser = userContext;

class Profile extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            first_name: '',
            last_name: '',
            phone_number: '',
            address: '',
            next_of_kin_name: '',
            next_of_kin_phone: '',
            bank_list: [],
            account_name: '',
            account_number: '',
            account_bank: '',
            add_usd: false,
            usd_country: 'NG',
            usd_account_name: '',
            usd_account_number: '',
            usd_bank_name: '',
            usd_correspondence_bank_address: '',
            usd_correspondence_bank: '',
            usd_beneficiary_address: '',
            usd_bank_address: '',
            usd_iban_number: '',
            usd_routing_number: '',
            usd_institution_number: '',
            usd_transit_number: '',
            usd_swift_code: '',
            usd_currency: '',
            bank_code: '',
            button_state: 'Retrieve Account Detail',
            redirect_url: null,
            account_changed: true,

            otp: '',

            fields: {
                first_name: true,
                last_name: true,
                phone: true,
                address: true,
                next_name: true,
                next_phone: true,
                account_number: true,
                bank_code: true,
                usd_fields: true,
                button_page: 1,
            },
            bvn: null
        };

        this.updateAccount = this.updateAccount.bind(this);
        this.verifyBvn = this.verifyBvn.bind(this);
        this.imageUpload = this.imageUpload.bind(this);
    }

    async componentDidMount() {
        trackMixpanel('ProfileView', false, false, this.context.user.email)
        if (this.props.location.query) {
            this.setState({redirect_url: this.props.location.query.back_url})
        }
        showLoader();
        await this.getBanks();
        await this.getAccountDetails();
        hideLoader();
    }

    componentWillMount() {
        if (this.context.user.completed_onboarding === null || this.context.user.completed_onboarding === undefined) {
            window.location.reload();
        }
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
        this.setState({user: this.context.user});
    }

    async getBanks() {
        try {
            let res = await API.get('https://api.paystack.co/bank');
            this.setState({bank_list: res.data.data});
        } catch (e) {
            toast.error("Something Went Wrong");
            // console.log(e.response);
        }
    }

    updateAccount = async (event) => {
        let postData = {
            first_name: this.state.user.first_name,
            last_name: this.state.user.last_name,
            phone: this.state.user.phone,
            address: this.state.user.address,
            next_of_kin_name: this.state.user.next_of_kin_name,
            next_of_kin_phone: this.state.user.next_of_kin_phone,
        };
        showLoader();
        try {
            let res = await API.put('users/' + this.context.user.id + '/', postData);
            trackMixpanel('UpdatedPersonalInformation', false, false, this.context.user.email)
            await this.context.updateUser();
            toast.success("User details successfully updated");
            this.setState({account_changed: false});
            (this.state.redirect_url == null) && hideLoader();
            if (this.checkFilled()) {
                hideLoader();
                if(!this.context.user.bvn_verified){
                    this.props.history.push("/profile")
                }
                (this.state.redirect_url == null) && this.props.history.push("/account");
                (this.state.redirect_url !== null) && setTimeout(() => this.props.history.push(this.state.redirect_url))
            }
        } catch (e) {
            toast.error("Something Went Wrong");
            hideLoader();
            // console.log(e.response);
        }
    };

    async imageUpload() {
        $("#img-upload").click();
        $(".profile-upload").on('change', async () => {
            const postData = new FormData();
            postData.append('file', $($('#img-upload'))[0].files[0]);
            showLoader();
            try {
                let res = await API.post('users/profile_photo/', postData, {headers: {'Content-Type': 'multipart/form-data'}});
                await this.context.updateUser();
                hideLoader();
                toast.success("Image Successfully Uploaded");
                this.props.history.push('/account');
            } catch (e) {
                // console.log(e.response);
            }
        });
    }

    checkFilled() {
        let count = 0, init = 0;
        let state = [this.state.user.first_name, this.state.user.address, this.state.user.last_name, this.state.user.phone,
            this.state.user.next_of_kin_name, this.state.user.next_of_kin_phone, this.state.account_name,
            this.state.account_number, this.state.account_bank];
        state.map(item => {
            count++;
            if (item !== "") {
                init++;
            } else {
                // console.log(item, this.state[item]);
            }
        });
        if (count === init) {
            return true
        } else {
            return false
        }
    }

    processForm = async (event) => {
        event.preventDefault();
        let postData = {
            account_number: this.state.account_number,
            bank_name: this.state.account_bank,
        };
        showLoader();
        if (this.state.button_state === 'Retrieve Account Detail') {
            await this.getAccountName(postData);
        } else if (this.state.button_state === "Update Information") {
            let main_bank = this.state.bank_list.filter(item => item.code === this.state.account_bank);
            postData.bank_name = main_bank[0].name;
            postData.bank_code = this.state.account_bank;
            postData.account_name = this.state.account_name;
            (this.state.account_changed) && await this.saveDetails(postData);
            await this.updateAccount(event);
        }
        hideLoader();
    };

    getAccountDetails = async () => {
        try {
            let res = await API.get('withdrawal_info/');
            if (res.data.length > 0) {
                let data = res.data.find(item => item.currency === "NGN");
                let usd_data = res.data.find(item => ["USD", "GBP", "EURO", "CAD"].includes(item.currency)) || null;
                this.setState({
                    account_name: data.account_name,
                    account_number: data.account_number,
                    account_bank: data.bank_code,
                    button_state: 'Update Information',
                })
                if (usd_data) {
                    this.setState({
                        add_usd: true,
                        usd_country: usd_data.country ? usd_data.country : 'NG',
                        usd_account_name: usd_data.account_name ? usd_data.account_name : '',
                        usd_account_number: usd_data.account_number ? usd_data.account_number : '',
                        usd_bank_name: usd_data.bank_name ? usd_data.bank_name : '',
                        usd_correspondence_bank_address: usd_data.correspondence_bank_address ? usd_data.correspondence_bank_address : '',
                        usd_correspondence_bank: usd_data.correspondence_bank ? usd_data.correspondence_bank : '',
                        usd_beneficiary_address: usd_data.beneficiary_address ? usd_data.beneficiary_address : '',
                        usd_bank_address: usd_data.bank_address ? usd_data.bank_address : '',
                        usd_iban_number: usd_data.iban_number ? usd_data.iban_number : '',
                        usd_routing_number: usd_data.routing_number ? usd_data.routing_number : '',
                        usd_institution_number: usd_data.institution_number ? usd_data.institution_number : '',
                        usd_transit_number: usd_data.transit_number ? usd_data.transit_number : '',
                        usd_swift_code: usd_data.swift_code ? usd_data.swift_code : '',
                        usd_currency: usd_data.currency ? usd_data.currency : ''
                    })
                }
            }

        } catch (e) {
            // console.log(e.response)
        }
    };

    getAccountName = async (postData) => {
        try {
            let res = await API.post("withdrawal_info/get_account_name/", postData);
            this.setState({account_name: res.data.account_name.toLowerCase(), button_state: 'Update Information'});
        } catch (e) {
            if (e.response.status === 400) {
                this.setState({account_name: '', button_state: 'Retrieve Data'});
                toast.error("Details didn't return a valid information");
            }
        }
    };

    saveDetails = async (postData) => {
        if (this.state.add_usd) {
            postData = {
                add_usd: this.state.add_usd,
                usd_country: this.state.usd_country,
                usd_account_name: this.state.usd_account_name,
                usd_account_number: this.state.usd_account_number,
                usd_account_bank: this.state.usd_account_bank,
                usd_correspondence_bank_address: this.state.usd_correspondence_bank_address,
                usd_correspondence_bank: this.state.usd_correspondence_bank,
                usd_bank_address: this.state.usd_bank_address,
                usd_iban_number: this.state.usd_iban_number,
                usd_bank_name: this.state.usd_bank_name,
                usd_beneficiary_address: this.state.usd_beneficiary_address,
                usd_routing_number: this.state.usd_routing_number,
                usd_institution_number: this.state.usd_institution_number,
                usd_transit_number: this.state.usd_transit_number,
                usd_swift_code: this.state.usd_swift_code,
                usd_currency: this.state.usd_currency,
                ...postData
            }
        }
        try {
            let res = await API.post('withdrawal_info/', postData);
            await this.context.updateUser();
            toast.success("User Account Details Successfully Updated");
            (this.state.redirect_url == null) && hideLoader();
            if (this.checkFilled()) {
                hideLoader();

                (this.state.redirect_url == null) && this.props.history.push("/account");
                (this.state.redirect_url !== null) && setTimeout(() => this.props.history.push(this.state.redirect_url))
            }
        } catch (e) {
            // toast.error("Something went wrong");
            // console.log(e.response)
        }
        hideLoader();
    };

    processOtp = async (e) => {
        e.preventDefault();
        showLoader();
        try {
            const res = await API.post('users/confirm_otp/', {otp: this.state.otp});
            this.setState({
                fields: {
                    first_name: false,
                    last_name: false,
                    phone: false,
                    address: false,
                    next_name: false,
                    next_phone: false,
                    account_number: false,
                    bank_code: false,
                    usd_fields: false,
                    button_page: 2,
                }
            })
            $('.modal').modal('hide');
            $(document.body).removeClass('modal-open');
            $('.modal-backdrop').remove();
            toast.success("OTP confirmation successful")
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    }

    requestOTP = async () => {
        showLoader();
        try {
            const res = await API.post('users/request_otp/');
            toast.success("Otp sent to your mail. Enter Otp to continue")
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    }


    dollarPage() {
        return (
            <div>
                <div className="form-group row">
                    <label
                        className="col-md-4 col-form-label">Country</label>
                    <div className="col-md-8">
                        <select disabled={this.state.fields.usd_fields}
                                onChange={event => this.setState({
                                    usd_country: event.target.value,
                                    usd_account_name: '',
                                    usd_account_number: '',
                                    usd_bank_name: '',
                                    usd_correspondence_bank_address: '',
                                    usd_correspondence_bank: '',
                                    usd_beneficiary_address: '',
                                    usd_bank_address: '',
                                    usd_iban_number: '',
                                    usd_routing_number: '',
                                    usd_institution_number: '',
                                    usd_transit_number: '',
                                    usd_swift_code: '',
                                    usd_currency: '',
                                    account_changed: true
                                })}
                                value={this.state.usd_country} className="form-control">
                            <option value="">--Select an option---</option>
                            {(countries().map(item => (
                                <option value={item.code}>{item.name}</option>
                            )))}
                        </select>
                    </div>
                </div>
                <div className="form-group row">
                    <label
                        className="col-md-4 col-form-label">Currency <sup className="text-danger">*</sup></label>
                    <div className="col-md-8">
                        <select required disabled={this.state.fields.usd_fields}
                               type="text"
                               value={this.state.usd_currency}
                               onChange={event => this.setState({
                                    usd_currency: event.target.value,
                                   account_changed: true
                               })}
                               className="form-control text-capitalize"
                        >
                            <option>Select Currency</option>
                            <option>GBP</option>
                            <option>USD</option>
                            <option>CAD</option>
                            <option>EURO</option>
                        </select>
                    </div>
                </div>
                <div className="form-group row">
                    <label
                        className="col-md-4 col-form-label">Bank Name <sup className="text-danger">*</sup></label>
                    <div className="col-md-8">
                        <input required disabled={this.state.fields.usd_fields}
                               type="text"
                               value={this.state.usd_bank_name}
                               onChange={event => this.setState({
                                   usd_bank_name: event.target.value,
                                   account_changed: true
                               })}
                               className="form-control text-capitalize"
                               placeholder=""/>
                    </div>
                </div>
                <div className="form-group row">
                    <label
                        className="col-md-4 col-form-label">Account Name <sup className="text-danger">*</sup></label>
                    <div className="col-md-8">
                        <input required disabled={this.state.fields.usd_fields}
                               type="text"
                               value={this.state.usd_account_name}
                               onChange={event => this.setState({
                                   usd_account_name: event.target.value,
                                   account_changed: true
                               })}
                               className="form-control text-capitalize"
                               placeholder=""/>
                    </div>
                </div>
                <div className="form-group row">
                    <label
                        className="col-md-4 col-form-label">Account Number <sup className="text-danger">*</sup></label>
                    <div className="col-md-8">
                        <input required disabled={this.state.fields.usd_fields}
                               type="text"
                               value={this.state.usd_account_number}
                               onChange={event => this.setState({
                                   usd_account_number: event.target.value,
                                   account_changed: true
                               })}
                               className="form-control"
                               placeholder=""/>
                    </div>
                </div>
                <div className="form-group row">
                    <label
                        className="col-md-4 col-form-label">Bank
                        address <sup className="text-danger">*</sup></label>
                    <div className="col-md-8">
                        <input required
                               type="text" disabled={this.state.fields.usd_fields}
                               value={this.state.usd_bank_address}
                               onChange={event => this.setState({
                                   usd_bank_address: event.target.value,
                                   account_changed: true
                               })}
                               className="form-control text-capitalize"
                               placeholder=""/>
                    </div>
                </div>
                {["NG"].includes(this.state.usd_country) || !["US", "CA", "AE"].includes(this.state.usd_country) ? (
                    <div className="form-group row">
                        <label
                            className="col-md-4 col-form-label">Correspondence Bank</label>
                        <div className="col-md-8">
                            <input required={(this.state.country === 'NG')}
                                   type="text" disabled={this.state.fields.usd_fields}
                                   value={this.state.usd_correspondence_bank}
                                   onChange={event => this.setState({
                                       usd_correspondence_bank: event.target.value,
                                       account_changed: true
                                   })}
                                   className="form-control text-capitalize"
                                   placeholder=""/>
                        </div>
                    </div>
                ) : ''}
                {["CA", "AE", "US"].includes(this.state.usd_country) || !["NG"].includes(this.state.usd_country) ? (
                    <div className="form-group row">
                        <label
                            className="col-md-4 col-form-label">Beneficiary
                            Address {["CA", "US"].includes(this.state.usd_country) ? (
                                <sup className="text-danger">*</sup>) : ''}</label>
                        <div className="col-md-8">
                            <input required={["CA", "US"].includes(this.state.usd_country)}
                                   type="text" disabled={this.state.fields.usd_fields}
                                   value={this.state.usd_beneficiary_address}
                                   onChange={event => this.setState({
                                       usd_beneficiary_address: event.target.value,
                                       account_changed: true
                                   })}
                                   className="form-control text-capitalize"
                                   placeholder=""/>
                        </div>
                    </div>
                ) : ''}
                {["US", "AE"].includes(this.state.usd_country) || !["CA", "NG"].includes(this.state.usd_country) ? (
                    <div className="form-group row">
                        <label
                            className="col-md-4 col-form-label">Routing
                            Number {["US"].includes(this.state.usd_country) ? (
                                <sup className="text-danger">*</sup>) : ''}</label>
                        <div className="col-md-8">
                            <input required={(this.state.country === "US")}
                                   type="text" disabled={this.state.fields.usd_fields}
                                   value={this.state.usd_routing_number}
                                   onChange={event => this.setState({
                                       usd_routing_number: event.target.value,
                                       account_changed: true
                                   })}
                                   className="form-control text-capitalize"
                                   placeholder=""/>
                        </div>
                    </div>

                ) : ''}
                {["NG", "US", "AE"].includes(this.state.usd_country) || !["CA"].includes(this.state.usd_country) ? (
                    <div className="form-group row">
                        <label
                            className="col-md-4 col-form-label">Swift
                            Code {["NG", "US"].includes(this.state.usd_country) ? (
                                <sup className="text-danger">*</sup>) : ''}</label>
                        <div className="col-md-8">
                            <input required={["NG", "US"].includes(this.state.usd_country)} type="text"
                                   disabled={this.state.fields.usd_fields}
                                   value={this.state.usd_swift_code}
                                   onChange={event => this.setState({
                                       usd_swift_code: event.target.value,
                                       account_changed: true
                                   })}
                                   className="form-control text-capitalize"
                                   placeholder=""/>
                        </div>
                    </div>
                ) : ''}
                {["AE"].includes(this.state.usd_country) ? (
                    <div className="form-group row">
                        <label
                            className="col-md-4 col-form-label">Iban Number {["AE"].includes(this.state.usd_country) ? (
                            <sup className="text-danger">*</sup>) : ''}</label>
                        <div className="col-md-8">
                            <input required={this.state.usd_country === "AE"} type="text"
                                   disabled={this.state.fields.usd_fields}
                                   value={this.state.usd_iban_number}
                                   onChange={event => this.setState({
                                       usd_iban_number: event.target.value,
                                       account_changed: true
                                   })}
                                   className="form-control text-capitalize"
                                   placeholder=""/>
                        </div>
                    </div>
                ) : ''}
                {["CA"].includes(this.state.usd_country) ? (
                    <div className="form-group row">
                        <label className="col-md-4 col-form-label">Institution Number</label>
                        <div className="col-md-8">
                            <input required={(this.state.usd_country === 'CA')}
                                   type="text" disabled={this.state.fields.usd_fields}
                                   maxLength={3}
                                   aria-valuemin={3}
                                   value={this.state.usd_institution_number}
                                   onChange={event => this.setState({
                                       usd_institution_number: event.target.value,
                                       account_changed: true
                                   })}
                                   className="form-control text-capitalize"
                                   placeholder=""/>
                        </div>
                    </div>
                ) : ''}
                <div className="form-group row">
                    <label
                        className="col-md-4 col-form-label">Transit Number <sup className="text-danger">(optional)</sup></label>
                    <div className="col-md-8">
                        <input disabled={this.state.fields.usd_fields}
                               type="text"
                               value={this.state.usd_transit_number}
                               onChange={event => this.setState({
                                    usd_transit_number: event.target.value,
                                    account_changed: true
                               })}
                               className="form-control"
                               placeholder=""/>
                    </div>
                </div>
            </div>
        )
    }

    async verifyBvn(event) {
        event.preventDefault();
        showLoader();
        let postData = {bvn: this.state.bvn};
        try {
            let res = await API.post('users/verify_bvn/', postData);
            if (res){
                toast.success("BVN verified Successfully");
                await this.context.updateUser();
                if (this.context.user.accounts.length == 0) {
                    toast.error("Please update your account number as well")
                    this.props.history.push("/profile");
                }
            }
            hideLoader();
        } catch (err) {
            toast.error("BVN could not be verified");
            hideLoader();
        }
    }

    render() {
        return (
            <Layout active_menu={'account'} user_state={'personal'} context={this.context}>
                <div className="section">
                    <div className="breadcrumb">
                        <Link to="/account"> <span className="fa fa-chevron-left"/> Settings</Link>
                    </div>
                    <div className="row">
                        <div className="col-lg-8 col-xl-8 col-md-12 col-sm-12 mx-auto">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4>Personal Profile</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-xl-12 col-lg-12">
                                            <div className="text-center">
                                                <div className="userprofile social">
                                                    <div className="img-p-container">
                                                        <div className="userpic">
                                                            <img onClick={this.imageUpload}
                                                                 src={(this.state.user.profile_photo) ? (this.state.user.profile_photo) : ('https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png')}
                                                                 alt="profile image"
                                                                 className="userpicimg img-square"/>
                                                        </div>
                                                        <div className="img-object">
                                                            <i className='fa fa-camera text-main'/>
                                                        </div>
                                                    </div>
                                                    <h3 className="username">{this.state.user.first_name} {this.state.user.last_name}</h3>
                                                    <p>{this.state.user.email}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <input id="img-upload" alt="image upload" className="profile-upload"
                                               type="file" accept="image/*"/>
                                        <div className="col-md-12 col-sm-12 col-xl-12 col-lg-12">
                                            <form onSubmit={event => this.processForm(event)}
                                                  className="form-horizontal">
                                                <hr/>
                                                <h3>Personal Information</h3>
                                                <hr/>
                                                <div className="form-group row">
                                                    <label htmlFor="inputName"
                                                           className="col-md-4 col-form-label">First Name</label>
                                                    <div className="col-md-8">
                                                        <input disabled={this.state.fields.first_name} required
                                                               value={this.state.user.first_name} type="text"
                                                               onChange={event => this.setState({
                                                                   user: {
                                                                       first_name: event.target.value,
                                                                       phone: this.state.user.phone,
                                                                       last_name: this.state.user.last_name,
                                                                       profile_photo: this.state.user.profile_photo,
                                                                       email: this.state.user.email,
                                                                       address: this.state.user.address,
                                                                       next_of_kin_name: this.state.user.next_of_kin_name,
                                                                       next_of_kin_phone: this.state.user.next_of_kin_phone
                                                                   }
                                                               })}
                                                               className="form-control"
                                                               placeholder="Name"/>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="inputName"
                                                           className="col-md-4 col-form-label">Last Name</label>
                                                    <div className="col-md-8">
                                                        <input disabled={this.state.fields.last_name} required
                                                               value={this.state.user.last_name} type="text"
                                                               onChange={event => this.setState({
                                                                   user: {
                                                                       first_name: this.state.user.first_name,
                                                                       phone: this.state.user.phone,
                                                                       last_name: event.target.value,
                                                                       profile_photo: this.state.user.profile_photo,
                                                                       email: this.state.user.email,
                                                                       address: this.state.user.address,
                                                                       next_of_kin_name: this.state.user.next_of_kin_name,
                                                                       next_of_kin_phone: this.state.user.next_of_kin_phone
                                                                   }
                                                               })}
                                                               className="form-control"
                                                               placeholder="Name"/>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="inputEmail3"
                                                           className="col-md-4 col-form-label">Email</label>
                                                    <div className="col-md-8">
                                                        <input disabled value={this.state.user.email} type="email"
                                                               className="form-control"
                                                               placeholder="Email"/>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="inputEmail3"
                                                           className="col-md-4 col-form-label">Phone Number</label>
                                                    <div className="col-md-8">
                                                        <input disabled={this.state.fields.phone} required
                                                               value={this.state.user.phone} type="text"
                                                               onChange={event => this.setState({
                                                                   user: {
                                                                       first_name: this.state.user.first_name,
                                                                       phone: event.target.value,
                                                                       last_name: this.state.user.last_name,
                                                                       address: this.state.user.address,
                                                                       profile_photo: this.state.user.profile_photo,
                                                                       email: this.state.user.email,
                                                                       next_of_kin_name: this.state.user.next_of_kin_name,
                                                                       next_of_kin_phone: this.state.user.next_of_kin_phone
                                                                   }
                                                               })}
                                                               className="form-control"
                                                               placeholder="Phone Number"/>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="inputAddress"
                                                           className="col-md-4 col-form-label">Address</label>
                                                    <div className="col-md-8">
                                                        <input disabled={this.state.fields.address} required
                                                               value={this.state.user.address} type="text"
                                                               onChange={event => this.setState({
                                                                   user: {
                                                                       first_name: this.state.user.first_name,
                                                                       phone: this.state.user.phone,
                                                                       last_name: this.state.user.last_name,
                                                                       address: event.target.value,
                                                                       profile_photo: this.state.user.profile_photo,
                                                                       email: this.state.user.email,
                                                                       next_of_kin_name: this.state.user.next_of_kin_name,
                                                                       next_of_kin_phone: this.state.user.next_of_kin_phone
                                                                   }
                                                               })}
                                                               className="form-control"
                                                               placeholder="Phone Number"/>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="inputEmail3"
                                                           className="col-md-4 col-form-label">Next of Kin Full
                                                        Name</label>
                                                    <div className="col-md-8">
                                                        <input disabled={this.state.fields.next_name} required
                                                               value={this.state.user.next_of_kin_name}
                                                               type="text"
                                                               onChange={event => this.setState({
                                                                   user: {
                                                                       first_name: this.state.user.first_name,
                                                                       phone: this.state.user.phone,
                                                                       last_name: this.state.user.last_name,
                                                                       address: this.state.user.address,
                                                                       profile_photo: this.state.user.profile_photo,
                                                                       email: this.state.user.email,
                                                                       next_of_kin_name: event.target.value,
                                                                       next_of_kin_phone: this.state.user.next_of_kin_phone
                                                                   }
                                                               })}
                                                               className="form-control"
                                                               placeholder="Full Name"/>
                                                        <small>{'<surname> <first name> and or <other names>'}</small>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="inputEmail3"
                                                           className="col-md-4 col-form-label">Next of Kin Phone
                                                        Number</label>
                                                    <div className="col-md-8">
                                                        <input disabled={this.state.fields.next_phone} required
                                                               value={this.state.user.next_of_kin_phone}
                                                               type="text"
                                                               onChange={event => this.setState({
                                                                   user: {
                                                                       first_name: this.state.user.first_name,
                                                                       phone: this.state.user.phone,
                                                                       last_name: this.state.user.last_name,
                                                                       address: this.state.user.address,
                                                                       profile_photo: this.state.user.profile_photo,
                                                                       email: this.state.user.email,
                                                                       next_of_kin_phone: event.target.value,
                                                                       next_of_kin_name: this.state.user.next_of_kin_name
                                                                   }
                                                               })}
                                                               className="form-control"
                                                               placeholder="Phone Number"/>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="inputPassword3"
                                                           className="col-md-4 col-form-label"/>
                                                    <div className="col-md-8">
                                                        <Link to="/account/update-password">
                                                            Change Password
                                                        </Link>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <h3>Account Information</h3>
                                                <hr/>
                                                <div className="form-group row">
                                                    <label
                                                        className="col-md-4 col-form-label">Bank
                                                        Name</label>
                                                    <div className="col-md-8">
                                                        <select disabled={this.state.fields.bank_code} required
                                                                className="form-control"
                                                                value={this.state.account_bank}
                                                                onChange={event => this.setState({
                                                                    account_bank: event.target.value,
                                                                    button_state: 'Retrieve Account Detail',
                                                                    account_changed: true
                                                                })}>
                                                            <option value="">---- Select Bank ----</option>
                                                            {this.state.bank_list.map(item => (
                                                                <option key={uuidv1()}
                                                                        value={item.code}>{item.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="inputEmail3"
                                                           className="col-md-4 col-form-label">Account Name</label>
                                                    <div className="col-md-8">
                                                        <input readOnly={true} required
                                                               type="text"
                                                               value={this.state.account_name}
                                                               onChange={event => this.setState({account_name: event.target.value})}
                                                               className="form-control text-capitalize border-unset"
                                                               placeholder="Account Name"/>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label
                                                        className="col-md-4 col-form-label">Account Number</label>
                                                    <div className="col-md-8">
                                                        <input disabled={this.state.fields.account_number} minLength="9"
                                                               required
                                                               type="number"
                                                               value={this.state.account_number}
                                                               onChange={event => this.setState({
                                                                   account_number: event.target.value,
                                                                   button_state: 'Retrieve Account Detail',
                                                                   account_changed: true
                                                               })}
                                                               className="form-control"
                                                               placeholder="Enter Account Number"/>
                                                    </div>
                                                </div>
                                                <hr/>
                                                {(this.state.add_usd) ? (
                                                    <button type="button" disabled={this.state.fields.usd_fields}
                                                            className="btn badge badge-primary btn-sm mb-3"
                                                            onClick={event => this.setState({
                                                                add_usd: false,
                                                                account_changed: true
                                                            })}>Remove dollar account</button>
                                                ) : (
                                                    <button type="button" disabled={this.state.fields.usd_fields}
                                                            className="btn badge badge-primary btn-sm"
                                                            onClick={event => this.setState({
                                                                add_usd: true,
                                                                account_changed: true
                                                            })}>Add domicilliary account</button>
                                                )}
                                                {(this.state.add_usd) ? this.dollarPage() : ''}
                                                <hr/>
                                                <div className="form-group row">
                                                    <label className="col-md-4"/>
                                                    <div className="col-md-8">
                                                        {(this.state.fields.button_page === 1) ? (
                                                            <button onClick={event => this.requestOTP(event)}
                                                                    type="button" data-toggle="modal"
                                                                    data-target="#otpModal"
                                                                    className="btn btn-blue text-white btn-block">Update
                                                                Information</button>
                                                        ) : (
                                                            <button
                                                                className="btn btn-blue text-white btn-block">{this.state.button_state}</button>
                                                        )}
                                                    </div>
                                                </div>
                                            </form>
                                            {/* {!this.context.user.bvn_verified ? 
                                            (<>
                                                <hr/>
                                                    <h3>Verify BVN <small className="verify_bvn_pop" data-toggle="popover" title="Why do I need to verify?" data-content="We verify your BVN for regulatory KYC purpose. We do not store your BVN but only verify it">Why do I need to verify? </small></h3>
                                                <hr/>
                                                <div className="card-body">
                                                    <form onSubmit={this.verifyBvn}>
                                                        <div className="form-group row">
                                                            <label className="col-4">Enter BVN</label>
                                                            <input onChange={event => this.setState({bvn: event.target.value})} 
                                                            className="form-control col-8" />
                                                        </div>
                                                        <br/>
                                                        <div className="form-group row">
                                                            <label className="col-4"/>

                                                            <button className="btn btn-blue text-white btn-block col-8">Verify</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </>) : 
                                            <>
                                                <hr/>
                                                    <h3>Verify BVN <small className="verify_bvn_pop" data-toggle="popover" title="Why do I need to verify?" data-content="We verify your BVN based on Know Your Customer (KYC) regulatory requirements for each user. We do not store your BVN information">Why do I need to verify? </small></h3>
                                                <hr/>
                                                <div className="card-body">
                                                    <h3 className="col-12 text-bold text-center">BVN successfully verified <i class="fa fa-check-circle text-green" aria-hidden="true"></i></h3>
                                                </div>
                                            </>

                                            } */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Row className="justify-content-md-center">
                    <Col sm={8}>
                        <IdentificationView />
                    </Col>
                </Row>
                <div id="otpModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h4 className="modal-title" id="example-Modal3">OTP</h4>
                                <button onClick={event => this.setState({otp: ''})} id="close-modal"
                                        type="button"
                                        className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <form onSubmit={event => this.processOtp(event)}>
                                <div className="modal-body">
                                    <div className="form-group mb-0">
                                        <label htmlFor="message-text"
                                               className="form-control-label">Enter OTP:</label>
                                        <input onChange={event => this.setState({otp: event.target.value})}
                                               type="number" className="form-control"
                                               placeholder="Enter opt code sent to your mail"/>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <div className="col-lg-12 mx-auto text-center">
                                        <button onClick={event => this.setState({otp: ''})}
                                                type="button"
                                                className="btn btn-outline-green mr-3"
                                                data-dismiss="modal">Close
                                        </button>
                                        <button type="submit" className="btn btn-main">Continue</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default Profile;

