import React from 'react'
import API from '../../api'
import $ from 'jquery'

import {Link} from 'react-router-dom';
import ReactTooltip from 'react-tooltip'

import Layout from '../Layout'

import {limitTo} from '../../_helpers/utils';
import {hideLoader, mixpanel, showLoader, toast, trackMixpanel} from "../../_helpers/Loader";
import {Context} from "../MyContext";
import Moment from "react-moment";

class MarketPlace extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            investments: [],
            all_investments: [],
            level: '',
        }
    }

    async componentDidMount() {
        trackMixpanel('SubInvestorMarketplaceView', false, false, this.context.user.email)
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
        showLoader();
        await this.getInvestments();
        hideLoader();
    }


    componentWillMount() {
        if (this.context.user.completed_onboarding === null || this.context.user.completed_onboarding === undefined) {
            window.location.reload();
        }
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
    }

    async getInvestments() {
        try {
            let res = await API.get('investments/list_investments/');
            this.setState({investments: res.data, all_investments: res.data})
        } catch (e) {
            hideLoader();
            toast.error("Something Went Wrong");
            // console.log(e.response)
        }
    }

    category = (level) => {
        showLoader();
        this.setState({level: level});
        if (level === 'all') {
            this.setState({investments: this.state.all_investments})
        } else {
            this.setState({investments: [...this.state.all_investments.filter(item => item.risk_level.toLowerCase() === level)]})
        }
        hideLoader();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        let cards = $(".card");
        let height = 0;
        cards.map((ind, item) => {
            ($(item).height() > height) && (height = $(item).height());
        });
        cards.height(height);
    }

    investmentCard(item) {
        const completion_rate = (parseInt(item.completion_rate) > 100) ? 100 : parseInt(item.completion_rate);
        const rate_if = (completion_rate < 100) ? 'bg-yellow' : 'bg-green';
        const status_color = (item.status.toLowerCase() === 'active') ? 'bg-green' : ((item.status.toLowerCase() === 'sold out') ? 'bg-orange' : ((item.status.toLowerCase() === 'coming soon') ? 'bg-yellow' : 'bg-danger'));
        const risk_color = (item.risk_level.toLowerCase() === 'low') ? 'bg-green' : (item.risk_level.toLowerCase() === 'medium') ? 'bg-yellow' : 'bg-danger';
        return (
            <div className="col-md-6 col-sm-12 col-lg-4 col-xl-4" key={item.id}>
                <div className="card">
                    {(completion_rate >= 100) ? (
                        <div className="eo2-sold-out js-sold-out-btn">
                            <a href="#">
                                <img src="/assets/img/sold_out_tag.png"/>
                            </a>
                        </div>
                    ) : ''}
                    <div className="img-container">
                        <img className="img-crop-sm " src={item.image} alt=""/>
                        <div className="overlay text-center">
                        </div>

                        <div className="text-overlay text-right">
                            {(completion_rate >= 100) ? '' : (
                                <span
                                    className={`badge ${(completion_rate >= 100) ? 'bg-orange' : status_color} m-b-5 text-capitalize`}>{(completion_rate >= 100) ? 'Sold Out' : item.status == 'coming soon' ? 'Upcoming Offer' : item.status }</span>
                            )}{(completion_rate >= 100) ? '' : (<br/>)}
                            <span className={`badge ${risk_color} m-b-5 text-capitalize`}>{item.risk_level} Risk</span>
                        </div>
                        <div className="text-overlay-left-bottom text-left">
                            {item.in_trust ? (<i className="fas fa-shield-alt text-dark"/>) : ''}
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row mb-2">
                            <div className="col-sm-12">
                                <h5 style={{fontWeight: 700}}>{item.title}</h5>
                                <p><b>By {item.company}</b></p>
                                <p className="text-justify">
                                    {item.description.substring(0, 100).replace(/<\/?[^>]+(>|$)/g, "")} . . .
                                </p>
                            </div>
                            <div className="col-sm-12 mb-4">
                                <div className="progress">
                                    <div className={`progress-bar progress-bar-striped ${rate_if}`} role="progressbar"
                                         style={{width: `${completion_rate}%`}}
                                         aria-valuenow={completion_rate} aria-valuemin="0"
                                         aria-valuemax="100">{completion_rate}%
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <p><strong>Trade Cycle: </strong>{item.trade_cycle}</p>
                            </div>
                            <div className="col-sm-12">
                                <p><strong>Tenor: </strong>{item.maturity_cycle} Months</p>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-sm-12 col-lg-6 col-xl-6 col-md-12">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <strong>ROI</strong>
                                    </div>
                                    <div className="col-sm-12">
                                        {parseFloat(item.inv_roi)}% {item.tenor_type == "p.a" ? "Per Annum" : "Flat"}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-6 col-xl-6 col-md-12">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <strong>Per Unit</strong>
                                    </div>
                                    <div className="col-sm-12">
                                        {item.currency === "USD" ? '$' : '₦'}{parseFloat((item.unit_price)).toLocaleString()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 text-center mx-auto">
                                <Link to={`/sub-investor/${this.context.sub_investor_id}/marketplace/${item.id}`}>
                                    {/* <button className="btn btn-blue text-white text-center"
                                            disabled={item.currency !== "NGN"}>Learn More
                                    </button> */}
                                    <button className="btn btn-blue text-white text-center">{ item.status.toLowerCase() !== "coming soon" ? 'Learn More' : 'Show Interest' } </button>
                                </Link>
                            </div>
                        </div>
                        {(item.investment_type === 'real estate') ? (
                            <div className="row">
                                <div className="col-sm-12 text-center">
                                    <p className="text-orange mt-3"><sup>**</sup>Note: Card transactions only</p>
                                </div>
                            </div>
                        ) : ''}
                    </div>
                </div>
            </div>
        )
    }

    checkAdmin(item) {
        if (item !== undefined) {
            for (let i = 0; i < item.length; i++) {
                if (item[i].user === this.context.user.id) {
                    return true;
                }
            }
            return false
        } else {
            return false
        }
    }

    render() {
        return (
            <Layout active_menu={'market'} user_state={this.props.match.params.id} context={this.context}>
                <ReactTooltip/>
                <div className="section">
                    <div className="breadcrumb">
                        <h5>Offers</h5>
                    </div>
                    <div className="row mb-5 tab-buttons">
                        <div className="col-sm-12">
                            <ul className="nav nav-pills pb-3" id="myTab3" role="tablist">
                                <li className="nav-item">
                                    <a onClick={event => this.category('all')} className="nav-link active show"
                                       id="home-tab3" data-toggle="tab" href="#home3" role="tab"
                                       aria-controls="home" aria-selected="false">All Investments</a>
                                </li>
                                <li className="nav-item">
                                    <a onClick={event => this.category('low')} className="nav-link" id="home-tab3"
                                       data-toggle="tab" href="#home3" role="tab"
                                       aria-controls="home" aria-selected="false">Low Risk Investments</a>
                                </li>
                                <li className="nav-item">
                                    <a onClick={event => this.category('medium')} className="nav-link" id="profile-tab3"
                                       data-toggle="tab" href="#profile3"
                                       role="tab" aria-controls="profile" aria-selected="false">Medium Risk
                                        Investments</a>
                                </li>
                                <li className="nav-item">
                                    <a onClick={event => this.category('high')} className="nav-link" id="contact-tab3"
                                       data-toggle="tab"
                                       href="#contact3" role="tab" aria-controls="contact"
                                       aria-selected="true">High Risk Investments</a>
                                </li>
                            </ul>
                        </div>
                        {this.state.level === 'medium' ? (
                            <div className="col-sm-6 mt-5">
                                <h4 className="">Features</h4>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item"><span className="fa fa-check"/> <strong>Investor
                                        Profile:</strong> Moderate
                                    </li>
                                    <li className="list-group-item"><span className="fa fa-check"/> <strong>Risk
                                        Level:</strong> Medium - High
                                    </li>
                                    <li className="list-group-item"><span className="fa fa-check"/> <strong>Great for
                                        passive income</strong></li>
                                </ul>
                            </div>
                        ) : ((this.state.level === 'low') ? (
                            <div className="col-sm-6 mt-5">
                                <h4 className="">Features</h4>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item"><span className="fa fa-check"/> <strong>Investor
                                        Profile:</strong> Conservative
                                    </li>
                                    <li className="list-group-item"><span className="fa fa-check"/> <strong>Risk
                                        Level:</strong> Low
                                    </li>
                                    <li className="list-group-item"><span className="fa fa-check"/> <strong>Great for
                                        long term investment</strong></li>
                                </ul>
                            </div>
                        ) : ((this.state.level === 'high') ? (
                            <div className="col-sm-6 mt-5">
                                <h4 className="">Features</h4>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item"><span className="fa fa-check"/> <strong>Investor
                                        Profile:</strong> Aggressive
                                    </li>
                                    <li className="list-group-item"><span className="fa fa-check"/> <strong>Risk
                                        Level:</strong> High
                                    </li>
                                    <li className="list-group-item"><span className="fa fa-check"/> <strong>Great for
                                        passive income</strong></li>
                                </ul>
                            </div>
                        ) : ''))}
                    </div>
                    <div className="row">
                        {this.state.investments.map(item => (
                            this.investmentCard(item)
                        ))}
                    </div>
                </div>
            </Layout>
        );
    }
}

export default MarketPlace
