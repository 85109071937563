import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Layout from "../Layout";
import {Context} from "../MyContext";
import NumberFormat from "react-number-format";
import API from '../../api';
import {hideLoader, mixpanel, showLoader, toast, trackMixpanel} from "../../_helpers/Loader";
import Moment from "react-moment";
import $ from "jquery";
import {Helmet} from "react-helmet";
import PdfViewer from './fileViewer/pdfViewer';


class ManageFiles extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            files: [],
            selected: {},
            form: {},
            pdf: false
        }

    }

    async componentDidMount() {
        trackMixpanel('ManageFileView', false, false, this.context.user.email)
        showLoader();
        await this.getFiles()
        hideLoader();
    }

    getFiles = async () => {
        try {
            const res = await API.get('users/manage_files/')
            this.setState({files: res.data})
        } catch (e) {

        }
    }

    handleUpload = async event => {
        event.preventDefault();
        const postData = this.state.form;
        postData.id = this.state.selected.id;
        showLoader();
        try {
            const res = await API.post(`users/upload_manage_files/`, postData);
            this.setState({files: res.data})
            $('.modal').modal('hide');
            $(document.body).removeClass('modal-open');
            $('.modal-backdrop').remove();
            toast.success("Upload Successful")
        } catch (e) {
            // console.log(e)
            toast.error("Error uploading file")
        }
        hideLoader();
    }

    imageUpload = (event) => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({
                    form: {...this.state.form, file: e.target.result}
                });
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    render() {
        return (
            <Layout active_menu={'files'} user_state={'personal'} context={this.context}>
                <Helmet>
                    <title>Manage Files | Twelvevest</title>
                    {/*Hotjar Tracking Code for https://dashboard.twelvevest.com*/}
                </Helmet>
                { !this.state.pdf ? <div className="section">
                    <div className="breadcrumb">
                        Files
                    </div>
                    <div className="row" id="investment">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="float-left">My Files</h4>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <div id="example_wrapper"
                                             className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table
                                                        className="table table-striped table-bordered border-t0 text-nowrap w-100 dataTable no-footer"
                                                        role="grid" aria-describedby="example_info">
                                                        <thead>
                                                        <tr role="row">
                                                            <th className="wd-15p sorting_asc" tabIndex="0"
                                                                aria-controls="example" rowSpan="1" colSpan="1"
                                                                aria-sort="ascending"
                                                                aria-label="First name: activate to sort column descending"
                                                                style={{width: "231px"}}>Name of Investment
                                                            </th>
                                                            <th className="wd-15p sorting_asc" tabIndex="0"
                                                                aria-controls="example" rowSpan="1" colSpan="1"
                                                                aria-sort="ascending"
                                                                aria-label="First name: activate to sort column descending"
                                                                style={{width: "231px"}}>Document Title
                                                            </th>
                                                            <th className="wd-15p sorting" tabIndex="0"
                                                                aria-controls="example"
                                                                rowSpan="1" colSpan="1"
                                                                aria-label="Last name: activate to sort column ascending"
                                                                style={{width: "113px"}}>Description
                                                            </th>
                                                            {/* <th className="wd-20p sorting" tabIndex="0"
                                                                aria-controls="example"
                                                                rowSpan="1" colSpan="1"
                                                                aria-label="Position: activate to sort column ascending"
                                                                style={{width: "113px"}}>Status
                                                            </th> */}
                                                            <th className="wd-20p sorting" tabIndex="0"
                                                                aria-controls="example"
                                                                rowSpan="1" colSpan="1"
                                                                aria-label="Position: activate to sort column ascending"
                                                                style={{width: "113px"}}>Date
                                                            </th>
                                                            <th className="wd-20p sorting" tabIndex="0"
                                                                aria-controls="example"
                                                                rowSpan="1" colSpan="1"
                                                                aria-label="Position: activate to sort column ascending"
                                                                style={{width: "113px"}}>Action
                                                                {/*    upload signed document*/}
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {this.state.files.map(item => (
                                                            <tr>
                                                                <td>{(item.investment&&item.investment.title) || item.filename}</td>
                                                                <td>{item.filename}</td> 
                                                                <td>
                                                                    <a href="#" data-target="#descModal"
                                                                       data-toggle="modal"
                                                                       onClick={event => this.setState({selected: item})}
                                                                    >View Description</a>
                                                                </td>
                                                                {/* <td>
                                                                    <span
                                                                        className="badge badge-orange text-capitalize">{item.status}</span>
                                                                </td> */}
                                                                <td>
                                                                    <Moment
                                                                        format="MMM D, Y">{item.created_at}</Moment>
                                                                </td>
                                                                <td>
                                                                    {item.is_not_downloadable ?    (<span
                                                                        className="badge btn-blue text-white pointer-cursor mr-2"><a
                                                                        className="text-white" target="_blank"
                                                                        rel="noopener noreferrer"
                                                                         onClick={e => this.setState({
                                                                            pdf: item.file
                                                                         })     
                                                                    }>View Document</a>
                                                                    </span>):(<span
                                                                        className="badge btn-blue text-white pointer-cursor mr-2"><a
                                                                        className="text-white" target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        href={item.file}>Download File</a>
                                                                    </span>)
                                                                    }

                                                                    {!item.is_not_downloadable ?
                                                                    
                                                                        (item.status === 'awaiting upload' || item.status === 'disapproved') ? (
                                                                            <span data-toggle="modal"
                                                                                onClick={event => this.setState({selected: item})}
                                                                                data-target="#uploadModal"
                                                                                className="badge badge-orange pointer-cursor">Upload File</span>
                                                                        ) : (
                                                                            <span
                                                                                className="badge btn-blue text-white pointer-cursor"><a
                                                                                className="text-white"
                                                                                href={item.uploaded_file}>Download Uploaded File</a></span>
                                                                        ) : ""
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : <PdfViewer pdf={this.state.pdf} back={true} />}
                {/* Modal */}
                <div className="modal fade" id="descModal" tabindex="-1" role="dialog"
                     aria-labelledby="descModalTitle" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="descModalTitle">Description</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {this.state.selected.description}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal" id="uploadModal" tabIndex="-1" role="dialog"
                     aria-labelledby="uploadModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h5 className="modal-title" id="uploadModalLabel">Upload
                                    To: <strong>{this.state.selected.filename}</strong></h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <form onSubmit={event => this.handleUpload(event)}>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label>File</label>
                                        <input required accept="image/*" id="get-image"
                                               onChange={event => this.imageUpload(event)}
                                               type="file"
                                               className="form-control"/>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close
                                    </button>
                                    <button type="submit" className="btn btn-primary">Save changes</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default ManageFiles;
