import React, {useState} from 'react';
import { OKRA_PUBLIC_KEY, OKRA_TOKEN } from '../../../env';
import {hideLoader, showLoader, toast} from "../../../_helpers/Loader";
import API from '../../../api';

const BankStatement = (props) => {

  const [connect, setConnect] = useState(false);

  const callIncomeApi = async (responseId) => {
    showLoader();
    try {
        let res = await API.post(`users/call_okra_to_process_income/`, {"customer_id": responseId});
        console.log(res.data, "res:::-->>>")
        hideLoader();
    } catch (e) {
        toast.error("Could not fetch income details")
        hideLoader();
    }
  }

  function buildWithOptions(){
    window.Okra.buildWithOptions({
      name: 'Twelve',
      env: 'production',
      key: OKRA_PUBLIC_KEY,
      token: OKRA_TOKEN,
      products: ['auth'],
      onSuccess: async function(data){
        console.log('data', data)
        // console.log(data, data.customer_id)
        setConnect(true)
        //add the okra customer id to the response so that it can be used to get/process the income later
        props.checkifNotEmpty(true)
        props.setAnswers({
          ...props.answers,
          [props.qtagsCurrent]: {"connectBank": {customer_id: data.customer_id}},
        })
        
        await callIncomeApi(data.customer_id)

      },
      onClose: function(){
        console.log('close widget')
      }
    })
}
  return (
    <>
      {<p className='text-center'>{ connect? (<b className='text-green'>Bank Connected Successfully</b>) : (<><b>Please input details of your most active bank account to validate your income status.Your banking credentials are safe and secure. <a href="https://twelvevest.com/privacypolicy/" target="_blank">(Read our privacy policy here)</a></b><br/><br/><br/><button className="btn btn-primary" onClick={buildWithOptions}>Connect your bank</button></>)}</p>}
    </>
  )
}

export default BankStatement;