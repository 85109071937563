import React, {Component} from 'react';
import {Link} from 'react-router-dom'

import {authReducer} from '../../reducers/authReducers'

import {showLoader, hideLoader, toast} from "../../_helpers/Loader";

import {userService} from '../../_services';
import API from '../../api'


import LOGO from '../../assets2/img/logo-white.png'
import {authHeader} from "../../_helpers";
import {Context} from "../MyContext";


class CrossAuth extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        userService.logout();

        this.state = {
            email: '',
            password: '',
            submitted: false,
            loading: false,
            error: false
        };

    }

    async componentWillMount() {
        hideLoader();
        localStorage.clear();
        await this.context.clearState();
    }

    async componentDidMount() {
        await this.handleReAuth();
    }

    handleReAuth=async ()=> {

        showLoader();
        let make_data = {token: this.props.match.params.token}
        try {
            await localStorage.setItem('auth', JSON.stringify(make_data));
            await API.set_header(authHeader());
            console.log(localStorage)
            try {
                let res2 = await API.get('users/');
                // For some reason I couldn't really understand I needed to set item again
                await localStorage.setItem('auth', JSON.stringify(make_data));
                localStorage.setItem('user', JSON.stringify(res2.data[0]));
                localStorage.setItem('sub_investors', JSON.stringify(res2.data[0].user_subinvestor));
                try {
                    let res3 = await API.get('clubs/');
                    localStorage.setItem('clubs', JSON.stringify(res3.data));
                    this.context.resetState();
                    setTimeout(timeout => {
                        this.props.history.push('/');
                    }, 2000)
                } catch (e) {
                    hideLoader();
                    // console.log(e)
                }
            } catch (e) {
                hideLoader();
                console.log(e.response);
            }
        } catch (e) {
            if (e.response.status === 400) {
                this.setState({error: e.response.data.non_field_errors})
            } else {
                hideLoader();
                //console.log(e.response)
            }
            hideLoader()
        }
    }

    render() {
        return (
            <div id="app">
                <section className="section section-2">
                    <div className="row">
                        <div className="single-page single-pageimage construction-bg cover-image">
                            <div className="row">
                                <div className="col-lg-6 login-image">
                                    <div className="log-wrapper text-center img-container">
                                        <img src={LOGO} width="50%"
                                             className="mb-2 mt-4 mt-lg-0 mb-5" alt="logo"/>
                                        <div className="overlay text-center">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 ">
                                    <div className="wrapper wrapper2">
                                        <form onSubmit={this.handleSubmit} className="card-body form-body"
                                              tabIndex="500">
                                            <h3 className="text-dark">Login</h3>
                                            <div className="mail text0left">
                                                <input required
                                                       onChange={event => this.setState({email: event.target.value})}
                                                       type="email" name="mail"/>
                                                <label>Email Address</label>
                                            </div>
                                            <div className="passwd text0left">
                                                <input required
                                                       onChange={event => this.setState({password: event.target.value})}
                                                       type="password" name="password"/>
                                                <label>Password</label>
                                            </div>
                                            {(this.state.error) ? (
                                                <p className="text-left text-danger">{this.state.error}</p>
                                            ) : ''}
                                            <div className="submit">
                                                <input type="submit" value="Login" className="btn btn-blue text-white btn-block"/>
                                            </div>
                                            <p className="mb-2"><Link to="/forget-password">Forgot Password</Link></p>
                                            <p className="text-dark mb-0">Don't have an account?<Link to="/signup"
                                                                                                      className="ml-1">Sign
                                                Up</Link></p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default CrossAuth;
