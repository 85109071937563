import React from 'react'
import API from '../../api'
import {Link, withRouter} from 'react-router-dom'
import Moment from 'react-moment';
import ReactSafeHtml from 'react-safe-html'
import Layout from '../Layout'
import {hideLoader, showLoader, toast, Utils} from "../../_helpers/Loader";
import {Context} from "../MyContext";
import {PAYSTACK_publicKey, MONNIFY_contractCode, MONNIFY_APIKey} from "../../env";
import Fade from 'react-reveal/Fade';
import uuidv1 from 'uuid';

import $ from 'jquery';
import NumberFormat from "react-number-format";

const PaystackPop = window.PaystackPop;

class MarketPlaceDetails extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            investment: {},
            amount: '',
            page: 1,
            units: '',
            total: '',
            no_buy: true,
            paystack_key: '',
            ref_code: ''
        };
    }

    async componentDidMount() {
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
        this.setState({not_eligible: this.context.notification});
        showLoader();
        await this.getInvestment();
        await this.setState({user: this.context.user});
        // await this.getWalletInfo();
        // await this.getReviews();
        hideLoader();
    }

    componentWillMount() {
        if (this.context.user.completed_onboarding === null || this.context.user.completed_onboarding === undefined) {
            window.location.reload();
        }
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
    }

    async getInvestment() {
        try {
            let res = await API.get(`axa_mansard/get_mansard_investments/?id=${this.props.match.params.id}`);
            this.setState({investment: res.data})
            console.log(res.data);
        } catch (e) {
            toast.error("Something Went Wrong");
            // console.log(e.repsonse)
        }
    }

    getInvestmentQuote = async (event) => {
        showLoader();
        try {
            const res = await API.get(`axa_mansard/get_mansard_investment_quote/?id=${this.props.match.params.id}&amount=${this.state.amount}`)
            if (res.data.mansard.IsSuccessful) {
                this.setState({
                    units: res.data.mansard.ReturnedObject.NoOfUnits,
                    total: res.data.mansard.ReturnedObject.Amount
                })
                if (this.state.amount >= this.state.investment.MinimumAmount) {
                    this.setState({no_buy: false, paystack_key: res.data.pk, ref_code: res.data.ref_code});
                }
            } else {
                toast.info(res.data.mansard.Message)
            }
            hideLoader();
        } catch (e) {
            hideLoader()
        }
    }

    detailForm = async (event) => {
        event.preventDefault();
        const data = {fund_id: this.state.investment.ID, amount: this.state.amount}
        showLoader();
        this.payWithCard();

    }

    onChangeUnit(event) {
        if (event.target.value < 1) {
            this.setState({units: '', total: ''});
        } else {
            this.setState({units: event.target.value, total: event.target.value * this.state.investment.unit_price});
        }
    }

    onBack() {
        this.setState({page: this.state.page - 1})
    }

    redirect = () => {
        toast.info("Kindly update your information first");
        setTimeout(() => {
            this.props.history.push({
                pathname: '/profile',
                query: {back_url: this.props.location.pathname}
            })
        }, 3000);
    };

    payWithCard = () => {

        const script = document.createElement("script");

        script.src = "https://js.paystack.co/v1/inline.js";
        script.async = true;

        document.body.appendChild(script);
        var handler = PaystackPop.setup({
            key: this.state.paystack_key,
            email: this.state.user.email,
            amount: parseFloat(this.state.total) * 100,
            currency: "NGN",
            ref: this.state.ref_code, // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
            metadata: {
                custom_fields: [
                    {
                        display_name: "First Name",
                        variable_name: "firstname",
                        value: this.state.user.first_name,
                    }, {
                        display_name: "Last Name",
                        variable_name: "lastname",
                        value: this.state.user.last_name,
                    }, {
                        display_name: "Customer Number",
                        variable_name: "customer_phone",
                        value: this.state.user.phone,
                    }, {
                        display_name: "Investment",
                        variable_name: "investment_title",
                        value: `${this.state.investment.title} by ${this.state.investment.company}`
                    },
                ]
            },
            callback: (response) => {
                response.fund_id = this.props.match.params.id;
                response.amount = this.state.total;
                showLoader();
                if (
                    response.status === "success"
                ) {
                    // redirect to a success page
                    API.post('axa_mansard/make_payment/', response).then((res) => {
                        hideLoader();
                        toast.success("Successfully Completed Transaction");
                        this.props.history.push("/manage-portfolio")
                    }, function (err) {
                        hideLoader();
                        toast.error("Something Went Wrong")
                    })

                } else {
                    // redirect to a failure page.
                    hideLoader();
                    toast.error("Payment Error.")
                }
                //alert('success. transaction ref is ' + response.reference);
            },
            onClose: function () {
                toast.info("Payment Cancelled");
                hideLoader();
            }
        });
        handler.openIframe();
    };

    detail() {
        return (
            <Layout active_menu={'market'} context={this.context}>
                <Fade right={(this.state.animate)}>
                    <div className="section">
                        <div className="breadcrumb">
                            <Link to="/marketplace"><span className="fa fa-chevron-left"/> Offers</Link>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-lg-7 col-xl-7">
                                <div className="card">
                                    <div className="img-container mb-4 full-block">
                                        <img className="img-crop-md" src="/assets/img/axa.jpg" alt=""/>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <ReactSafeHtml
                                                    html={(this.state.investment.Description) ? this.state.investment.Description : this.state.investment.ShortDescription}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-lg-5 col-xl-5">
                                <div className="card">
                                    <div className="card-header bg-green">
                                        <strong>{this.state.investment.Name} by AXA Mansard</strong>
                                    </div>
                                    <div className="card-body">
                                        <dl className="row">
                                            <dt className="col-sm-6 text-right"><strong>Minimum Amount</strong></dt>
                                            <dd className="col-sm-6">₦{parseFloat(this.state.investment.MinimumAmount).toLocaleString()}</dd>

                                            <dt className="col-sm-6 text-right"><strong>Per Unit</strong></dt>
                                            <dd className="col-sm-6">₦{parseFloat(this.state.investment.PricePerUnit).toLocaleString()}</dd>
                                        </dl>
                                        <form onSubmit={this.detailForm} autoComplete="off">
                                            <div className="">
                                                <div className="form-group">
                                                    <label htmlFor="units">Amount</label>
                                                    <div className="input-group">
                                                        <NumberFormat thousandSeparator={true} prefix={'₦'} required
                                                                      onValueChange={(values) => {
                                                                          const {formattedValue, value} = values;
                                                                          this.setState({amount: value})
                                                                      }} className="form-control"
                                                                      placeholder="Enter Amount"
                                                                      value={this.state.amount}/>
                                                        <div className="input-group-append">
                                                            <button disabled={!(this.state.amount > 0)}
                                                                    onClick={event => this.getInvestmentQuote(event)}
                                                                    className="btn btn-blue text-white"
                                                                    type="button">Get Quote
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="amount">Total Units</label>
                                                    <input readOnly style={{color: 'black'}}
                                                           value={(this.state.units) ? parseFloat(this.state.units).toLocaleString() : this.state.units}
                                                           type="text" className="form-control" id="units"/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="amount">Total Amount</label>
                                                    <input readOnly style={{color: 'black'}}
                                                           value={(this.state.total) ? parseFloat(this.state.total).toLocaleString() : this.state.total}
                                                           type="text" className="form-control" id="total"/>
                                                </div>
                                                <button className="btn btn-blue text-white btn-block"
                                                        disabled={this.state.no_buy}>Buy {this.state.ref_code}
                                                </button>
                                            </div>
                                            {(this.state.units > 0 && this.state.not_eligible) ? (
                                                <button type="button" onClick={event => this.redirect()}
                                                        className="btn btn-blue text-white mt-1 mb-0 btn-block">Update Profile
                                                </button>
                                            ) : (this.state.total >= this.state.investment.unit_price) ? ((this.state.investment.status.toLowerCase() !== 'active' || this.state.completion_rate >= 100) ? (
                                                <button disabled type="button"
                                                        className="btn btn-blue text-white mt-1 mb-0 btn-block">Investment
                                                    Closed
                                                </button>
                                            ) : (
                                                <button type="submit"
                                                        className="btn btn-blue text-white mt-1 mb-0 btn-block">Continue
                                                </button>
                                            )) : ''}
                                        </form>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="col-md-12 col-sm-12 col-lg-7 col-xl-7">*/}
                            {/*    <div className="card">*/}
                            {/*        <div className="card-header bg-green">*/}
                            {/*            <strong>Reviews</strong>*/}
                            {/*        </div>*/}
                            {/*        <div className="card-body">*/}
                            {/*            <div className="row">*/}
                            {/*                <div className="col-md-12">*/}
                            {/*                    <div className="status-upload mt-2 mb-4 d-inline-block w-100">*/}
                            {/*                        <form onSubmit={this.createReview}>*/}
                            {/*                            {(this.state.comments.length < 1) ? (*/}
                            {/*                                <div className="mb-3">*/}
                            {/*                                    <strong>No reviews. <br/>*/}
                            {/*                                        Be the first to write a review on this*/}
                            {/*                                        investment</strong>*/}
                            {/*                                </div>*/}
                            {/*                            ) : ''}*/}
                            {/*                            <textarea value={this.state.review_msg} rows="5"*/}
                            {/*                                      maxLength="5000"*/}
                            {/*                                      required*/}
                            {/*                                      onChange={event => this.setState({review_msg: event.target.value})}*/}
                            {/*                                      className="form-control"*/}
                            {/*                                      placeholder="Add review here"/>*/}

                            {/*                            <div className="pull-left">*/}
                            {/*                                <small>{5000 - this.state.review_msg.length} Words Max*/}
                            {/*                                </small>*/}
                            {/*                            </div>*/}
                            {/*                            <button type="submit"*/}
                            {/*                                    className="btn btn-blue text-white btn-sm pull-right mt-2"> Post*/}
                            {/*                            </button>*/}
                            {/*                        </form>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*                <div className="col-md-12" id="reviews">*/}
                            {/*                    {this.state.comments.map(item => (*/}
                            {/*                        <div key={item.id}>*/}
                            {/*                            <div className="media mt-0">*/}
                            {/*                                <div className="media-left"><img*/}
                            {/*                                    src={(item.user.profile_photo) ? item.user.profile_photo : 'https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png'}*/}
                            {/*                                    alt=""*/}
                            {/*                                    className="media-object"/></div>*/}
                            {/*                                <div className="media-body">*/}
                            {/*                                    <h4 className="media-heading text-dark">{item.user.first_name} {item.user.last_name} {' '}*/}
                            {/*                                        <i className="fa fa-certificate text-green"/> Verified<br/>*/}
                            {/*                                        <small className="text-muted"><span*/}
                            {/*                                            className="fa fa-clock"/> <Moment*/}
                            {/*                                            format="LLLL">{item.modified_at}</Moment>*/}
                            {/*                                        </small>*/}
                            {/*                                    </h4>*/}
                            {/*                                    <p className="text-justify text-main">{item.body}</p>*/}
                            {/*                                    <ul className="nav nav-pills pull-left">*/}
                            {/*                                        <li><a id={`likeNum-${item.id}`} href="#"*/}
                            {/*                                               onClick={event => this.likeComment(event, item.id, $(`#likeNum-${item.id}`), 1)}><span*/}
                            {/*                                            className="fa fa-thumbs-up"></span> {(item.likes_num > 0) ? item.likes_num : ''}*/}
                            {/*                                        </a></li>*/}
                            {/*                                        <li><a href="" onClick={event => {*/}
                            {/*                                            event.preventDefault();*/}
                            {/*                                            $(`#replybox-${item.id}`).toggleClass("d-none");*/}
                            {/*                                        }}><span*/}
                            {/*                                            className="fa fa-share"></span> {(item.reply.length > 0) ? item.reply.length : ''}*/}
                            {/*                                        </a></li>*/}
                            {/*                                    </ul>*/}
                            {/*                                    <br/>*/}
                            {/*                                    {(item.reply.length > 0) ?*/}
                            {/*                                        <div>*/}
                            {/*                                            {item.reply.map(reply => (*/}
                            {/*                                                <div className="media mb-2">*/}
                            {/*                                                    <div className="media-left"><a*/}
                            {/*                                                        href="javascript:void(0)"> <img*/}
                            {/*                                                        alt="64x64"*/}
                            {/*                                                        src={(reply.user.profile_photo) ? reply.user.profile_photo : 'https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png'}*/}
                            {/*                                                        className="media-object"/>*/}
                            {/*                                                    </a></div>*/}
                            {/*                                                    <div className="media-body">*/}
                            {/*                                                        <h4 className="media-heading">{reply.user.first_name} {reply.user.last_name} {' '}*/}
                            {/*                                                            <span*/}
                            {/*                                                                className="fa fa-user-cog"/>*/}
                            {/*                                                        </h4>*/}
                            {/*                                                        <p>{reply.body}</p>*/}
                            {/*                                                        <ul className="nav nav-pills pull-left">*/}
                            {/*                                                            <li><a href="#"*/}
                            {/*                                                                   onClick={event => this.likeComment(event, item.id, $(`#likeNum-${item.id}`), 2)}*/}
                            {/*                                                            ><span*/}
                            {/*                                                                className="fa fa-thumbs-up"></span> {(reply.likes_num > 0) ? reply.likes_num : ''}*/}
                            {/*                                                            </a>*/}
                            {/*                                                            </li>*/}
                            {/*                                                            <li><a href="#"*/}
                            {/*                                                                   onClick={event => {*/}
                            {/*                                                                       event.preventDefault();*/}
                            {/*                                                                       $(`#replybox-${item.id}`).toggleClass("d-none");*/}
                            {/*                                                                   }}><span*/}
                            {/*                                                                className="fa fa-share"></span>*/}
                            {/*                                                            </a></li>*/}
                            {/*                                                        </ul>*/}
                            {/*                                                    </div>*/}
                            {/*                                                </div>*/}
                            {/*                                            ))}*/}
                            {/*                                        </div>*/}
                            {/*                                        : ''}*/}
                            {/*                                    <div className="col-md-12 d-none"*/}
                            {/*                                         id={`replybox-${item.id}`}>*/}
                            {/*                                        <div*/}
                            {/*                                            className="status-upload mt-2 mb-3 d-inline-block w-100">*/}
                            {/*                                            <form*/}
                            {/*                                                onSubmit={event => this.replyComment(event, item.id)}>*/}
                            {/*                                                <textarea id={`reply-text-${item.id}`}*/}
                            {/*                                                          className="form-control"*/}
                            {/*                                                          placeholder="Comment here"></textarea>*/}
                            {/*                                                <br/>*/}
                            {/*                                                <button type="submit"*/}
                            {/*                                                        className="btn btn-blue text-white btn-sm pull-right"> Reply*/}
                            {/*                                                </button>*/}
                            {/*                                            </form>*/}
                            {/*                                        </div>*/}
                            {/*                                    </div>*/}
                            {/*                                </div>*/}
                            {/*                            </div>*/}
                            {/*                            <hr/>*/}
                            {/*                        </div>*/}
                            {/*                    ))}*/}
                            {/*                    {(this.state.number_of_pages > 1) ? (*/}
                            {/*                        <div className="row">*/}
                            {/*                            <div className="dataTables_paginate paging_simple_numbers"*/}
                            {/*                                 id="example_paginate">*/}
                            {/*                                <ul className="pagination">*/}
                            {/*                                    {(this.state.review_page > 1 && this.state.number_of_pages > 1) ? (*/}
                            {/*                                        <li onClick={event => this.getMoreReview(this.state.review_page - 1)}*/}
                            {/*                                            className="paginate_button page-item previous"*/}
                            {/*                                            id="example_previous"><a*/}
                            {/*                                            aria-controls="example"*/}
                            {/*                                            data-dt-idx="0"*/}
                            {/*                                            tabIndex="0"*/}
                            {/*                                            className="page-link">Previous</a>*/}
                            {/*                                        </li>*/}
                            {/*                                    ) : ''}*/}
                            {/*                                    {this.createdArray(this.state.number_of_pages).map(item => (*/}
                            {/*                                        <li onClick={event => this.getMoreReview(item)}*/}
                            {/*                                            className={`paginate_button page-item ${(this.state.review_page === item) ? 'active' : ''}`}>*/}
                            {/*                                            <a aria-controls="example"*/}
                            {/*                                               data-dt-idx="1"*/}
                            {/*                                               tabIndex="0"*/}
                            {/*                                               className="page-link">{item}</a>*/}
                            {/*                                        </li>*/}
                            {/*                                    ))}*/}
                            {/*                                    {(this.state.number_of_pages > 1 && this.state.review_page < this.state.number_of_pages) ? (*/}
                            {/*                                        <li onClick={event => this.getMoreReview(this.state.review_page + 1)}*/}
                            {/*                                            className="paginate_button page-item next"*/}
                            {/*                                            id="example_next"><a aria-controls="example"*/}
                            {/*                                                                 data-dt-idx="6"*/}
                            {/*                                                                 tabIndex="0"*/}
                            {/*                                                                 className="page-link">Next</a>*/}
                            {/*                                        </li>*/}
                            {/*                                    ) : ''}*/}
                            {/*                                </ul>*/}
                            {/*                            </div>*/}
                            {/*                            <div className="col-12 text-center">*/}
                            {/*                                {(!this.state.review_page_end) ? ((this.state.comments.length > 0) ? (*/}
                            {/*                                        <button type="button"*/}
                            {/*                                                onClick={this.getMoreReview}*/}
                            {/*                                                className="btn btn-blue text-white text-white text-center">Load*/}
                            {/*                                            more*/}
                            {/*                                        </button>) : ('')*/}
                            {/*                                ) : (*/}
                            {/*                                    <p>No more comments.</p>*/}
                            {/*                                )}*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}
                            {/*                    ) : ''}*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </Fade>
            </Layout>
        )
    }

    lessBalance() {
        toast.info("Insufficient fund.");
        setTimeout(() => {
            toast.info("Add money to wallet.");
        }, 1000);
        setTimeout(() => {
            this.props.history.push({
                pathname: '/account/wallet',
                query: {back_url: this.props.location.pathname}
            })
        }, 2000);
    }

    summary() {
        return (
            <Layout active_menu={'market'} context={this.context}>
                <div className="section">
                    <div className="breadcrumb">
                        <a onClick={this.onBack}><span className="fa fa-chevron-left"></span> Back</a>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-lg-6 col-xl-6 mx-auto">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row mb-4">
                                        <div className="col-sm-12">
                                            <h4 className="text-center">Transaction Summary</h4>
                                            <p className="text-center">{this.state.investment.title} by {this.state.investment.company}</p>
                                        </div>

                                        <div className="col-sm-12">
                                            {(this.state.total && this.state.investment.club_tag) ? (
                                                <div className="col-sm-12 mx-auto mb-3 text-orange font-italic">
                                                    Note: This investment is paid
                                                    in {this.state.investment.maturity_cycle} installments at
                                                    NGN{parseFloat(this.state.total / this.state.investment.maturity_cycle).toLocaleString()} per
                                                    month.
                                                    You will have the opportunity to review transaction details
                                                    before
                                                    making a
                                                    payment.
                                                </div>) : ''
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-9 mx-auto">
                                            <dl className="row mx-auto">
                                                <dt className="col-sm-6 text-right"><strong>Unit Price</strong></dt>
                                                <dd className="col-sm-6">₦{parseFloat(this.state.investment.unit_price).toLocaleString()}</dd>

                                                <dt className="col-sm-6 text-right"><strong>Purchasing Unit</strong>
                                                </dt>
                                                <dd className="col-sm-6">{this.state.units}</dd>

                                                <dt className="col-sm-6 text-right"><strong>Total</strong></dt>
                                                <dd className="col-sm-6">₦{parseFloat(this.state.total).toLocaleString()}</dd>

                                                <dt className="col-sm-6 text-right"><strong>Amount To Pay</strong>
                                                </dt>
                                                <dd className="col-sm-6">₦
                                                    {
                                                        (this.state.investment.club_tag) ?
                                                            parseFloat(parseFloat(this.state.total / this.state.investment.maturity_cycle).toFixed(2)).toLocaleString() :
                                                            parseFloat(this.state.total).toLocaleString()
                                                    }
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-sm-12">
                                            <div className="form-check">
                                                <input onChange={event => this.setState({fraud: !this.state.fraud})}
                                                       className="form-check-input" type="checkbox"
                                                       checked={this.state.fraud}
                                                       id="defaultCheck1"/>
                                                <label className="form-check-label" htmlFor="defaultCheck1"><strong>
                                                    <sup className="text-danger">*</sup> Tick to confirm you are not
                                                    involved in money laundering to
                                                    continue.</strong>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            {(this.state.investment.card_payment) ? (
                                                <button disabled={!this.state.fraud}
                                                        onClick={event => this.payWithCard()}
                                                        className="btn btn-blue text-white btn-lg btn-block"><i
                                                    className="fa fa-credit-card"/> Pay with Card
                                                </button>
                                            ) : ''}
                                            {/* {(this.state.investment.transfer_payment) ? (
                                                <button disabled={!this.state.fraud}
                                                        onClick={event => this.payWithMonnify()}
                                                        className="btn btn-blue text-white btn-lg btn-block"><i
                                                    className="fa fa-bank"/> Pay with
                                                    Bank Transfer
                                                </button>
                                            ) : ''} */}
                                            {(this.state.investment.wallet_payment) ? ((this.state.balance >= this.state.total) ? (
                                                <button disabled={!this.state.fraud} data-toggle="modal"
                                                        data-target="#walletModal"
                                                        className="btn btn-blue text-white btn-lg btn-block"><i
                                                    className="fa fa-wallet"/> Pay with
                                                    Wallet
                                                </button>
                                            ) : (
                                                <button disabled={!this.state.fraud} data-toggle="modal"
                                                        data-target="#walletModal"
                                                        onClick={event => this.lessBalance}
                                                        className="btn btn-blue text-white btn-lg btn-block"><i
                                                    className="fa fa-wallet"/> Pay with
                                                    Wallet
                                                </button>
                                            )) : ''}
                                            {(this.state.investment.club_tag) ? (
                                                <small className="text-orange">Note: Transactions charges may
                                                    apply</small>
                                            ) : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="walletModal" tabIndex="-1" role="dialog"
                     aria-labelledby="walletModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h5 className="modal-title" id="exampleModalLabel">Confirm wallet transaction</h5>
                                <button id="close-modal" type="button" className="close" data-dismiss="modal"
                                        aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="mb-0">Wallet balance:
                                    ₦{parseFloat(this.state.balance).toLocaleString()}</p>
                                {(this.state.investment.club_tag) ? (
                                    <p className="mb-0">Balance after transaction:
                                        ₦{parseFloat(parseFloat(this.state.balance - (parseFloat(this.state.total) / parseFloat(this.state.investment.maturity_cycle))).toFixed(2)).toLocaleString()}</p>
                                ) : (
                                    <p className="mb-0">Balance after transaction:
                                        ₦{parseFloat(this.state.balance - this.state.total).toLocaleString()}</p>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-green mr-3"
                                        data-dismiss="modal">Cancel
                                </button>
                                {(this.state.investment.club_tag) ?
                                    ((this.state.balance - parseFloat(this.state.total) / parseFloat(this.state.investment.maturity_cycle)) >= 0) ? (
                                        <button onClick={event => this.walletAction(event)} type="button"
                                                className="btn btn-main"><i
                                            className="fas fa-wallet"/> Pay with wallet</button>
                                    ) : (
                                        <button onClick={event => {
                                            document.getElementById('close-modal').click();
                                            $('.modal-backdrop').remove();
                                            this.props.history.push("/account/wallet")
                                        }}
                                                type="button" className="btn btn-main"><i
                                            className="fas fa-wallet"/> Fund wallet</button>
                                    )
                                    : ((this.state.balance - this.state.total) >= 0) ? (
                                        <button onClick={event => this.walletAction(event)} type="button"
                                                className="btn btn-main"><i
                                            className="fas fa-wallet"/> Pay with wallet</button>
                                    ) : (
                                        <button onClick={event => {
                                            document.getElementById('close-modal').click();
                                            $('.modal-backdrop').remove();
                                            this.props.history.push("/account/wallet")
                                        }}
                                                type="button" className="btn btn-main"><i
                                            className="fas fa-credit-card"/> Fund wallet</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="bankModal" tabIndex="-1" role="dialog"
                     aria-labelledby="bankModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green text-center">
                                <h4 className="modal-title" id="bankModalLabel">Bank Transfer Details</h4>
                                <button id="close-modal" type="button" className="close" data-dismiss="modal"
                                        aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <dl className="row">
                                            <dt className="col-sm-6 text-right">Account Name</dt>
                                            <dd className="col-sm-6">
                                                <strong>{this.state.investment.title} by {this.state.investment.company}</strong>
                                            </dd>
                                            <dt className="col-sm-6 text-right">Account Number</dt>
                                            <dd className="col-sm-6">
                                                <strong>{this.state.bank_transfer_details.monnify_account_number}</strong>
                                            </dd>
                                            <dt className="col-sm-6 text-right">Bank</dt>
                                            <dd className="col-sm-6">
                                                <strong>{this.state.bank_transfer_details.monnify_account_bank}</strong>
                                            </dd>
                                            <dt className="col-sm-6 text-right">Expiry Datetime</dt>
                                            <dd className="col-sm-6">
                                                <strong>{this.state.bank_transfer_details.monnify_expiry_date}</strong>
                                            </dd>
                                            <dt className="col-sm-6 text-right">Invoice Number</dt>
                                            <dd className="col-sm-6">
                                                <strong>{this.state.bank_transfer_details.monnify_account_name}</strong>
                                            </dd>
                                        </dl>
                                        <p>Once payment is confirmed, you will get a notification about the
                                            completion
                                            of your
                                            purchase.</p>
                                        <p>Invoice is only valid before the expiry date and time.</p>
                                        <p>You can always create a new request, if you need to.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-blue text-white text-white" data-dismiss="modal">Close
                                </button>
                                <button onClick={event => {
                                    $('.close').click();
                                    $('.modal-backdrop').remove();
                                    this.props.history.push(`/manage-portfolio`)
                                }} type="button"
                                        className="btn btn-blue text-white">Check Portfolio
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }

    payWithBank() {
        return (
            <Layout active_menu={'market'} user_state={'personal'} context={this.context}>
                <div className="section">
                    <div className="breadcrumb">
                        <a onClick={this.onBack}><span className="fa fa-chevron-left"/> Back</a>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-lg-6 col-xl-6 mx-auto">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h4 className="text-center">Transaction Summary</h4>
                                            <p className="text-center">{this.state.investment.title} by {this.state.investment.company}</p>
                                            <hr style={{border: '2px solid #23414D'}}/>
                                        </div>

                                        <div className="col-sm-12">
                                            {(this.state.total && this.state.investment.club_tag) ? (
                                                <div className="col-sm-12 mx-auto mb-3 text-orange font-italic">
                                                    Note: This investment is paid
                                                    in {this.state.investment.maturity_cycle} installments at
                                                    NGN{parseFloat(this.state.total / this.state.investment.maturity_cycle).toLocaleString()} per
                                                    month.
                                                    You will have the opportunity to review transaction details
                                                    before
                                                    making a
                                                    payment.
                                                </div>) : ''
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <p className="text-center"><i className="text-dark">Please transfer
                                                funds to
                                                the account number below with the following
                                                code: <span
                                                    className="bg-orange text-main" style={{
                                                    fontSize: '20px',
                                                    padding: '4px',
                                                    paddingRight: '8px',
                                                    fontStyle: 'initial'
                                                }}>{this.state.bank_ref_code} </span></i>
                                            </p>
                                            <dl className="row">
                                                <dt style={{fontWeight: 100}}
                                                    className="col-sm-6 text-right">Account
                                                    Name
                                                </dt>
                                                <dd className="col-sm-6"><strong>Twelvevest</strong></dd>
                                                <dt className="col-sm-6 text-right">Account Number</dt>
                                                <dd className="col-sm-6"><strong>0825927041</strong></dd>
                                                <dt className="col-sm-6 text-right">Bank</dt>
                                                <dd className="col-sm-6"><strong>Access Bank</strong></dd>
                                            </dl>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12" onClick={this.confirmBank}>
                                            <button className="btn btn-blue text-white btn-block">Click to confirm</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }

    render() {
        if (this.state.page === 1) {
            return this.detail()
        } else if (this.state.page === 2) {
            return this.summary()
        } else if (this.state.page === 3 && this.state.payment_type === 'bank') {
            return this.payWithBank();
        }
    }
}


export default MarketPlaceDetails
