import React, {Component} from 'react';
import Layout from "../Layout";
import {Link} from "react-router-dom";
import API from "../../api";
import NumberFormat from "react-number-format";
import {hideLoader, showLoader, toast, trackMixpanel} from "../../_helpers/Loader";
import {Context} from "../MyContext";
import {Editor} from "react-draft-wysiwyg";
import {EditorState, convertToRaw, convertFromRaw, ContentState, convertFromHTML} from 'draft-js';
import draftToHtml from 'draftjs-to-html';

class ClubSettings extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            club: {},
            editorState: EditorState.createEmpty(),
        };

        this.updateClub = this.updateClub.bind(this);
    }

    componentWillMount() {
        if (this.context.user.completed_onboarding === null || this.context.user.completed_onboarding === undefined) {
            window.location.reload();
        }
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
    }

    async componentDidMount() {
        trackMixpanel('ClubSettingsView', false, false, this.context.user.email)
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
        showLoader();
        await this.getSettings();
        (!this.checkAdmin(this.state.club.club_members)) && this.props.history.push(`club/`);
        hideLoader();
    }

    async getSettings() {
        try {
            let res = await API.get(`clubs/${this.props.match.params.id}/`);
            this.setState({
                club: res.data,
                editorState: EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(res.data.description).contentBlocks))
            })
        } catch (e) {
            toast.error("Something Went Wrong");
            // console.log(e.response)
        }
    }

    async updateClub(event) {
        event.preventDefault();
        showLoader();
        const postData = {
            name: this.state.club.name,
            is_contributions: this.state.club.is_contributions,
            contribution_interval: (this.state.club.is_contributions) ? this.state.club.contribution_interval : null,
            contribution_amount: (this.state.club.is_contributions) ? this.state.club.contribution_amount : 0.0,
            networth_goal: this.state.club.networth_goal,
            description: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
        };
        if (postData.contribution_amount < 100 && postData.is_contributions) {
            toast.error('Contribution amount must be greater than a hundred');
            hideLoader();
            return false;
        }
        postData['description'] = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
        try {
            let res = await API.put('clubs/' + this.state.club.id + '/', postData);
            await this.context.updateClubs();
            toast.success("Club Details Updated Successfully");
            hideLoader();
            this.props.history.push(`/manage-club/${this.props.match.params.id}`);
        } catch (e) {
            hideLoader();
        }
    }

    checkAdmin(item) {
        if (item !== undefined) {
            for (let i = 0; i < item.length; i++) {
                if (item[i].user.id === this.context.user.id && item[i].user_role === 'admin') {
                    return true;
                }
            }
            return false
        } else {
            return false
        }
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    render() {
        const {editorState} = this.state;
        return (
            <Layout active_menu={'settings'} user_state={this.props.match.params.id}
                    club_admin={this.checkAdmin(this.state.club.club_admins)} context={this.context}>
                <div className="section">
                    <div className="breadcrumb">
                        <Link to={`/manage-club/${this.props.match.params.id}`}> <span
                            className="fa fa-chevron-left"/> Back</Link>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 col-xl-7 col-md-12 col-sm-12 mx-auto">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <h4>Club Details</h4>
                                            <p>Edit Club Details</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <form onSubmit={event => this.updateClub(event)}>
                                                <div className="">
                                                    <div className="form-group">
                                                        <label htmlFor="number">Club Name</label>
                                                        <input required value={this.state.club.name}
                                                               onChange={event => this.setState({
                                                                   club: {
                                                                       ...this.state.club,
                                                                       name: event.target.value
                                                                   }
                                                               })}
                                                               type="text" className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="number">Target Net worth</label>
                                                        <NumberFormat value={this.state.club.networth_goal}
                                                                      thousandSeparator={true} prefix={'₦'} required
                                                                      onValueChange={(values) => {
                                                                          const {formattedValue, value} = values;
                                                                          this.setState({
                                                                              club: {
                                                                                  ...this.state.club,
                                                                                  networth_goal: value
                                                                              }
                                                                          })
                                                                      }} className="form-control"
                                                                      placeholder="Enter Amount"/>
                                                    </div>
                                                    <div className="form-group row overflow-hidden">
                                                        <label className="col-sm-12 col-form-label">Description</label>
                                                        <div className="col-sm-12">
                                                            <Editor
                                                                editorState={editorState}
                                                                toolbar={options}
                                                                initialEditorState={editorState}
                                                                wrapperClassName="demo-wrapper"
                                                                editorClassName="demo-editor"
                                                                onEditorStateChange={this.onEditorStateChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit"
                                                        className="btn btn-blue text-white mt-1 mb-0 btn-block">
                                                    <strong>Update</strong>
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default ClubSettings;

let options = {
    options: ['inline', 'textAlign', 'fontSize', 'list', 'link', 'emoji', 'colorPicker', 'history'],
    inline: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'],
    },
    list: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['unordered', 'ordered'],
    },
    colorPicker: {
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
            'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
            'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
            'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
            'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
            'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
    },
    textAlign: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['left', 'center', 'right', 'justify'],
    },
    link: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        dropdownClassName: undefined,
        showOpenOptionOnHover: true,
        defaultTargetOption: '_self',
        options: ['link', 'unlink'],
        linkCallback: undefined
    },
    fontSize: {
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
    },
    emoji: {
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        emojis: [
            '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
            '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
            '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
            '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
            '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
            '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
            '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
            '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
            '✅', '❎', '💯',
        ],
    },
    history: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['undo', 'redo'],
        undo: {className: undefined},
        redo: {className: undefined},
    },
};
